import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

@Component({
  selector: 'lemon-dialog-header',
  templateUrl: './lemon-dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LemonDialogHeader {

  @Input()
    naytaSuljeRuksi = true

  @Input()
    naytaOtsikko = true

}
