import { Injectable, ErrorHandler } from '@angular/core'

import { LaskuTotalsPerCurrency } from '../../../_jaettu/model/lasku'

import { KayttajaService } from '../kayttaja.service'

import { BehaviorSubject, of } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { FirebaseLemonaid } from '../firebase-lemonaid.service'

export interface AsikkaanLaskusummatValuutalleAnimointejaVarten {
  avoinna: number
  eraantynyt: number
  avoinnaAlkuperainen: number
  eraantynytAlkuperainen: number
  valuutta: string
  valuuttaAlkuperainen: string
}

@Injectable()
export class LaskuSummatService {

  private summat: AsikkaanLaskusummatValuutalleAnimointejaVarten = {
    avoinna: 0,
    eraantynyt: 0,
    avoinnaAlkuperainen: 0,
    eraantynytAlkuperainen: 0,
    valuutta: 'EUR',
    valuuttaAlkuperainen: 'EUR'
  }

  private summatSubject = new BehaviorSubject<AsikkaanLaskusummatValuutalleAnimointejaVarten>(this.summat)
  summatObservable = this.summatSubject.asObservable()

  constructor(
    private errorHandler: ErrorHandler,
    private lemonaidFirebase: FirebaseLemonaid,
    private kayttajaService: KayttajaService
  ) {

    this.kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {

        if (kayttajanTiedot) {
          const uri = 'laskut/summat-new/' + kayttajanTiedot.asiakasAvain
          return this.lemonaidFirebase.databaseListenValue<LaskuTotalsPerCurrency>(uri).pipe(
            map(totals => {
              const suurin: { avoinna: number, eraantynyt: number, valuutta: string } = {
                avoinna: 0,
                eraantynyt: 0,
                valuutta: 'EUR'
              }
              if (totals && Object.keys(totals).length > 0) {
                for (const key of Object.keys(totals)) {
                  const value = totals[key]
                  if (value && suurin.avoinna + suurin.eraantynyt < value.avoinna + value.eraantynyt) {
                    suurin.avoinna = value.avoinna
                    suurin.eraantynyt = value.eraantynyt
                    suurin.valuutta = key
                  }
                }
              }
              if (suurin) {
                this.summat.avoinna = suurin.avoinna
                this.summat.eraantynyt = suurin.eraantynyt
                this.summat.valuutta = suurin.valuutta
              }
              return this.summat
            })
          )
        }

        // Ei käyttäjää, nollaa kaikki
        this.summat.avoinna = 0
        this.summat.eraantynyt = 0
        this.summat.avoinnaAlkuperainen = 0
        this.summat.eraantynytAlkuperainen = 0
        this.summat.valuutta = 'EUR'
        this.summat.valuuttaAlkuperainen = 'EUR'

        return of(this.summat)
      })
    ).subscribe(data => {
      this.summatSubject.next(data)
    })

  }

  public numerotRullattiin() {
    if (
      this.summat.avoinnaAlkuperainen !== this.summat.avoinna ||
      this.summat.eraantynytAlkuperainen !== this.summat.eraantynyt ||
      this.summat.valuuttaAlkuperainen !== this.summat.valuutta
    ) {
      this.summat.avoinnaAlkuperainen = this.summat.avoinna
      this.summat.eraantynytAlkuperainen = this.summat.eraantynyt
      this.summat.valuuttaAlkuperainen = this.summat.valuutta
      this.summatSubject.next(this.summat)
    }
  }

}
