import { Observable, Subject, combineLatest } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { ErrorHandler, Injectable } from '@angular/core'
import { MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog'
import { MatDialog } from '@angular/material/dialog'
import { ComponentType } from '@angular/cdk/portal'
import { BankDialogType } from 'app/_jaettu/model/banks'
import { KayttajaService } from './kayttaja.service'

export type LemonaidDialogs = 'tervetuloa' | 'uusi-sopimus' | 'tunnistautuminen-nets' | 'uudet-tuntemistiedot' | BankDialogType | 'mainos-vastaanota-laskuja'

export interface IPrioritizedDialogAndParameters<T> {
  dialog: IPrioritizedDialog<T>
  parameters: MatDialogConfig<T>
}

export interface IPrioritizedDialog<T> {
  identifier: LemonaidDialogs
  priority: number
  open(config: MatDialogConfig<T>): MatDialogRef<any, any>
  close()
  shouldOpenObservable(): Observable<null | IPrioritizedDialogAndParameters<T>>
}

export class PrioritizedDialog<D, T> implements IPrioritizedDialog<T> {

  priority: number
  identifier: LemonaidDialogs
  private _openedDialogRef: MatDialogRef<D, any> = null

  constructor(
    private _matDialog: MatDialog,
    private _shouldOpenObservable: Observable<null | T>,
    private _component: ComponentType<D>,
    priority: number,
    identifier: LemonaidDialogs
  ) {
    this.priority = priority
    this.identifier = identifier
  }

  open(config: MatDialogConfig<T>): MatDialogRef<D, any> {
    return this._matDialog.open(this._component, config)
  }

  close() {
    if (this._openedDialogRef) {
      if (this._openedDialogRef.getState() === MatDialogState.OPEN) {
        this._openedDialogRef.close()
      }
      this._openedDialogRef = null
    }
  }

  shouldOpenObservable(): Observable<IPrioritizedDialogAndParameters<T>> {
    return this._shouldOpenObservable.pipe(
      map(data => {
        if (data) {
          const retVal: IPrioritizedDialogAndParameters<T> = {
            dialog: this,
            parameters: data
          }
          return retVal
        }
        return null
      })
    )
  }

}

@Injectable()
export class AllowedDialogsService {

  private _openDialog: IPrioritizedDialogAndParameters<any> = null
  private _registeredDialogs: Map<LemonaidDialogs, IPrioritizedDialog<any>> = new Map()
  private _allowedDialogsSubject = new Subject<LemonaidDialogs[]>()

  public allowedDialogsObservable = this._allowedDialogsSubject.asObservable().pipe(
    startWith<LemonaidDialogs[]>(['tervetuloa', 'uusi-sopimus', 'tunnistautuminen-nets', 'uudet-tuntemistiedot', BankDialogType.GENERAL, BankDialogType.GENERAL_WO_HOLVI, BankDialogType.HOLVI_RENEW, BankDialogType.PSD2_RENEW, 'mainos-vastaanota-laskuja'])
  )

  constructor(
    private _dialog: MatDialog,
    private _errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService
  ) { }

  disableAllDialogs() {
    this._allowedDialogsSubject.next([])
  }
  onlyAllowAiia() {
    this._allowedDialogsSubject.next([BankDialogType.PSD2_RENEW])
  }
  onlyAllowTervetuloaOrSopimus() {
    this._allowedDialogsSubject.next(['tervetuloa', 'uusi-sopimus'])
  }
  onlyAllowTervetuloaOrTunnistaminen() {
    this._allowedDialogsSubject.next(['tervetuloa', 'tunnistautuminen-nets'])
  }
  allowAll() {
    this._allowedDialogsSubject.next(['tervetuloa', 'uusi-sopimus', 'tunnistautuminen-nets', 'uudet-tuntemistiedot', BankDialogType.GENERAL, BankDialogType.GENERAL_WO_HOLVI, BankDialogType.HOLVI_RENEW, BankDialogType.HOLVI_RENEW, BankDialogType.PSD2_RENEW, 'mainos-vastaanota-laskuja'])
  }
  onlyAllowHolvi() {
    this._allowedDialogsSubject.next([BankDialogType.HOLVI_RENEW])
  }

  registerDialog<D, T>(
    dialogClass: ComponentType<D>,
    identifier: LemonaidDialogs,
    priority: number,
    shouldOpenObservable: Observable<MatDialogConfig<T>>
  ) {
    if (this._showingStarted) {
      throw new Error('Can\'t register dialogs anymore, showing has already started!')
    }
    const dialog = new PrioritizedDialog(
      this._dialog,
      shouldOpenObservable,
      dialogClass,
      priority,
      identifier
    )
    this._registeredDialogs.set(dialog.identifier, dialog)
  }

  private _showingStarted = false
  startShowingDialogs() {

    if (this._showingStarted) {
      return
    }
    this._showingStarted = true

    const dialogObservables = combineLatest(Array.from(this._registeredDialogs.values()).map(d => d.shouldOpenObservable()))
    combineLatest([
      this._kayttajaService.kayttajanTiedotObservable, // .pipe(tap(() => console.log('this._kayttajaService.kayttajanTiedotObservable changed'))),
      dialogObservables, // .pipe(tap(() => console.log('dialogObservables changed'))),
      this.allowedDialogsObservable // .pipe(tap(() => console.log('allowedDialogsObservable changed')))
    ]).subscribe({
      next: ([kayttaja, dialogsOrNulls, allowedDialogs]) => {

        // console.log('HERE, kayttaja:', !!kayttaja)

        // If we have a dialog open
        if (this._openDialog) {

          // console.log('CLOSING')
          // But it's not allowed, close it
          if (!kayttaja || !allowedDialogs.includes(this._openDialog.dialog.identifier)) {
            this._openDialog.dialog.close()
            // console.log('CLOSED 1 ' + this._openDialog.dialog.identifier)
            this._openDialog = null
          }
        }

        // If we don't have an open dialog, open the most prioritized one that is currently allowed
        if (kayttaja && !this._openDialog) {
          const filtered = dialogsOrNulls.filter(d => d && allowedDialogs.includes(d.dialog.identifier))
          if (filtered.length) {
            const theOneToShow = filtered.reduce((p, c) => p.dialog.priority > c.dialog.priority ? p : c)
            const opened = theOneToShow.dialog.open(theOneToShow.parameters)
            // console.log('OPEN', theOneToShow.dialog.identifier)
            opened.afterClosed().subscribe(() => {
              this._openDialog.dialog.close()
              // console.log('CLOSED 2 ' + this._openDialog.dialog.identifier)
              this._openDialog = null
            })
            this._openDialog = theOneToShow
          }
        }

      },
      error: (err) => {
        this._errorHandler.handleError(err)
      }
    })

  }

}
