<mat-dialog-content>

  <lemon-dialog-header>
    <div>{{'maksut.lopeta-vastaanotto-dialog.otsikko' | translate | async}}</div>
  </lemon-dialog-header>

  <p style="margin: 3em 3em 2em 3em;">{{'maksut.lopeta-vastaanotto-dialog.teksti' | translate | async}}</p>

</mat-dialog-content>

<mat-dialog-actions style="margin: 30px; margin-top: 12.5px; justify-content: center;">
  <button class="secondarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
  <button class="primarybutton" mat-button (click)="tallennaVastaanotonLopetus()" cdkFocusRegionstart>{{'maksut.lopeta-vastaanotto-dialog.lopeta' | translate | async}}</button>
</mat-dialog-actions>