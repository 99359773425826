<h1 class="title">
  {{'raportit.virallinen-tulos' | translate | async}}
</h1>
<ng-container *ngIf="tuloslaskelmanDataObservable | async; let data">
  <ng-container *ngIf="!(loadingSubject | async) else loading">
    <!-- <div *ngIf="data.e == 'a'">
      Valittu päivämääräväli ei ole millään Lemonatorista löytyvällä tilikaudella.
    </div> -->
    <div *ngIf="lastSucessfullyUpdated" class="gray-text" style="font-size: 11px; text-align: right; line-height: 13px;">{{ 'yleiset.paivitetty' | translate | async }} {{ lastSucessfullyUpdated | ltimestamptime | async }}</div>
    <table *ngIf="data.c == 'c2'" class="report-table">
      <thead>
        <tr>
          <th></th>
          <th>{{data.c1Header}}</th>
          <th>{{data.c2Header}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.data; trackBy: trackAccountRowByAccountNumberFn" class="report-row-lvl-{{row.l}}" [class.report-row-summary]="row.s">
          <td class="n">{{row.n}}</td>
          <td>{{row.s1 | lmoney | async }}</td>
          <td>{{row.s2 | lmoney | async }}</td>
        </tr>
      </tbody>
    </table>
    <table *ngIf="data.c == 'c1'" class="report-table">
      <thead>
        <tr>
          <th></th>
          <th>{{data.c1Header}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.data; trackBy: trackAccountRowByAccountNumberFn" class="report-row-lvl-{{row.l}}" [class.report-row-summary]="row.s">
          <td class="n">{{row.n}}</td>
          <td>{{row.s1 | lmoney | async }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>