<div class="kapea hyvaksyminen-listaus-page" style="padding: 0;">

  <div class="center-text-block">
    <h2 class="title">{{ 'tositteet.laskujen-hyvaksyminen.laskujen-hyvaksyminen' | translate | async }}</h2>

    <div>
      {{ 'tositteet.laskujen-hyvaksyminen.saapuneet-verkkolaskut' | translate | async }}
      <br />
      {{ 'tositteet.laskujen-hyvaksyminen.kun-hyvaksyt-laskun' | translate | async }}
    </div>

  </div>

  <div class="gray-icon-and-text" *ngIf="showNoUnapprovedObservable | async; else unapproved">
    <i matListItemIcon class="icon ion-ios-checkmark-circle-outline big-icon"></i>
    <div style="font-size: 24px;">{{ 'tositteet.laskujen-hyvaksyminen.ei-hyvaksymattomia' | translate | async }}</div>
  </div>

  <ng-template #unapproved>
    <div class="kortti-container">
      <div *ngFor="let kuitti of naytettavatKuititObservable | async; trackBy: trackKuitti">
        <div class="kuitti-laatikko lemon-mat-elevation-z4" (click)="katsele(kuitti)">

          <button class="poista-btn" mat-icon-button (click)="rejectInvoice(kuitti, $event)" type="button">
            <mat-icon class="poista-icon">close</mat-icon>
          </button>

          <div app-tosite-kuva [url]="kuitti.ensimmainenKuva" class="kuva-container" [thumbnail]="true"></div>

          <div class="tiedot-container">
            <div [style.visibility]="!!kuitti.dueDate ? 'visible' : 'hidden'" [class]="kuitti.dueDate < currentDate ? 'due-date-expired' : 'due-date-active'"> {{ 'tositteet.laskujen-hyvaksyminen.erapaiva' | translate | async }} {{ kuitti.dueDate | lnumberpvm | async }}</div>
            <!-- <div class="pvm dark-gray-text">{{ kuitti.localPvm | lokaalipvm | async }}</div> -->
            <div class="summa light-blue-text">{{ kuitti.sum ?? kuitti.summa | lmoney: 'EUR' | async }}</div>
            <div class="viiva light-gray-background"></div>
            <div class="el-kommentti" *ngIf="kuitti?.el || kuitti?.seller">{{kuitti.el || kuitti.seller}}</div>
            <div class="selite dark-gray-text">{{ kuitti.selite }}</div>
            <div style="display: flex; justify-content: flex-end;">
              <button mat-button class="primarybutton" (click)="approveInvoice(kuitti, $event)">{{ 'tositteet.laskujen-hyvaksyminen.hyvaksy' | translate | async }}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-template>
</div>