import { Component } from '@angular/core'
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog'


@Component({
  templateUrl: './lasku.muokkaus-estetty.dialog.html'
})
export class LaskuMuokkausEstettyDialog {

  virhe: Error | null

  constructor(
    private dialogRef: MatDialogRef<LaskuMuokkausEstettyDialog>
  ) { }

}
