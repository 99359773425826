<form [formGroup]="form" novalidate>

  <h3>{{'laskuasetukset.automaattinen-reskontra' | translate | async}}</h3>
  <div>
    <mat-checkbox [disabled]="activationDisabled" style="margin-left: auto; margin-top: 1em;" formControlName="reskontra">{{'laskuasetukset.automaattinen-reskontra-kaytossa' | translate | async}}</mat-checkbox>
  </div>


  <div class="dark-gray-text" style="margin: 3em 0; font-size: 14px;">
    <p *ngIf="activationDisabled">{{'laskuasetukset.reskontraa-ei-voida-kayttaa' | translate | async }}</p>
    <p>{{'laskuasetukset.reskontra-kun-kaytossa' | translate | async}}</p>
  </div>

  <div>
    <button [disabled]="activationDisabled" class="primarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
  </div>
</form>