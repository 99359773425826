import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'

import { DateAdapter } from '@angular/material/core'

import { VersionTarkistusPalvelu } from '../../_angular/service/version-tarkistus.palvelu'
import { TositeService } from '../../_angular/service/tosite/tosite.service'
import { DragAndDropService } from '../../_jaettu-angular/service/drag-and-drop.service'
import { TiedostojenLataamisService } from '../../_jaettu-angular/service/tiedostojen-lataamis.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { TositeKatseleComponentDataResolve, TositeLuoUusiComponentData, TositeLuoUusiComponentDataResolve } from '../../_angular/_resolvers/tosite.resolve'

import { Maksutapa, FirestoreTosite, TILIOTETOSITE_MAKSUTAPA, PALKKATOSITE_MAKSUTAPA, MYYNTITOSITE_MAKSUTAPA, MYYNTILASKU_MAKSUTAPA_ID, MUU_MAKSUTAPA_ID, SAHKOISET_LASKUT_MAKSUTAPA_ID } from '../../_jaettu/model/tosite'
import { FirestoreIndeksoija } from '../../_jaettu/service/firestore.indeksoija'
import { CurrencyService } from '../../_shared-core/service/currency.service'

import { ListausFirestoreKuitti, MaksutapaJaKuva, Summat } from '../../_angular/service/tosite/tosite-datasource.service'

import { Subject, combineLatest, Observable, BehaviorSubject, firstValueFrom, of } from 'rxjs'
import { debounceTime, takeUntil, map, switchMap } from 'rxjs/operators'

import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop'
import { TositeDatasourceService } from '../../_angular/service/tosite/tosite-datasource.service'
import { SelvitettavatTositteetService } from 'app/_angular/service/tosite/selvitettavat-tositteet.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  selector: 'app-ostotositteet',
  templateUrl: './ostotosite.listaus.component.html',
  styleUrls: ['./ostotosite.listaus.component.css']
})
export class OstotositeListausComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileInput', { static: true }) fileInput

  private ngUnsubscribe = new Subject<void>()

  form: UntypedFormGroup

  naytaHuomautus = false
  naytaKaikkiSummat = true

  vanhaValittuMaksutapa: Maksutapa | null = null
  etsitaan = false

  private poistaFunktio: any = null

  isDragging = false
  kuvavirheAvain: string = null
  kuvavirheParametrit: any = null

  private selvitettavatValittuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  selvitettavatValittuObservable = this.selvitettavatValittuSubject.asObservable()
  selvitettavienTiedotObservable: Observable<{ maara: number, summa: number }>
  naytaSelvitettaviaObservable: Observable<boolean>

  maksutavatJaKuvatObservable: Observable<MaksutapaJaKuva[]>
  kuukaudenSummaObservable: Observable<number>
  naytaEiYhtaanObservable: Observable<boolean>
  lataaObservable: Observable<boolean>
  naytettavatKuititObservable: Observable<ListausFirestoreKuitti[]>
  otsikkoObservable: Observable<string>

  constructor(
    private router: Router,
    private firestoreIndeksoija: FirestoreIndeksoija,
    private sanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>,
    private currencyService: CurrencyService,
    private translationService: LemonTranslationService,
    private tositeService: TositeService,
    private tositeDatasourceService: TositeDatasourceService,
    private dndService: DragAndDropService,
    private tiedostojenLataamisService: TiedostojenLataamisService,
    private kuittiKatseleComponentDataResolver: TositeKatseleComponentDataResolve,
    private luoComponentDataResolve: TositeLuoUusiComponentDataResolve,
    private versionTarkistaja: VersionTarkistusPalvelu,
    private selvitettavatTositteetService: SelvitettavatTositteetService,
    private kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) { }

  private etsiArvolla(arvo: string) {
    this.etsitaan = true
    if (this.tositeDatasourceService.annaValittuMaksutapa()) {
      this.vanhaValittuMaksutapa = this.tositeDatasourceService.annaValittuMaksutapa()
      this.tositeDatasourceService.vaihdaValittuMaksutapa(null)
    }
    this.tositeDatasourceService.changeSearch(arvo)
  }

  ngOnInit() {

    this.tositeDatasourceService.changeSearch(null)

    this.selvitettavienTiedotObservable = this.selvitettavatTositteetService.annaSelvitettavienMaaraJaSummaObservable()

    this.naytaSelvitettaviaObservable = this.selvitettavienTiedotObservable.pipe(
      map(tiedot => !!tiedot?.maara)
    )
    // If the previously chosen kuitti wasn't selvitettävä, start with that maksutapa;
    // otherwise start with selvitettävät if there are any
    firstValueFrom(this.naytaSelvitettaviaObservable).then(async naytaSelvitettavia => {
      const lastViewedKuitinMaksutapa = this.tositeService.getLastViewedKuitinMaksutapa()
      if (lastViewedKuitinMaksutapa) {
        this.selvitettavatValittuSubject.next(false)
        this.tositeDatasourceService.vaihdaValittuMaksutapa(lastViewedKuitinMaksutapa)
      } else if (naytaSelvitettavia) {
        this.selvitettavatValittuSubject.next(true)
      }
      await this.tositeService.setLastViewedKuitinMaksutapa(null)
    })

    this.lataaObservable = this.tositeDatasourceService.lataaObservable

    this.otsikkoObservable = this.tositeDatasourceService.hakukriteeritObservable.pipe(
      map(kriteerit => {
        if (kriteerit && kriteerit.vuosikk) {
          return this.dateAdapter.getMonthNames('long')[kriteerit.vuosikk.kk] + ' ' + kriteerit.vuosikk.vuosi
        }
        return ''
      })
    )

    const apixConfigObservable = this.kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja) {
          return of<ApixReceivedInvoiceConfig>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttaja.asiakasAvain + '/apix-received-invoice-config/' + kayttaja.asiakasAvain).listen()
      })
    )

    const uusiVastaanottoOnPaallaObservable = apixConfigObservable.pipe(
      map(config => {
        return config && !!config.paymentReceiveIsActive
      })
    )

    this.naytettavatKuititObservable = combineLatest([
      this.tositeDatasourceService.kuititObservable,
      this.tositeDatasourceService.valittuMaksutapaObservable,
      this.tositeService.maksutavatObservable,
      uusiVastaanottoOnPaallaObservable
    ]).pipe(
      map(([kuitit, valittuMaksutapa, naytettavatMaksutavat, uusiVastaanottoOnPaalla]) => {
        const maksutapaIdt = naytettavatMaksutavat.map(maksutapa => maksutapa.i)
        const maksutavatKuitit = kuitit.filter(kuitti => {

          // Suodata pois kuitit, joissa ei ole maksupäivää jos uusi vastaanotto on päällä
          if (
            valittuMaksutapa?.i === SAHKOISET_LASKUT_MAKSUTAPA_ID &&
            uusiVastaanottoOnPaalla &&
            !kuitti.paymentDate
          ) {
            return false
          }

          if (
            (!valittuMaksutapa || kuitti.maksutapa === valittuMaksutapa.i) &&
            kuitti.maksutapa !== TILIOTETOSITE_MAKSUTAPA.i &&
            kuitti.maksutapa !== MYYNTITOSITE_MAKSUTAPA.i &&
            kuitti.maksutapa !== PALKKATOSITE_MAKSUTAPA.i &&
            kuitti.maksutapa !== MUU_MAKSUTAPA_ID &&
            kuitti.maksutapa !== MYYNTILASKU_MAKSUTAPA_ID &&
            maksutapaIdt.includes(kuitti.maksutapa) &&
            kuitti.einvoiceApprovalStatus !== 'rejected' &&
            kuitti.einvoiceApprovalStatus !== 'pending-approval'
          ) {
            return true
          }
          return false
        })
        return maksutavatKuitit
      })
    )

    const maksutapojenSummatObservable: Observable<{ [maksutavanTunnus: string]: Summat }> = this.tositeDatasourceService.kuititObservable.pipe(
      map(kuitit => {
        const maksutavoittain: { [maksutavanTunnus: string]: Summat } = {}
        if (kuitit) {
          for (const kuitti of kuitit) {
            if (
              kuitti.einvoiceApprovalStatus === 'rejected' ||
              kuitti.einvoiceApprovalStatus === 'pending-approval'
            ) {
              continue
            }
            if (!maksutavoittain[kuitti.maksutapa]) {
              maksutavoittain[kuitti.maksutapa + ''] = {
                summa: 0,
                lukumaara: 0
              }
            }
            const maksutavanTiedot = maksutavoittain[kuitti.maksutapa + '']
            maksutavanTiedot.summa += kuitti.summa
            maksutavanTiedot.lukumaara++
          }
        }
        return maksutavoittain
      })
    )

    const internalMaksutavatJaKuvatObservable: Observable<MaksutapaJaKuva[]> = this.tositeService.maksutavatObservable.pipe(
      map(maksutavat => {
        const maksutavatJaKuvat: MaksutapaJaKuva[] = []
        for (const maksutapa of maksutavat) {
          const kuva = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + maksutapa.img)
          maksutavatJaKuvat.push({
            maksutapa: maksutapa,
            kuva: new BehaviorSubject(kuva),
            onkoNuoli: false,
            timeout: null,
            lukumaara: 0,
            summa: 0,
            valittu: false
          })
        }
        return maksutavatJaKuvat
      })
    )

    this.maksutavatJaKuvatObservable = combineLatest([
      maksutapojenSummatObservable,
      internalMaksutavatJaKuvatObservable,
      this.tositeDatasourceService.valittuMaksutapaObservable,
      this.selvitettavatValittuObservable
    ]).pipe(
      map(([maksutavoittain, maksutavat, valittuMaksutapa, selvitettavatValittu]) => {
        for (const maksutapa of maksutavat) {
          const maksutavanTiedot = maksutavoittain[maksutapa.maksutapa.i + '']
          maksutapa.lukumaara = maksutavanTiedot ? maksutavanTiedot.lukumaara : 0
          maksutapa.summa = maksutavanTiedot ? maksutavanTiedot.summa : 0

          maksutapa.valittu = valittuMaksutapa && !selvitettavatValittu ? valittuMaksutapa.i === maksutapa.maksutapa.i : false
        }
        const aktiivisetTaiTositteillaMaksutavat = maksutavat.filter(m => m.maksutapa.a || m.lukumaara > 0)
        return aktiivisetTaiTositteillaMaksutavat
      }),
      lemonShare()
    )

    this.kuukaudenSummaObservable = this.maksutavatJaKuvatObservable.pipe(
      map(maksutavat => {
        let summa = 0
        for (const maksutapa of maksutavat) {
          summa += maksutapa.summa
        }
        return summa
      })
    )

    this.naytaEiYhtaanObservable = combineLatest([this.tositeDatasourceService.rivienMaaraObservable, this.tositeDatasourceService.valittuMaksutapaObservable, this.maksutavatJaKuvatObservable, this.tositeDatasourceService.lataaObservable]).pipe(
      map(([rivienMaara, valittuMaksutapa, maksutavat, lataa]) => {
        if (lataa) {
          return false
        }
        if (valittuMaksutapa) {
          let maksutapa: MaksutapaJaKuva = null
          for (const m of maksutavat) {
            if (m.maksutapa.i === valittuMaksutapa.i) {
              maksutapa = m
              break
            }
          }
          return maksutapa ? maksutapa.lukumaara < 1 : true
        }
        return rivienMaara < 1
      })
    )

    this.versionTarkistaja.sovelluksenVersioObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(versiotiedot => {
      this.versionTarkistaja.tarkistaVersio(versiotiedot)
    })

    this.form = new UntypedFormGroup({
      'nimiFirestore': new UntypedFormControl(this.tositeDatasourceService.getSearch(), [])
    })

    this.form.get('nimiFirestore').valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        let val = this.firestoreIndeksoija.poistaValilyonnit(value)
        const syoteOnNumero = this.currencyService.onkoMerkkijonoNumero(val)
        if (syoteOnNumero) {
          val = this.firestoreIndeksoija.korvaaDesimaaliErotinHakuerottimella(val)
          val = this.firestoreIndeksoija.poistaValimerkitJaValilyonnit(val)
          this.etsiArvolla('s' + val)
        } else {
          val = this.firestoreIndeksoija.poistaValimerkitJaValilyonnit(val)
          if (val.length > 2) {
            this.etsiArvolla(val)
          }
        }
      } else {
        this.etsitaan = false
        this.tositeDatasourceService.vaihdaValittuMaksutapa(this.vanhaValittuMaksutapa)
        this.tositeDatasourceService.changeSearch(null)
      }
    })

    this.dndService.isDraggingInWindowObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(dragging => {
      this.isDragging = dragging
    })

    if (
      this.tositeDatasourceService.getMaksutapa() === (MYYNTITOSITE_MAKSUTAPA.i + '') ||
      this.tositeDatasourceService.getMaksutapa() === (TILIOTETOSITE_MAKSUTAPA.i + '') ||
      this.tositeDatasourceService.getMaksutapa() === (MYYNTITOSITE_MAKSUTAPA.i + '') ||
      this.tositeDatasourceService.getMaksutapa() === (PALKKATOSITE_MAKSUTAPA.i + '') ||
      this.tositeDatasourceService.getMaksutapa() === (MUU_MAKSUTAPA_ID + '') ||
      this.tositeDatasourceService.getMaksutapa() === (MYYNTILASKU_MAKSUTAPA_ID + '')
    ) {
      this.tositeDatasourceService.changeMaksutapa(null)
    }

  }

  ngAfterViewInit() {

  }

  valitseMaksutapa(maksutapa: MaksutapaJaKuva) {
    this.selvitettavatValittuSubject.next(false)
    this.tositeDatasourceService.vaihdaValittuMaksutapa(maksutapa.maksutapa)
  }

  onkoSelvitettavatValittu() {
    this.selvitettavatValittuSubject.next(true)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  vihreaPilvi(kuitti: FirestoreTosite): boolean {
    if (kuitti.alkuperaiset) {
      for (const alkuperaisenAvain of Object.keys(kuitti.alkuperaiset)) {
        const alkuperainen = kuitti.alkuperaiset[alkuperaisenAvain]
        if (!alkuperainen.kasitelty) {
          return false
        }
      }
    }
    return true
  }

  edellinenKuukausi() {
    const vuosiKk = this.tositeDatasourceService.getVuosiKk()
    const vuosi = vuosiKk.kk < 1 ? vuosiKk.vuosi - 1 : vuosiKk.vuosi
    const kuukausi = vuosiKk.kk < 1 ? 11 : vuosiKk.kk - 1
    this.tositeDatasourceService.changeVuosiKk(vuosi, kuukausi)
  }

  seuraavaKuukausi() {
    const vuosiKk = this.tositeDatasourceService.getVuosiKk()
    const vuosi = vuosiKk.kk > 10 ? vuosiKk.vuosi + 1 : vuosiKk.vuosi
    const kuukausi = vuosiKk.kk > 10 ? 0 : vuosiKk.kk + 1
    this.tositeDatasourceService.changeVuosiKk(vuosi, kuukausi)
  }

  dateChange() {
    // console.log('CHaNGEA')
  }

  dateInput() {
    // console.log('INPuTTIA')
  }

  public fileOver(event: any, maksutapa: MaksutapaJaKuva) {
    if (maksutapa.timeout) {
      clearTimeout(maksutapa.timeout)
      maksutapa.timeout = null
    }
    if (!maksutapa.onkoNuoli) {
      maksutapa.onkoNuoli = true
      maksutapa.kuva.next('/assets/nuoli_alas.png')
    }
    this.dndService.setDragging(true)
  }

  public fileLeave(event: any, maksutapa: MaksutapaJaKuva) {

    if (maksutapa.onkoNuoli) {
      if (maksutapa.timeout) {
        clearTimeout(maksutapa.timeout)
        maksutapa.timeout = null
      }
      maksutapa.timeout = setTimeout(() => {
        maksutapa.onkoNuoli = false
        const kuva = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + maksutapa.maksutapa.img)
        maksutapa.kuva.next(kuva)
      }, 25)
    }

    this.dndService.setDragging(true)
  }

  public async fileDrop(entries: NgxFileDropEntry[], maksutapa: MaksutapaJaKuva): Promise<void> {

    this.dndService.setDragging(false)

    if (maksutapa.maksutapa.i === SAHKOISET_LASKUT_MAKSUTAPA_ID) {
      return
    }

    const voidaanLadata = await this.tiedostoVoidaanLadata(entries)
    if (!voidaanLadata) {
      return
    }

    this.siirryLuomiseen(entries, maksutapa.maksutapa)

  }

  private async tiedostoVoidaanLadata(tiedostot: NgxFileDropEntry[]): Promise<boolean> {

    this.kuvavirheAvain = ''
    this.kuvavirheParametrit = {}
    // if (kuvienMaara > 1) {
    //   this.kuvavirheAvain = 'laskuasetukset.logo.virheet.vain-yksi-kerrallaan'
    //   setTimeout(() => {
    //     this.kuvavirheAvain = ''
    //   }, 10000)
    //   return false
    // }

    if (tiedostot.length < 1) {
      if (this.poistaFunktio) {
        clearTimeout(this.poistaFunktio)
      }
      this.kuvavirheAvain = 'kuitit.lataaminen.virheet.ei-tiedosto'
      this.poistaFunktio = setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 15000)
      return false
    }

    const supportedImageTypes = ['jpeg', 'jpg', 'png', 'webp', 'tiff', 'gif', 'svg', 'pdf', 'heic', 'heif']
    for (const tiedosto of tiedostot) {

      const file = tiedosto.fileEntry as FileSystemFileEntry

      const fileEnding = this.tiedostojenLataamisService.getFileEndingFromFileName(file.name)
      const fileSize = file ? await this.tiedostojenLataamisService.getFileSize(file) : -1

      // console.log(fileSize, file)

      if (!fileEnding || supportedImageTypes.indexOf(fileEnding.toLowerCase()) < 0) {
        if (this.poistaFunktio) {
          clearTimeout(this.poistaFunktio)
        }
        this.kuvavirheParametrit = {
          tuetutMuodot: supportedImageTypes.join(', ')
        }
        this.kuvavirheAvain = 'kuitit.lataaminen.virheet.vaara-muoto'
        this.poistaFunktio = setTimeout(() => {
          this.kuvavirheAvain = ''
        }, 15000)
        return false
      }

      const maxKoko = 25 * 1024 * 1024
      if (fileSize > maxKoko) {
        if (this.poistaFunktio) {
          clearTimeout(this.poistaFunktio)
        }
        const kokoMegatavuissaLokalisoitu = this.currencyService.formatoiDesimaali((fileSize / 1024 / 1024), 2, this.translationService.nykyinenKieli)
        const maxKokoLokalisoitu = this.currencyService.formatoiDesimaali((maxKoko / 1024 / 1024), 2, this.translationService.nykyinenKieli)
        this.kuvavirheParametrit = {
          kokoMax: maxKokoLokalisoitu,
          kokoNyt: kokoMegatavuissaLokalisoitu
        }
        this.kuvavirheAvain = 'kuitit.lataaminen.virheet.liian-suuri'
        this.poistaFunktio = setTimeout(() => {
          this.kuvavirheAvain = ''
        }, 15000)
        return false
      }

    }

    return true

  }

  naytaTiedostonValintaDialogi() {
    this.fileInput.nativeElement.click()
  }

  async fileChanged(event) {

    const list: FileList = event.target.files
    const tiedostot: NgxFileDropEntry[] = this.tiedostojenLataamisService.fileListToNgxFileDropEntries(list)

    const voidaanLadata = await this.tiedostoVoidaanLadata(tiedostot)
    if (!voidaanLadata) {
      return
    }
    this.siirryLuomiseen(tiedostot)
  }

  private siirryLuomiseen(tiedostot: NgxFileDropEntry[], maksutapa?: Maksutapa) {
    const data: TositeLuoUusiComponentData = {
      tiedostot: tiedostot,
      maksutapa: maksutapa
    }
    this.luoComponentDataResolve.asetaOlemassaolevaData(data)
    this.router.navigate(['/tositteet/osto/luo'])
  }

  katsele(tosite: FirestoreTosite) {
    this.kuittiKatseleComponentDataResolver.asetaOlemassaolevaData({ tosite: tosite })
    this.router.navigate(['tositteet', 'osto', tosite.avain])
  }
  openDefaultMaksutapa() {
    this.selvitettavatValittuSubject.next(false)
  }
  trackKuitti(index: number, kuitti: ListausFirestoreKuitti) {
    return kuitti.avain
  }

}
