import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { HyvaksytynSopimuksenTekstiJaAvainRequest, HyvaksytynSopimuksenTekstiJaAvainResponse, PalvelukuvauksenPdfRequest, PalvelukuvauksenPdfResponse, Palvelukuvaus, SopimuksenAllekirjoitanutRequest, SopimuksenAllekirjoitanutResponse, SopimuksenAllekirjoittajaJaAika, SopimuksenHintaJaTekstiRequest, SopimusPdfRequest, SopimusPdfResponse } from 'app/_jaettu/model/sopimus'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { map, Observable, switchMap, of as observableOf, tap, combineLatest, firstValueFrom, takeUntil, Subject } from 'rxjs'

import * as base64 from 'base64-js'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

@Component({
  templateUrl: './sopimus-ja-ehdot.component.html',
  styleUrls: ['./sopimus-ja-ehdot.component.css']
})
export class SopimusJaEhdotComponent implements OnInit, OnDestroy {

  commonError: string
  sopimusExists: boolean
  sopimusTekstiObservable: Observable<string>
  sopimuksenAllekirjoitanutObservable: Observable<SopimuksenAllekirjoittajaJaAika[]>
  palvelukuvausTekstiObservable: Observable<string>
  valittuKieliObservable: Observable<TuettuKieli>

  private _naytettavanSopimuksenAvainObservable: Observable<string>
  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    this.valittuKieliObservable = this._translationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe),
    )

    const sopimusDataObservable: Observable<HyvaksytynSopimuksenTekstiJaAvainResponse> = this._translationService.currentLanguageObservable.pipe(
      tap(() => this._ladataanService.aloitaLataaminen()),
      switchMap(kieli => {
        const req: SopimuksenHintaJaTekstiRequest = {
          kieli: kieli || 'fi'
        }
        return this._firebaseLemonaid.functionsCall<HyvaksytynSopimuksenTekstiJaAvainRequest, HyvaksytynSopimuksenTekstiJaAvainResponse>('sopimusAnnaKayttajanViimeisinHyvaksyttyVersio', req).then(resp => {
          if (!resp || (resp && resp.e)) {
            this._errorHandler.handleError(new Error(resp ? resp.e : 'unknown-error'))
            return null
          }
          return resp
        })
      })
    )

    const palvelukuvausDataObservable: Observable<Palvelukuvaus> = this._firebaseLemonaid.firestoreDoc<Palvelukuvaus>('admin/palvelukuvaus').listen()
    this.palvelukuvausTekstiObservable = combineLatest([this._translationService.currentLanguageObservable, palvelukuvausDataObservable]).pipe(
      map(([kieli, palvelukuvaus]) => {
        if (!palvelukuvaus?.textInDiffLangs) {
          return ''
        }
        if (kieli === 'fi' || kieli === 'en') {
          return palvelukuvaus.textInDiffLangs[kieli]
        }
        return palvelukuvaus.textInDiffLangs['fi']
      })
    )

    this.sopimusTekstiObservable = sopimusDataObservable.pipe(
      tap(data => {
        this.sopimusExists = !!data?.sopimusTeksti
        this._ladataanService.lopetaLataaminen()
      }),
      map(sopimusData => sopimusData?.sopimusTeksti || '')
    )

    this._naytettavanSopimuksenAvainObservable = sopimusDataObservable.pipe(
      tap(() => this._ladataanService.lopetaLataaminen()),
      map(data => data.sopimusAvain)
    )

    this.sopimuksenAllekirjoitanutObservable = sopimusDataObservable.pipe(
      switchMap(sopimusData => {
        if (!sopimusData?.sopimusAvain) {
          return observableOf<SopimuksenAllekirjoittajaJaAika[]>([])
        }

        const req: SopimuksenAllekirjoitanutRequest = {
          sopimusAvain: sopimusData.sopimusAvain
        }
        return this._firebaseLemonaid.functionsCall<SopimuksenAllekirjoitanutRequest, SopimuksenAllekirjoitanutResponse>('sopimusAnnaAllekirjoittajienTiedot', req).then(resp => {
          if (!resp || (resp && resp.e)) {
            this._errorHandler.handleError(new Error(resp ? resp.e : 'unknown-error'))
            return null
          }
          if (!resp?.allekirjoitanut?.length) {
            return []
          }
          return resp.allekirjoitanut
        })

      })
    )
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  async downloadSopimus() {

    this._ladataanService.aloitaLataaminen()

    const req: SopimusPdfRequest = {
      versio: await firstValueFrom(this._naytettavanSopimuksenAvainObservable),
      kieli: this._translationService.nykyinenKieli || 'fi'
    }
    return this._firebaseLemonaid.functionsCall<SopimusPdfRequest, SopimusPdfResponse>('sopimusAnnaSopimuksenPdf', req).then(response => {
      if (!response || response.e || !response.base64String) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        this._downloadSopimusPdf(response.base64String)
      }
    }).catch(err => this._errorHandler.handleError(err)).finally(() => {
      this._ladataanService.lopetaLataaminen()
    })

  }

  private _downloadSopimusPdf(base64File: string) {
    const output = base64.toByteArray(base64File)
    const fileName = 'Tilitoimisto Lemon Tree Oy Toimeksiantosopimus.pdf'
    this._fileSaverService.saveAs(new Blob([output], { type: 'application/pdf' }), fileName)
  }

  async downloadPalvelukuvaus() {

    this._ladataanService.aloitaLataaminen()

    const req: PalvelukuvauksenPdfRequest = {
      kieli: this._translationService.nykyinenKieli || 'fi'
    }
    return this._firebaseLemonaid.functionsCall<PalvelukuvauksenPdfRequest, PalvelukuvauksenPdfResponse>('sopimusAnnaPalvelukuvausPdf', req).then(response => {
      if (!response || response.e || !response.base64String) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        this._downloadPalvelukuvausPdf(response.base64String)
      }
    }).catch(err => this._errorHandler.handleError(err)).finally(() => {
      this._ladataanService.lopetaLataaminen()
    })
  }

  private _downloadPalvelukuvausPdf(base64File: string) {
    const output = base64.toByteArray(base64File)
    const fileName = 'Tilitoimisto Lemon Tree Oy Palvelukuvaus.pdf'
    this._fileSaverService.saveAs(new Blob([output], { type: 'application/pdf' }), fileName)
  }

}
