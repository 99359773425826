<div class="outer-container">
  <div class="container" *ngIf="!ilmoitusLahetetty">
    <h2>{{'sopimus-tauolla.tauko' | translate | async}}</h2>
    <p>{{'sopimus-tauolla.sopimuksesi-on-tauolla' | translate | async}}</p>
    <button class="primarybutton" mat-button (click)="lopetaTauko()">{{'sopimus-tauolla.lopeta-tauko' | translate | async}}</button>
  </div>
  <div class="container" *ngIf="ilmoitusLahetetty">
    <h2>{{'sopimus-tauolla.kiitos-yhteydenotostasi' | translate | async}}</h2>
    <p>{{'sopimus-tauolla.kirjanpitajan-tiedotus' | translate | async}}</p>
  </div>
  <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
</div>