import { Component, OnInit, OnDestroy, AfterViewInit, ErrorHandler, ChangeDetectionStrategy, OnChanges, ViewChild } from '@angular/core'
import { Location } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'
import { Validators, FormControl, FormArray, FormGroup } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'

import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

import { LaskuService } from '../_angular/service/lasku/lasku.service'
import { LaskuComponentData, LaskuKatseleComponentExistingData, LaskuKatseleComponentDataResolve, LaskutComponentData, LaskutComponentDataResolve } from '../_angular/_resolvers/lasku.resolve'

import { Lasku, LaskuBase, LaskunTyyppi, Laskuasetukset, UUDEN_LASKUN_AVAIN, LaskunTuote, LaskunAsiakas, LaskunLahetystapa, LaskunumeroTyyppi, LaskunTyypit, TypeaheadAsiakas, LaskuReskontra, LaskunTila } from '../_jaettu/model/lasku'
import { TuettuKieli } from '../_shared-core/model/common'
import { LaskuSharedService } from '../_jaettu/service/lasku/lasku-shared.service'
import { CurrencyService } from '../_shared-core/service/currency.service'
import { LaskuKopioija } from '../_jaettu/service/lasku/lasku.kopioija'

import { Subject, ReplaySubject, BehaviorSubject, Observable, combineLatest, of } from 'rxjs'
import { takeUntil, take, auditTime, map, switchMap, pairwise } from 'rxjs/operators'

import { LaskuPdfEsikatselutiedot } from '../_jaettu-angular/laskut/esikatselu/pdf.perinteinen.component'
import { KonfiguraatioPalvelu } from '../_jaettu-angular/service/lasku/configuration.service'
import { LaskuAsiakkaanTiedotComponent } from '../_jaettu-angular/laskut/muokkaa/lasku-asiakkaan-tiedot.component'
import { LaskunLahetysdata, LaskunLahetystiedot } from './laheta.component'
import { LadattavaLiitetiedosto, LaskuForm, LaskunRiviForm } from 'app/_jaettu-angular/laskut/muokkaa/lasku-rivit.component'
import { LaskuKorkoService } from 'app/_jaettu/service/lasku/lasku-korko.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { KayttajanTiedot } from 'app/_jaettu/model/kayttaja'
import { LaskuUriService } from 'app/_jaettu/service/lasku/lasku-uri.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MatDialog } from '@angular/material/dialog'
import { InfoDialog } from 'app/_jaettu-angular/_components/info.dialog'


@Component({
  selector: 'app-lasku',
  templateUrl: './lasku.component.html',
  styleUrls: ['./lasku.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('asiakkaanTiedot', { read: LaskuAsiakkaanTiedotComponent }) asiakkaanTiedotComponent: LaskuAsiakkaanTiedotComponent

  private ngUnsubscribe = new Subject<void>()
  tilaaEsikatseluPaivitysSubject: ReplaySubject<number> = new ReplaySubject(1)
  aktiivinenValilehti: ReplaySubject<number> = new ReplaySubject(0)
  lahetysdata: ReplaySubject<LaskunLahetysdata> = new ReplaySubject(null)

  laskunTuotteetObservable: ReplaySubject<LaskunTuote[]> = new ReplaySubject(1)
  laskunLiitetiedostotObservable: ReplaySubject<LadattavaLiitetiedosto[]> = new ReplaySubject(1)
  laskunTyyppiObservable: BehaviorSubject<LaskunTyyppi> = new BehaviorSubject(LaskunTyypit.TAVALLINEN)
  laskunVastaanottajanMaaObservable: BehaviorSubject<string> = new BehaviorSubject('FIN')
  laskuObservable: ReplaySubject<{ juurilasku: Lasku, kasiteltava: LaskuBase }> = new ReplaySubject(1)
  esikatselutiedot: ReplaySubject<LaskuPdfEsikatselutiedot> = new ReplaySubject(1)
  naytaViivastuskorkoObservable: Observable<boolean>
  paivitaValidaatiot = {}
  hyvitys = false

  laskuForm: FormGroup<LaskuForm>

  juurilasku: Lasku = null
  kasiteltava: LaskuBase = null
  reskontra: LaskuReskontra[] = null
  asetukset: Laskuasetukset = null
  aiemminMaksettu = 0

  alvitonSumma = 0
  alvSumma = 0

  asetuksissaOnOletustietojaVirhe = false
  hyvitysPositiivinenVirhe = false
  alkuperainenNrotyyppi: LaskunumeroTyyppi = null

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private validationService: FormValidationService,
    private errorHandler: ErrorHandler,
    private laskuSharedService: LaskuSharedService,
    private currencyService: CurrencyService,
    private ladataanService: LadataanService,
    private snackbar: MatSnackBar,
    private translationService: LemonTranslationService,
    private laskutResolve: LaskutComponentDataResolve,
    private location: Location,
    private laskuKatseleComponentDataResolve: LaskuKatseleComponentDataResolve,
    private konfiguraatioPalvelu: KonfiguraatioPalvelu,
    private _korkoService: LaskuKorkoService,
    private _kayttajaService: KayttajaService,
    private _laskuUriService: LaskuUriService,
    private _lemonaidFirebase: FirebaseLemonaid
  ) {

    this.juurilasku = this.laskuKopioija.annaUusiLasku()
    this.kasiteltava = this.juurilasku

    const laskunTyyppi: LaskunTyyppi = {
      avain: this.kasiteltava.tyyppi
    }
    const tyyppiDisabled = this.kasiteltava.avain !== this.juurilasku.avain
    this.laskuForm = new FormGroup<LaskuForm>({

      // Yläosan dropparit
      tyyppi: new FormControl<LaskunTyyppi>({ value: laskunTyyppi, disabled: tyyppiDisabled }),
      kieli: new FormControl<TuettuKieli>({ value: this.kasiteltava.kieli, disabled: false }),
      valuutta: new FormControl<string>(this.kasiteltava.valuutta),

      // Asiakkaan tiedot
      // asiakastyyppi:          new FormControl<?>(,           [ Validators.required ] ),
      nimi: new FormControl<TypeaheadAsiakas>(null, []),
      ytunnus: new FormControl<string>(''),
      katuosoite: new FormControl<string>('', [Validators.required]),
      maa: new FormControl<string>('', [Validators.required]),
      postinro: new FormControl<string>('', [Validators.required]),
      postitoimipaikka: new FormControl<string>('', [Validators.required]),

      // Laskun muut tiedot
      laskunpvm: new FormControl<Date>({ value: null, disabled: true }),
      erapaiva: new FormControl<Date>(null, [Validators.required]),
      maksuaikaa: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
      toimituspvm: new FormControl<Date>(null, []),
      lisatiedot: new FormControl<string>('', []),
      viitteenne: new FormControl<string>('', []),
      viivastyskorko: new FormControl<number>(null, [Validators.required, Validators.min(0.01)]),

      // Tuotteet
      tuotteet: new FormArray<FormGroup<LaskunRiviForm>>([], [this.vahintaaYksiRiviValidator]),
      liitetiedostot: new FormArray<FormControl<LadattavaLiitetiedosto>>([], [])

    })

    this.registerForChanges()

  }

  vahintaaYksiRiviValidator = (c: FormArray<any>) => {
    if (c.length < 1) {
      return { min: 'true' }
    }
    return null
  }

  ngOnChanges() {
    // console.log('lasku changes')
  }

  ngOnInit() {

    this.naytaViivastuskorkoObservable = combineLatest([this._kayttajaService.kayttajaObservable, this.laskuObservable]).pipe(
      map(([kayttaja, lasku]) => {
        if (
          lasku?.juurilasku?.avain === lasku.kasiteltava?.avain &&
          kayttaja?.features?.CAN_EDIT_PENALTY_INTEREST === true
        ) {
          return true
        }
        return false
      })
    )

    this.laskuForm.get('tuotteet').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      auditTime(50)
    ).subscribe((aika) => {
      this.laskeSummat()
    })

    this.tilaaEsikatseluPaivitysSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      auditTime(250)
    ).subscribe((aika) => {
      // console.log('tilattu esikatselu paivitys: ', aika)
      if (this.asetukset && this.juurilasku && this.kasiteltava) {
        this.esikatselutiedot.next({
          asetukset: this.asetukset,
          juurilasku: this.juurilasku,
          kasiteltava: this.kasiteltava
        })
      }
    })

    combineLatest([this.route.data, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      switchMap(([data, kayttaja]: [{ data: LaskuComponentData }, KayttajanTiedot]) => {
        if (kayttaja?.asiakasId && data?.data?.juurilasku?.avain) {
          const laskuReskontraUri = this._laskuUriService.getLaskuReskontraCollectionUri(kayttaja.asiakasId, data.data.juurilasku.avain)
          return this._lemonaidFirebase.firestoreCollection<LaskuReskontra>(laskuReskontraUri).listen().pipe(
            map(reskontra => {
              return [data, reskontra]
            })
          )
        }
        return of([null, null])
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: ([data, reskontra]: [{ data: LaskuComponentData }, LaskuReskontra[]]) => {
        this.alustaNakymaKokonaan(data.data.asetukset, data.data.juurilasku, data.data.kasiteltava, reskontra)
      },
      error: error => {
        this.errorHandler.handleError(error)
      }
    })

    window.scroll({ top: 0, left: 0 })

  }

  private alustaNakymaKokonaan(asetukset: Laskuasetukset, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]) {

    if (!reskontra) {
      return
    }

    this.asetukset = asetukset
    this.juurilasku = juurilasku
    this.kasiteltava = kasiteltava
    this.reskontra = reskontra

    this.laskuSharedService.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    if (!this.kasiteltava.tuotteet) {
      this.kasiteltava.tuotteet = []
    }

    if (!this.kasiteltava.liitteet) {
      this.kasiteltava.liitteet = []
    }

    if (this.juurilasku.viivastyskorkoprosentti === undefined || this.juurilasku.viivastyskorkoprosentti === null) {
      this.juurilasku.viivastyskorkoprosentti = this._korkoService.annaOletusViivastyskorkoProsenttiNumber()
    }

    this.alkuperainenNrotyyppi = kasiteltava.nrotyyppi

    this.asetaTyyppiLaskusta(this.juurilasku, this.kasiteltava, true)
    this.laskunTuotteetObservable.next(this.kasiteltava.tuotteet)
    this.laskuObservable.next({ juurilasku: this.juurilasku, kasiteltava: this.kasiteltava })

    // NOTE! THE EXACT ARRAY INSTANCE FROM KASITELTAVA MUST BE PLACED TO THIS
    // ARRAY! IT IS UGLY UGLY, BUT WORKS!
    // NO MAP, NO FILTER, NO NOTHING!
    // The reason for is that the sub component directly modifies the array, which is
    // inside the lasku instance. If we map of filter the array, a new array is created, and
    // the changes are not visible inside the lasku anymore.
    //
    // Loop below marks all already uploaded liitteet as done.
    // The data in lasku is cleaned when saving by the lasku.kopioija, which doesn't identify these
    // properties. (and hence leaves those out of the created copy)
    for (const liite of this.kasiteltava.liitteet) {
      const asLadattava = liite as LadattavaLiitetiedosto
      asLadattava.uploadDone = true
      asLadattava.uploadDonePercentage = 100
    }
    this.laskunLiitetiedostotObservable.next(this.kasiteltava.liitteet)

    if (this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
      const summat = this.laskuSharedService.annaLaskunSummatJaTilaLaskulle(this.juurilasku, reskontra)
      const aiemminMaksettuTaiHyvitetty = summat.reskontrasumma.plus(summat.hyvitykset.abs())
      this.aiemminMaksettu = 0 - this.currencyService.muutaBigDecimalRahaksi(aiemminMaksettuTaiHyvitetty)
    } else {
      this.aiemminMaksettu = 0
    }

    if (this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
      this.hyvitys = true
    } else {
      this.hyvitys = false
    }

    if (!this.kasiteltava.tuotteet) {
      this.kasiteltava.tuotteet = []
    }

    // Hae laskunumero
    if (this.kasiteltava.lukossa) {
      // const muotoiltu = this.laskuSharedService.annaMuotoiltuLaskunumero(this.kasiteltava)
      // this.laskuForm.get('laskunro').setValue(muotoiltu)
    } else {
      this.laskuService.haeLaskunumeroArvio(this.kasiteltava).then(numero => {
        // console.log('TÄÄLLÄ!!', numero)
        this.kasiteltava.nro = numero
        this.tilaaEsikatseluPaivitysSubject.next(1)
        // const muotoiltu = this.laskuSharedService.annaMuotoiltuLaskunumero(this.kasiteltava)
        // this.laskuForm.get('laskunro').setValue(muotoiltu)
      })
    }

    this.laskuForm.get('kieli').setValue(this.kasiteltava.kieli)
    this.laskuForm.get('valuutta').setValue(this.kasiteltava.valuutta)

    this.laskeSummat()

    setTimeout(() => {
      if (this.asiakkaanTiedotComponent) {
        this.asiakkaanTiedotComponent.focus()
      }
    }, 25)

  }

  ngAfterViewInit() { }

  asetaTyyppiLaskusta(juurilasku: Lasku, kasiteltava: LaskuBase, force: boolean) {
    // Set the existing type
    if (kasiteltava.tyyppi != null) {
      this.konfiguraatioPalvelu.tyypitObservable.pipe(
        take(1)
      ).subscribe(tyypit => {
        for (const tyyppi of tyypit) {
          if (kasiteltava.tyyppi === tyyppi.avain) {
            this.laskuForm.get('tyyppi').setValue(tyyppi)
            if (kasiteltava.avain === juurilasku.avain) {
              this.laskuForm.get('tyyppi').enable()
            } else {
              this.laskuForm.get('tyyppi').disable()
            }
            if (force) {
              this._vaihdaLaskunTyyppi(tyyppi)
            }
            break
          }
        }
      })
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  private laskeSummat() {
    const summat = this.laskuSharedService.annaLaskunSummat(this.kasiteltava)
    this.alvitonSumma = this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaIlmanAlv)
    this.alvSumma = this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaAlv)
    const yhteensa = this.alvSumma + this.alvitonSumma
    if (yhteensa < 0) {
      this.hyvitysPositiivinenVirhe = false
      this.kasiteltava.nrotyyppi = LaskunumeroTyyppi.HYVITYS
    } else {
      this.kasiteltava.nrotyyppi = this.alkuperainenNrotyyppi
    }
    this.tilaaEsikatseluPaivitysSubject.next(1)
  }

  registerForChanges(): void {

    this.laskuForm.valueChanges.subscribe(() => {
      this.tilaaEsikatseluPaivitysSubject.next(1)
    })

    this.laskuForm.get('maa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(maa => {
      if (this.laskunVastaanottajanMaaObservable.value !== maa) {
        // console.log('maa muuttui', maa, '!==', this.laskunVastaanottajanMaaObservable.value)
        this.laskunVastaanottajanMaaObservable.next(maa)
      }
    })

    this.laskuForm.get('tyyppi').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: LaskunTyyppi) => {
      if (value) {
        if (this.kasiteltava.tyyppi !== value.avain) {
          // console.log('tyyppi muuttui', value?.avain, '!==', this.laskunTyyppiObservable.value?.avain)
          this._vaihdaLaskunTyyppi(value)
        }
      }
    })

    this.laskuForm.get('kieli').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async (value: TuettuKieli) => {
      if (value) {
        this.kasiteltava.kieli = value
      }
    })

    this.laskuForm.get('valuutta').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: string) => {
      if (value) {
        this.kasiteltava.valuutta = value
      }
    })

    combineLatest({
      tyyppi: this.laskuForm.get('tyyppi').valueChanges.pipe(pairwise()),
      asiakas: this._kayttajaService.nykyinenAsiakasObservable
    }).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(({ tyyppi: [edellinenTyyppi, nykyinenTyyppi], asiakas }) => {
      // Indikoi jos suomi.fi -valtuutus puuttuu laskutyypeillä "Yhteisömyynti toiseen EU-maahan (tavara)" ja "Yhteisömyynti toiseen EU-maahan (palvelu)".
      if (
        (nykyinenTyyppi === LaskunTyypit.EU_PALVELU || nykyinenTyyppi === LaskunTyypit.EU_TAVARA) &&
        asiakas.suomiFiValtuutusPuuttuu
      ) {
        // Palautetaan käyttäjän valinta.
        this.laskuForm.get('tyyppi').setValue(edellinenTyyppi)

        const linkki = this._annaTettranLinkki(this.translationService.nykyinenKieli ?? 'fi')
        this.dialog.open(InfoDialog, {
          data: {
            header: this.translationService.lokalisoi('lasku.suomifi-valtuutus-puuttuu-head'),
            text: `
            ${this.translationService.lokalisoi('lasku.suomifi-valtuutus-puuttuu-teksti1')}
            <br>
            <br>
            <a href="${linkki}">${linkki}</a>
            <br>
            <br>
            ${this.translationService.lokalisoi('lasku.suomifi-valtuutus-puuttuu-teksti2')}`
          }
        })
      }
    })
  }

  private _annaTettranLinkki(kieli: TuettuKieli) {
    if (kieli === 'en') {
      return 'https://app.tettra.co/teams/lemontree/pages/suomifi-authorization'
    }
    return 'https://app.tettra.co/teams/lemontree/pages/suomifi-valtuutuksen-antaminen'
  }

  private _vaihdaLaskunTyyppi(laskunTyyppi: LaskunTyyppi) {
    this.kasiteltava.tyyppi = laskunTyyppi.avain
    this.laskunTyyppiObservable.next(laskunTyyppi)
    this.laskuObservable.next({ juurilasku: this.juurilasku, kasiteltava: this.kasiteltava })
  }

  paivitaValittuAsiakas(asiakas: LaskunAsiakas) {

    if (!asiakas) {
      return
    }

    this.kasiteltava.asiakas = asiakas

    // Aseta laskun tyyppi
    if (asiakas.laskunTyyppi && this.kasiteltava.tyyppi !== asiakas.laskunTyyppi) {
      this.kasiteltava.tyyppi = asiakas.laskunTyyppi
      this.asetaTyyppiLaskusta(this.juurilasku, this.kasiteltava, true)
    }

    // Aseta laskun valuutta
    if (asiakas.laskunValuutta && this.kasiteltava.valuutta !== asiakas.laskunValuutta) {
      this.kasiteltava.valuutta = asiakas.laskunValuutta
      this.laskuForm.get('valuutta').setValue(this.kasiteltava.valuutta)
    }

    // Aseta laskun kieli
    if (asiakas.laskunKieli && this.kasiteltava.kieli !== asiakas.laskunKieli) {
      this.kasiteltava.kieli = asiakas.laskunKieli
      this.laskuForm.get('kieli').setValue(this.kasiteltava.kieli)
    }

    // Päivitä kaikki.
    this.laskuObservable.next({ juurilasku: this.juurilasku, kasiteltava: this.kasiteltava })

  }

  private tarkistaOnkoTiedotOikein(): boolean {
    if (this.alvSumma + this.alvitonSumma + this.aiemminMaksettu > -0.01 && this.hyvitys) {
      this.hyvitysPositiivinenVirhe = true
      return false
    } else {
      this.hyvitysPositiivinenVirhe = false
    }
    const virheitaLomakkeella = this.validationService.onkoLomakkeessaVirheita(this.laskuForm)
    if (virheitaLomakkeella) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.laskuForm)
      this.paivitaValidaatiot = {}
      return false
    }
    return true
  }

  async save() {
    this.ladataanService.aloitaLataaminen()
    this.laskuService.tallennaLuonnos(this.juurilasku, this.kasiteltava).then(result => {
      const lokalisoitu = this.translationService.lokalisoi('lasku.tallennettu-onnistuneesti')
      this.snackbar.open(lokalisoitu, 'OK', { duration: 5000, verticalPosition: 'top' })
      this.location.replaceState('/laskutus/laskut/' + this.juurilasku.avain + '/' + this.kasiteltava.avain)
      const data: LaskuKatseleComponentExistingData = {
        juurilasku: this.juurilasku,
        kasiteltava: this.kasiteltava
      }
      this.laskuKatseleComponentDataResolve.asetaOlemassaolevaData(data)
      this.alustaNakymaKokonaan(this.asetukset, this.juurilasku, this.kasiteltava, this.reskontra)
      this.ladataanService.lopetaLataaminen()
    }).catch(err => {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
    })
  }

  vaihdaValilehti(indeksi: number) {
    this.aktiivinenValilehti.next(indeksi)
  }

  peruutaLahettaminen() {
    this.lahetysdata.next(null)
  }

  kasitteleLahetys(event: LaskunLahetystiedot) {
    if (!event.error && event.tapa) {
      const olemassaoleva: LaskuKatseleComponentExistingData = {
        juurilasku: event.lahetettyJuuri,
        kasiteltava: event.lahetettyKasiteltava
      }
      this.laskuKatseleComponentDataResolve.asetaOlemassaolevaData(olemassaoleva)
      if (event.tapa === LaskunLahetystapa.ITSE) {
        const nollaus: LaskutComponentData = {
          nollaaHakuasetukset: true
        }
        this.laskutResolve.asetaOlemassaolevaData(nollaus)
        const data: LaskuKatseleComponentExistingData = {
          juurilasku: event.lahetettyJuuri,
          kasiteltava: event.lahetettyKasiteltava
        }
        this.laskuKatseleComponentDataResolve.asetaOlemassaolevaData(data)
        this.router.navigate(['/laskutus/laskut', event.lahetettyJuuri.avain, event.lahetettyKasiteltava.avain, 'tulosta'])
      } else {
        const nollaus: LaskutComponentData = {
          nollaaHakuasetukset: true
        }
        this.laskutResolve.asetaOlemassaolevaData(nollaus)
        this.router.navigate(['/laskutus/laskut'])
      }
    } else {
      if (event.error) {
        this.ladataanService.naytaVirheDialog(event.error)
      }
    }
  }

  async saveAndSendEmail() {

    if (this.tarkistaOnkoTiedotOikein()) {

      const laskunTyyppi = this.laskuForm.get('tyyppi').value
      if (
        this.juurilasku.tila === LaskunTila.luonnos &&
        (this.alvSumma + this.alvitonSumma + this.aiemminMaksettu < 0) &&
        (laskunTyyppi === LaskunTyypit.EU_PALVELU || laskunTyyppi === LaskunTyypit.EU_TAVARA)
      ) {
        this.dialog.open(InfoDialog, {
          data: {
            text: this.translationService.lokalisoi('lasku.yhteisomyynnit-esta-hyvitys-popup')
          }
        })
        return
      }

      const data: LaskunLahetysdata = {
        juurilasku: this.juurilasku,
        asetukset: this.asetukset,
        kasiteltava: this.kasiteltava,
        reskontra: this.reskontra,
        huomautuskulu: null,
        korkokulu: null,
        muistutus: false
      }
      this.lahetysdata.next(data)

      if (this.kasiteltava.asiakas.viimeisinLaskuLahetetty) {
        switch (this.kasiteltava.asiakas.viimeisinLaskuLahetetty) {
          case LaskunLahetystapa.SAHKOPOSTI:
            this.aktiivinenValilehti.next(0)
            break
          case LaskunLahetystapa.SAHKOINEN:
            this.aktiivinenValilehti.next(1)
            break
          case LaskunLahetystapa.ITSE:
            this.aktiivinenValilehti.next(2)
            break
          case LaskunLahetystapa.PAPERI:
            this.aktiivinenValilehti.next(3)
            break
          default:
            this.aktiivinenValilehti.next(0)
        }
      } else {
        this.aktiivinenValilehti.next(0)
      }

      setTimeout(() => {
        const scrollable = document.querySelector('[cdk-scrollable]')
        if (scrollable) {
          scrollable.scrollTop = 0
        } else {
          window.scrollTo(0, 0)
        }
      }, 50)

    }
  }

  peruuta() {

    if (this.juurilasku && this.juurilasku.avain !== UUDEN_LASKUN_AVAIN) {
      const juurilaskunAvain = this.juurilasku.avain
      let kasiteltavanAvain = this.juurilasku.avain

      if (this.juurilasku.korvaus) {
        for (const korvaava of this.juurilasku.korvaus) {
          if (korvaava.avain !== UUDEN_LASKUN_AVAIN) {
            kasiteltavanAvain = korvaava.avain
          }
        }
      }

      this.router.navigate(['/laskutus/laskut', juurilaskunAvain, kasiteltavanAvain, 'katsele'])
    } else {
      this.router.navigate(['/laskutus/laskut'])
    }

  }

}
