<div class="mat-elevation-z4 image-box">
  <div class="image-buttons-container">
    <ng-container *ngIf="totalPages > 1">
      <div class="image-change-button lemon-mat-elevation-z4" [style.cursor]="currentPage > 1 ? 'pointer' : 'default'" (click)="previous()">
        < </div>
          <div class="image-change-button lemon-mat-elevation-z4" [style.cursor]="currentPage < totalPages ? 'pointer' : 'default'" (click)="next()">
            >
          </div>
          <div class="image-pages-indicator lemon-mat-elevation-z4">{{ currentPage }} / {{ totalPages }}</div>
    </ng-container>
  </div>
  <div app-tosite-kuva [url]="valittuKuva" [expandable]="true" class="mat-elevation-z4" style="width: 100%; position: relative; overflow-y: scroll;"></div>

  <button *ngIf="!lukittu" class="poista-btn mat-elevation-z4" mat-icon-button (click)="poista()" type="button">
    <mat-icon class="poista-icon">close</mat-icon>
  </button>
</div>