import { Component, OnInit, AfterViewInit, AfterContentChecked, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { ApixMaksutLopetaVastaanottoRequest, ApixMaksutLopetaVastaanottoResponse } from 'app/_jaettu/model/apix'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MatSnackBar } from '@angular/material/snack-bar'


export interface MaksutLopetaVastaanottoDialogData { }

@Component({
  templateUrl: './maksut-lopeta-vastaanotto.dialog.html'
})
export class MaksutLopetaVastaanottoDialog implements OnInit, AfterViewInit, AfterContentChecked {

  voidaanMitatoida = false

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MaksutLopetaVastaanottoDialogData,
    private dialogRef: MatDialogRef<MaksutLopetaVastaanottoDialog>,
    private _errorHandler: ErrorHandler,
    private _snackBar: MatSnackBar,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) { }

  ngAfterContentChecked() {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  private _saveStarted: boolean = false
  async tallennaVastaanotonLopetus() {
    if (this._saveStarted) {
      return
    }

    this._saveStarted = true
    this._ladataanService.aloitaLataaminen()

    try {

      const reqData: ApixMaksutLopetaVastaanottoRequest = {}
      const resp = await this._firebaseLemonaid.functionsCall<ApixMaksutLopetaVastaanottoRequest, ApixMaksutLopetaVastaanottoResponse>('apixLopetaLaskujenVastaanotto', reqData)

      if (!resp || resp.e) {
        /** Throw error to end up in the catch. */
        throw new Error('Forwarded address save failed! ' + (resp?.e || 'unknown-error'))
      }

      this.dialogRef.close()

    } catch (err) {
      const msg = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      this._snackBar.open(msg, null, { duration: 5000 })
      this._errorHandler.handleError(err)
    } finally {
      this._saveStarted = false
      this._ladataanService.lopetaLataaminen()
    }
  }

}
