<div class="blue-background">
  <div style="margin-left: auto; margin-right: auto">

    <div class="ylapalkki">

      <button class="sulje" mat-button (click)="peruuta()" type="button">
        <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
      </button>

      <h2 style="margin: 0; width: calc(100% - 18vw); text-align: center;">
        {{ 'maksut.muokkaa.otsikko-lisaa' | translate | async }}
      </h2>

    </div>

  </div>
</div>

<div class="kuitti-container">

  <form [formGroup]="form" novalidate autocomplete="kirjanpitoaineistoarkki" style="width: 100%;">

    <!-- <div *ngIf="naytaYhdistaKuitit" style="margin-bottom: 10px;">
      <mat-checkbox [disabled]="yhdistaminenDisabled" (change)="yhdistaKuitit($event)" style="font-size: 14px;">{{ 'kuitit.muokkaa.yhdista' | translate | async }}</mat-checkbox>
    </div> -->

    <div formArrayName="tositeArray">

      <div *ngFor="let group of tositeArray.controls; let i = index;" [formGroupName]="i" class="tosite-container">

        <div class="tosite-tiedot">

          <!-- <h1 *ngIf="naytaYhdistaKuitit" style="font-size: 28px; line-height: 34px; text-align: left;" class="light-blue-text">{{ 'kuitit.muokkaa.tosite' | translate | async }} {{i+1}}</h1> -->

          <!-- <div *ngIf="showMaksutapa" style="padding-bottom: 5px;">
        <div class="mat-form-field" style="font-size: 10.5px;">{{ 'kuitit.muokkaa.maksettu' | translate | async }} *</div>
        <app-kuitit-maksutapa formControlName="maksutapa" [filterNonActive]="true" [error]="group.get('maksutapa')?.errors?.length"></app-kuitit-maksutapa>
        <mat-error *ngIf="group.get('maksutapa'); let n">
          <ng-container *ngIf="(n?.dirty || n?.touched)">
            <ng-container *ngIf="(saajaVar?.ngControl?.dirty || saajaVar?.ngControl?.touched) && saajaVar.ngControl?.errors?.required">{{ 'kuitit.muokkaa.validation.maksutapa.required' | translate | async }}</ng-container>
          </ng-container>
        </mat-error>
      </div> -->

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.iban' | translate | async }}</mat-label>
            <input matInput class="alignLeft" #ibanVar="matInput" formControlName="iban" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(ibanVar?.ngControl?.dirty || ibanVar?.ngControl?.touched) && ibanVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.iban.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(ibanVar?.ngControl?.dirty || ibanVar?.ngControl?.touched) && ibanVar.ngControl?.errors?.invalidiban">{{ 'maksut.muokkaa.validation.iban.invalid' | translate | async }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.bic' | translate | async }}</mat-label>
            <input matInput class="alignLeft" #bicVar="matInput" formControlName="bic" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(bicVar?.ngControl?.dirty || bicVar?.ngControl?.touched) && bicVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.bic.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(bicVar?.ngControl?.dirty || bicVar?.ngControl?.touched) && bicVar.ngControl?.errors?.invalidbic">{{ 'maksut.muokkaa.validation.bic.invalid' | translate | async }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.saaja' | translate | async }}</mat-label>
            <input matInput class="alignLeft" #saajaVar="matInput" formControlName="saaja" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(saajaVar?.ngControl?.dirty || saajaVar?.ngControl?.touched) && saajaVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.saaja.required' | translate | async }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.pvm' | translate | async }}</mat-label>
            <input numberDatePicker matInput #pvmVar="matInput" formControlName="pvm" [name]="namename" [matDatepicker]="dayPicker" [min]="minDate" [max]="maxDate" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.pvm.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerParse">{{ 'maksut.muokkaa.validation.pvm.matDatepickerParse' | translate | async }}</mat-error>
            <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerMax">{{ 'maksut.muokkaa.validation.pvm.matDatepickerMax' | translate | async }}</mat-error>
            <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerMin">{{ 'maksut.muokkaa.validation.pvm.matDatepickerMin' | translate | async }}</mat-error>
            <mat-datepicker-toggle matSuffix [for]="dayPicker"></mat-datepicker-toggle>
            <mat-datepicker #dayPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.erapvm' | translate | async }}</mat-label>
            <input numberDatePicker matInput #erapvmVar="matInput" formControlName="erapvm" [name]="namename" [matDatepicker]="dueDatePicker" [min]="minDate" [max]="maxDate" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(erapvmVar?.ngControl?.dirty || erapvmVar?.ngControl?.touched) && erapvmVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.erapvm.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(erapvmVar?.ngControl?.dirty || erapvmVar?.ngControl?.touched) && erapvmVar.ngControl?.errors?.matDatepickerParse">{{ 'maksut.muokkaa.validation.erapvm.matDatepickerParse' | translate | async }}</mat-error>
            <mat-error *ngIf="(erapvmVar?.ngControl?.dirty || erapvmVar?.ngControl?.touched) && erapvmVar.ngControl?.errors?.matDatepickerMax">{{ 'maksut.muokkaa.validation.erapvm.matDatepickerMax' | translate | async }}</mat-error>
            <mat-error *ngIf="(erapvmVar?.ngControl?.dirty || erapvmVar?.ngControl?.touched) && erapvmVar.ngControl?.errors?.matDatepickerMin">{{ 'maksut.muokkaa.validation.erapvm.matDatepickerMin' | translate | async }}</mat-error>
            <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field>

          <br />

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.summa' | translate | async }}</mat-label>
            <input decimalNumberField matInput #summaVar="matInput" class="alignLeft" formControlName="summa" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
            <mat-error *ngIf="(summaVar?.ngControl?.dirty || summaVar?.ngControl?.touched) && summaVar.ngControl?.errors?.required">{{ 'maksut.muokkaa.validation.summa.required' | translate | async }}</mat-error>
            <mat-error *ngIf="(summaVar?.ngControl?.dirty || summaVar?.ngControl?.touched) && summaVar.ngControl?.errors?.min">{{ 'maksut.muokkaa.validation.summa.min' | translate | async }}</mat-error>
            <mat-error *ngIf="(summaVar?.ngControl?.dirty || summaVar?.ngControl?.touched) && summaVar.ngControl?.errors?.max">{{ 'maksut.muokkaa.validation.summa.max' | translate | async }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.viite' | translate | async }}</mat-label>
            <input matInput class="alignLeft" #viiteVar="matInput" formControlName="viite" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true">
            <mat-error *ngIf="(viiteVar?.ngControl?.dirty || viiteVar?.ngControl?.touched) && viiteVar.ngControl?.errors?.viitenumero">{{ 'maksut.muokkaa.validation.viite.viitenumero' | translate | async }}</mat-error>
            <mat-error *ngIf="(viiteVar?.ngControl?.dirty || viiteVar?.ngControl?.touched) && viiteVar.ngControl?.errors?.viestitaiviite">{{ 'maksut.muokkaa.validation.viite.viite-tai-viesti' | translate | async }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.viesti' | translate | async }}</mat-label>
            <textarea matInput formControlName="viesti" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="35" autocomplete="kirjnpitomekiarki"></textarea>
            <mat-error *ngIf="(viiteVar?.ngControl?.dirty || viiteVar?.ngControl?.touched) && viiteVar.ngControl?.errors?.viestitaiviite">{{ 'maksut.muokkaa.validation.viesti.viite-tai-viesti' | translate | async }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'maksut.muokkaa.lisatiedot' | translate | async }}</mat-label>
            <textarea matInput formControlName="lisatiedot" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="35" autocomplete="kirjnpitomekiarki"></textarea>
          </mat-form-field>

          <div class="button-container">
            <!-- <div *ngIf="tositeArray.controls?.length === i + 1"> -->

            <div *ngIf="virheviestiLatausOnKesken" class="error-text" style="padding-bottom: 2em;">
              {{virheviestiLatausOnKesken}}
            </div>

            <div *ngIf="virheviestiPysyva" class="error-text" style="padding-bottom: 2em;">
              {{virheviestiPysyva}}
            </div>

            <div *ngIf="(latausvirheetObservable | async)?.length > 0" class="error-text" style="padding-bottom: 2em; text-align: left;">
              {{'lasku.tiedoston-lahettaminen-epaonnistui-alku' | translate | async}}
              <ul>
                <li *ngFor="let virhe of latausvirheetObservable | async">{{virhe}}</li>
              </ul>
              {{'lasku.tiedoston-lahettaminen-epaonnistui-loppu' | translate | async}}
            </div>

            <button class="primarybutton" mat-button (click)="save()">{{'yleiset.tallenna' | translate | async}}</button>
            <br />

            <div *ngIf="1 > (latausvirheetObservable | async)?.length && 100 > (donePercentageObservable | async) && uploadTasks.length > 0" style="width:50%; margin-top: 2em; text-align: center; display: inline-block">
              <mat-progress-bar mode="determinate" [value]="donePercentageObservable | async"></mat-progress-bar>
              <div style="padding-top: 3px; font-size: 12px">{{ 'kuitit.muokkaa.ladataan' | translate | async }}</div>
            </div>

            <!-- </div> -->
          </div>

        </div>

        <div class="tosite-sivut">

          <div class="image-change-page-buttons-container" *ngIf="kuvat[i]?.length > 1">
            <div *ngFor="let kuva of kuvat[i]; let a = index;" class="image-change-page-button lemon-mat-elevation-z4" [class.selected-image]="kuva == nakyvatKuvat[i]" (click)="vaihdaNakyvaKuva(0, kuva)">
              {{a + 1}}
            </div>
          </div>

          <div app-tosite-kuva [url]="nakyvatKuvat[i]" class="mat-elevation-z4" style="width: 1080px; max-width: 100%; margin-bottom: 50px; position: relative; min-height: 50px;"></div>

        </div>

      </div>

    </div>
  </form>

</div>