import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { KayttajaService } from '../service/kayttaja.service'

import { LaskuService } from '../service/lasku/lasku.service'

import { Observable } from 'rxjs'
import { map, tap, mergeMap } from 'rxjs/operators'

@Injectable()
export class AsetuksetGuard  {

  constructor(
    private router: Router,
    private kayttajaService: KayttajaService,
    private laskuService: LaskuService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.kayttajaService.kayttajanTiedotObservable.pipe(
      mergeMap(kayttajanTiedot => {
        return this.laskuService.annaAsetukset(kayttajanTiedot)
      }),
      map(asetukset => !!asetukset),
      tap(asetuksetLoytyy => {
        if (!asetuksetLoytyy) {
          this.router.navigate(['/eiasetuksia'])
          return false
        }
        return true
      })
    )
  }

}
