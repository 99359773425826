<mat-dialog-content>

  <lemon-dialog-header>
    {{'kuitit.poista-dialog.otsikko' | translate | async}}
  </lemon-dialog-header>

  <div class="tosite-tiedot">
    {{annaLokalisoituLeipateksti() | async}}
  </div>

</mat-dialog-content>
<mat-dialog-actions style="padding-left: 0; padding-right: 0;">
  <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
  <span style="display: inline-block; width: 8vw; max-width: 4em;"></span>
  <button class="primarybutton" mat-button (click)="poista()">{{'kuitit.poista-dialog.poista' | translate | async}}</button>
</mat-dialog-actions>