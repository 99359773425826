<div mat-dialog-content class="all centered-content">
  <lemon-dialog-header>
    {{'lasku.perinta-sposti-dialog.vie-perintaan' | translate | async}}
  </lemon-dialog-header>

  <div class="container">
    <div>
      <p [innerHTML]="'lasku.perinta-sposti-dialog.laheta-teksti' | translate | async"></p>

      <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
        <button class="primarybutton" mat-button (click)="send()">{{'lasku.perinta-sposti-dialog.laheta' | translate | async}}</button>
      </div>
    </div>
  </div>
</div>