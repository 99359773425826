import { Injectable } from '@angular/core'

import { HolviOauthConnectRequest, HolviOauthConnectResponse } from '../../_jaettu/model/holvi'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { FirebaseLemonaid } from './firebase-lemonaid.service'
import { BankConsentLog } from 'app/_jaettu/model/banks'
import { Timestamp } from 'firebase/firestore'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'

@Injectable()
export class HolviDialogService {
  private _holviFlowCompletedAt: Date = null
  private _postponeStarted: Date = null

  constructor(
    private _timestampService: TimestampService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _bankConsentUriService: BankConsentUriService
  ) { }

  setHolviFlowCompleted() {
    this._holviFlowCompletedAt = new Date()
  }

  holviCompletedRecently(): boolean {
    if (!this._holviFlowCompletedAt) {
      return false
    }
    const oneHourInMilliseconds = 60 * 60 * 1000
    return new Date().getTime() - this._holviFlowCompletedAt.getTime() < oneHourInMilliseconds
  }

  postpone() {
    this._postponeStarted = new Date()
  }

  postponedRecently(): boolean {
    if (!this._postponeStarted) {
      return false
    }
    const oneHourInMilliseconds = 60 * 60 * 1000
    return new Date().getTime() - this._postponeStarted.getTime() < oneHourInMilliseconds
  }


  async fetchHolviOauthStartUrl(asiakasAvain: string) {
    const data: HolviOauthConnectRequest = {}

    const resp = await this._firebaseLemonaid.functionsCall<HolviOauthConnectRequest, HolviOauthConnectResponse>('holviOauthConnectStart', data)
    if (!resp || resp.e) {
      // await this.saveHistory(asiakasAvain, 'failed', resp?.e ?? null)
      throw new Error(resp ? resp.e : 'unknown-error')
    }
    // await this.saveHistory(asiakasAvain, 'success', null)
    return resp.redirectUrl
  }

  saveHistory(asiakasAvain: string, changedReason: string, details: string) {
    const logData: BankConsentLog = {
      asiakasAvain: asiakasAvain,
      source: 'holvi',
      changedTime: Timestamp.now(),
      changedReason: changedReason,
      details: details ?? null
    }
    const avain = this._firebaseLemonaid.firestoreCreateId()
    const uri = this._bankConsentUriService.getBankConsentLogUri(asiakasAvain, avain)

    return this._firebaseLemonaid.firestoreSetData(uri, logData)
  }

}
