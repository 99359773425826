import { Component, ChangeDetectionStrategy } from '@angular/core'
import { parseUserAgent, BrowserInfo, BotInfo, SearchBotDeviceInfo } from 'detect-browser'

@Component({
  selector: 'app-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsupportedBrowser {

  show: boolean = false
  minimiVersio = 0
  tunnistettuVersio = 0

  browserInfo = parseUserAgent(navigator.userAgent)

  constructor() {

    if (navigator.userAgent && navigator.userAgent.indexOf('Googlebot') > -1) {
      // NOOP, THIS IS GOOGLE BOT
    } else if (this.browserInfo) {
      this.tunnistettuVersio = this.annaVersio(this.browserInfo)
      if (this.browserInfo.name === 'ie') {
        this.show = true
      } else {
        if (this.browserInfo.name === 'safari') {
          this.minimiVersio = 15
        } else if (this.browserInfo.name === 'edge') {
          this.minimiVersio = 86
        } else if (this.browserInfo.name === 'firefox') {
          this.minimiVersio = 77
        } else if (this.browserInfo.name === 'ios') {
          this.minimiVersio = 15
        } else if (this.browserInfo.name === 'chrome') {
          this.minimiVersio = 86
        }
        this.show = this.tunnistettuVersio < this.minimiVersio
      }
    }
  }

  private annaVersio(browserInfo: BrowserInfo | BotInfo | SearchBotDeviceInfo): number {
    if (browserInfo.version) {
      if (browserInfo.version.indexOf('.') > -1) {
        const version = Number(browserInfo.version.split('.')[0])
        if (version && !isNaN(version)) {
          console.log('Tunnistettu selain ' + browserInfo.name + ' v' + version)
          return version
        }
      } else {
        const version = Number(browserInfo.version)
        if (version && !isNaN(version)) {
          console.log('Tunnistettu selain ' + browserInfo.name + ' v' + version)
          return version
        }
      }
    }
    return 99999
  }

}
