import { Component, OnInit, ErrorHandler, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'

import { KayttajaService } from './_angular/service/kayttaja.service'
import { VersionTarkistusPalvelu } from './_angular/service/version-tarkistus.palvelu'

import { LadataanService } from './_jaettu-angular/service/ladataan.service'

import { Laskuasetukset } from './_jaettu/model/lasku'
import { Roolit, Kayttaja, NewFeature } from './_jaettu/model/kayttaja'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from './vaihda-kieli.dialog'
import { VaihdaSalasanaDialogData, VaihdaSalasanaDialog } from './vaihda-salasana.dialog'

import { combineLatest, firstValueFrom, Observable, of as observableOf, Subject } from 'rxjs'
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators'
import { SelvitettavatTositteetService } from './_angular/service/tosite/selvitettavat-tositteet.service'
import { FirebaseLemonaid } from './_angular/service/firebase-lemonaid.service'
import { EinvoicesAngularService } from './_angular/service/tosite/einvoices-angular.service'
import { ApixReceivedInvoiceConfig } from './_jaettu/model/apix'
import { MaksutSharedStateService } from './maksut/maksut-shared-state.service'
import { UserSettingsService } from './_angular/service/user-settings.service'
import { TilinpaatosLemonaidUsersService } from './_angular/service/tilinpaatos-lemonaid-users.service'
import { TilinpaatosUserData } from './_jaettu/model/tilinpaatos'

type Alavalikko = 'laskutus' | 'tositteet' | 'palkat' | 'none' | 'raportit' | 'maksut'

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMenuComponent implements OnInit, OnDestroy {

  private _kayttaja: Kayttaja
  private _url: string

  @Output() vasemmanValikonSulkupyynto: EventEmitter<void> = new EventEmitter()

  @Input() asetukset: Laskuasetukset = null
  @Input() naytaSuper = false
  @Input()


  set kayttaja(kayttaja: Kayttaja) {
    this._kayttaja = kayttaja
    if (this._kayttaja && this._kayttaja.roolit) {
      this.naytaPalkat = this._kayttaja.roolit[Roolit.PALKAT] === true
      this.naytaLaskutus = this._kayttaja.roolit[Roolit.LASKUTUS] === true
      this.naytaMaksut = this._kayttaja.roolit[Roolit.MAKSUT] === true

      this.naytaTositteetOsto = this._kayttaja.roolit[Roolit.TOSITTEET_OSTO] === true
      this.naytaTositteetMyynti = this._kayttaja.roolit[Roolit.TOSITTEET_MYYNTI] === true
      this.naytaTositteetTiliotteet = this._kayttaja.roolit[Roolit.TOSITTEET_TILI] === true
      this.naytaTositteetPalkkamateriaalit = this._kayttaja.roolit[Roolit.TOSITTEET_PALKKA] === true
      this.naytaLaskujenVastaanotto = this._kayttaja.roolit[Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO] === true
      this.naytaTositteet = this.naytaTositteetOsto || this.naytaTositteetMyynti || this.naytaTositteetTiliotteet || this.naytaTositteetPalkkamateriaalit
      this.naytaSopimus = this._kayttaja.roolit[Roolit.HALLINTO_SOPIMUKSEN_HYVAKSYJA] === true
      this.naytaKirjanpito = this._kayttaja.roolit[Roolit.RAPORTIT] === true
    } else {
      this.naytaPalkat = false
      this.naytaLaskutus = false
      this.naytaMaksut = false

      this.naytaTositteet = false
      this.naytaTositteetOsto = false
      this.naytaTositteetMyynti = false
      this.naytaTositteetTiliotteet = false
      this.naytaTositteetPalkkamateriaalit = false
      this.naytaSopimus = false
      this.naytaKirjanpito = false
    }
  }
  get kayttaja(): Kayttaja {
    return this._kayttaja
  }

  @Input()
  set url(url: string) {
    this._url = url || ''
    if (this._url.startsWith('/laskutus/') ||
      this._url.startsWith('/asetukset') ||
      this._url === '/') {
      this.valittuAlavalikko = 'laskutus'
    } else if (this._url.startsWith('/tositteet/')) {
      this.valittuAlavalikko = 'tositteet'
    } else if (this._url.startsWith('/palkat')) {
      this.valittuAlavalikko = 'palkat'
    } else if (this._url.startsWith('/tilinpaatokset') || this._url.startsWith('/kirjanpidon-raportit')) {
      this.valittuAlavalikko = 'raportit'
    } else if (this._url.startsWith('/maksut')) {
      this.valittuAlavalikko = 'maksut'
    } else {
      this.valittuAlavalikko = 'none'
    }
  }
  get url(): string {
    return this._url
  }

  lemonaidAltTextObservable: Observable<string>
  naytaPankitObservable: Observable<boolean>
  selvitettaviaTositteitaObservable: Observable<number>
  showNewFeatureSignAtLaskujenVastaanottoObservable: Observable<boolean>
  showNewFeatureSignAtMaksutLaskujenVastaanottoObservable: Observable<boolean>
  showNewFeatureSignAtLaskutusAsetuksetObservable: Observable<boolean>
  numberOfUnapprovedEinvoicesObservable: Observable<number>
  einvoiceApprovingActivatedObservable: Observable<boolean>
  naytaTilinpaatoksetObservable: Observable<boolean>

  tositteetRequiringAttentionObservable: Observable<number>

  maksutEraantyneetCountObservable: Observable<number>
  maksutAvoimetCountObservable: Observable<number>

  // sopimuskausiTauolla: boolean = false

  naytaTositteet = false
  naytaTositteetOsto = false
  naytaTositteetMyynti = false
  naytaTositteetTiliotteet = false
  naytaTositteetPalkkamateriaalit = false
  naytaLaskujenVastaanotto = false
  naytaLaskujenHyvaksyminen = false
  naytaSopimus = false
  naytaMaksut = false
  naytaKirjanpito = false
  // naytaTinkAutentikointi = false
  // toggleMomentumScrollingOff = false
  naytaPalkat = false
  naytaLaskutus = false
  ylapalkkiNakyvissa = false
  vasenValikkoAuki = false
  vasemmanValikonTila: 'side' | 'over' = 'side'
  internetYhteys: 'hide' | 'show' = 'hide'
  tekstit = ''
  valittuAlavalikko: Alavalikko = 'laskutus'

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService,
    private _router: Router,
    private _dialog: MatDialog,
    private _ladataanService: LadataanService,
    private _versionTarkistusPalvelu: VersionTarkistusPalvelu,
    private _selvitettavatTositteetService: SelvitettavatTositteetService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _einvoicesAngularService: EinvoicesAngularService,
    private _maksutSharedStateService: MaksutSharedStateService,
    private _userSettingsService: UserSettingsService,
    private _tilinpaatosLemonaidUsersService: TilinpaatosLemonaidUsersService
  ) {

    this.maksutEraantyneetCountObservable = this._maksutSharedStateService.getDataSource().eraantyneetCountObservable
    this.maksutAvoimetCountObservable = this._maksutSharedStateService.getDataSource().avoimetCountObservable

    this.lemonaidAltTextObservable = this._versionTarkistusPalvelu.sovelluksenVersioObservable.pipe(
      map(versio => {
        if (versio) {
          console.log('Sovelluksen versio: ' + versio.binaarinversio + ', viimeisin: ' + versio.viimeisinversio)
          return 'Lemonaid ' + versio.binaarinversio
        }
        return 'Lemonaid'
      }),
      startWith('Lemonaid')
    )

    // this._kayttajaService.nykyinenAsiakasObservable.pipe(
    //   map(asiakas => {
    //     if (asiakas) {
    //       const today = this._dateService.currentLocalDate()
    //       const sopimuskausi = this._asiakasJaettuService.annaKausiPaivamaaralle(asiakas.sopimuskaudet, today)
    //       return sopimuskausi ? this._asiakasJaettuService.onkoSopimuskausiTauolla(sopimuskausi, today, false) : false
    //     }
    //     return false
    //   })
    // ).subscribe(tauolla => {
    //   this.sopimuskausiTauolla = tauolla
    // })

    this.selvitettaviaTositteitaObservable = this._kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja || !kayttaja.roolit[Roolit.TOSITTEET_OSTO] || !kayttaja.roolit[Roolit.TOSITTEET_MYYNTI]) {
          return observableOf<number>(0)
        }
        return this._selvitettavatTositteetService.annaSelvitettavienMaaraJaSummaObservable().pipe(
          map(values => values?.maara)
        )
      })
    )

    this.showNewFeatureSignAtLaskujenVastaanottoObservable = this._userSettingsService.userSettingsObservable.pipe(
      map(newFeatures => {
        if (!newFeatures?.seen[NewFeature.LASKUJEN_VASTAANOTTO]) {
          return true
        }
        return false
      })
    )

    this.showNewFeatureSignAtMaksutLaskujenVastaanottoObservable = this._userSettingsService.userSettingsObservable.pipe(
      map(newFeatures => {
        if (!newFeatures?.seen[NewFeature.MAKSUT_LASKUJEN_VASTAANOTTO]) {
          return true
        }
        return false
      })
    )

    this.showNewFeatureSignAtLaskutusAsetuksetObservable = this._userSettingsService.userSettingsObservable.pipe(
      map(newFeatures => {
        if (!newFeatures?.seen[NewFeature.AUTOMAATTINEN_RESKONTRA]) {
          return true
        }
        return false
      })
    )


    const einvoiceApprovingActivatedObservable = this._kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja || !kayttaja.roolit[Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO]) {
          return observableOf<number>(0)
        }
        return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttaja.asiakasAvain + '/apix-received-invoice-config/' + kayttaja.asiakasAvain).listen().pipe(
          map(config => !!config && config.forwardActive && config.forwardRequiresApproving)
        )
      })
    )

    this.numberOfUnapprovedEinvoicesObservable = combineLatest([this._kayttajaService.kayttajaObservable, einvoiceApprovingActivatedObservable]).pipe(
      switchMap(([kayttaja, approvingIsActive]) => {
        if (!kayttaja || !kayttaja.roolit[Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO]) {
          return observableOf<number>(0)
        }
        if (!approvingIsActive) {
          return observableOf<number>(0)
        }
        return this._einvoicesAngularService.unapprovedEinvoicesTotalSumAndNumberObservable().pipe(
          map(values => values?.maara)
        )
      })
    )


    this.tositteetRequiringAttentionObservable = combineLatest([this.selvitettaviaTositteitaObservable, this.numberOfUnapprovedEinvoicesObservable]).pipe(
      map(([selvitettavia, unapprovedEinvoices]) => {
        return (selvitettavia || 0) + (unapprovedEinvoices || 0)
      })
    )

    einvoiceApprovingActivatedObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(approvingIsActive => {
      this.naytaLaskujenHyvaksyminen = !!approvingIsActive
    })

    this.naytaTilinpaatoksetObservable = combineLatest([this._tilinpaatosLemonaidUsersService.tilinpaatosUsersObservable, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      map(([tilinpaatosUsers, kayttaja]) => {
        if (!kayttaja) {
          return false
        }
        return this._isTilinpaatosUser(kayttaja.uid, tilinpaatosUsers)
      })
    )
  }

  suljeVasenValikko() {
    this.vasemmanValikonSulkupyynto.emit()
  }

  ngOnInit() {

  }

  etusivulle() {
    this._router.navigate(['/'])
  }

  logout() {
    this._ladataanService.aloitaLataaminen()
    this.suljeVasenValikko()
    this._kayttajaService.logout().then(res => {
      this._router.navigate(['/kirjaudu']).then(() => {
        this._ladataanService.lopetaLataaminen()
      }).catch(err => {
        this._ladataanService.lopetaLataaminen()
        this._errorHandler.handleError(err)
      })
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
  }

  vaihdaKieli() {
    this.suljeVasenValikko()
    const data: VaihdaKieliDialogData = {
      tallenna: true
    }
    this._dialog.open(VaihdaKieliDialog, { data: data, autoFocus: false })
  }

  vaihdaSalasana() {
    this.suljeVasenValikko()
    const data: VaihdaSalasanaDialogData = {}
    this._dialog.open(VaihdaSalasanaDialog, { data: data, autoFocus: false, maxWidth: '350px' })
  }

  vaihdaAlavalikko(valittu: Alavalikko) {
    this.valittuAlavalikko = valittu

    if (this.valittuAlavalikko === 'laskutus') {
      this._router.navigate(['laskutus', 'laskut'])
    } else if (this.valittuAlavalikko === 'tositteet') {
      this._router.navigate(['tositteet', 'osto'])
    } else if (this.valittuAlavalikko === 'palkat') {
      this._router.navigate(['palkat'])
    } else if (this.valittuAlavalikko === 'raportit') {
      this._router.navigate(this.naytaKirjanpito ? ['kirjanpidon-raportit'] : ['tilinpaatokset'])
    } else if (this.valittuAlavalikko === 'maksut') {
      // The doc is already here, so no additional read is required
      firstValueFrom(this.showNewFeatureSignAtMaksutLaskujenVastaanottoObservable).then(isVisible => {
        if (isVisible) {
          this.updateSeen(NewFeature.MAKSUT_LASKUJEN_VASTAANOTTO)
        }
      })
      this._router.navigate(['maksut'])
    }

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }


  async updateSeen(seenFeature: NewFeature) {
    const kayttajanTiedot = await firstValueFrom(this._kayttajaService.kayttajanTiedotObservable)
    if (!kayttajanTiedot?.asiakasAvain) { return }

    return this._userSettingsService.setFeatureSeen(seenFeature, kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid, this._firebaseLemonaid)
  }

  private _isTilinpaatosUser(kayttajaAvain: string, tilinpaatosUsers: TilinpaatosUserData) {
    const userAsShareholder = tilinpaatosUsers?.osakkaatUsers?.find(shareholder => shareholder.lemonaidUid === kayttajaAvain)
    if (userAsShareholder) {
      return true
    }
    const userAsBoardMember = tilinpaatosUsers?.hallitusUsers?.find(boardMember => boardMember.lemonaidUid === kayttajaAvain)
    if (
      userAsBoardMember
    ) {
      return true
    }
    return false
  }

}
