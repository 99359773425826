/* eslint-disable @typescript-eslint/naming-convention */
import { LocalDate, NumberDate, NumberTimestamp, Timestamp } from '../../_shared-core/model/common'
import { ViiaAccountTokenInfo, ViiaTransaction } from './aiia'
import { BankConnectionSource } from './bank-connection'

// export interface TinkAnnaValtuudetPyynto {
//   code: string
// }

// export interface TinkValtuudenLaajuus {
//   key: 'accounts' | 'transactions' | 'statistics' | 'user'
//   level: 'read' | 'write'
// }

// export interface TinkAnnaValtuudetVastaus {
//   // data {
//   //   'token_type' : 'bearer',
//   //   'expires_in' : 7200,
//   //   'access_token' : '528c8fb86efa4d02bf33946bf56efca9',
//   //   'refresh_token' : '9366e0644aaa49f499d25999323c9060',
//   //   'scope' : 'accounts:read,transactions:read,statistics:read,user:read'
//   // }
//   accessToken: string
//   refreshToken: string
//   scope: TinkValtuudenLaajuus[]
//   voimassaSekuntia: number
// }

// export interface TinkRefreshValtuudetPyynto {
//   asiakasId: number
// }

// export interface TinkTilitapahtumatPyynto {
//   token: string
//   limit: number
// }

// export interface TinkTilitPyynto {
//   token: string
// }



// export interface NagAloitaKirjautuminenPyynto {
//   redirectUrl: string
//   kieli: TuettuKieli
// }

// export interface NagAloitaKirjautuminenVastaus {
//   authUrl: string
// }

// export interface NagHaeTokenPyynto {
//   koodi: string
// }

// export interface NagHaeTokenVastaus {
//   payload: any
// }

// export interface NagAccountsPyynto {
//   token: string
//   pagingToken?: string
// }

// export interface NagAccountsVastaus {
//   accounts: NagAccount[]
//   pagingToken?: string
// }

// export interface NagTransactionsPyynto {
//   token: string
//   /**
//    * id property of an account from a list of accounts returned by /v2/accounts
//    */
//   accountId: string
//   pagingToken?: string
//   withDetails?: boolean
//   fromDate?: LocalDate
// }

// export interface NagTransactionsVastaus {
//   transactions: NagTransaction[]
//   pagingToken?: string
// }

// export interface NagTransaction {
//   /**
//    * The ID of the transaction
//    */
//   'id': string
//   /**
//    * The booked date of the transaction: docs say: string<date>, example is '2019-05-27T00:00:00'
//    */
//   'date': string
//   /**
//    * Creation timestamp, if available. Often more meaningful to the user than the booked date: docs say: string <date-time>
//    */
//   'creationDate': string | null
//   /**
//    * A nice, user-friendly version of OriginalText. May be identical to OriginalText if no cleanup could be done.
//    */
//   'text': string
//   /**
//    * The transaction text as sent from the provider
//    */
//   'originalText': string
//   /**
//    * Additional details of the transaction. Only present if withDetails is set to true in the transactions request. All properties of the details object are populated on a best-effort basis.
//    */
//   'details': NagTransactionDetailsV2
//   /**
//    * Transaction category, if available.
//    */
//   'category': NagTransactionCategoryV1 | null
//   /**
//    * The amount and currency of the transaction.
//    */
//   'amount': NagAmountV2
//   /**
//    * The account balance after the transaction, if available.
//    */
//   'balance': NagAmountV2 | null
//   /**
//    * One of the following codes:
//    *   'Unknown' Type not known
//    *   'Card Card' payment
//    *   'PeerToPeer' Peer to peer apps, e.g. MobilePay and Vipp
//    *   'DirectDebit' E.g. "Betalingsservice" in DK
//    *   'BankTransfer' Regular account to account transfer
//    *   'CashWithdrawal' ATM withdrawal
//    *   'InternalCounterEntry' Exceptional, for "shadow account transfers"
//    */
//   'type': 'Unknown' | 'Card Card' | 'PeerToPeer' | 'DirectDebit' | 'BankTransfer' | 'CashWithdrawal' | 'InternalCounterEntry'
//   /**
//    * One of the following codes:
//    *   'Booked' The transaction has been executed and booked
//    *   'Reserved' The amount of the transaction has been reserved on the account
//    *   'Scheduled' The transaction is scheduled for execution
//    */
//   'state': 'Booked' | 'Reserved' | 'Scheduled'
// }

// interface NagTransactionCategoryV1 {
//   /**
//    * The ID of the category, which the transaction was found to belong to
//    */
//   id: string
//   /**
//    * The set ID of the category, which the transaction was found to belong to
//    */
//   setId: string
// }

// interface NagTransactionDetailsV2 {
//   /**
//    * Narrative, message or unstructured remittance information. Can contain multiple lines
//    */
//   message: string
//   /**
//    * The date from where interest is calculated on the transaction: docs say: string <date>
//    */
//   valueDate: string
//   /**
//    * When the transaction was sent: docs say: string <date>
//    */
//   executionDate: string
//   /**
//    * Cashback or points rewarded as a part of a loyalty or credit card purchase
//    */
//   reward: NagRewardV2 | null
//   /**
//    * Source or origin of the transaction
//    */
//   source: NagTransactionPartyV2
//   /**
//    * Destination of the transaction
//    */
//   destination: NagTransactionPartyV2
//   /**
//    * A set of identifiers related to the transactions, a part of the structured remittance information
//    */
//   identifiers: NagTransactionIdentifiersV2
//   /**
//    * Unstructured routing information of service providers involved in the processing of this transaction
//    */
//   routing: string
//   /**
//    * Details when a transaction had an implicit currency conversion
//    */
//   currencyConversion: NagCurrencyConversionV2
// }

// interface NagCurrencyConversionV2 {
//   /**
//    * The original amount before currency conversion
//    */
//   originalAmount: NagAmountV2
//   /**
//    * The applied currency exchange rate, where the booked transaction amount in local currency is ExchangeRate * OriginalAmount: docs say: number <double>
//    */
//   exchangeRate: number
// }

// interface NagTransactionIdentifiersV2 {
//   /**
//    * Proprietary reference for the provider
//    */
//   reference: string
//   /**
//    * Proprietary reference number for a specific document, such as an invoice number
//    */
//   document: string
//   /**
//    * Proprietary sequence number from card payments
//    */
//   sequenceNumber: string
//   /**
//    * Terminal identifier from card payments
//    */
//   terminal: string
//   /**
//    * Creditor Reference according to ISO 11649
//    */
//   creditorReference: string
//   /**
//    * End-to-end identification according to ISO 20022
//    */
//   endToEndId: string
// }

// interface NagTransactionPartyV2 {
//   /**
//    * Account details
//    */
//   account: NagAccountNumberV2
//   /**
//    * Name of the party
//    */
//   name: string
//   /**
//    * Unstructured address information. Can contain multiple lines
//    */
//   address: string
//   /**
//    * Merchant Category Code as defined in ISO 18245
//    */
//   merchantCategoryCode: string
//   /**
//    * Merchant Category Name as defined in ISO 18245
//    */
//   merchantCategoryName: string
// }

// interface NagRewardV2 {
//   /**
//    * Type of the reward. One of the following codes:
//    * 'Cashback' Appears when a cashback amount is found in the amount field
//    * 'Points' Appears when awarded proprietary points are found in the points field
//    */
//   type: 'Cashback' | 'Points'
//   /**
//    * Monetary amount rewarded for this transaction
//    */
//   amount: NagAmountV2 | null
//   /**
//    * Proprietary points rewarded by the provider: docs say: number <double>
//    */
//   points: number | null
// }

// export interface NagAccount {
//   'id': string
//   'providerId': string
//   'name': string
//   'owner': string
//   'number': NagAccountNumberV2
//   'bookedBalance': NagAmountV2
//   'availableBalance': NagAmountV2 | null
//   'type': 'Consumption' /* Normal payment account */ | 'LockedSavings' | 'Loan' | 'Card' /* Account is locked to a payment card */ | 'OpenSavings' | 'Unknown'
//   'features': {
//     'queryable': boolean
//     'psdPaymentAccount': boolean
//     'paymentFrom': boolean
//     'paymentTo': boolean
//   }
// }

// interface NagAmountV2 {
//   /**
//    * Value of the amount.
//    */
//   value: number
//   /**
//    * Currency of the amount, as a ISO 4217 currency code.
//    */
//   currency: string
// }

// interface NagAccountNumberV2 {
//   'bbanType': string
//   'bban': string
//   'iban': string
//   card: NagPaymentCardV2 | null
//   bbanParsed: NagParsedBbanV2
// }

// interface NagPaymentCardV2 {
//   /**
//    * Creditcard number, MUST be masked according to PCI requirements
//    */
//   maskedPan: string

//   /**
//    * Name of the card holder
//    */
//   cardHolder: string

//   /**
//    * Full year (4 digits) when the card expires
//    */
//   expireYear: number

//   /**
//    * Month when the card expires
//    */
//   expireMonth: number
// }

// /**
//  * Parsed BBAN number
//  */
// interface NagParsedBbanV2 {
//   /**
//    * Bank code, e.g. "0400"
//    */
//   bankCode: string
//   /**
//    * Bank specific account number, e.g. "3302021"
//    */
//   accountNumber: string
// }

export type AcceptedPsd2Connection = 'op' | 'handelsbanken-personal' | 'handelsbanken-business' | 'alandsbanken' | 's-pankki' | 'pop-pankki' | 'saastopankki' | 'oma-saastopankki' | 'danske-personal' | 'danske-business' | 'nordea-personal' | 'nordea-business' | 'nordea-corporate'


export interface GetRawTransactionsQuery {
  asiakasAvain: string
  iban: string
  start: LocalDate
  end: LocalDate
}

export interface GetRawTransactionsResponse {
  outcome: 'wait-for-user-consent' | 'needs-re-sync' | 'wait-for-sync-to-finish' | 'ok'
  accountInfo: ViiaAccountTokenInfo
  transactions: ViiaTransaction[]
}

export enum BankDialogType {
  GENERAL = 'general',
  GENERAL_WO_HOLVI = 'general without holvi',
  HOLVI_RENEW = 'holvi renew',
  PSD2_RENEW = 'psd2 renew'
}

/**
 * @connectionLost Note! Type is NumberTimestamp (yyMMddhhmmss), not NumberDate!
 */
export interface DialogToShowMetadata { providerId?: string, ibansMissing?: string[], ibansOk?: string[], dialogToShow: BankDialogType, connectionLost: NumberTimestamp }
export interface BankCustomerConsent {
  asiakasAvain: string

  /** User postponed */
  // postponed?: { until: NumberDate, dialogType: BankDialogType }[]

  dialogsToShow?: DialogToShowMetadata[]

  forceDialogToShow?: BankDialogType.GENERAL_WO_HOLVI

  dialogCompletePreventReShow?: BankDialogType[]
}

export interface BankConsentLog {
  asiakasAvain: string
  source: BankConnectionSource
  changedTime: Timestamp
  changedReason: string
  details?: string
}

export interface BankConsentExpiredSmsTyojono {
  firstCreated: NumberDate
}
