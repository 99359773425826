import { Component, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { AsiakkaanTuote } from '../../_jaettu/model/lasku'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

export interface TuotePoistoVarmistusDialogData {
  tuote: AsiakkaanTuote
}

@Component({
  templateUrl: './tuote-poisto-varmistus.dialog.html'
})
export class TuotePoistoVarmistusDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TuotePoistoVarmistusDialogData,
    private errorHandler: ErrorHandler,
    private dialogRef: MatDialogRef<TuotePoistoVarmistusDialog>,
    private ladataanService: LadataanService,
    private laskuService: LaskuService
  ) {

  }

  poista() {
    this.ladataanService.aloitaLataaminen()
    this.laskuService.poistaTuotteet([this.data.tuote]).then(result => {
      this.ladataanService.lopetaLataaminen()
      this.dialogRef.close(true)
    }).catch(err => {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
      this.dialogRef.close(true)
    })
  }

}
