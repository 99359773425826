
<mat-dialog-content>

  <lemon-dialog-header>
    <div *ngIf="voidaanMitatoida" >{{'lasku.mitatointi-dialog.voidaan.otsikko' | translate | async}}</div>
    <div *ngIf="!voidaanMitatoida">{{'lasku.mitatointi-dialog.ei-voida.otsikko' | translate | async}}</div>
  </lemon-dialog-header>

  <p *ngIf="voidaanMitatoida"  style="margin: 3em 3em 2em 3em;">{{'lasku.mitatointi-dialog.voidaan.teksti' | translate | async}}</p>
  <p *ngIf="!voidaanMitatoida" style="margin: 3em 3em 2em 3em;">{{'lasku.mitatointi-dialog.ei-voida.teksti' | translate | async}}</p>
  
</mat-dialog-content>

<mat-dialog-actions style="margin: 30px; margin-top: 12.5px; justify-content: center;">
  <button class="secondarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
  <button class="primarybutton"   mat-button *ngIf="voidaanMitatoida" (click)="mitatoi()" cdkFocusRegionstart>{{'lasku.mitatointi-dialog.voidaan.mitatoi' | translate | async}}</button>
</mat-dialog-actions>
