import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { TositteenLisaysprosessinOsanTunniste } from '../../_jaettu/model/kayttaja'
import { Maksutapa, Kuitti, FirestoreTosite, FirestoreTositteenAlkuperainenTiedosto } from '../../_jaettu/model/tosite'

import { TositeService, AlvPortaatJaoletus } from '../../_angular/service/tosite/tosite.service'
import { KayttajaService } from '../../_angular/service/kayttaja.service'
import { TositeLuoUusiComponentData } from '../../_angular/_resolvers/tosite.resolve'

import { TiedostojenLataamisService, UploadData } from '../../_jaettu-angular/service/tiedostojen-lataamis.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'

import { DateService } from '../../_shared-core/service/date.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'

import { FileSystemFileEntry } from 'ngx-file-drop'

import { Observable, Subject } from 'rxjs'
import { takeUntil, tap, map } from 'rxjs/operators'
import { TositeKuvaCacheService } from '../kuvat/tosite-kuva-cache.service'
import { TositeDatasourceService } from 'app/_angular/service/tosite/tosite-datasource.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { QrTaiViivakoodi } from 'app/_angular/service/barcode/barcode-detector.service'

@Component({
  templateUrl: './tosite.luo-uusi.component.html',
  styleUrls: ['./tosite.luo-uusi.component.css']
})
export class TositeLuoUusiComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  maxDate: Date = new Date(2099, 11, 31)
  minDate: Date = new Date(2010, 0, 1)

  form: UntypedFormGroup

  yhdistaminenDisabled = false
  uploadTasks: UploadData[] = []
  kuitit: FirestoreTosite[] = []
  alvPortaat: number[] = null
  naytaYhdistaKuitit = false
  virheviestiLatausOnKesken: string = null
  virheviestiPysyva: string = null
  muokataan: boolean = false
  paivitaValidaatiot = {}
  donePercentageObservable: Observable<number>
  latausvirheetObservable: Observable<string[]>

  kuvat: string[][] = []
  nakyvatKuvat: string[] = []

  private oletusAlv: number = null
  alvPortaatObservable: Observable<AlvPortaatJaoletus>


  otsikko: string
  pvmText: string
  summaText: string
  showAlv: boolean = false
  showSumma: boolean = false
  showMaksutapa: boolean = false
  showPvm: boolean = false
  showPvmAsMonth: boolean = false

  namename = 'asfasf' + Math.random()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tositeService: TositeService,
    private ladataanService: LadataanService,
    private dateService: DateService,
    private validationService: FormValidationService,
    private timestampService: TimestampService,
    private currencyService: CurrencyService,
    private kayttajaService: KayttajaService,
    private tiedostojenLataamisService: TiedostojenLataamisService,
    private translationService: LemonTranslationService,
    private errorHandler: ErrorHandler,
    private tositeKuvaCacheService: TositeKuvaCacheService,
    private tositeDatasourceService: TositeDatasourceService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this.maxDate = this.dateService.lisaaKuukausia(new Date(), 12)
    this.minDate = this.dateService.lisaaKuukausia(new Date(), -48)
  }

  ngOnInit() {

    this.form = new UntypedFormGroup({
      'tositeArray': new UntypedFormArray([], [this.vahintaaYksiRiviValidator])
    })

    this.route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async (data: { data: TositeLuoUusiComponentData }) => {

      if (!data || !data.data || !data.data.tiedostot || data.data.tiedostot.length < 1) {
        this.peruuta()
        return
      }

      this.naytaYhdistaKuitit = data.data.tiedostot.length > 1

      // Separate display settings for different tosite types
      const lahde = this.tositeService.getMaksutapaNameForRouter(data.data?.maksutapa?.i)
      this.setTextsAndFieldsForToTositeType(lahde)
      await this.tositeService.setLastViewedKuitinMaksutapa(data.data?.maksutapa?.i)

      const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
      for (const tiedosto of data.data.tiedostot) {

        const kuitti = this.tositeService.annaUusiKuitti(data.data.maksutapa)
        if (data.data.maksutapa) {
          kuitti.maksutapa = data.data.maksutapa.i
        }
        const tositeGroup = this.annaKuitinFormGroup(kuitti)

        this.kuitit.push(kuitti)
        this.tositeArray.push(tositeGroup)

        const fileEnding = this.tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)
        const file = await this.tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

        const alkuperainen: FirestoreTositteenAlkuperainenTiedosto = {
          avain: this._firebaseLemonaid.firestoreCreateId(),
          kuvakansio: kuitti.kuvakansio,
          nimi: tiedosto.relativePath,
          fileEnding: fileEnding,
          kasitelty: false
        }
        kuitti.alkuperaiset[alkuperainen.avain] = alkuperainen

        const uploadData = this.tiedostojenLataamisService.tallennaTositetiedosto(this._firebaseLemonaid, kayttajanTiedot.asiakasId + '', alkuperainen, file)
        this.uploadTasks.push(uploadData)

        const purettuTiedosto = await this.tositeService.puraYksiTiedosto(kuitti, file, tiedosto, fileEnding, uploadData.uploadUri, alkuperainen, kayttajanTiedot, true, Promise.resolve({}))
        this._asetaSumma(purettuTiedosto.koodi, tositeGroup)
        if (purettuTiedosto.virhe) {
          this.virheviestiPysyva = this.virheviestiPysyva ? this.virheviestiPysyva + ' ' + purettuTiedosto.virhe : purettuTiedosto.virhe
        }
        if (purettuTiedosto.kasitellytKuvat) {
          for (const kuva of purettuTiedosto.kasitellytKuvat) {
            kuitti.kuvat[kuva.avain] = kuva
          }
        }

        const tamanKuitinKuvat = this.tositeKuvaCacheService.annaKuvienUrlit(kuitti, kayttajanTiedot, 'full')
        this.kuvat.push(tamanKuitinKuvat)
        this.nakyvatKuvat.push(tamanKuitinKuvat.length > 0 ? tamanKuitinKuvat[0] : 'asdfdsfdsfdfsasdffss')
      }

      this.donePercentageObservable = this.tiedostojenLataamisService.annaKokonaisprosentti(this.uploadTasks).pipe(
        tap(percentage => {
          if (percentage > 99.99) {
            this.virheviestiLatausOnKesken = null
          }
        })
      )
      this.latausvirheetObservable = this.tiedostojenLataamisService.annaKaikkiVirheet(this.uploadTasks)

      this.asetaOletusprosentit(this.oletusAlv, this.tositeArray, this.kuitit)

    }, error => {
      this.errorHandler.handleError(error)
    })

    const alvPortaatObservable = this.kayttajaService.kayttajaObservable.pipe(
      map(kayttaja => {
        if (kayttaja && kayttaja.alvVahennysoikeusPortaat && kayttaja.prosessinOsat) {

          const prosessissaAlv = kayttaja.prosessinOsat.filter(a => { return a.tunniste === TositteenLisaysprosessinOsanTunniste.ALV_VAHENNYSOIKEUS }).length > 0
          if (prosessissaAlv) {
            const portaat: number[] = []

            let def: number | null = null
            for (const porras of kayttaja.alvVahennysoikeusPortaat) {
              if (!isNaN(porras.arvo)) {
                if (porras.oletus) {
                  def = porras.arvo
                }
                portaat.push(porras.arvo)
              }
            }

            portaat.sort((a, b) => {
              return a - b
            })

            return portaat.length > 0 ? { portaat: portaat, oletusProsentti: def } : null
          } else {
            return null
          }

        } else {
          return null
        }
      })
    )

    alvPortaatObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(portaatJaOletus => {
      this.oletusAlv = portaatJaOletus ? portaatJaOletus.oletusProsentti : null
      this.alvPortaat = portaatJaOletus ? portaatJaOletus.portaat : null
      this.asetaOletusprosentit(this.oletusAlv, this.tositeArray, this.kuitit)
    }, error => {
      this.errorHandler.handleError(error)
    })

  }
  get tositeArray(): UntypedFormArray {
    return this.form.get('tositeArray') as UntypedFormArray
  }

  private _asetaSumma(koodiPromise: Promise<QrTaiViivakoodi>, tositeGroup: UntypedFormGroup) {
    koodiPromise.then(koodi => {
      if (koodi?.viiva?.summa && !tositeGroup.get('summa').value) {
        tositeGroup.get('summa').setValue(this.currencyService.muutaBigDecimalRahaksi(koodi.viiva.summa))
      } else if (koodi?.qr?.summa && !tositeGroup.get('summa').value) {
        tositeGroup.get('summa').setValue(this.currencyService.roundHalfUp(koodi?.qr.summa, 2))
      }
    })
  }

  private annaKuitinFormGroup(kuitti: FirestoreTosite): UntypedFormGroup {

    const tositeGroup = new UntypedFormGroup({
      'maksutapa': new UntypedFormControl({ value: kuitti.maksutapa, disabled: !this.showMaksutapa }, [Validators.required]),
      'pvm': new UntypedFormControl(this.dateService.timestampToDate(kuitti.pvm), [Validators.required]),
      'summa': new UntypedFormControl({ value: kuitti.summa, disabled: !this.showSumma }, [Validators.required, Validators.min(0.01), Validators.max(999999.99)]),
      'lisatiedot': new UntypedFormControl(kuitti.selite, []),
      'alv': new UntypedFormControl({ value: kuitti.alvVahennysoikeus, disabled: !this.showAlv }, [])
    })

    tositeGroup.get('maksutapa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.maksutapa = value
    })
    tositeGroup.get('pvm').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      kuitti.pvm = this.timestampService.dateToTimestamp(value)
      kuitti.localPvm = this.dateService.dateToLocalDate(value)
      kuitti.p = this.dateService.dateToNumber(value)
    })
    tositeGroup.get('summa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.summa = value
      kuitti.sum = value
    })
    tositeGroup.get('lisatiedot').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.selite = value
    })
    tositeGroup.get('alv').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.alvVahennysoikeus = value
    })
    return tositeGroup
  }

  vaihdaNakyvaKuva(index: number, url: string) {
    this.nakyvatKuvat[index] = url
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  async yhdistaKuitit(change: MatCheckboxChange) {
    if (change.checked) {
      this.yhdistaminenDisabled = true
      const ekaControlli = this.tositeArray.controls[0]
      const ekaKuitti = this.kuitit.shift()

      const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
      this.tositeKuvaCacheService.siirraKuvatKuitille(kayttajanTiedot, this.kuitit, ekaKuitti)
      const tamanKuitinKuvat = this.tositeKuvaCacheService.annaKuvienUrlit(ekaKuitti, kayttajanTiedot, 'full')
      this.kuvat = [tamanKuitinKuvat]
      this.nakyvatKuvat = [tamanKuitinKuvat.length > 0 ? tamanKuitinKuvat[0] : 'asdfdsfdsfdfsasdffss']

      this.tositeArray.controls = [ekaControlli]
      this.kuitit = [ekaKuitti]
    }
  }


  public valitseMaksutapa(maksutapa: Maksutapa, kuitti: Kuitti) {
    kuitti.p = maksutapa.i
  }

  private _saveInFlight = false
  async save() {

    if (this._saveInFlight) {
      return
    }
    this._saveInFlight = true

    try {

      let stopSave: boolean = false

      for (const control of this.tositeArray.controls) {
        const onkoTallentaminenEstetty = await this.tositeService.saveIsPrevented(control as UntypedFormGroup, this.virheviestiPysyva, this.latausvirheetObservable, this.uploadTasks)
        if (onkoTallentaminenEstetty?.tallennusEstetty) {
          if (onkoTallentaminenEstetty.lataaminenKesken) {
            this.virheviestiLatausOnKesken = this.translationService.lokalisoi('kuitit.muokkaa.validation.ladataan')
          }
          this.validationService.merkitseKokoLomakeKosketuksi(control as UntypedFormGroup)
          setTimeout(() => { this.paivitaValidaatiot = {} }, 0)
          stopSave = true
        }
      }
      if (stopSave) {
        return
      }

      this.ladataanService.aloitaLataaminen()

      await this.tositeService.tallennaKuitit(this.kuitit)

      // Pre-select the maksutapa that the user will land to at list view
      const lastKuitti = this.kuitit[this.kuitit.length - 1]
      await this.tositeService.setLastViewedKuitinMaksutapa(lastKuitti.maksutapa)

      const targetLocation = this.tositeService.getMaksutapaNameForRouter(lastKuitti.maksutapa)
      this.tositeDatasourceService.changeVuosiKk(lastKuitti.localPvm.year, lastKuitti.localPvm.month - 1)

      this.router.navigate(['tositteet', targetLocation])

    } catch (error) {
      this.virheviestiPysyva = this.translationService.lokalisoi('tositteet.yhteiset.tallentaminen-epaonnistui')
      this.errorHandler.handleError(error)
    } finally {
      this._saveInFlight = false
      this.ladataanService.lopetaLataaminen()
    }

  }

  peruuta() {
    // Pre-select the maksutapa that the user will land to at list view
    const lastKuitti = this.kuitit[this.kuitit.length - 1]

    const targetLocation = this.tositeService.getMaksutapaNameForRouter(lastKuitti.maksutapa)
    this.router.navigate(['tositteet', targetLocation])
  }
  private asetaOletusprosentit(oletusProsentti: number, controllit: UntypedFormArray, kuitit: FirestoreTosite[]) {
    if (oletusProsentti !== null && oletusProsentti !== undefined && kuitit && controllit) {
      let i = 0
      for (const kuitti of kuitit) {
        if (kuitti.alvVahennysoikeus === null || kuitti.alvVahennysoikeus === undefined) {
          kuitti.alvVahennysoikeus = oletusProsentti
          controllit.controls[i].get('alv').setValue(oletusProsentti)
        }
        i++
      }
    }
  }
  private vahintaaYksiRiviValidator = (c: UntypedFormArray) => {
    if (c.length < 1) {
      return { min: 'true' }
    }
    return null
  }
  private setTextsAndFieldsForToTositeType(lahde: 'osto' | 'myynti' | 'tiliote' | 'palkka') {
    if (lahde === 'myynti') {

      this.otsikko = this.translationService.lokalisoi('kuitit.muokkaa.otsikko-lisaa')
      this.pvmText = this.translationService.lokalisoi('tositteet.myynti.muokkaa.pvm')
      this.summaText = this.translationService.lokalisoi('tositteet.myynti.muokkaa.summa')
      this.showSumma = true
      this.showPvm = true
    } else if (lahde === 'tiliote') {

      this.otsikko = this.translationService.lokalisoi('tositteet.tiliote.muokkaa.otsikko-lisaa')
      this.pvmText = this.translationService.lokalisoi('tositteet.tiliote.muokkaa.pvm')
      this.showPvm = true
      this.showPvmAsMonth = true

    } else if (lahde === 'palkka') {

      this.otsikko = this.translationService.lokalisoi('tositteet.palkka.muokkaa.otsikko-lisaa')
      this.pvmText = this.translationService.lokalisoi('tositteet.palkka.muokkaa.pvm')

    } else {
      // All others are osto
      this.otsikko = this.translationService.lokalisoi('kuitit.muokkaa.otsikko-lisaa')
      this.pvmText = this.translationService.lokalisoi('kuitit.muokkaa.maksupvm')
      this.summaText = this.translationService.lokalisoi('kuitit.muokkaa.summa')
      this.showMaksutapa = true
      this.showSumma = true
      this.showAlv = true
      this.showPvm = true
    }
  }
}
