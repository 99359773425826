import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core'
import { KuvaAvainUrlJaJarjestys } from './tositteen.muokkaus.component'

@Component({
  selector: 'app-tositteen-pdf-images-box',
  templateUrl: './tositteen.muokkaus.pdf-image-box.component.html',
  styleUrls: ['./tositteen.muokkaus.component.css', './tositteen.muokkaus.pdf-image-box.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TositteenMuokkausPdfImageBox {

  totalPages: number
  currentPage: number
  private kaikkiKuvat: KuvaAvainUrlJaJarjestys[]
  valittuKuva: string

  @Input() lukittu: boolean = false
  @Input() set inputPdfData(pdfData: KuvaAvainUrlJaJarjestys[]) {
    this.kaikkiKuvat = pdfData
    this.totalPages = pdfData.length || 0
    this.currentPage = 1
    this.openCurrentPage()
  }
  @Output() poistaKuvat = new EventEmitter()

  next() {
    if (this.currentPage + 1 <= this.totalPages) {
      this.currentPage += 1
      this.openCurrentPage()
    }
  }
  previous() {
    if (this.currentPage - 1 >= 1) {
      this.currentPage -= 1
      this.openCurrentPage()
    }
  }
  openCurrentPage() {
    const valittuKuvaIfFound = this.kaikkiKuvat.find(kuva => kuva.jarjestys === this.currentPage)
    if (!valittuKuvaIfFound) {
      this.valittuKuva = this.kaikkiKuvat?.length ? this.kaikkiKuvat.sort((a, b) => a.jarjestys - b.jarjestys)[0]?.url : null
    } else {
      this.valittuKuva = valittuKuvaIfFound.url
    }
  }
  poista() {
    this.poistaKuvat.emit()
  }

}
