import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { KayttajaService } from '../service/kayttaja.service'

import { combineLatest, Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { TilinpaatosLemonaidUsersService } from '../service/tilinpaatos-lemonaid-users.service'

@Injectable()
export class TilinpaatosUsersGuard {

  constructor(
    private _router: Router,
    private _tilinpaatosLemonaidUsersService: TilinpaatosLemonaidUsersService,
    private _kayttajaService: KayttajaService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this._tilinpaatosLemonaidUsersService.tilinpaatosUsersObservable, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      map(([tilinpaatosUsers, kayttajanTiedot]) => {
        const userAsShareholder = tilinpaatosUsers?.osakkaatUsers?.find(shareholder => shareholder.lemonaidUid === kayttajanTiedot.uid)
        if (userAsShareholder) {
          return true
        }
        const userAsBoardMember = tilinpaatosUsers?.hallitusUsers?.find(boardMember => boardMember.lemonaidUid === kayttajanTiedot.uid)
        if (
          userAsBoardMember
          // &&
          // (
          //   userAsBoardMember.role === 'toimitusjohtaja' ||
          //   userAsBoardMember.role === 'jasen' ||
          //   userAsBoardMember.role === 'puheenjohtaja'
          // )
        ) {
          return true
        }
      }),
      tap(allowed => {
        if (!allowed) {
          this._router.navigate(['/eioikeuksia'])
          return false
        }
        return true
      })
    )
  }

}
