import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { TositeService } from '../../_angular/service/tosite/tosite.service'
import { TositeKatseleComponentDataResolve, TositeMuokkaaComponentDataResolve, TositeMuokkaaComponentData } from '../../_angular/_resolvers/tosite.resolve'

import { FirestoreTosite, TositeAutokirjaustyyppi, MYYNTITOSITE_MAKSUTAPA_ID, PALKKATOSITE_MAKSUTAPA_ID, TILIOTETOSITE_MAKSUTAPA_ID, TositeAutokirjausdata } from '../../_jaettu/model/tosite'

import { TositeLukittuDialog } from '../tosite-lukittu.dialog'
import { MatDialog } from '@angular/material/dialog'
import { SelvitettavatTositteetService, SelvitettavaListausFirestoreKuitti } from 'app/_angular/service/tosite/selvitettavat-tositteet.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'

import { Subject, Observable, firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'

type TositeTyyppi = 'osto' | 'myynti' | 'palkka' | 'tiliote'

@Component({
  selector: 'app-selvitettavat-tositteet',
  templateUrl: './selvitettava-tosite.listaus.component.html',
  styleUrls: ['./selvitettava-tosite.listaus.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelvitettavaTositeListausComponent implements OnInit, OnDestroy {

  @Input() tositeTyyppi?: TositeTyyppi
  @Input() showLoading: boolean = true

  @Output() selvitettavatDone: EventEmitter<void> = new EventEmitter<void>(true)

  private ngUnsubscribe = new Subject<void>()

  naytaHuomautus = false
  naytaKaikkiSummat = true

  kuvavirheAvain: string = null
  kuvavirheParametrit: any = null

  lataaObservable: Observable<boolean>
  naytettavatKuititObservable: Observable<SelvitettavaListausFirestoreKuitti[]>

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _tositeService: TositeService,
    private _selvitettavatTositteetService: SelvitettavatTositteetService,
    private _kuittiMuokkaaComponentDataResolver: TositeMuokkaaComponentDataResolve,
    private _kuittiKatseleComponentDataResolver: TositeKatseleComponentDataResolve,
    private _dialog: MatDialog,
    private _translationService: LemonTranslationService
  ) { }

  ngOnInit() {

    this.lataaObservable = this._selvitettavatTositteetService.lataaObservable

    this.naytettavatKuititObservable = this._selvitettavatTositteetService.selvitettavatObservable.pipe(
      map(selvitettavat => {
        // Show only selvitettävät belonging to a specific tosite type (off by default)
        if (this.tositeTyyppi) {
          return this.filterByTositeTyyppi(selvitettavat, this.tositeTyyppi)
        }
        return selvitettavat
      })
    )

  }

  private filterByTositeTyyppi(tositteet: SelvitettavaListausFirestoreKuitti[], tyyppi: TositeTyyppi): SelvitettavaListausFirestoreKuitti[] {
    if (tyyppi === 'osto') {
      return tositteet.filter(t => ![MYYNTITOSITE_MAKSUTAPA_ID, PALKKATOSITE_MAKSUTAPA_ID, TILIOTETOSITE_MAKSUTAPA_ID].includes(t.maksutapa))
    }
    if (tyyppi === 'myynti') {
      return tositteet.filter(t => t.maksutapa === MYYNTITOSITE_MAKSUTAPA_ID)
    }
    if (tyyppi === 'palkka') {
      return tositteet.filter(t => t.maksutapa === PALKKATOSITE_MAKSUTAPA_ID)
    }
    if (tyyppi === 'tiliote') {
      return tositteet.filter(t => t.maksutapa === TILIOTETOSITE_MAKSUTAPA_ID)
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  async merkitseYksityisotoksi(event: MouseEvent, tosite: FirestoreTosite) {

    // Stop bubbling to clickable parent
    event.preventDefault()
    event.stopPropagation()

    const title = this._translationService.lokalisoi('tositteet.yksityisotto.dialog.title')
    const message = this._translationService.lokalisoi('tositteet.yksityisotto.dialog.message')
    const mark = this._translationService.lokalisoi('tositteet.yksityisotto.dialog.action')

    const data: AreYouSureDialogData = {
      header: title,
      text: message,
      rightAction: mark
    }

    this._dialog.open(AreYouSureDialog, { data: data }).afterClosed().subscribe(async yes => {
      if (yes) {

        delete tosite.m
        delete tosite.w
        delete tosite.x
        tosite.selite = this._translationService.lokalisoi('tositteet.yksityisotto.receipt-comment')
        if (!tosite.b) {
          tosite.b = {}
        }

        let kuvia = false
        for (const kuvanAvain of Object.keys(tosite.kuvat ?? {})) {
          const mahdollinen = tosite.kuvat[kuvanAvain]
          if (!mahdollinen.poistettu) {
            kuvia = true
            break
          }
        }
        const poistettu = !!(tosite.z && !kuvia)
        tosite.poistettu = poistettu
        const yksityisottoData: TositeAutokirjausdata = { t: TositeAutokirjaustyyppi.OMALLA_RAHALLA }
        tosite.b[TositeAutokirjaustyyppi.OMALLA_RAHALLA] = yksityisottoData
        const onkoVielaSelvitettavia = (await firstValueFrom(this.naytettavatKuititObservable).then(kuitit => kuitit?.length || 0)) > 1
        if (!onkoVielaSelvitettavia) {
          this.selvitettavatDone.emit()
        }

        await this._tositeService.tallennaKuitti(tosite)
      }
    })

  }

  async muokkaa(event: MouseEvent, tosite: FirestoreTosite) {
    // Stop bubbling to clickable parent
    event.preventDefault()
    event.stopPropagation()

    if (tosite.l) {
      this._dialog.open(TositeLukittuDialog)
    } else {
      const amountOfSelvitettavia = await firstValueFrom(this.naytettavatKuititObservable).then(kuitit => kuitit?.length || 0)

      const muokkaaData: TositeMuokkaaComponentData = {
        tosite: tosite || null,
        onkoVielaSelvitettavia: amountOfSelvitettavia > 1
      }
      this._kuittiMuokkaaComponentDataResolver.asetaOlemassaolevaData(muokkaaData)
      this._router.navigate(['tositteet', 'muokkaus', tosite.avain])
    }
  }
  katsele(tosite: FirestoreTosite) {
    this._kuittiKatseleComponentDataResolver.asetaOlemassaolevaData({ tosite: tosite })
    this._router.navigate([tosite.avain], { relativeTo: this._route })
  }
  trackKuitti(index: number, kuitti: SelvitettavaListausFirestoreKuitti) {
    return kuitti.avain
  }
}
