import { Component, OnInit, AfterContentChecked, ErrorHandler, ViewChild, AfterViewInit, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { FormValidationService, FieldErrors } from '../_jaettu-angular/service/form-validation.service'
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms'

import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { KayttajaService } from '../_angular/service/kayttaja.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

import { VaihdaKieliDialogData, VaihdaKieliDialog } from '../vaihda-kieli.dialog'
import { AloitaSalasananPalautusProsessiPyynto, AloitaSalasananPalautusProsessiVastaus } from '../_jaettu/model/common'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  templateUrl: './forgot.component.html',
  styleUrls: ['./login.component.css']
})
export class ForgotComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @ViewChild(MatInput) emailInput: MatInput

  loginForm: UntypedFormGroup
  fieldErrors: FieldErrors = {}
  commonError: string
  success = false
  online = true
  year = new Date().getFullYear()

  constructor(
    private kayttajaService: KayttajaService,
    private dialog: MatDialog,
    private router: Router,
    private validationService: FormValidationService,
    private errorHandler: ErrorHandler,
    private ladataanService: LadataanService,
    private translationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this.kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
      if (kayttajanTiedot) {
        this.router.navigate(['/'])
      }
    })
  }

  ngAfterContentChecked() {
    this.fieldErrors = this.validationService.updateValidationStatus('login', this.loginForm)
  }

  private emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null
    }
    return Validators.email(control)
  }

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      'email': new UntypedFormControl('', [Validators.required, this.emailValidator])
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.emailInput.focus() }, 0)
  }

  setLoginProcessStarted() {
    this.ladataanService.aloitaLataaminen()
  }

  setLoginProcessEnded() {
    this.ladataanService.lopetaLataaminen()
  }

  @HostListener('document:keydown.esc')
  back() {
    this.router.navigate(['/kirjaudu'])
  }

  @HostListener('document:keydown.enter')
  login() {

    if (!this.loginForm.valid) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
      return
    }

    this.setLoginProcessStarted()
    this.commonError = null
    this.fieldErrors = {}

    const req: AloitaSalasananPalautusProsessiPyynto = {
      email: this.loginForm.value.email
    }
    this._firebaseLemonaid.functionsCall<AloitaSalasananPalautusProsessiPyynto, AloitaSalasananPalautusProsessiVastaus>('passwordResetSendEmail', req).then(result => {

      const code = result.e
      if (code) {
        switch (code) {
          case 'auth/user-not-found':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('email').setErrors({ 'user-not-found': true })
            break
          case 'auth/invalid-email':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('email').setErrors({ 'invalid-email': true })
            break
          case 'auth/user-disabled':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('email').setErrors({ 'user-disabled': true })
            break
          default:
            this.commonError = this.translationService.lokalisoi('yleiset.tuntematon-virhe')
        }
        this.ngAfterContentChecked()
        this.validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
        this.setLoginProcessEnded()
      } else {
        this.success = true
        this.setLoginProcessEnded()
      }

    }).catch(err => {
      this.commonError = this.translationService.lokalisoi('yleiset.tuntematon-virhe')
      this.errorHandler.handleError(err)
      this.setLoginProcessEnded()
    })

  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this.dialog.open(VaihdaKieliDialog, { data: data })
  }

}
