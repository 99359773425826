<form (ngSubmit)="save()" [formGroup]="form" novalidate>

  <mat-form-field>
    <mat-label>{{ 'laskuasetukset.nimi' | translate | async }}</mat-label>
    <input #nimiInput type="text" formControlName="nimi" matInput required />
    <mat-error>{{fieldErrors.nimi}}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'laskuasetukset.ytunnus' | translate | async }}</mat-label>
    <input type="text" formControlName="ytunnus" matInput required />
    <mat-error>{{fieldErrors.ytunnus}}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'laskuasetukset.katuosoite' | translate | async }}</mat-label>
    <input type="text" formControlName="katuosoite" matInput required />
    <mat-error>{{fieldErrors.katuosoite}}</mat-error>
  </mat-form-field>

  <div style="display: flex;">
    <mat-form-field style="width: 150px; margin-right: 1em;">
      <mat-label>{{ 'laskuasetukset.postinro' | translate | async }}</mat-label>
      <input type="text" formControlName="postinro" matInput required />
      <mat-error>{{fieldErrors.postinro}}</mat-error>
    </mat-form-field>
    <mat-form-field style="flex-grow: 1;">
      <mat-label>{{ 'laskuasetukset.postitoimipaikka' | translate | async }}</mat-label>
      <input type="text" formControlName="postitoimipaikka" matInput required />
      <mat-error>{{fieldErrors.postitoimipaikka}}</mat-error>
    </mat-form-field>
  </div>

  <mat-form-field>
    <mat-label>{{ 'laskuasetukset.maa' | translate | async }}</mat-label>
    <mat-select formControlName="maa" style="width: 100%;" required>
      <mat-option *ngFor="let maa of maat | async" [value]="maa.koodi">
        {{ maa.nimi }}
      </mat-option>
    </mat-select>
    <mat-error>{{fieldErrors.maa}}</mat-error>
  </mat-form-field>

  <div class="asetusnappulat">
    <button class="primarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
  </div>

</form>