import { Routes, RouterModule } from '@angular/router'

import { LoginComponent } from './login/login.component'
import { SecondFactorComponent } from './login/second-factor.component'
import { AddNumberComponent } from './login/add-number.component'
import { ForgotComponent } from './login/forgot.component'
import { ResetComponent } from './login/reset.component'
import { ChangeComponent } from './login/change.component'
import { NoRightsComponent } from './login/no-rights.component'
import { NoSettingsComponent } from './login/no-settings.component'
import { NoRolesComponent } from './login/no-roles.component'
import { DefaultRedirectComponent } from './default-redirect.component'
import { TervetuloaUusiKayttajaComponent } from './tervetuloa/tervetuloa-uusi-kayttaja.component'
import { KirjanpidonUlkopuolisetKulutComponent } from './ajopaivakirja/kirjanpidon-ulkopuoliset-kulut.component'
import { SopimusJaEhdotComponent } from './sopimukset/sopimus-ja-ehdot.component'

import { LaskutComponent } from './laskut/laskut.component'
import { LaskuComponent } from './laskut/lasku.component'
import { TuotteetComponent } from './laskut/tuotteet/tuotteet.component'
import { TuoteComponent } from './laskut/tuotteet/tuote.component'
import { AsiakkaatComponent } from './laskut/asiakkaat/asiakkaat.component'
import { AsiakasComponent } from './laskut/asiakkaat/asiakas.component'
import { AsetuksetComponent } from './asetukset/asetukset.component'
import { LaskuKatseleMuokattavaComponent } from './laskut/lasku.katsele.component'
import { LaskuTeePdfUudelleenComponent } from './laskut/lasku.tee-pdf-uudelleen.component'
import { LaskuTeeIndeksiUudelleenComponent } from './laskut/lasku.tee-indeksi-uudelleen.component'
import { LaskuTeeTilaJaIndeksiUudelleenComponent } from './laskut/lasku.tee-tila-ja-indeksi-uudelleen.component'

import { TositteenMuokkausComponent } from './tositteet/muokkaus/tositteen.muokkaus.component'
import { TositeLuoUusiComponent } from './tositteet/luo-uusi/tosite.luo-uusi.component'
import { TositeKatseleComponent } from './tositteet/katsele/tosite.katsele.component'

import { OstotositeListausComponent } from './tositteet/osto/ostotosite.listaus.component'
// import { OstotositeKatseleComponent } from './tositteet/osto/ostotosite.katsele.component'
// import { OstotositeMuokkaaComponent } from './tositteet/osto/ostotosite.muokkaa.component'

import { MyyntitositeListausComponent } from './tositteet/myynti/myyntitosite.listaus.component'
// import { MyyntitositeMuokkaaComponent } from './tositteet/myynti/myyntitosite.muokkaa.component'
// import { MyyntitositeKatseleComponent } from './tositteet/myynti/myyntitosite.katsele.component'

import { PalkkatositeListausComponent } from './tositteet/palkka/palkkatosite.listaus.component'
// import { PalkkatositeMuokkaaComponent } from './tositteet/palkka/palkkatosite.muokkaa.component'
// import { PalkkatositeKatseleComponent } from './tositteet/palkka/palkkatosite.katsele.component'

import { TiliotetositeListausComponent } from './tositteet/tiliote/tiliotetosite.listaus.component'
// import { TiliotetositeMuokkaaComponent } from './tositteet/tiliote/tiliotetosite.muokkaa.component'
// import { TiliotetositeKatseleComponent } from './tositteet/tiliote/tiliotetosite.katsele.component'

import { TositeKatseleComponentDataResolve, TositeMuokkaaComponentDataResolve, TositeLuoUusiComponentDataResolve } from './_angular/_resolvers/tosite.resolve'

import { LaskujenVastaanottoComponent } from './tositteet/laskujen-vastaanotto/laskujen-vastaanotto.component'
import { LaskujenHyvaksyminenComponent } from './tositteet/laskujen-hyvaksyminen/laskujen-hyvaksyminen.component'

import { PalkkausfiComponent } from './palkkausfi/palkkausfi.component'

// import { LaskuTeeTyhjennaReskontraComponent } from './laskut/lasku.tee-tyhjenna-reskontra.component'

// import { TinkOauthCallbackComponent } from './banks/tink/tink-oauth-callback.component'
// import { TinkNaytaDataComponent } from './banks/tink/tink-nayta-data.component'

import { ViiaOauthCallbackComponent } from './banks/viia/viia-oauth-callback.component'
import { TunnistautuminenNetsReturnComponent } from './tunnistautuminen/nets/nets-return.component'

import {
  LaskuKatseleComponentDataResolve,
  TuoteComponentDataResolve,
  AsiakasComponentDataResolve,
  LaskuComponentDataResolve,
  LaskutComponentDataResolve
} from './_angular/_resolvers/lasku.resolve'

import { AuthGuard } from './_angular/_guards/auth.guard'
import { AsetuksetGuard } from './_angular/_guards/asetukset.guard'
import { RoleGuard, RoleByTositeTypeGuard } from './_angular/_guards/role.guard'
import { ValikkoKiinniGuard, ValikkoAukiGuard } from './_angular/_guards/valikko-kiinni.guard'
import { TaukoGuard } from './_angular/_guards/tauko.guard'

import { Roolit } from './_jaettu/model/kayttaja'
import { TilinpaatoksetComponent } from './reports/tilinpaatokset.component'
import { TilinpaatosUsersGuard } from './_angular/_guards/tilinpaatos-users.guard'
import { HolviOauthCallbackComponent } from './banks/holvi/holvi-oauth-callback.component'
import { MaksutComponent } from './maksut/maksut.component'
import { MaksutAsetuksetComponent } from './maksut/maksut-asetukset.component'
import { MaksutVahvistaComponent } from './maksut/maksut-vahvista.component'
import { MaksutUusiComponent } from './maksut/maksut-uusi.component'
import { MaksuKatseleComponentDataResolve, MaksuLuoUusiComponentDataResolve } from './_angular/_resolvers/maksu.resolve'
import { MaksutKatseleComponent } from './maksut/maksut-katsele.component'
import { SopimusTauollaComponent } from './sopimukset/sopimus-tauolla.component'
import { KirjanpidonRaportitComponent } from './kirjanpidon-raportit/kirjanpidon-raportit.component'

/**
 * HUOM!!! JOS MUUTAT ROUTEJA, TARKISTA MYÖS VASEN-VALIKKO.SERVICE.TS!! METODI annaAlkuperainenTila() !!!
 */
const appRoutes: Routes = [
  { path: 'tositteet/muokkaus/:id', component: TositteenMuokkausComponent, canActivate: [AuthGuard, RoleByTositeTypeGuard, ValikkoKiinniGuard], resolve: { 'data': TositeMuokkaaComponentDataResolve } },

  { path: 'tositteet/osto', component: OstotositeListausComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_OSTO] } }, // , resolve: { 'data': LaskutComponentDataResolve }
  { path: 'tositteet/osto/luo', component: TositeLuoUusiComponent, canActivate: [AuthGuard, RoleGuard, ValikkoKiinniGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_OSTO] }, resolve: { 'data': TositeLuoUusiComponentDataResolve } },
  { path: 'tositteet/osto/:id', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_OSTO] }, resolve: { 'data': TositeKatseleComponentDataResolve } },
  { path: 'tositteet/osto/:id/:toiminto', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_OSTO] }, resolve: { 'data': TositeKatseleComponentDataResolve } },

  { path: 'tositteet/myynti', component: MyyntitositeListausComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_MYYNTI] } }, // , resolve: { 'data': LaskutComponentDataResolve }
  { path: 'tositteet/myynti/luo', component: TositeLuoUusiComponent, canActivate: [AuthGuard, RoleGuard, ValikkoKiinniGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_MYYNTI] }, resolve: { 'data': TositeLuoUusiComponentDataResolve } },
  { path: 'tositteet/myynti/:id', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_MYYNTI] }, resolve: { 'data': TositeKatseleComponentDataResolve } },
  { path: 'tositteet/myynti/:id/:toiminto', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_MYYNTI] }, resolve: { 'data': TositeKatseleComponentDataResolve } },

  { path: 'tositteet/tiliote', component: TiliotetositeListausComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_TILI] } }, // , resolve: { 'data': LaskutComponentDataResolve }
  { path: 'tositteet/tiliote/luo', component: TositeLuoUusiComponent, canActivate: [AuthGuard, RoleGuard, ValikkoKiinniGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_TILI] }, resolve: { 'data': TositeLuoUusiComponentDataResolve } },
  { path: 'tositteet/tiliote/:id', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_TILI] }, resolve: { 'data': TositeKatseleComponentDataResolve } },
  { path: 'tositteet/tiliote/:id/:toiminto', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_TILI] }, resolve: { 'data': TositeKatseleComponentDataResolve } },

  { path: 'tositteet/palkka', component: PalkkatositeListausComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_PALKKA] } }, // , resolve: { 'data': LaskutComponentDataResolve }
  { path: 'tositteet/palkka/luo', component: TositeLuoUusiComponent, canActivate: [AuthGuard, RoleGuard, ValikkoKiinniGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_PALKKA] }, resolve: { 'data': TositeLuoUusiComponentDataResolve } },
  { path: 'tositteet/palkka/:id', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_PALKKA] }, resolve: { 'data': TositeKatseleComponentDataResolve } },
  { path: 'tositteet/palkka/:id/:toiminto', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.TOSITTEET_PALKKA] }, resolve: { 'data': TositeKatseleComponentDataResolve } },

  { path: 'tositteet/laskujen-vastaanotto', component: LaskujenVastaanottoComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO] } },
  { path: 'tositteet/laskujen-hyvaksyminen', component: LaskujenHyvaksyminenComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO] } },
  { path: 'tositteet/laskujen-hyvaksyminen/:id', component: TositeKatseleComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO] }, resolve: { 'data': TositeKatseleComponentDataResolve } },

  { path: 'laskutus/laskut/:id/:kasiteltavaAvain/katsele', component: LaskuKatseleMuokattavaComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'detaljiKatseleLasku' }, resolve: { 'data': LaskuKatseleComponentDataResolve } },
  { path: 'laskutus/laskut/:id/:kasiteltavaAvain/teepdf', component: LaskuTeePdfUudelleenComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'detaljiKatseleLasku' }, resolve: { 'data': LaskuKatseleComponentDataResolve } },
  { path: 'laskutus/laskut/:id/:kasiteltavaAvain/indeksoi', component: LaskuTeeIndeksiUudelleenComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'detaljiKatseleLasku' }, resolve: { 'data': LaskuKatseleComponentDataResolve } },
  { path: 'laskutus/laskut/:id/:kasiteltavaAvain/tilajaindeksoi', component: LaskuTeeTilaJaIndeksiUudelleenComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'detaljiKatseleLasku' }, resolve: { 'data': LaskuKatseleComponentDataResolve } },
  { path: 'laskutus/laskut/:id/:kasiteltavaAvain/:toiminto', component: LaskuKatseleMuokattavaComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'detaljiKatseleLasku' }, resolve: { 'data': LaskuKatseleComponentDataResolve } },
  { path: 'laskutus/laskut/:id/:kasiteltavaAvain', component: LaskuComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoKiinniGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'detaljiMuokkaaLasku' }, resolve: { 'data': LaskuComponentDataResolve } },
  { path: 'laskutus/laskut', component: LaskutComponent, canActivate: [AuthGuard, TaukoGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'listausLaskut' }, resolve: { 'data': LaskutComponentDataResolve } },

  { path: 'laskutus/tuotteet/:id', component: TuoteComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'detaljiTuotteet' }, resolve: { 'tuote': TuoteComponentDataResolve } },
  { path: 'laskutus/tuotteet', component: TuotteetComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'listausTuotteet' } },

  { path: 'laskutus/asiakkaat/:id', component: AsiakasComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'detaljiAsiakkaat' }, resolve: { 'asiakas': AsiakasComponentDataResolve } },
  { path: 'laskutus/asiakkaat', component: AsiakkaatComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'listausAsiakkaat' } },

  { path: 'maksut', component: MaksutComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.MAKSUT] } },
  { path: 'maksut/asetukset/:tyyppi', component: MaksutAsetuksetComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard,], data: { sallitutRoolit: [Roolit.MAKSUT] } },
  { path: 'maksut/vahvista', component: MaksutVahvistaComponent, canActivate: [AuthGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.MAKSUT_MAKSAJA] } },
  { path: 'maksut/uusi', component: MaksutUusiComponent, canActivate: [AuthGuard, RoleGuard, ValikkoKiinniGuard], data: { sallitutRoolit: [Roolit.MAKSUT] }, resolve: { 'data': MaksuLuoUusiComponentDataResolve } },
  { path: 'maksut/:id', component: MaksutKatseleComponent, canActivate: [AuthGuard, RoleGuard], data: { sallitutRoolit: [Roolit.MAKSUT] }, resolve: { 'data': MaksuKatseleComponentDataResolve } },

  { path: 'palkat', component: PalkkausfiComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard], data: { sallitutRoolit: [Roolit.PALKAT], state: 'palkkausfi' } },

  { path: 'tilinpaatokset', component: TilinpaatoksetComponent, canActivate: [AuthGuard, AsetuksetGuard, ValikkoAukiGuard, TilinpaatosUsersGuard] },

  { path: 'asetukset', component: AsetuksetComponent, canActivate: [AuthGuard, AsetuksetGuard, RoleGuard, ValikkoAukiGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.LASKUTUS], state: 'listausAsetukset' } },

  { path: 'kirjaudu', component: LoginComponent, canActivate: [ValikkoKiinniGuard] },
  { path: 'lisaa-puhelin', component: AddNumberComponent, canActivate: [ValikkoKiinniGuard] },
  { path: 'kirjaudu-vahvista', component: SecondFactorComponent, canActivate: [ValikkoKiinniGuard] },
  { path: 'unohdin', component: ForgotComponent, canActivate: [ValikkoKiinniGuard] },
  { path: 'vaihda', component: ChangeComponent, canActivate: [ValikkoKiinniGuard] },
  { path: 'palauta', component: ResetComponent, canActivate: [ValikkoKiinniGuard] },
  { path: 'eiasetuksia', component: NoSettingsComponent, canActivate: [AuthGuard, ValikkoKiinniGuard] },
  { path: 'eirooleja', component: NoRolesComponent, canActivate: [AuthGuard, ValikkoKiinniGuard] },
  { path: 'eioikeuksia', component: NoRightsComponent, canActivate: [AuthGuard, ValikkoKiinniGuard] },
  { path: 'tervetuloa/:token', component: TervetuloaUusiKayttajaComponent, canActivate: [ValikkoKiinniGuard] },
  { path: 'ajopaivakirja/:token', component: KirjanpidonUlkopuolisetKulutComponent, canActivate: [ValikkoKiinniGuard] },

  // { path: 'tink/callback', component: TinkOauthCallbackComponent, canActivate: [AuthGuard], data: {} },
  // { path: 'tink/nayta', component: TinkNaytaDataComponent, canActivate: [AuthGuard], data: {} },

  { path: 'viia-oauth', component: ViiaOauthCallbackComponent, canActivate: [AuthGuard], data: {} },
  { path: 'holvi-oauth', component: HolviOauthCallbackComponent, canActivate: [AuthGuard], data: {} },

  { path: 'tunnistautuminen/nets-tarkista', component: TunnistautuminenNetsReturnComponent, canActivate: [ValikkoKiinniGuard], data: {} },

  { path: 'sopimus', component: SopimusJaEhdotComponent, canActivate: [AuthGuard, ValikkoAukiGuard, RoleGuard, TaukoGuard], data: { sallitutRoolit: [Roolit.HALLINTO_SOPIMUKSEN_HYVAKSYJA], state: 'sopimus' } },
  { path: 'sopimus-tauolla', component: SopimusTauollaComponent, canActivate: [AuthGuard, AsetuksetGuard, ValikkoAukiGuard] },

  { path: 'kirjanpidon-raportit', component: KirjanpidonRaportitComponent, canActivate: [AuthGuard, AsetuksetGuard, ValikkoAukiGuard, RoleGuard], data: { sallitutRoolit: [Roolit.RAPORTIT] } },

  { path: '', component: DefaultRedirectComponent, canActivate: [AuthGuard], data: {} },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }

]

export const routing = RouterModule.forRoot(appRoutes, {})
// export const routing = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'disabled' })
