<style>
  .all {
    /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
    max-height: 95vh;
  }
</style>

<div class="all" [class.iso]="!cellphone" mat-dialog-content>

  <div class="teksti">
    <div class="light-blue-text otsikko" [innerHtml]="'lemonaid-sopimus-dialog.asiakkaan-tunnistaminen' | translate | async"></div>
    <div class="dynamic-spacer"></div>
    <div class="dynamic-spacer"></div>
    <ng-container *ngIf="tunnistautuminenStepState === 'start' || tunnistautuminenStepState === 'error' || tunnistautuminenStepState === 'error-cancel'">
      <div [innerHtml]="'lemonaid-sopimus-dialog.rahanpesulaki-velvoittaa-tunnistamaan' | translate | async"></div>
      <div class="dynamic-spacer"></div>
      <div *ngIf="tunnistautuminenStepState === 'start'" [innerHtml]="'lemonaid-sopimus-dialog.tunnistaudu-pankkitunnuksilla' | translate | async"></div>
      <div class="lemon-error" *ngIf="tunnistautuminenStepState === 'error' || tunnistautuminenStepState === 'error-cancel'" [innerHtml]="'lemonaid-sopimus-dialog.tunnistautuminen-epaonnistui' | translate | async"></div>
      <div class="dynamic-spacer"></div>
      <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
      <div class="dynamic-spacer"></div>
      <div tunnistautuminen-nets-pankit [actionAfterSuccess]="'continue-standalone-dialog'" [kieli]="kieli"></div>
      <div class="dynamic-spacer"></div>
      <div class="dynamic-spacer"></div>
    </ng-container>
    <ng-container *ngIf="tunnistautuminenStepState === 'success'">

      <ng-container *ngIf="!tunnistautujanTiedotAnnettu">

        <div [innerHtml]="'lemonaid-sopimus-dialog.anna-lisatiedot' | translate | async"></div>

        <form [formGroup]="tunnistautujaForm" class="tunnistautuja">
          <mat-form-field class="tunnistautuja-short">
            <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
            <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
          </mat-form-field>
          <mat-form-field class="tunnistautuja-short">
            <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
            <mat-select formControlName="kansallisuus" required>
              <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                {{ kansa.nimi }}
              </mat-option>
            </mat-select>
            <mat-error>
              <ng-container *ngIf="tunnistautujaForm.get('kansallisuus'); let n">
                <ng-container *ngIf="n.touched">
                  <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="tunnistautuja-long">
            <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
            <mat-select formControlName="pep" required>
              <mat-option [value]="true">
                {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
              </mat-option>
              <mat-option [value]="false">
                {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
              </mat-option>
            </mat-select>
            <mat-error>
              <ng-container *ngIf="tunnistautujaForm.get('pep'); let n">
                <ng-container *ngIf="n.touched">
                  <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </form>

        <div class="form-comment-container">
          <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
        </div>

        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>

        <div class="pep-miksi-kerataan-container">
          <div style="font-weight: bold;">{{'lemonaid-sopimus-dialog.kyc.pep-miksi-kerataan' | translate | async}}</div>
          <div class="dynamic-spacer"></div>
          <div>{{'lemonaid-sopimus-dialog.kyc.pep-naita-tuntemistietoja' | translate | async}}</div>
        </div>

        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>

        <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>

        <div class="button-container">
          <button (click)="tallennaTunnistautujaLisatiedot()" class="primarybutton" mat-button>{{'yleiset.jatka' | translate | async }}</button>
        </div>
        <div class="dynamic-spacer"></div>

      </ng-container>

      <ng-container *ngIf="tunnistautujanTiedotAnnettu">

        <div class="dynamic-spacer"></div>

        <div [innerHtml]="'lemonaid-sopimus-dialog.tunnistautuminen-onnistui' | translate | async"></div>

        <div class="dynamic-spacer"></div>
        <div class="button-container">
          <button class="primarybutton" mat-button matDialogClose>{{'yleiset.jatka' | translate | async }}</button>
        </div>
        <div class="dynamic-spacer"></div>
      </ng-container>
    </ng-container>
  </div>

</div>