<picture>
  <source srcset="/assets/taustat/login_720x512_crushed.webp 720w, /assets/taustat/login_1440x1024_crushed.webp 1440w, /assets/taustat/login_2880x2048_crushed.webp 2880w, /assets/taustat/login_4320x3072_crushed.webp 4320w" type="image/webp" sizes="100vw">
  <source srcset="/assets/taustat/login_720x512_crushed.png 720w, /assets/taustat/login_1440x1024_crushed.png 1440w, /assets/taustat/login_2880x2048_crushed.png 2880w, /assets/taustat/login_4320x3072_crushed.png 4320w" type="image/png" sizes="100vw">
  <img src="/assets/taustat/login_720x512_crushed.png" class="tausta">
</picture>

<div class="all-container">

  <mat-nav-list style="position: absolute; left: 4px; top: 4px; padding-top: 0; display: flex;">
    <mat-list-item (click)="logout()">
      <div style="text-align: center; width: 100%; color:black;">{{'app.kirjaudu-ulos' | translate | async}}</div>
    </mat-list-item>
    <mat-list-item (click)="vaihdaKieli()">
      <div style="text-align: center; width: max-content; color:black;">Kieli /
        <!-- Språk /--> Language</div>
    </mat-list-item>
  </mat-nav-list>

  <div class="login-container">
    <form novalidate>
      <div class="mat-elevation-z3 top-corners-rounded bottom-corners-rounded">
        <div class="top-corners-rounded" style="padding: 2px;">
          <div class="valkoinen logo-container top-corners-rounded" style="font-size: 28px;">
            {{ 'ei-rooleja.otsikko' | translate | async }}
          </div>
        </div>
        <div class="content-container">
          <div class="login-controls">
            <div style="padding-top: 2em;" class="red-error-text">{{ 'ei-rooleja.teksti' | translate | async }}</div>
          </div>
          <div class="button-container">
            <div>
              <button type="button" class="primarybutton" mat-button (click)="palaaSovellukseen()">{{ 'yleiset.ok' | translate | async }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <span class="disclaimer">© TILITOIMISTO LEMON TREE OY {{year}}</span>

</div>