import { Component, OnInit, ChangeDetectionStrategy, Input, NgZone, ErrorHandler, EventEmitter } from '@angular/core'

import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs'
import { map, startWith, switchMap, tap } from 'rxjs/operators'
import { RaporttienHakuvaihtoehdot } from './kirjanpidon-raportit.component'

import { Timestamp } from 'app/_shared-core/model/common'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { LemonaidKirjanpidonRaporttiRequest, LemonaidKirjanpidonRaporttiResponse, LemonaidRaporttiTuloslaskelmaAccountRow, LemonaidRaporttiTuloslaskelmaData, RaporttiType } from '../_jaettu/model/reports-shared'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'

@Component({
  selector: '[app-kirjanpito-tuloslaskelma]',
  templateUrl: './tuloslaskelma.component.html',
  styleUrls: ['./kirjanpidon-raportit.component.css', './tuloslaskelma.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpidonRaportitTuloslaskelmaComponent implements OnInit {

  @Input() hakuvaihtoehdotObservable: Observable<RaporttienHakuvaihtoehdot>

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  tuloslaskelmanDataObservable: Observable<LemonaidRaporttiTuloslaskelmaData>

  lastSuccessfullyUpdated: Timestamp
  paivitaArvotHiljaisestiSubject: BehaviorSubject<number> = new BehaviorSubject(0)

  private _expandedAccountsBehaviorSubject: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set())

  constructor(
    private _errorHandler: ErrorHandler,
    private _ngZone: NgZone,
    private _firebase: FirebaseLemonaid,
    private _timestampService: TimestampService,
    private _kayttajaService: KayttajaService,
    private _translationService: LemonTranslationService
  ) { }

  ngOnInit() {

    const tuloslaskelmanRawDataObservable = combineLatest([
      this._kayttajaService.kayttajanTiedotObservable,
      this.hakuvaihtoehdotObservable.pipe(tap(() => { this._setLoadingTrue() })),
      this._translationService.currentLanguageObservable,
      this.paivitaArvotHiljaisestiSubject
    ]).pipe(
      switchMap(([kayttaja, hakuvaihtoehdot, kieli, paivita]) => {

        if (!hakuvaihtoehdot?.alkaa || !hakuvaihtoehdot?.loppuu || !kayttaja) {
          return of<LemonaidRaporttiTuloslaskelmaData>({
            c: 'c1',
            c1: null,
            r: []
          })
        }

        const requestData: LemonaidKirjanpidonRaporttiRequest = {
          a: kayttaja.asiakasAvain,
          k: kieli,
          w: RaporttiType.TULOS,
          s: hakuvaihtoehdot.alkaa,
          e: hakuvaihtoehdot.loppuu
        }

        if (this._expandedAccountsBehaviorSubject.value.size > 0) {
          requestData.f = Array.from(this._expandedAccountsBehaviorSubject.value)
        }

        return this._firebase.functionsCall<LemonaidKirjanpidonRaporttiRequest, LemonaidKirjanpidonRaporttiResponse>('pyydaRaporttiLemonatorista', requestData).then(res => {
          if (res.e) {
            throw new Error(res.e)
          }
          this.lastSuccessfullyUpdated = this._timestampService.now()
          return res.data
        }).catch(err => {
          this._errorHandler.handleError(err)
        }).finally(() => {
          this._setLoadingFalse()
        })
      }),
      startWith<LemonaidRaporttiTuloslaskelmaData>({
        c: 'c1',
        c1: null,
        r: []
      })
    )

    this.tuloslaskelmanDataObservable = combineLatest([tuloslaskelmanRawDataObservable, this._expandedAccountsBehaviorSubject]).pipe(
      map(([data, rivienExpand]) => {
        if (data?.r) {
          for (const r of data.r) {
            if (rivienExpand.has(r.a)) {
              r.e = 1
            } else {
              delete r.e
            }
          }
        }
        return data
      })
    )

  }


  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }

  handleClick(event: MouseEvent, data: LemonaidRaporttiTuloslaskelmaData) {
    const element = event.target as HTMLElement
    if (element?.classList?.contains('n')) {
      const tilinumero = element.parentElement.dataset.tnro
      if (tilinumero?.length > 3) {
        const set = this._expandedAccountsBehaviorSubject.value
        if (set.has(tilinumero)) {
          set.delete(tilinumero)
        } else {
          set.add(tilinumero)
          this.paivitaArvotHiljaisestiSubject.next(this.paivitaArvotHiljaisestiSubject.value + 1)
        }
        this._expandedAccountsBehaviorSubject.next(set)
        event.preventDefault()
        event.stopPropagation()
      }
    } else if (data?.r) {
      const tr = this._findTr(element)
      if (tr?.dataset.i !== undefined) {
        const closestBody = tr.parentElement as HTMLTableSectionElement
        if (closestBody?.dataset?.n) {
          // console.log('secondTd found', closestBody)
          const tilinumero = closestBody.dataset.n
          const tilinItem = data.r.find(a => a.n === tilinumero)
          if (tilinItem?.d) {
            // console.log('item found', tilinItem)
            const d = tilinItem.d[Number(tr?.dataset.i)]
            if (d.l) {
              delete d.l
            } else {
              d.l = true
            }
          }
        }
      }
    }
  }

  private _findTr(elem: HTMLElement): HTMLTableRowElement {
    if (elem?.tagName === 'TR') {
      return elem as HTMLTableRowElement
    } else if (elem?.parentElement?.tagName === 'TR') {
      return elem.parentElement as HTMLTableRowElement
    } else if (elem?.parentElement?.parentElement?.tagName === 'TR') {
      return elem.parentElement.parentElement as HTMLTableRowElement
    }
    return null
  }



  trackAccountRowByAccountNumberFn(index: number, item: LemonaidRaporttiTuloslaskelmaAccountRow) {
    return item.a
  }

}
