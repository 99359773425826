import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface PoytakirjaSignedDialogData {
  year: number
}


@Component({
  templateUrl: './poytakirja-signed.dialog.html'
})
export class PoytakirjaSignedDialog implements OnInit {

  year: number

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: PoytakirjaSignedDialogData
  ) { }

  ngOnInit() {
    this.year = this._data.year
  }
}

