import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core'
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { AllowedDialogsService } from 'app/_angular/service/allowed-dialogs.service'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  templateUrl: './viia-login-completed.dialog.html',
  styleUrls: ['./viia-login.dialog.css']
})
export class ViiaLoginCompletedDialog implements OnInit, OnDestroy {

  @ViewChild('selitys', { static: true }) selitysRef: ElementRef

  commonError: string
  extraTextExpanded: boolean = false

  private _ngUnsubscribe = new Subject<void>()

  constructor(
    private _dialogRef: MatDialogRef<ViiaLoginCompletedDialog>,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    private _allowedDialogsService: AllowedDialogsService
  ) { }

  ngOnInit() {

    this._dialogRef.beforeClosed().pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this._allowedDialogsService.allowAll()
      this._router.navigate(['/'])
    })
  }

  expandExtraText() {
    this.extraTextExpanded = !this.extraTextExpanded
    this._cd.detectChanges()
    if (this.extraTextExpanded && this.selitysRef) {
      setTimeout(() => {
        this.selitysRef?.nativeElement?.scrollIntoView({ behavior: 'smooth' },
          10)
      })
    }
  }

  sulje() {
    this._dialogRef.close()
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
