<!-- {{iframeSrcObservable | async}}
{{iframeLataaminenValmisObservable | async}} -->
<div *ngIf="iframeSrcObservable | async as srcOsoite" [style.display]="(iframeLataaminenValmisObservable | async) ? 'block' : 'none'">
  <iframe [src]="srcOsoite" #palkkausfi style="width: 100%; border: 0; height: 1000px; height: 100vh;"></iframe>
</div>
<div *ngIf="(iframeLataaminenValmisObservable | async) ? false : true && !(iframeErrorObservable | async)" style="display: flex; width: 100%; height: 90vh; justify-content: center; align-items: center;">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="iframeErrorObservable | async; let error">
  <div *ngIf="error === 1" class="error-text" style="margin: 2em;">
    {{'palkkausfi.virhe' | translate | async}}
  </div>
  <div *ngIf="error === 2" class="error-text" style="margin: 5em 2em 2em 2em;">
    Yrityksellesi on jo perustettu palkkaus.fi tili.
    Tilitoimisto Lemon Tree on valtuutettava hoitamaan palkkausasioita ennen kuin voimme jatkaa.
    Ole hyvä ja kirjaudu osoitteessa <a href="https://www.palkkaus.fi" target="_BLANK">https://www.palkkaus.fi</a>, tee valtuutus ja päivitä tämä selainikkuna sen jälkeen.
    <br /><br />
    Tämä valtuutus on tehtävä vain kerran. Mikäli tarviset apua valtuutuksessa ota yhteys kirjanpitäjääsi. Huomioithan, että me emme voi palauttaa unohtuneita palkkaus.fi tunnuksia, vaan näissä asioissa on otettava yhteys palkkaus.fi:n asiakaspalveluun.
  </div>
  <div *ngIf="error === 2" class="error-text" style="margin: 5em 2em 2em 2em;">
    Your company already has an account created in palkkaus.fi service.
    Tilitoimisto Lemon Tree must be authorized to handle your salary related matters at their site before we can continue.
    Please login in <a href="https://www.palkkaus.fi" target="_BLANK">https://www.palkkaus.fi</a>, authorize us and refresh this browser window after that.
    <br /><br />
    This authorization process must be done only once. If you need help during authorization contact your accountant. Please note that we can't help you with forgotten palkkaus.fi password, so in that case please contact palkkaus.fi helpdesk.
  </div>
</div>