<div style="text-align: center; min-width: 300px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      {{ 'lasku.katsele-muokattava-dialog.peruuta-perinta' | translate | async }}
    </lemon-dialog-header>

    <div>
      {{'lasku.perinta-sposti-dialog.peruuta-toimeksianto-teksti' | translate | async}}
    </div>
    <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
      <button (click)="send()" class="primarybutton" mat-button> {{ 'lasku.katsele-muokattava-dialog.peruuta-perinta' | translate | async }}</button>
    </div>
  </div>
</div>