import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { KayttajaService } from '../service/kayttaja.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AsiakasJaettuLemonaidService } from '../../_jaettu/service/asiakas-jaettu-lemonaid.service'
import { DateService } from '../../_shared-core/service/date.service'

@Injectable()
export class TaukoGuard {

  constructor(
    private _router: Router,
    private _kayttajaService: KayttajaService,
    private _asiakasJaettuService: AsiakasJaettuLemonaidService,
    private _dateService: DateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas) {
          return true
        }
        const today = this._dateService.currentLocalDate()
        const sopimuskausi = this._asiakasJaettuService.annaKausiPaivamaaralle(asiakas.sopimuskaudet, today)
        const tauolla = sopimuskausi ? this._asiakasJaettuService.onkoSopimuskausiTauolla(sopimuskausi, today) : false

        if (tauolla) {
          this._router.navigate(['/sopimus-tauolla'])
          return false
        }

        return true
      })
    )
  }
}
