import { Component } from '@angular/core'


@Component({
  template: `
  <mat-dialog-content>

  <lemon-dialog-header>
    {{'kuitit.muokkaa.tosite-lukittu' | translate | async}}
  </lemon-dialog-header>

  <div style="max-width: 600px; text-align: center;">
    {{'kuitit.muokkaa.kirjanpitaja-on-lukinnut' | translate | async}}
  </div>

  </mat-dialog-content>
  <mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="primarybutton" mat-button [mat-dialog-close]="true">{{ 'yleiset.ok' | translate | async }}</button>
  </mat-dialog-actions>
`,
})

export class TositeLukittuDialog { }
