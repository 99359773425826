

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { EnvironmentType } from './app/app.environment'

if (
  environment.environment === EnvironmentType.PRODUCTION ||
  environment.environment === EnvironmentType.BETA
) {
  enableProdMode()
}

platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
