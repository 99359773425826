import { breadcrumbsIntegration, captureException, captureMessage, init as SentryInit } from '@sentry/browser'

import { NgModule, ErrorHandler, Injectable, ChangeDetectorRef } from '@angular/core'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { routing } from './app.routing'

import { environment } from '../environments/environment'

// Fileuploads
import { NgxFileDropModule } from 'ngx-file-drop'
import { CdkTableModule } from '@angular/cdk/table'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions, MatNativeDateModule, DateAdapter } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortHeaderIntl, SortDirection, MatSortModule } from '@angular/material/sort'
import { MatStepperModule, MatStepperIntl } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'

// Material
const globalRippleConfig: RippleGlobalOptions = { disabled: true }

import { LemonDateAdapter } from './_jaettu-angular/_material/LemonDateAdapter'

// Pipes
import { CurrencyPipe, CurrencyWithLocalePipe, BigCurrencyPipe, BigCurrencyWithLocalePipe } from './_jaettu-angular/_pipes/lemon-currency.pipe'
import { LemonKaannaPipe, LemonKaannaWithLocalePipe } from './_jaettu-angular/_pipes/lemon-translate.pipe'
import {
  LemonTimestampDateAndTimePipe,
  LemonTimestampDateAndTimeWithLocalePipe,
  LemonTimestampPipe,
  LemonTimestampWithLocalePipe,
  LemonDatePipe,
  LemonDateWithLocalePipe,
  LemonLocalDatePipe,
  LemonLocalDateWithLocalePipe,
  LemonDateAndTimePipe,
  LemonLocalToMonthAndYearPipe,
  LemonNumberDateWithLocalePipe,
  LemonNumberDatePipe,
  LemonNumberDateAndTimePipe,
  LemonNumberMonthToMonthAndYearPipe,
  LemonNumberDateWithLocaleNarrowPipe
} from './_jaettu-angular/_pipes/lemon-date.pipe'
import { LemonMaaPipe, LemonMaaWithlocalePipe } from './_jaettu-angular/_pipes/lemon-maa.pipe'
import { DecimalPipe, DecimalWithLocalePipe, BigDecimalPipe, BigDecimalWithLocalePipe } from './_jaettu-angular/_pipes/lemon-decimal.pipe'
import { PrettyPrintJsonPipe } from './_jaettu-angular/_pipes/lemon-json.pipe'
// import { AuthImagePipe } from './_angular/_pipes/auth-image-pipe'

// Directives
import { LemonNumberOnlyDirective } from './_jaettu-angular/_directives/number-only.directive'
import { MonthPickerDirective } from './_jaettu-angular/_directives/month-picker.directive'
import { NumberDatePickerDirective } from './_jaettu-angular/_directives/number-date-picker.directive'
import { CountToDirective } from './_jaettu-angular/_directives/count-to.directive'
import { TimeFormatDirective } from './_jaettu-angular/_directives/time-format.directive'
import { PlaceholderWithRequiredIndicatorDirective } from './_jaettu-angular/_directives/placeholder-with-required-indicator.directive'

// Components
import { LoadingComponent } from './_jaettu-angular/_components/loading.component'
import { BarcodeComponent } from './_jaettu-angular/_components/barcode.component'
import { QrcodeComponent } from './_jaettu-angular/_components/qrcode.component'
// import { LemonHenkiloYritysValitsin } from './_jaettu-angular/_components/valinta-component'
import { LemonDialogHeader } from './lemon-dialog-header.component'

// Banks, Tink
// import { TinkOauthCallbackComponent } from './banks/tink/tink-oauth-callback.component'
// import { TinkNaytaDataComponent } from './banks/tink/tink-nayta-data.component'

// Banks, Nordic Api Gateway
import { ViiaOauthCallbackComponent } from './banks/viia/viia-oauth-callback.component'

// Banks, Holvi
import { HolviOauthCallbackComponent } from './banks/holvi/holvi-oauth-callback.component'

// Login, Forgot password, Reset password, home
import { AppComponent } from './app.component'
import { AppMenuComponent } from './app-menu.component'
import { DefaultRedirectComponent } from './default-redirect.component'
import { LoginComponent } from './login/login.component'
import { AddNumberComponent } from './login/add-number.component'
import { SecondFactorComponent } from './login/second-factor.component'
import { ForgotComponent } from './login/forgot.component'
import { ResetComponent } from './login/reset.component'
import { ChangeComponent } from './login/change.component'
import { NoRightsComponent } from './login/no-rights.component'
import { NoSettingsComponent } from './login/no-settings.component'
import { NoRolesComponent } from './login/no-roles.component'
import { TervetuloaUusiKayttajaComponent } from './tervetuloa/tervetuloa-uusi-kayttaja.component'
import { SopimusJaEhdotComponent } from './sopimukset/sopimus-ja-ehdot.component'
import { TilinpaatoksetComponent } from './reports/tilinpaatokset.component'
import { TilinpaatosComponent } from './reports/tilinpaatos.component'
import { TilinpaatosSignedDialog } from './reports/tilinpaatos-signed.dialog'
import { YhtiokokouksenPoytakirjaComponent } from './reports/yhtiokokouksen-poytakirja.component'
import { PoytakirjaSignedDialog } from './reports/poytakirja-signed.dialog'
import { KirjanpidonRaportitComponent } from './kirjanpidon-raportit/kirjanpidon-raportit.component'
import { KirjanpidonRaportitPaakirjaComponent } from './kirjanpidon-raportit/paakirja.component'
import { KirjanpidonRaportitTaseComponent } from './kirjanpidon-raportit/tase.component'
import { KirjanpidonRaportitTuloslaskelmaComponent } from './kirjanpidon-raportit/tuloslaskelma.component'
import { KirjanpidonRaportitBruttotuloslaskelmaComponent } from './kirjanpidon-raportit/bruttotuloslaskelma.component'
import { KirjanpidonRaportitVirallinenTaseComponent } from './kirjanpidon-raportit/virallinen-tase.component'
import { KirjanpidonRaportitVirallinenTuloslaskelmaComponent } from './kirjanpidon-raportit/virallinen-tulos.component'
import { UnsupportedBrowser } from './unsupported-browser.component'
import { BrowserClockCheckComponent } from './_jaettu-angular/_components/browser-clock-check.component'

import { TunnistautuminenNetsPankitComponent } from './tunnistautuminen/nets/pankit.component'
import { TunnistautuminenNetsMaksujenVahvistusComponent } from './tunnistautuminen/nets/maksujen-vahvistus-pankit.component'
import { TunnistautuminenNetsReturnComponent } from './tunnistautuminen/nets/nets-return.component'
import { TunnistautuminenNetsDialog } from './tunnistautuminen/nets/tunnistautuminen-nets.dialog'

import { SopimusTauollaComponent } from './sopimukset/sopimus-tauolla.component'

// Dialogit
import { AreYouSureDialog } from './_jaettu-angular/_components/are-you-sure.dialog'
import { VaihdaKieliDialog } from './vaihda-kieli.dialog'
import { VaihdaSalasanaDialog } from './vaihda-salasana.dialog'
import { VirheDialog } from './_jaettu-angular/_components/virhe.dialog'
import { InfoDialog } from './_jaettu-angular/_components/info.dialog'
// import { UudetKayttoehdotDialog } from './kayttoehdot/uudet-kayttoehdot.dialog'
import { UusiSopimusDialog } from './sopimukset/uusi-sopimus.dialog'
import { NaytaTekstiaDialog } from './nayta-tekstia.dialog'
import { TervetuloaSopimusDialog } from './tervetuloa/tervetuloa-sopimus.dialog'
import { ViiaLoginRequiredDialog } from './banks/viia/viia-login-required.dialog'
import { ViiaLoginCompletedDialog } from './banks/viia/viia-login-completed.dialog'
import { ViiaLoginFailedDialog } from './banks/viia/viia-login-failed.dialog'
import { UudetTuntemistiedotDialog } from './tervetuloa/uudet-tuntemistiedot.dialog'
import { PepTekstiSharedDialog } from './tervetuloa/pep-teksti-shared.dialog'
import { TilinpaatosAreYouSureDialog } from './reports/tilinpaatos-are-you-sure.dialog'
import { HolviLoginRequiredDialog } from './banks/holvi/holvi-login-required.dialog'
import { HolviLoginCompletedDialog } from './banks/holvi/holvi-login-completed.dialog'
import { HolviLoginFailedDialog } from './banks/holvi/holvi-login-failed.dialog'
import { GeneralBankSelectionDialog } from './banks/general-bank-selection.dialog'
import { MaksutMainosLaskujenVastaanottoDialog } from './maksut/maksut-mainos-laskujen-vastaanotto.dialog'

// Services, vain tämä projekti
import { VersionTarkistusPalvelu } from './_angular/service/version-tarkistus.palvelu'
import { LoginService } from './_angular/service/login.service'
import { LemonHttpService } from './_angular/service/lemon-http.service'
import { KayttajaService, LaskuttavaAsiakasLemonaidProvider } from './_angular/service/kayttaja.service'
import { LaskuService } from './_angular/service/lasku/lasku.service'
import { LaskunAsiakasTypeahead, LaskunTuoteTypeahead } from './_angular/service/lasku/lasku.typeahead'
import { LaskuSummatService } from './_angular/service/lasku/lasku-summat.service'
import { AsetustenOletuksetService } from './_angular/service/asetusten-oletukset.service'
import { AiiaDialogService } from './_angular/service/aiia-dialog.service'
import { HolviDialogService } from './_angular/service/holvi-dialog.service'
import { TunnistautuminenService } from './_angular/service/tunnistautuminen.service'
import { AllowedDialogsService } from './_angular/service/allowed-dialogs.service'
import { TuntemistiedotDialogService } from './_angular/service/tuntemistiedot-dialog.service'
import { UusiSopimusDialogService } from './_angular/service/uusi-sopimus-dialog.service'
import { ReportImagesService } from './reports/report-images.service'
import { TilinpaatosLemonaidUsersService } from './_angular/service/tilinpaatos-lemonaid-users.service'
import { LemonBarcodeDetector } from './_angular/service/barcode/barcode-detector.service'
import { UserSettingsService } from './_angular/service/user-settings.service'

// Services, jaettu angular projektien kesken
import { VasenValikkoService } from './_jaettu-angular/service/vasen-valikko.service'
import { MaaService } from './_jaettu-angular/service/maa.service'
import { LemonTranslationService } from './_jaettu-angular/service/lemon-translation.service'
import { WindowSizeService } from './_jaettu-angular/service/window.service'
import { FormValidationService } from './_jaettu-angular/service/form-validation.service'
import { LadataanService } from './_jaettu-angular/service/ladataan.service'
import { TimestampService } from './_jaettu-angular/service/timestamp-service'
import { TimestampProviderBase } from './_shared-core/service/timestamp-provider.interface'
import { DragAndDropService } from './_jaettu-angular/service/drag-and-drop.service'
import { TiedostojenLataamisService } from './_jaettu-angular/service/tiedostojen-lataamis.service'
import { LaskuttavaAsiakasProvider } from './_jaettu-angular/service/lasku/laskuttava-asiakas.service'
import { LocalStorageService } from './_jaettu-angular/service/local-storage.service'
import { AlvService } from './_jaettu-angular/service/alv.service'
import { FileSaverService } from './_jaettu-angular/service/file-saver'

// Shared
import { BicService } from './_shared-core/service/bic.service'
import { IbanService } from './_shared-core/service/iban.service'
import { CurrencyService } from './_shared-core/service/currency.service'
import { StringService } from './_shared-core/service/string.service'
import { DateService } from './_shared-core/service/date.service'
import { FirestoreIndeksoija } from './_jaettu/service/firestore.indeksoija'
import { KopioijaPalvelu } from './_jaettu/service/kopioija.service'
import { TranslationService, CountryLocalizationService } from './_jaettu/service/translation.service'
import { ViitenumeroService } from './_shared-core/service/viitenumero.service'
import { KayttoehdotUriService } from './_jaettu/service/kayttoehdot-uri.service'
import { CodeCheckService } from './_shared-core/service/code-check.service'
import { KycUriService } from './_jaettu/service/kyc-uri.service'
import { TilinpaatosUriService } from './_jaettu/service/tilinpaatos-uri.service'
import { BankConsentUriService } from './_jaettu/service/bank-consent-uri.service'
import { VatNumberValidationService } from './_jaettu/service/vat-number-validation.service'
import { BankBalancePublicUriService } from './_jaettu/service/bank-balance-public-uri.service'
import { LemonaidRaportitUriService } from './_jaettu/service/lemonaid-raportit-uri.service'

// Shared, Lasku
import { LaskuIndeksoija } from './_jaettu/service/lasku/lasku.indeksoija'
import { LaskuKopioija } from './_jaettu/service/lasku/lasku.kopioija'
import { LaskuKorkoService } from './_jaettu/service/lasku/lasku-korko.service'
import { LaskuSpostiService } from './_jaettu/service/lasku/lasku-sposti.service'
import { LaskuSharedService, ReskontraService } from './_jaettu/service/lasku/lasku-shared.service'
import { LaskuUriService } from './_jaettu/service/lasku/lasku-uri.service'
import { SahkoisenLaskunValittajaService } from './_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'

// Palkkausfi
import { PalkkausfiComponent, PalkkausFiService } from './palkkausfi/palkkausfi.component'

// Maksut
import { MaksutComponent } from './maksut/maksut.component'
import { MaksutVahvistaComponent } from './maksut/maksut-vahvista.component'
import { MaksutUusiComponent } from './maksut/maksut-uusi.component'
import { MaksuKatseleComponentDataResolve, MaksuMuokkaaComponentDataResolve, MaksuLuoUusiComponentDataResolve } from './_angular/_resolvers/maksu.resolve'
import { MaksutKatseleComponent } from './maksut/maksut-katsele.component'
import { MaksutAsetuksetComponent } from './maksut/maksut-asetukset.component'
import { MaksutMainosComponent } from './maksut/maksut-mainos.component'
import { MaksutLopetaVastaanottoDialog } from './maksut/maksut-lopeta-vastaanotto.dialog'
import { MaksutVastaanottoAloitettuDialog } from './maksut/maksut-vastaanotto-aloitettu.dialog'
import { MaksutMaksutapaDialog } from './maksut/maksut-maksutapa.dialog'
import { MaksutMaksupaivaDialog } from './maksut/maksut-maksupaiva.dialog'
import { MaksutMaksutavatComponent } from './maksut/maksut-maksutavat.component'
import { MaksutSharedStateService } from './maksut/maksut-shared-state.service'

// Laskut
import { LaskutComponent } from './laskut/laskut.component'
import { LaskuComponent } from './laskut/lasku.component'
import { LahetaComponent } from './laskut/laheta.component'
import { LaskuMuokkausEstettyDialog } from './laskut/dialogit/lasku.muokkaus-estetty.dialog'
import { LaskuRivitComponent } from './_jaettu-angular/laskut/muokkaa/lasku-rivit.component'
import { LaskuRiviComponent } from './_jaettu-angular/laskut/muokkaa/lasku-rivi.component'
import { LaskuYlaosanDropparitComponent } from './_jaettu-angular/laskut/muokkaa/lasku-ylaosan-dropparit.component'
import { LaskuAsiakkaanTiedotComponent } from './_jaettu-angular/laskut/muokkaa/lasku-asiakkaan-tiedot.component'
import { LaskuMuutTiedotComponent } from './_jaettu-angular/laskut/muokkaa/lasku-muut-tiedot.component'
import { TuoteHakuComponent } from './_jaettu-angular/laskut/tuotteet/tuote-haku.component'
import { TuotteetComponent } from './laskut/tuotteet/tuotteet.component'
import { TuoteComponent } from './laskut/tuotteet/tuote.component'
import { AsiakasComponent } from './laskut/asiakkaat/asiakas.component'
import { AsiakkaatComponent } from './laskut/asiakkaat/asiakkaat.component'
import { LaskuasetuksetComponent } from './laskut/asetukset/laskuasetukset.component'
import { PerusasetuksetComponent } from './laskut/asetukset/perusasetukset.component'
import { ReskontraAsetuksetComponent } from './laskut/asetukset/reskontra-asetukset.component'
import { EmailPohjaComponent } from './laskut/asetukset/emailpohja.component'
import { LaskuKatseleMuokattavaComponent } from './laskut/lasku.katsele.component'
import { LaskuTeePdfUudelleenComponent } from './laskut/lasku.tee-pdf-uudelleen.component'
import { LaskuTeeIndeksiUudelleenComponent } from './laskut/lasku.tee-indeksi-uudelleen.component'
import { LaskuTeeTilaJaIndeksiUudelleenComponent } from './laskut/lasku.tee-tila-ja-indeksi-uudelleen.component'

// Laskujen dialogit
import { LaskuLataaDialog } from './laskut/dialogit/lasku.lataa.dialog'
import { LaskuMuokkaaTulostettuaDialog } from './laskut/dialogit/lasku.muokkaa-tulostettua.dialog'
import { LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog } from './laskut/dialogit/lasku.haluatko-varmasti-perua-maksetuksi-merkitsemisen.dialog'
import { LaskuHaluatkoVarmastiMitatoidaLaskunDialog } from './laskut/dialogit/lasku.haluatko-varmasti-mitatoida-laskun.dialog'
import { LaskuKokoVirheDialog } from './laskut/dialogit/lasku.koko-virhe.dialog'
import { LaskuTaydennaAsetuksetDialog } from './laskut/dialogit/lasku-taydenna-asetukset.dialog'
import { LaskuKommenttiDialog } from './laskut/dialogit/lasku-kommentti.dialog'
import { LaskuMaksumuistutusSpostiDialog } from './laskut/dialogit/lasku-maksumuistutus.sposti.dialog'
import { LaskuPerintaSpostiDialog } from './laskut/dialogit/lasku-perinta.sposti.dialog'
import { LaskuPerintaPeruutusAreYouSureDialog } from './laskut/dialogit/lasku-perinta-peruutus-are-you-sure.dialog'
import { LaskuMerkitseOsittainMaksetuksiDialog } from './laskut/dialogit/lasku.merkitse-osittain-maksetuksi.dialog'
import { TuotePoistoVarmistusDialog } from './laskut/tuotteet/tuote-poisto-varmistus.dialog'
import { AsiakasPoistoVarmistusDialog } from './laskut/asiakkaat/asiakas-poisto-varmistus.dialog'
import { LaskuReskontraTiedotDialog } from './laskut/dialogit/lasku.reskontra-tiedot.dialog'

// Laskujen esikatselut
import { LaskuPdfEsikatseluPerinteinen } from './_jaettu-angular/laskut/esikatselu/pdf.perinteinen.component'
import { LaskuEmailEsikatseluPerinteinen } from './_jaettu-angular/laskut/esikatselu/email.perinteinen.component'

// Laskujen datasourcet
import { TuotteetFirestoreDataSource } from './laskut/tuotteet/tuotteet.firestore.datasource'
import { AsiakkaatFirestoreDataSource } from './laskut/asiakkaat/asiakkaat.firestore.datasource'
import { LaskutFirestoreDataSource } from './laskut/laskut.firestore.datasource'

// Tositteet, jaettu
import { TositteenMuokkausComponent } from './tositteet/muokkaus/tositteen.muokkaus.component'
import { TositteenMuokkausPdfImageBox } from './tositteet/muokkaus/tositteen.muokkaus.pdf-image-box.component'
import { TositeKuvaComponent } from './tositteet/kuvat/tosite-kuva.component'
import { TositeKuvaCacheService } from './tositteet/kuvat/tosite-kuva-cache.service'
import { TositeLuoUusiComponent } from './tositteet/luo-uusi/tosite.luo-uusi.component'
import { TositePoistaDialog } from './tositteet/poista-dialog/tosite.poista.dialog'
import { TositeKatseleComponent } from './tositteet/katsele/tosite.katsele.component'

// Tositteet, osto
import { OstotositeListausComponent } from './tositteet/osto/ostotosite.listaus.component'
// import { OstotositeKatseleComponent } from './tositteet/osto/ostotosite.katsele.component'
// import { OstotositeMuokkaaComponent } from './tositteet/osto/ostotosite.muokkaa.component'
// import { OstotositePoistaDialog } from './tositteet/osto/ostotosite.poista.dialog'
// import { OstotositeListausFirebaseDataSource } from './tositteet/osto/ostotosite.listaus.firebase.datasource'

// Tositteet, myynti
import { MyyntitositeListausComponent } from './tositteet/myynti/myyntitosite.listaus.component'
// import { MyyntitositeMuokkaaComponent } from './tositteet/myynti/myyntitosite.muokkaa.component'
// import { MyyntitositeKatseleComponent } from './tositteet/myynti/myyntitosite.katsele.component'
// import { MyyntitositePoistaDialog } from './tositteet/myynti/myyntitosite.poista.dialog'
// import { MyyntitositeListausFirebaseDataSource } from './tositteet/myynti/myyntitosite.listaus.firebase.datasource'

// Tositteet, palkka
// import { PalkkatositePoistaDialog } from './tositteet/palkka/palkkatosite.poista.dialog'
// import { PalkkatositeKatseleComponent } from './tositteet/palkka/palkkatosite.katsele.component'
// import { PalkkatositeMuokkaaComponent } from './tositteet/palkka/palkkatosite.muokkaa.component'
import { PalkkatositeListausComponent } from './tositteet/palkka/palkkatosite.listaus.component'
// import { PalkkatositeListausFirebaseDataSource } from './tositteet/palkka/palkkatosite.listaus.firebase.datasource'

// Tositteet, tiliote
// import { TiliotetositePoistaDialog } from './tositteet/tiliote/tiliotetosite.poista.dialog'
import { TiliotetositeListausComponent } from './tositteet/tiliote/tiliotetosite.listaus.component'
// import { TiliotetositeMuokkaaComponent } from './tositteet/tiliote/tiliotetosite.muokkaa.component'
// import { TiliotetositeKatseleComponent } from './tositteet/tiliote/tiliotetosite.katsele.component'
// import { TiliotetositeListausFirebaseDataSource } from './tositteet/tiliote/tiliotetosite.listaus.firebase.datasource'

// Tositteet, selvitettävät
import { SelvitettavaTositeListausComponent } from './tositteet/selvitettavat/selvitettava-tosite.listaus.component'
import { SelvitettavatMaksutapaComponent } from './tositteet/selvitettavat/selvitettavat-maksutapa.component'

// Tositteet, yhteiset
import { TositeLukittuDialog } from './tositteet/tosite-lukittu.dialog'
import { MaksutavatComponent } from './tositteet/maksutavat.component'
import { TositeKatseleComponentDataResolve, TositeMuokkaaComponentDataResolve, TositeLuoUusiComponentDataResolve } from './_angular/_resolvers/tosite.resolve'
import { PdfService } from './_angular/service/pdf.service'

import { TositeService } from './_angular/service/tosite/tosite.service'
import { TositeKuvaService } from './_angular/service/tosite/tosite-kuva.service'
import { TositeDatasourceService } from './_angular/service/tosite/tosite-datasource.service'
import { TositeUriService } from './_jaettu/service/tosite/tosite-uri.service'
import { TositeKopioija } from './_jaettu/service/tosite/tosite.kopioija'
import { TositeIndeksoija } from './_jaettu/service/tosite/tosite.indeksoija'
import { SelvitettavatTositteetService } from './_angular/service/tosite/selvitettavat-tositteet.service'
import { EinvoicesAngularService } from './_angular/service/tosite/einvoices-angular.service'

// Laskujen vastaanotto
import { LaskujenVastaanottoComponent } from './tositteet/laskujen-vastaanotto/laskujen-vastaanotto.component'
import { LaskujenHyvaksyminenComponent } from './tositteet/laskujen-hyvaksyminen/laskujen-hyvaksyminen.component'
import { LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialog } from './tositteet/laskujen-hyvaksyminen/laskujen-hyvaksyminen.ei-voi-hyvaksya-enaa.dialog'

// Ajopäiväkirja
import { KirjanpidonUlkopuolisetKulutComponent } from './ajopaivakirja/kirjanpidon-ulkopuoliset-kulut.component'

// Guards
import { AuthGuard } from './_angular/_guards/auth.guard'
import { AsetuksetGuard } from './_angular/_guards/asetukset.guard'
import { RoleGuard, RoleByTositeTypeGuard } from './_angular/_guards/role.guard'
import { ValikkoKiinniGuard, ValikkoAukiGuard } from './_angular/_guards/valikko-kiinni.guard'
import { TilinpaatosUsersGuard } from './_angular/_guards/tilinpaatos-users.guard'
import { TaukoGuard } from './_angular/_guards/tauko.guard'

// Resolvit
import {
  LaskuKatseleComponentDataResolve,
  TuoteComponentDataResolve,
  AsiakasComponentDataResolve,
  LaskuComponentDataResolve,
  LaskutComponentDataResolve
} from './_angular/_resolvers/lasku.resolve'

import { FirebaseLemonaid } from './_angular/service/firebase-lemonaid.service'
import { SharedFirebaseLemonaid } from './_jaettu-angular/base-firebase.service'

import { AsetuksetComponent } from './asetukset/asetukset.component'

import { Subject } from 'rxjs'

// import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router'

import { AlvNumeronTarkistajaService } from './_jaettu-angular/service/vat-number-check.service'
import { KonfiguraatioPalvelu } from './_jaettu-angular/service/lasku/configuration.service'
import { LaskunAsiakasTypeaheadBase, LaskunTuoteTypeaheadBase } from './_jaettu-angular/service/lasku/typeahead.service'
import {
  BicAngularServiceAngular,
  IbanServiceAngular,
  ViitenumeroServiceAngular,
  CurrencyServiceAngular,
  DateServiceAngular,
  FirestoreIndeksoijaAngular,
  CodeCheckServiceAngular,
  KopioijaPalveluAngular,
  TranslationServiceAngular,
  StringServiceAngular,
  KayttoehdotUriServiceAngular,
  KycUriServiceAngular,
  LaskuIndeksoijaAngular,
  LaskuKopioijaAngular,
  LaskuKorkoServiceAngular,
  ReskontraServiceAngular,
  LaskuSharedServiceAngular,
  LaskuSpostiServiceAngular,
  LaskuUriServiceAngular,
  SahkoisenLaskunValittajaServiceAngular,
  TositeUriServiceAngular,
  TositeIndeksoijaAngular,
  TositeKopioijaAngular,
  TilinpaatosUriServiceAngular,
  VatNumberValidationServiceAngular,
  BankConsentUriServiceAngular,
  BankBalancePublicUriServiceAngular,
  AsiakasJaettuLemonaidServiceAngular,
  MaksutServiceAngular,
  LemonaidRaportitUriServiceAngular
} from './_jaettu-angular/angularified-services'
import { EnvironmentType } from './app.environment'
import { AsiakasJaettuLemonaidService } from './_jaettu/service/asiakas-jaettu-lemonaid.service'
import { MaksutService } from './_jaettu/service/maksut.service'

// Dynamic templates
// import { LemonDynOutput } from './_angular/components/lemon-dyn-output.component'
// import { LemonDynContent } from './_angular/components/lemon-dyn-content.component'
// import { LemonDynTrustedOutput } from './_angular/components/lemon-dyn-trusted-output.component'
// import { LemonDynLoop } from './_angular/components/lemon-dyn-loop.component'

// export class MyDateAdapter extends DateAdapter< {

// }

SentryInit({
  environment: environment.environment === EnvironmentType.PRODUCTION ? 'production' : 'beta',
  release: 'lemonaid@' + environment.version,
  dsn: 'https://158d898b509a4051bf9caaa6eb44a754@sentry.io/275018',
  integrations: [breadcrumbsIntegration({ console: false })]
})

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {

    // Try local logging to console
    try {
      if (console) {
        console.log(err)
      }
    } catch (e) { }

    try {
      if (environment.airbrakeEnabled) {

        const error = err.originalError || err

        if (
          Object.prototype.toString.call(error) === '[object Error]' ||
          Object.prototype.toString.call(error) === '[object ErrorEvent]' ||
          Object.prototype.toString.call(error) === '[object ProgressEvent]'
        ) {
          captureException(error)
        } else {
          captureMessage(error.error || error)
        }

      }
    } catch (error) {
      try {
        if (console) {
          console.log(err)
        }
      } catch (e) { }
    }

    try {
      // Try to avoid deep loop
      const debugCtx = err['ngDebugContext']
      const changeDetectorRef = debugCtx && debugCtx.injector.get(ChangeDetectorRef)
      if (changeDetectorRef) {
        changeDetectorRef.detach()
      }
    } catch (error) {
      try {
        if (console) {
          console.log(err)
        }
      } catch (e) { }
    }

  }
}

@Injectable()
export class MyMaterialMatStepperIntl implements MatStepperIntl {

  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  changes: Subject<void> = new Subject()

  optionalLabel = ''
  completedLabel = ''
  editableLabel = ''

  constructor(
    private lemonTranslationService: LemonTranslationService
  ) {
    lemonTranslationService.currentLanguageObservable.subscribe(kieli => {
      this.optionalLabel = this.lemonTranslationService.lokalisoiKielella('stepper.optional', kieli)
      this.completedLabel = this.lemonTranslationService.lokalisoiKielella('stepper.completed', kieli)
      this.editableLabel = this.lemonTranslationService.lokalisoiKielella('stepper.editable', kieli)
      this.changes.next()
    })
  }

}

@Injectable()
export class MyMaterialMatSortHeaderIntl implements MatSortHeaderIntl {

  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  changes: Subject<void> = new Subject()

  /** ARIA label for the sorting button. */
  sortButtonLabel(id: string): string {
    return '// TODO: FIXME: IMPLEMENT ME'
  }

  /** A label to describe the current sort (visible only to screenreaders). */
  sortDescriptionLabel(id: string, direction: SortDirection): string {
    return '// TODO: FIXME: IMPLEMENT ME'
  }

  constructor(
    private lemonTranslationService: LemonTranslationService
  ) {
    // lemonTranslationService.currentLanguageObservable.subscribe(kieli => {
    // this.changes.next()
    // })
  }

}

@Injectable()
export class MyMaterialPagingIntl implements MatPaginatorIntl {

  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  changes: Subject<void> = new Subject()

  /** A label for the page size selector. */
  itemsPerPageLabel: string

  /** A label for the button that increments the current page. */
  nextPageLabel: string

  /** A label for the button that decrements the current page. */
  previousPageLabel: string

  /** A label for the button that moves to the first page. */
  firstPageLabel: string

  /** A label for the button that moves to the last page. */
  lastPageLabel: string

  constructor(
    private lemonTranslationService: LemonTranslationService
  ) {
    lemonTranslationService.currentLanguageObservable.subscribe(kieli => {
      this.itemsPerPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.kpl-per-sivu', kieli)
      this.nextPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.seuraava-sivu', kieli)
      this.previousPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.edellinen-sivu', kieli)
      this.firstPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.ensimmainen-sivu', kieli)
      this.lastPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.viimeinen-sivu', kieli)
      this.changes.next()
    })
  }

  /** A label for the range of items within the current page and the length of the whole list. */
  getRangeLabel(page: number, pageSize: number, length: number): string {
    const lengthString = length === Number.MAX_SAFE_INTEGER ? '?' : '' + length


    if (length === 0 || pageSize === 0) {
      return '0 / ' + lengthString
    }
    length = Math.max(length, 0)
    const startIndex = page * pageSize
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
    return startIndex + 1 + ' - ' + endIndex + ' / ' + lengthString
  }
}


/**
 * Does not detach any subtrees. Reuses routes as long as their route config is the same.
 */
// export class LemonRouteReuseStrategy implements RouteReuseStrategy {

//   // private storepaths = ['', 'laskutus/tuotteet', 'laskutus/asiakkaat']
//   private cache = new Map<string, {route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle}>()

//   shouldDetach(route: ActivatedRouteSnapshot): boolean {
//     const key = this.key(route.routeConfig.path)
//     console.log('SHOULD DETACH "' + key + '"', route)
//     return key !== 'laskutus/tuotteet'
//   }
//   store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
//     if (route && route.routeConfig && route.routeConfig.path) {
//       const key = this.key(route.routeConfig.path)
//       // console.log('STORE "' + key + '"', detachedTree)
//       this.cache.set(key, { route: route, detachedTree: detachedTree })
//       if (key === 'laskutus/laskut/:id/kasiteltavaAvain') {
//         // console.log('store ', route.routeConfig)
//       }
//     }
//   }
//   shouldAttach(route: ActivatedRouteSnapshot): boolean {
//     const key = this.key(route.routeConfig.path)
//     const attach: boolean = this.cache.get(key) != null
//     // console.log('SHOULD ATTACH ' + attach + ' "' + key + '"')
//     return attach
//   }
//   retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null {
//     if (route && route.routeConfig) {
//       const key = this.key(route.routeConfig.path)
//       const fromCache = this.cache.get(key)
//       // console.log('RETRIEVE "' + key + '"', fromCache)
//       return fromCache ? fromCache.detachedTree : null
//     }
//     return null
//   }
//   shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
//     return false
//   }

//   private key(key: string): string {
//     if (key === '') {
//       return 'root'
//     }
//     return key
//   }

// }

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    DefaultRedirectComponent,
    UnsupportedBrowser,
    BrowserClockCheckComponent,
    TunnistautuminenNetsReturnComponent,
    TunnistautuminenNetsPankitComponent,
    TunnistautuminenNetsMaksujenVahvistusComponent,
    TunnistautuminenNetsDialog,
    LoginComponent,
    AddNumberComponent,
    SecondFactorComponent,
    ForgotComponent,
    ResetComponent,
    ChangeComponent,
    NoRightsComponent,
    NoSettingsComponent,
    NoRolesComponent,
    TervetuloaUusiKayttajaComponent,
    SopimusJaEhdotComponent,
    TilinpaatoksetComponent,
    TilinpaatosComponent,
    YhtiokokouksenPoytakirjaComponent,
    SopimusTauollaComponent,
    KirjanpidonRaportitComponent,
    KirjanpidonRaportitPaakirjaComponent,
    KirjanpidonRaportitTaseComponent,
    KirjanpidonRaportitTuloslaskelmaComponent,
    KirjanpidonRaportitBruttotuloslaskelmaComponent,
    KirjanpidonRaportitVirallinenTaseComponent,
    KirjanpidonRaportitVirallinenTuloslaskelmaComponent,

    // Dialogit
    LemonDialogHeader,
    AreYouSureDialog,
    LaskuTaydennaAsetuksetDialog,
    LaskuKommenttiDialog,
    VaihdaKieliDialog,
    VaihdaSalasanaDialog,
    VirheDialog,
    InfoDialog,
    // UudetKayttoehdotDialog,
    UusiSopimusDialog,
    NaytaTekstiaDialog,
    TervetuloaSopimusDialog,
    ViiaLoginRequiredDialog,
    ViiaLoginCompletedDialog,
    ViiaLoginFailedDialog,
    UudetTuntemistiedotDialog,
    PepTekstiSharedDialog,
    TilinpaatosSignedDialog,
    TilinpaatosAreYouSureDialog,
    PoytakirjaSignedDialog,
    HolviLoginRequiredDialog,
    HolviLoginCompletedDialog,
    HolviLoginFailedDialog,
    GeneralBankSelectionDialog,
    MaksutMainosLaskujenVastaanottoDialog,
    // Laskudialogit
    LaskuLataaDialog,
    LaskuMuokkaaTulostettuaDialog,
    LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog,
    LaskuHaluatkoVarmastiMitatoidaLaskunDialog,
    LaskuKokoVirheDialog,
    // LaskuSpostiDialog,
    LaskuMaksumuistutusSpostiDialog,
    LaskuPerintaSpostiDialog,
    LaskuPerintaPeruutusAreYouSureDialog,
    LaskuMerkitseOsittainMaksetuksiDialog,
    TuotePoistoVarmistusDialog,
    AsiakasPoistoVarmistusDialog,
    LaskuReskontraTiedotDialog,
    // Palkkaus.fi
    PalkkausfiComponent,
    // Maksut
    MaksutComponent,
    MaksutVahvistaComponent,
    MaksutUusiComponent,
    MaksutKatseleComponent,
    MaksutAsetuksetComponent,
    MaksutMainosComponent,
    MaksutLopetaVastaanottoDialog,
    MaksutVastaanottoAloitettuDialog,
    MaksutMaksutapaDialog,
    MaksutMaksupaivaDialog,
    MaksutMaksutavatComponent,
    // Laskukomponentit
    LaskutComponent,
    LaskuComponent,
    LahetaComponent,
    LaskuRiviComponent,
    LaskuRivitComponent,
    LaskuMuokkausEstettyDialog,
    LaskuYlaosanDropparitComponent,
    LaskuAsiakkaanTiedotComponent,
    LaskuMuutTiedotComponent,
    AsiakasComponent,
    AsiakkaatComponent,
    AsetuksetComponent,
    EmailPohjaComponent,
    LaskuTeePdfUudelleenComponent,
    LaskuTeeIndeksiUudelleenComponent,
    LaskuTeeTilaJaIndeksiUudelleenComponent,
    LaskuKatseleMuokattavaComponent,
    PerusasetuksetComponent,
    LaskuasetuksetComponent,
    ReskontraAsetuksetComponent,
    LaskuPdfEsikatseluPerinteinen,
    LaskuEmailEsikatseluPerinteinen,
    TuoteHakuComponent,
    TuotteetComponent,
    TuoteComponent,
    // Yleiset
    BarcodeComponent,
    QrcodeComponent,
    LoadingComponent,
    LemonNumberOnlyDirective,
    MonthPickerDirective,
    NumberDatePickerDirective,
    CountToDirective,
    TimeFormatDirective,
    PlaceholderWithRequiredIndicatorDirective,
    // Banks, Tink
    // TinkOauthCallbackComponent,
    // TinkNaytaDataComponent,
    // Banks, Nordic Api Gateway
    ViiaOauthCallbackComponent,
    // Banks, Holvi
    HolviOauthCallbackComponent,
    // Tositteiden dialogit
    TositePoistaDialog,
    TositeLukittuDialog,
    // Tositteet, jaettu
    TositteenMuokkausComponent,
    TositteenMuokkausPdfImageBox,
    TositeKuvaComponent,
    TositeLuoUusiComponent,
    TositeKatseleComponent,
    // Ostotositteiden komponentit
    OstotositeListausComponent,
    MaksutavatComponent,
    // OstotositeKatseleComponent,
    // OstotositeMuokkaaComponent,
    // Myyntitositteiden komponentit
    MyyntitositeListausComponent,
    // MyyntitositeMuokkaaComponent,
    // MyyntitositeKatseleComponent,
    // Palkkatositteiden komponentit
    PalkkatositeListausComponent,
    // PalkkatositeMuokkaaComponent,
    // PalkkatositeKatseleComponent,
    // Tiliotetositteiden komponentit
    TiliotetositeListausComponent,
    // TiliotetositeMuokkaaComponent,
    // TiliotetositeKatseleComponent,
    // Selvitettävien tositteiden komponentit
    SelvitettavaTositeListausComponent,
    SelvitettavatMaksutapaComponent,
    // Laskujen vastaanotto
    LaskujenVastaanottoComponent,
    LaskujenHyvaksyminenComponent,
    LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialog,
    // Ajopäiväkirja
    KirjanpidonUlkopuolisetKulutComponent,
    // Pipes
    CurrencyPipe,
    CurrencyWithLocalePipe,
    BigCurrencyPipe,
    BigCurrencyWithLocalePipe,
    LemonKaannaPipe,
    LemonKaannaWithLocalePipe,
    LemonDatePipe,
    LemonDateWithLocalePipe,
    LemonLocalDatePipe,
    LemonNumberDatePipe,
    LemonNumberDateWithLocalePipe,
    LemonNumberDateAndTimePipe,
    LemonNumberDateWithLocaleNarrowPipe,
    LemonDateAndTimePipe,
    LemonLocalDateWithLocalePipe,
    LemonTimestampPipe,
    LemonTimestampWithLocalePipe,
    LemonTimestampDateAndTimePipe,
    LemonTimestampDateAndTimeWithLocalePipe,
    LemonLocalToMonthAndYearPipe,
    LemonNumberMonthToMonthAndYearPipe,
    LemonMaaPipe,
    LemonMaaWithlocalePipe,
    DecimalPipe,
    DecimalWithLocalePipe,
    BigDecimalPipe,
    BigDecimalWithLocalePipe,
    PrettyPrintJsonPipe
    // AuthImagePipe
  ],
  bootstrap: [AppComponent],
  imports: [
    NgxFileDropModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    // CDK below this
    CdkTableModule,
    // Material below this
    MatInputModule,
    MatListModule,
    MatStepperModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSlideToggleModule
  ],
  providers: [
    // Guards
    AuthGuard,
    AsetuksetGuard,
    RoleGuard,
    RoleByTositeTypeGuard,
    ValikkoKiinniGuard,
    ValikkoAukiGuard,
    TilinpaatosUsersGuard,
    TaukoGuard,
    // Resolvers, lasku
    LaskuKatseleComponentDataResolve,
    TuoteComponentDataResolve,
    AsiakasComponentDataResolve,
    LaskuComponentDataResolve,
    LaskutComponentDataResolve,
    // Resolvers, tosite
    TositeKatseleComponentDataResolve,
    TositeMuokkaaComponentDataResolve,
    TositeLuoUusiComponentDataResolve,
    // Resolvers, maksu
    MaksuKatseleComponentDataResolve,
    MaksuMuokkaaComponentDataResolve,
    MaksuLuoUusiComponentDataResolve,
    // Services
    // Vain webin käytössä olevat palvelut
    VersionTarkistusPalvelu,
    LoginService,
    LemonHttpService,
    KayttajaService,
    LemonTranslationService,
    MaaService,
    VasenValikkoService,
    FormValidationService,
    WindowSizeService,
    LaskuSummatService,
    LocalStorageService,
    LadataanService,
    TimestampService,
    AsetustenOletuksetService,
    PdfService,
    DragAndDropService,
    TiedostojenLataamisService,
    LaskuttavaAsiakasLemonaidProvider,
    AiiaDialogService,
    HolviDialogService,
    TunnistautuminenService,
    AllowedDialogsService,
    TuntemistiedotDialogService,
    UusiSopimusDialogService,
    ReportImagesService,
    TilinpaatosLemonaidUsersService,
    LemonBarcodeDetector,
    UserSettingsService,
    // Palkkaus.fi
    PalkkausFiService,
    // Shared
    { provide: BicService, useClass: BicAngularServiceAngular },
    { provide: IbanService, useClass: IbanServiceAngular },
    { provide: CurrencyService, useClass: CurrencyServiceAngular },
    { provide: DateService, useClass: DateServiceAngular },
    { provide: StringService, useClass: StringServiceAngular },
    { provide: FirestoreIndeksoija, useClass: FirestoreIndeksoijaAngular },
    { provide: KopioijaPalvelu, useClass: KopioijaPalveluAngular },
    { provide: TranslationService, useClass: TranslationServiceAngular },
    { provide: ViitenumeroService, useClass: ViitenumeroServiceAngular },
    { provide: KayttoehdotUriService, useClass: KayttoehdotUriServiceAngular },
    { provide: CodeCheckService, useClass: CodeCheckServiceAngular },
    { provide: KycUriService, useClass: KycUriServiceAngular },
    { provide: TilinpaatosUriService, useClass: TilinpaatosUriServiceAngular },
    { provide: BankConsentUriService, useClass: BankConsentUriServiceAngular },
    { provide: BankBalancePublicUriService, useClass: BankBalancePublicUriServiceAngular },
    { provide: VatNumberValidationService, useClass: VatNumberValidationServiceAngular },
    { provide: AsiakasJaettuLemonaidService, useClass: AsiakasJaettuLemonaidServiceAngular },
    { provide: MaksutService, useClass: MaksutServiceAngular },
    { provide: LemonaidRaportitUriService, useClass: LemonaidRaportitUriServiceAngular },

    // Shared, Lasku
    { provide: LaskuIndeksoija, useClass: LaskuIndeksoijaAngular },
    { provide: LaskuKopioija, useClass: LaskuKopioijaAngular },
    { provide: LaskuKorkoService, useClass: LaskuKorkoServiceAngular },
    { provide: ReskontraService, useClass: ReskontraServiceAngular },
    { provide: LaskuSharedService, useClass: LaskuSharedServiceAngular },
    { provide: LaskuSpostiService, useClass: LaskuSpostiServiceAngular },
    { provide: LaskuUriService, useClass: LaskuUriServiceAngular },
    { provide: SahkoisenLaskunValittajaService, useClass: SahkoisenLaskunValittajaServiceAngular },
    // Shared in Angular, Lasku
    KonfiguraatioPalvelu,
    AlvNumeronTarkistajaService,
    LaskunAsiakasTypeahead,
    LaskunTuoteTypeahead,
    LaskuService,
    AlvService,
    FileSaverService,
    // Shared, Tosite
    { provide: TositeUriService, useClass: TositeUriServiceAngular },
    { provide: TositeIndeksoija, useClass: TositeIndeksoijaAngular },
    { provide: TositeKopioija, useClass: TositeKopioijaAngular },
    TositeService,
    TositeKuvaService,
    TositeKuvaCacheService,
    TositeDatasourceService,
    SelvitettavatTositteetService,
    EinvoicesAngularService,
    // Datasources, Lasku
    TuotteetFirestoreDataSource,
    AsiakkaatFirestoreDataSource,
    LaskutFirestoreDataSource,
    // Maksut
    MaksutSharedStateService,
    // Datasources, Tositteet
    // OstotositeListausFirebaseDataSource,
    // MyyntitositeListausFirebaseDataSource,
    // PalkkatositeListausFirebaseDataSource,
    // TiliotetositeListausFirebaseDataSource,
    { provide: CountryLocalizationService, useExisting: MaaService },
    { provide: LaskuttavaAsiakasProvider, useExisting: LaskuttavaAsiakasLemonaidProvider },
    { provide: TimestampProviderBase, useExisting: TimestampService },
    { provide: LaskunAsiakasTypeaheadBase, useExisting: LaskunAsiakasTypeahead },
    { provide: LaskunTuoteTypeaheadBase, useExisting: LaskunTuoteTypeahead },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: DateAdapter, useClass: LemonDateAdapter },
    { provide: LemonDateAdapter, useClass: LemonDateAdapter },
    // { provide: RouteReuseStrategy, useClass: LemonRouteReuseStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: MatSortHeaderIntl, useClass: MyMaterialMatSortHeaderIntl },
    { provide: MatStepperIntl, useClass: MyMaterialMatStepperIntl },
    { provide: MatPaginatorIntl, useClass: MyMaterialPagingIntl },
    // Firebase
    FirebaseLemonaid,
    { provide: SharedFirebaseLemonaid, useExisting: FirebaseLemonaid },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
