<div mat-dialog-content>
  <lemon-dialog-header>
    {{ 'change.otsikko' | translate | async }}
  </lemon-dialog-header>

  <div style="padding: 0 1em 0 2em;">
    <form [formGroup]="loginForm" novalidate style="width: 100%;">
      <div *ngIf="!success">
        <div class="red-text common-error" *ngIf="commonError">{{commonError}}</div>
        <mat-form-field>
          <mat-label>{{'change.oldpassword' | translate | async}}</mat-label>
          <input #oldpasswordInput name="current-password" type="password" formControlName="oldpassword" autocomplete="current-password" matInput required />
          <i matPrefix class="fa fa-lock fa-kuva" aria-hidden="true"></i>
          <mat-error *ngIf="oldpassword?.errors?.required && (oldpassword?.dirty || oldpassword?.touched)">{{ 'change.validation.oldpassword.required' | translate | async }}</mat-error>
          <mat-error *ngIf="oldpassword?.errors?.wrongpassword && (oldpassword?.dirty || oldpassword?.touched)">{{ 'change.validation.oldpassword.wrong-password' | translate | async }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'change.newpassword' | translate | async}}</mat-label>
          <input type="password" name="new-password" formControlName="newpassword" autocomplete="new-password" matInput required />
          <i matPrefix class="fa fa-lock fa-kuva" aria-hidden="true"></i>
          <mat-error *ngIf="newpassword?.errors?.required && (newpassword?.dirty || newpassword?.touched)">{{ 'change.validation.newpassword.required' | translate | async }}</mat-error>
        </mat-form-field>
        <div class="vihjeet">
          <div [class.oikein]="!virheet.number && !virheet.required" [class.green-text]="!virheet.number && !virheet.required">{{ 'reset.validation.number' | translate | async }}</div>
          <div [class.oikein]="!virheet.lettersmall && !virheet.required" [class.green-text]="!virheet.lettersmall && !virheet.required">{{ 'reset.validation.lettersmall' | translate | async }}</div>
          <div [class.oikein]="!virheet.letterbig && !virheet.required" [class.green-text]="!virheet.letterbig && !virheet.required">{{ 'reset.validation.letterbig' | translate | async }}</div>
          <div [class.oikein]="!virheet.differentchars && !virheet.required" [class.green-text]="!virheet.differentchars && !virheet.required">{{ 'reset.validation.differentchars' | translate | async }}</div>
          <div [class.oikein]="!virheet.min && !virheet.required" [class.green-text]="!virheet.min && !virheet.required">{{ 'reset.validation.min' | translate | async }}</div>
        </div>
      </div>
      <div style="padding: 5px;" class="green-text" *ngIf="success">{{ 'change.onnistui' | translate | async }}</div>
    </form>
  </div>

</div>
<div mat-dialog-actions>
  <button type="button" *ngIf="!success" class="secondarybutton" mat-button mat-dialog-close>{{ 'yleiset.peruuta' | translate | async }}</button>
  <span *ngIf="!success" class="button-spacer"></span>
  <button type="button" *ngIf="!success" class="primarybutton" mat-button (click)="changePassword()">{{ 'change.vaihda' | translate | async }}</button>
  <button type="button" *ngIf="success" class="primarybutton" mat-button mat-dialog-close>{{ 'yleiset.ok' | translate | async }}</button>
</div>