
<mat-dialog-content>

  <lemon-dialog-header>
    {{'lasku.asiakas-poisto-varmitus-dialog.otsikko' | translate | async}}
  </lemon-dialog-header>

  <div style="margin: 2em;">
      {{'lasku.asiakas-poisto-varmitus-dialog.teksti' | translate | async}}
  </div>
  
</mat-dialog-content>
<mat-dialog-actions style="padding-left: 3em; padding-right: 3em;">
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
  <button class="secondarybutton" mat-button (click)="poista()">{{'yleiset.poista' | translate | async}}</button>
</mat-dialog-actions>
