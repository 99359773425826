import { Timestamp } from '../../_shared-core/model/common'

export type NetsEnvironment = 'prod' | 'beta' | 'dev' | 'local-dev'

export interface StartNewStrongAuthenticationSession {
  action: ActionAfterAuthentication
}

export type PaymentDueType = 'heti' | 'erapaivana'
export interface StartNewStrongAuthenticationSessionForPayments extends StartNewStrongAuthenticationSession {
  /** array of FirestoreTosite.avain */
  maksut: string[]
  dueType: PaymentDueType
  paymentConfigId: string
}

export interface StrongAuthenticationSessionInfo {
  avain: string
  state: string
  stateHash: string
  nonce: string
}

export interface NetsTunnistauduRequest {
  avain: string
  state: string
  stateHash: string
  code: string
  env: NetsEnvironment
}

export interface NetsTunnistauduResponse {
  result: 'success' | 'failure'
  action: ActionAfterAuthentication
  resultCause?: NetsTunnistauduResponseResultCause
}

export enum NetsTunnistauduResponseResultCause {
  SSN_MISMATCH = 'ssn-mismatch'
}

export interface TallennaUserKycRequest {
  kansallisuus: string
  pep: boolean
}

export type ActionAfterAuthentication = 'continue-welcome' | 'continue-standalone-dialog' | 'continue-document-sign' | 'continue-payment-approval'

export interface StrongAuthenticationSessionHistory {
  asiakasAvain: string
  kayttajaAvain: string
  logTime: Timestamp
  step: StrongAuthenticationSessionStep
  details: string
  env: NetsEnvironment
}

export type StrongAuthenticationSessionStep = 'redirect' | 'returned' | 'success' | 'error'
