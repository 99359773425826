<picture>
  <source srcset="/assets/taustat/login_720x512_crushed.webp 720w, /assets/taustat/login_1440x1024_crushed.webp 1440w, /assets/taustat/login_2880x2048_crushed.webp 2880w, /assets/taustat/login_4320x3072_crushed.webp 4320w" type="image/webp" sizes="100vw">
  <source srcset="/assets/taustat/login_720x512_crushed.png 720w, /assets/taustat/login_1440x1024_crushed.png 1440w, /assets/taustat/login_2880x2048_crushed.png 2880w, /assets/taustat/login_4320x3072_crushed.png 4320w" type="image/png" sizes="100vw">
  <img src="/assets/taustat/login_720x512_crushed.png" class="tausta">
</picture>

<div class="all-container">

  <mat-nav-list style="position: absolute; left: 4px; top: 4px; padding-top: 0;">
    <mat-list-item (click)="vaihdaKieli()">
      <div style="text-align: center; width: 100%; color:black;">Kieli /
        <!-- Språk /--> Language
      </div>
    </mat-list-item>
  </mat-nav-list>



  <div class="login-container">
    <form [formGroup]="loginForm" novalidate>
      <div class="mat-elevation-z3 top-corners-rounded bottom-corners-rounded">
        <div class="top-corners-rounded" style="padding: 2px;">
          <div class="valkoinen logo-container top-corners-rounded" style="font-size: 28px;">
            {{ 'forgot.palauta' | translate | async }}
          </div>
        </div>
        <div class="content-container">
          <div class="login-controls">
            <div class="top-login-spacer-top"></div>
            <div class="red-text common-error" *ngIf="commonError">{{commonError}}</div>
            <div class="top-login-spacer-bottom"></div>
            <div *ngIf="!success">
              <mat-form-field>
                <mat-label>{{'login.email' | translate | async}}</mat-label>
                <input type="email" inputmode="email" name="email" formControlName="email" autocomplete="email" matInput required />
                <i matPrefix class="fa fa-user-circle fa-kuva" aria-hidden="true"></i>
                <mat-error>{{fieldErrors.email}}</mat-error>
              </mat-form-field>
            </div>
            <div style="padding: 5px; color: #00BDFB" *ngIf="success">{{ 'forgot.onnistui' | translate | async }}</div>
          </div>
          <div class="button-container">
            <div>
              <button type="button" [class.secondarybutton]="!success" [class.primarybutton]="success" style="margin-right: 1vw; margin-left: 0;" mat-button (click)="back()">{{ (success ? 'yleiset.ok' : 'yleiset.peruuta') | translate | async }}</button>
              <span class="button-spacer"></span>
              <button type="button" *ngIf="!success" class="primarybutton" style="margin-right: 0; margin-left: 1vw;" mat-button (click)="login()">{{ 'forgot.palauta' | translate | async }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <span class="disclaimer">© TILITOIMISTO LEMON TREE OY {{year}}</span>

</div>