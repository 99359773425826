import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'

import { Router } from '@angular/router'

@Component({
  selector: '[app-maksut-mainos]',
  templateUrl: './maksut-mainos.component.html',
  styleUrls: ['./maksut-mainos.component.css']
})
export class MaksutMainosComponent implements OnInit, OnDestroy, AfterViewInit {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _router: Router
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    // this.updateSeenFeatures(NewFeature.LASKUJEN_VASTAANOTTO)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  otaKayttoonVastaanotto(event: MouseEvent) {
    event?.preventDefault()
    event?.stopImmediatePropagation()
    event?.stopPropagation()
    window.scrollTo(0, 0)
    this._router.navigate(['maksut', 'asetukset', 'vastaanotto'])
  }

  otaKayttoonMaksaminen(event: MouseEvent) {
    event?.preventDefault()
    event?.stopImmediatePropagation()
    event?.stopPropagation()
    window.scrollTo(0, 0)
    this._router.navigate(['maksut', 'asetukset', 'maksaminen'])
  }

}
