<style>
  .all {
    /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
    max-height: 80vh;
  }

  @media screen and (min-height: 751px) and (max-height: 1100px) {

    .all {
      /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
      max-height: 85vh;
    }

  }

  @media screen and (max-height: 750px) {

    .all {
      /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
      max-height: 92vh;
    }

  }
</style>

<div class="all" mat-dialog-content style="padding: 0 2vw">
  <mat-horizontal-stepper [@.disabled]="true" *ngIf="!mobiili" [linear]="true" style="margin-top: 30px;">

    <mat-step>
      <ng-template matStepLabel>
        {{'yleiset.aloitus' | translate | async}}
      </ng-template>

      <div style="min-height: 415px; display: flex; justify-content: center; flex-direction: row; align-items: center; max-width: 415px; margin-left: auto; margin-right: auto;">

        <div style="text-align: center; font-size: 17.5px;">
          <h2 class="light-blue-text" style="font-size: 46px; margin-bottom: 80px;" [innerHtml]="'laskuasetukset.welcome-dialog.laskutusasetukset' | translate | async"></h2>
          <p>{{'laskuasetukset.welcome-dialog.paaset-kohta-laskuttamaan' | translate | async}}</p>
          <p>{{'laskuasetukset.welcome-dialog.varmistetaan-ensin' | translate | async}}</p>
        </div>

      </div>

      <div style="min-height: 100px; display: flex; justify-content: center; flex-direction: row; align-items: center;">
        <button class="secondarybutton" mat-button matDialogClose style="margin-right: 20px;">{{'yleiset.peruuta' | translate | async}}</button>
        <button class="primarybutton" mat-button matStepperNext>{{'yleiset.jatka' | translate | async}}</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="yrityksenTiedotForm">

      <ng-template matStepLabel>
        {{'laskuasetukset.welcome-dialog.yrityksen-tiedot' | translate | async}}
      </ng-template>

      <div style="min-height: 415px; max-width: 400px; margin-left: auto; margin-right: auto;">

        <h2 class="light-blue-text" style="text-align: center; padding-top: 25px;">{{'laskuasetukset.welcome-dialog.yrityksen-tiedot' | translate | async}}</h2>

        <form [formGroup]="yrityksenTiedotForm" novalidate>

          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.nimi' | translate | async }}</mat-label>
            <input #nimiInput type="text" formControlName="nimi" matInput required />
            <mat-error>{{yrityksenTiedotErrors.nimi}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.ytunnus' | translate | async }}</mat-label>
            <input type="text" formControlName="ytunnus" matInput required />
            <mat-error>{{yrityksenTiedotErrors.ytunnus}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.katuosoite' | translate | async }}</mat-label>
            <input type="text" formControlName="katuosoite" matInput required />
            <mat-error>{{yrityksenTiedotErrors.katuosoite}}</mat-error>
          </mat-form-field>

          <div style="display: flex;">
            <mat-form-field style="width: 150px; margin-right: 1em;">
              <mat-label>{{ 'laskuasetukset.postinro' | translate | async }}</mat-label>
              <input type="text" formControlName="postinro" matInput required />
              <mat-error>{{yrityksenTiedotErrors.postinro}}</mat-error>
            </mat-form-field>
            <mat-form-field style="flex-grow: 1;">
              <mat-label>{{ 'laskuasetukset.postitoimipaikka' | translate | async }}</mat-label>
              <input type="text" formControlName="postitoimipaikka" matInput required />
              <mat-error>{{yrityksenTiedotErrors.postitoimipaikka}}</mat-error>
            </mat-form-field>
          </div>

          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.maa' | translate | async }}</mat-label>
            <mat-select formControlName="maa" style="width: 100%;" required>
              <mat-option *ngFor="let maa of maat | async" [value]="maa.koodi">
                {{ maa.nimi }}
              </mat-option>
            </mat-select>
            <mat-error>{{yrityksenTiedotErrors.maa}}</mat-error>
          </mat-form-field>
          <!-- <mat-form-field>
            <mat-label>{{ 'laskuasetukset.maa' | translate | async }}</mat-label>
<input type="text" formControlName="maa"  matInput required />
            <mat-error>{{yrityksenTiedotErrors.maa}}</mat-error>
          </mat-form-field> -->

        </form>

      </div>

      <div style="min-height: 100px; display: flex; justify-content: center; flex-direction: row; align-items: center;">
        <button class="secondarybutton" style="margin-right: 20px;" mat-button matStepperPrevious>{{'yleiset.takaisin' | translate | async}}</button>
        <button class="primarybutton" mat-button matStepperNext>{{'yleiset.jatka' | translate | async}}</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="yhteystiedotForm">
      <ng-template matStepLabel>
        {{'laskuasetukset.welcome-dialog.yhteystiedot' | translate | async}}
      </ng-template>

      <div style="min-height: 415px; max-width: 400px; margin-left: auto; margin-right: auto;">
        <!-- <h2 class="light-blue-text" style="text-align: center;">Yhteystiedot</h2> -->

        <form [formGroup]="yhteystiedotForm" novalidate>

          <h2 class="light-blue-text" style="text-align: center; padding-top: 25px;">{{'laskuasetukset.pankkiyhteyden-tiedot' | translate | async}}</h2>
          <div class="asetusrypas">
            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.iban' | translate | async }}</mat-label>
              <input type="text" formControlName="iban" matInput required />
              <!-- <mat-hint>{{ibanVihje}}</mat-hint> -->
              <mat-error>{{yhteystiedotErrors.iban}}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.bic' | translate | async }}</mat-label>
              <input type="text" formControlName="bic" matInput required />
              <mat-error>{{yhteystiedotErrors.bic}}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.pankki' | translate | async }}</mat-label>
              <input type="text" formControlName="pankki" matInput required />
              <mat-error>{{yhteystiedotErrors.pankki}}</mat-error>
            </mat-form-field>
          </div>

          <h2 class="light-blue-text" style="text-align: center; padding-top: 50px;">{{'laskuasetukset.asiakaspalvelun-yhteystiedot' | translate | async}}</h2>
          <div class="asetusrypas">
            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.aspaemail' | translate | async }}</mat-label>
              <input type="text" formControlName="aspaemail" matInput required />
              <mat-error>{{yhteystiedotErrors.aspaemail}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.aspapuh' | translate | async }}</mat-label>
              <input type="text" formControlName="aspapuh" matInput />
              <mat-error>{{yhteystiedotErrors.aspapuh}}</mat-error>
            </mat-form-field>
          </div>

        </form>

      </div>

      <div style="min-height: 100px; display: flex; justify-content: center; flex-direction: row; align-items: center;">
        <button class="secondarybutton" style="margin-right: 20px;" mat-button matStepperPrevious>{{'yleiset.takaisin' | translate | async}}</button>
        <button class="primarybutton" mat-button matStepperNext>{{'yleiset.jatka' | translate | async}}</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        {{'yleiset.valmis' | translate | async}}
      </ng-template>

      <div style="min-height: 415px; display: flex; justify-content: center; flex-direction: row; align-items: center; max-width: 400px; margin-left: auto; margin-right: auto;">

        <div style="text-align: center;">
          <svg style="height: calc(76px + 1vw);" xmlns="http://www.w3.org/2000/svg" viewBox="4 4 22 22">
            <defs>
              <style>
                .cls-1 {
                  fill: rgb(62, 203, 244);
                }
              </style>
            </defs>
            <path class="cls-1" d="M15 25.62A10.62 10.62 0 1 1 25.62 15 10.63 10.63 0 0 1 15 25.62ZM15 5.23A9.77 9.77 0 1 0 24.77 15 9.78 9.78 0 0 0 15 5.23Z"></path>
            <rect class="cls-1" x="13.26" y="10.91" width="0.85" height="2.93"></rect>
            <rect class="cls-1" x="15.89" y="10.91" width="0.85" height="2.93"></rect>
            <path class="cls-1" d="M15 20.08a3.22 3.22 0 0 1-3.22-3.22h0.85a2.37 2.37 0 1 0 4.74 0h0.85A3.22 3.22 0 0 1 15 20.08Z"></path>
          </svg>
          <h2 class="light-blue-text" style="margin: 0; padding-top: 15px;">{{'yleiset.valmista' | translate | async}}</h2>
          <p style="font-weight: bold; padding-top: 25px; padding-bottom: 15px;">{{'laskuasetukset.welcome-dialog.kaikki-valmis' | translate | async}}</p>
          <p>{{'laskuasetukset.welcome-dialog.kay-tutustumassa' | translate | async}}</p>
        </div>

      </div>

      <div style="min-height: 100px; display: flex; justify-content: center; flex-direction: row; align-items: center;">
        <button class="secondarybutton" mat-button matStepperPrevious>{{'yleiset.takaisin' | translate | async}}</button>
        <button (click)="tallenna()" class="primarybutton" style="margin-left: 20px;" mat-button>{{'yleiset.aloita' | translate | async}}</button>
      </div>
    </mat-step>

  </mat-horizontal-stepper>

  <mat-vertical-stepper *ngIf="mobiili" [linear]="true" style="margin-top: 15px;">

    <mat-step>
      <ng-template matStepLabel>
        {{'yleiset.aloitus' | translate | async}}
      </ng-template>

      <div style="min-height: 315px; display: flex; justify-content: center; flex-direction: row; align-items: center; max-width: 500px; margin-left: auto; margin-right: auto;">

        <div style="text-align: center;">
          <h2 class="light-blue-text" style="font-size: 46px; margin-bottom: 40px; padding-top: 0px;" [innerHtml]="'laskuasetukset.welcome-dialog.laskutusasetukset' | translate | async"></h2>
          <p>{{'laskuasetukset.welcome-dialog.paaset-kohta-laskuttamaan' | translate | async}}</p>
          <p>{{'laskuasetukset.welcome-dialog.varmistetaan-ensin' | translate | async}}</p>
        </div>

      </div>

      <div style="min-height: 60px; display: flex; justify-content: space-around; flex-direction: row; align-items: flex-end;">
        <button class="secondarybutton" mat-button matDialogClose>{{'yleiset.peruuta' | translate | async}}</button>
        <button class="primarybutton" mat-button matStepperNext>{{'yleiset.jatka' | translate | async}}</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="yrityksenTiedotForm">

      <ng-template matStepLabel>
        {{'laskuasetukset.welcome-dialog.yrityksen-tiedot' | translate | async}}
      </ng-template>

      <div style="min-height: 415px; max-width: 400px; margin-left: auto; margin-right: auto;">

        <h2 class="light-blue-text" style="text-align: center; padding-top: 25px;">{{'laskuasetukset.welcome-dialog.yrityksen-tiedot' | translate | async}}</h2>

        <form [formGroup]="yrityksenTiedotForm" novalidate>

          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.nimi' | translate | async }}</mat-label>
            <input #nimiInput type="text" formControlName="nimi" matInput required />
            <mat-error>{{yrityksenTiedotErrors.nimi}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.ytunnus' | translate | async }}</mat-label>
            <input type="text" formControlName="ytunnus" matInput required />
            <mat-error>{{yrityksenTiedotErrors.ytunnus}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.katuosoite' | translate | async }}</mat-label>
            <input type="text" formControlName="katuosoite" matInput required />
            <mat-error>{{yrityksenTiedotErrors.katuosoite}}</mat-error>
          </mat-form-field>

          <div style="display: flex;">
            <mat-form-field style="width: 150px; margin-right: 1em;">
              <mat-label>{{ 'laskuasetukset.postinro' | translate | async }}</mat-label>
              <input type="text" formControlName="postinro" matInput required />
              <mat-error>{{yrityksenTiedotErrors.postinro}}</mat-error>
            </mat-form-field>
            <mat-form-field style="flex-grow: 1;">
              <mat-label>{{ 'laskuasetukset.postitoimipaikka' | translate | async }}</mat-label>
              <input type="text" formControlName="postitoimipaikka" matInput required />
              <mat-error>{{yrityksenTiedotErrors.postitoimipaikka}}</mat-error>
            </mat-form-field>
          </div>

          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.maa' | translate | async }}</mat-label>
            <mat-select formControlName="maa" style="width: 100%;" required>
              <mat-option *ngFor="let maa of maat | async" [value]="maa.koodi">
                {{ maa.nimi }}
              </mat-option>
            </mat-select>
            <mat-error>{{yrityksenTiedotErrors.maa}}</mat-error>
          </mat-form-field>
          <!-- <mat-form-field>
            <mat-label>{{ 'laskuasetukset.maa' | translate | async }}</mat-label>
<input type="text" formControlName="maa"  matInput required />
            <mat-error>{{yrityksenTiedotErrors.maa}}</mat-error>
          </mat-form-field> -->

        </form>

      </div>

      <div style="min-height: 60px; display: flex; justify-content: space-around; flex-direction: row; align-items: flex-end; flex-wrap: wrap;">
        <button class="secondarybutton" style="margin-top: 25px; margin-left: 25px; margin-right: 25px;" mat-button matStepperPrevious>{{'yleiset.takaisin' | translate | async}}</button>
        <button class="primarybutton" style="margin-top: 25px;" mat-button matStepperNext>{{'yleiset.jatka' | translate | async}}</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="yhteystiedotForm">
      <ng-template matStepLabel>
        {{'laskuasetukset.welcome-dialog.yhteystiedot' | translate | async}}
      </ng-template>

      <div style="min-height: 415px; max-width: 400px; margin-left: auto; margin-right: auto;">
        <!-- <h2 class="light-blue-text" style="text-align: center;">Yhteystiedot</h2> -->

        <form [formGroup]="yhteystiedotForm" novalidate>

          <h2 class="light-blue-text" style="text-align: center; padding-top: 25px;">{{'laskuasetukset.pankkiyhteyden-tiedot' | translate | async}}</h2>
          <div class="asetusrypas">
            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.iban' | translate | async }}</mat-label>
              <input type="text" formControlName="iban" matInput required />
              <!-- <mat-hint>{{ibanVihje}}</mat-hint> -->
              <mat-error>{{yhteystiedotErrors.iban}}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.bic' | translate | async }}</mat-label>
              <input type="text" formControlName="bic" matInput required />
              <mat-error>{{yhteystiedotErrors.bic}}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.pankki' | translate | async }}</mat-label>
              <input type="text" formControlName="pankki" matInput required />
              <mat-error>{{yhteystiedotErrors.pankki}}</mat-error>
            </mat-form-field>
          </div>

          <h2 class="light-blue-text" style="text-align: center; padding-top: 50px;">{{'laskuasetukset.asiakaspalvelun-yhteystiedot' | translate | async}}</h2>
          <div class="asetusrypas">
            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.aspaemail' | translate | async }}</mat-label>
              <input type="text" formControlName="aspaemail" matInput required />
              <mat-error>{{yhteystiedotErrors.aspaemail}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'laskuasetukset.aspapuh' | translate | async }}</mat-label>
              <input type="text" formControlName="aspapuh" matInput />
              <mat-error>{{yhteystiedotErrors.aspapuh}}</mat-error>
            </mat-form-field>
          </div>

        </form>

      </div>

      <div style="min-height: 60px; display: flex; justify-content: space-around; flex-direction: row; align-items: flex-end; flex-wrap: wrap;">
        <button class="secondarybutton" style="margin-top: 25px; margin-left: 25px; margin-right: 25px;" mat-button matStepperPrevious>{{'yleiset.takaisin' | translate | async}}</button>
        <button class="primarybutton" style="margin-top: 25px;" mat-button matStepperNext>{{'yleiset.jatka' | translate | async}}</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        {{'yleiset.valmis' | translate | async}}
      </ng-template>

      <div style="min-height: 250px; display: flex; justify-content: center; flex-direction: row; align-items: center; max-width: 300px; margin-left: auto; margin-right: auto;">

        <div style="text-align: center;">
          <svg style="height: calc(76px + 1vw);" xmlns="http://www.w3.org/2000/svg" viewBox="4 4 22 22">
            <defs>
              <style>
                .cls-1 {
                  fill: rgb(62, 203, 244);
                }
              </style>
            </defs>
            <path class="cls-1" d="M15 25.62A10.62 10.62 0 1 1 25.62 15 10.63 10.63 0 0 1 15 25.62ZM15 5.23A9.77 9.77 0 1 0 24.77 15 9.78 9.78 0 0 0 15 5.23Z"></path>
            <rect class="cls-1" x="13.26" y="10.91" width="0.85" height="2.93"></rect>
            <rect class="cls-1" x="15.89" y="10.91" width="0.85" height="2.93"></rect>
            <path class="cls-1" d="M15 20.08a3.22 3.22 0 0 1-3.22-3.22h0.85a2.37 2.37 0 1 0 4.74 0h0.85A3.22 3.22 0 0 1 15 20.08Z"></path>
          </svg>
          <h2 class="light-blue-text" style="margin: 0; padding-top: 15px;">{{'yleiset.valmista' | translate | async}}</h2>
          <p style="font-weight: bold; padding-top: 25px; padding-bottom: 15px;">{{'laskuasetukset.welcome-dialog.kaikki-valmis' | translate | async}}</p>
          <p>{{'laskuasetukset.welcome-dialog.kay-tutustumassa' | translate | async}}</p>
        </div>

      </div>

      <div style="min-height: 60px; display: flex; justify-content: space-around; flex-direction: row; align-items: flex-end;">
        <button class="secondarybutton" mat-button matStepperPrevious>{{'yleiset.takaisin' | translate | async}}</button>
        <button (click)="tallenna()" class="primarybutton" style="margin-left: 25px;" mat-button>{{'yleiset.aloita' | translate | async}}</button>
      </div>
    </mat-step>

  </mat-vertical-stepper>

</div>