import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'

import { TuettuKieli } from './_shared-core/model/common'

import { LemonTranslationService } from './_jaettu-angular/service/lemon-translation.service'
import { KayttajaService } from './_angular/service/kayttaja.service'

export interface VaihdaKieliDialogData {
  tallenna: boolean
}

@Component({
  templateUrl: './vaihda-kieli.dialog.html'
})
export class VaihdaKieliDialog {

  get kieli(): TuettuKieli {
    return this.translationService.nykyinenKieli
  }

  set kieli(kieli: TuettuKieli) {
    this.translationService.vaihdaKieli(kieli)
    if (this.data.tallenna) {
      this.kayttajaService.tallennaKayttajanKieli(kieli)
    }
    this.dialogRef.close()
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: VaihdaKieliDialogData,
    private translationService: LemonTranslationService,
    private dialogRef: MatDialogRef<VaihdaKieliDialog>,
    private kayttajaService: KayttajaService
  ) {

  }

}
