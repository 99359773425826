<div *ngIf="lahetysdata | async">

  <div class="blue-background">
    <div style="margin-left: auto; margin-right: auto">

      <div class="ylapalkki valkoinen">

        <button class="sulje" mat-button (click)="peruutaLahettaminen()" type="button" class="valkoinen">
          <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
        </button>

        <div class="dropparit-container" style="width: 100%;">
          <div class="dropparit">
            <button (click)="vaihdaValilehti(0)" mat-button style="text-align: center; margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; min-width: 84.5px;" [class.selectedmenu]="(aktiivinenValilehti | async) === 0">
              <i class="icon ion-ios-at-outline" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px;"></i>
              <br>
              {{'lasku.sposti-dialog.laheta-lasku-spostilla' | translate | async}}
            </button>
            <button (click)="vaihdaValilehti(1)" mat-button style="text-align: center; margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; min-width: 84.5px;" [class.selectedmenu]="(aktiivinenValilehti | async) === 1">
              <i class="icon ion-ios-flash-outline" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px;"></i>
              <br>
              {{'lasku.sposti-dialog.verkkolasku.laheta-verkkolaskuna' | translate | async}}
            </button>
            <button (click)="vaihdaValilehti(3)" disabled mat-button style="text-align: center; margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; min-width: 84.5px;" [class.selectedmenu]="(aktiivinenValilehti | async) === 3">
              <i class="icon ion-ios-mail-outline" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px;"></i>
              <br>
              {{'lasku.sposti-dialog.paperi.laheta' | translate | async}}
            </button>
            <button (click)="vaihdaValilehti(2)" mat-button style="text-align: center; margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; min-width: 84.5px;" [class.selectedmenu]="(aktiivinenValilehti | async) === 2">
              <i class="icon ion-ios-person-outline" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px;"></i>
              <br>
              {{'lasku.sposti-dialog.itse.laheta' | translate | async}}
            </button>
          </div>
        </div>

      </div>

    </div>
  </div>

  <app-laheta-component [data]="lahetysdata | async" [aktiivinenValilehti]="aktiivinenValilehti | async" (laskuLahetettiin)="kasitteleLahetys($event)"></app-laheta-component>

</div>

<form *ngIf="!(lahetysdata | async)" [formGroup]="laskuForm" novalidate autocomplete="kirjanpitoaineistoarkki" style="width: 100%;">

  <div class="blue-background">
    <div style="margin-left: auto; margin-right: auto">

      <div class="ylapalkki valkoinen">

        <button class="sulje" mat-button (click)="peruuta()" type="button">
          <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
        </button>

        <app-lasku-ylaosan-dropparit style="width: 100%;" [parent]="laskuForm"></app-lasku-ylaosan-dropparit>

      </div>

    </div>
  </div>

  <div style="margin-left: auto; margin-right: auto;">

    <div class="infoboksi">
      <div *ngIf="(laskunTyyppiObservable | async)?.avain === 'kuleur'" style="text-align: center;" class="red-text">
        <p>{{ 'lasku.alv-info-teksti-head' | translate | async }}</p>
        <p style="margin: 0 0 5px">{{ 'lasku.alv-info-teksti1' | translate | async }}</p>
        <p style="margin: 0 0 5px">{{ 'lasku.alv-info-teksti2' | translate | async }}</p>
        <p style="margin: 0 0 5px">{{ 'lasku.alv-info-teksti3' | translate | async }}</p>
      </div>
      <div *ngIf="juurilasku.avain !== kasiteltava.avain && kasiteltava.nrotyyppi === 'h'" style="text-align: center;">{{ 'lasku.hyvitys-info-teksti' | translate | async }}</div>
      <div *ngIf="juurilasku.avain !== kasiteltava.avain && kasiteltava.nrotyyppi !== 'h'" style="text-align: center;">{{ 'lasku.muokkaus-info-teksti' | translate | async }}</div>
    </div>

    <div class="lasku-container">

      <div class="vasenp">

        <div class="ylaosantiedot">
          <div class="ylaosavasen">
            <app-lasku-asiakkaan-tiedot #asiakkaanTiedot [parent]="laskuForm" [laskunTyyppiObservable]="laskunTyyppiObservable" [laskuObservable]="laskuObservable" (asiakasMuuttui)="paivitaValittuAsiakas($event)" [paivitaValidaatiot]="paivitaValidaatiot"></app-lasku-asiakkaan-tiedot>
          </div>
          <div class="ylaosaoikea">
            <app-lasku-muut-tiedot [parent]="laskuForm" [laskuObservable]="laskuObservable" [paivitaValidaatiot]="paivitaValidaatiot" [naytaViivastuskorko]="naytaViivastuskorkoObservable | async"></app-lasku-muut-tiedot>
          </div>
        </div>

        <!-- {{fieldErrors | json}} -->

        <app-lasku-rivit [parent]="laskuForm" [laskunLiitetiedostotObservable]="laskunLiitetiedostotObservable" [laskunVastaanottajanMaaObservable]="laskunVastaanottajanMaaObservable" [laskunTyyppiObservable]="laskunTyyppiObservable" [laskunTuotteetObservable]="laskunTuotteetObservable" [paivitaValidaatiot]="paivitaValidaatiot"></app-lasku-rivit>

        <div>
          <table style="width: 100%; text-align: right; font-size: .9rem;">
            <tr style="color: rgb(153,153,153);">
              <td>
                {{'lasku.yhteensa-veroton' | translate | async}}
              </td>
              <td style="width: 150px; padding-right: 5px;">
                {{ alvitonSumma | lmoney:kasiteltava?.valuutta | async}}
              </td>
            </tr>
            <tr style="color: rgb(153,153,153);">
              <td>
                {{'lasku.alv' | translate | async}}
              </td>
              <td style="padding-right: 5px;">
                {{ alvSumma | lmoney:kasiteltava?.valuutta | async }}
              </td>
            </tr>
            <tr *ngIf="aiemminMaksettu" style="color: rgb(153,153,153);">
              <td>
                {{'lasku.yhteensa-verollinen' | translate | async}}
              </td>
              <td style="padding-right: 5px;">
                {{ alvSumma + alvitonSumma | lmoney:kasiteltava?.valuutta | async }}
              </td>
            </tr>
            <tr *ngIf="aiemminMaksettu" style="color: rgb(153,153,153);">
              <td>
                {{'lasku.aiemmin-maksettu-tuote' | translate | async}}
              </td>
              <td style="padding-right: 5px;">
                {{ aiemminMaksettu | lmoney:kasiteltava?.valuutta | async }}
              </td>
            </tr>
            <tr style="font-size: 1.1rem;" class="light-blue-text">
              <td>
                {{'pdf-perinteinen.maksettava-yhteensa' | translate | async}}
              </td>
              <td style="padding-right: 5px;">
                {{ alvSumma + alvitonSumma + aiemminMaksettu | lmoney:kasiteltava?.valuutta | async }}
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="asetuksissaOnOletustietojaVirhe" class="red-text" style="margin-top: 4em;">
          {{'lasku.validation.asetukset.oletuksia-print' | translate | async}}
        </div>

        <div *ngIf="hyvitysPositiivinenVirhe" class="red-text" style="text-align: center; margin-top: 4em;">
          {{'lasku.validation.hyvitys.ei-voi-olla-positiivinen' | translate | async}}
        </div>

        <div style="margin-top: 4em; margin-bottom: 4em; text-align: center;">
          <button *ngIf="!hyvitys && juurilasku.avain === kasiteltava.avain" class="secondarybutton" mat-button (click)="save()" type="button" style="margin-left: .5em; margin-right: .5em; margin-top: 1em;">
            {{'yleiset.tallenna' | translate | async}}
          </button>
          <button class="primarybutton" mat-button (click)="saveAndSendEmail()" type="button" style="margin-left: .5em; margin-right: .5em; margin-top: 1em;">
            {{'lasku.spostilahetys' | translate | async}}
          </button>
        </div>

      </div>

      <div class="oikeap">
        <div style="margin: 14px 0; font-size: 1.2rem;" class="light-blue-text">{{'lasku.esikatselu.otsikko' | translate | async}}</div>
        <lasku-pdf-esikatselu-perinteinen [tiedot]="esikatselutiedot | async"></lasku-pdf-esikatselu-perinteinen>
      </div>

    </div>

  </div>

</form>