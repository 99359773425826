import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { TimestampService } from '../_jaettu-angular/service/timestamp-service'

import { LaskuKatseleComponentData } from '../_angular/_resolvers/lasku.resolve'
import { KayttajaService } from '../_angular/service/kayttaja.service'

import { LaskuUriService } from '../_jaettu/service/lasku/lasku-uri.service'

import { LaskuTeePdfUudelleentyojononMerkinta } from '../_jaettu/model/lasku'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  template: ''
})
export class LaskuTeePdfUudelleenComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private laskuUriService: LaskuUriService,
    private kayttajaService: KayttajaService,
    private errorHandler: ErrorHandler,
    private timestampService: TimestampService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) { }

  ngOnInit() {
    this.route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: { data: LaskuKatseleComponentData }) => {

      this.kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
        const merkintaUri = this.laskuUriService.getTyojonoLaskuGeneroiPdfUudelleenUri(kayttajanTiedot.asiakasId, this._firebaseLemonaid.firestoreCreateId())
        const merkinta: LaskuTeePdfUudelleentyojononMerkinta = {
          aloitettu: this.timestampService.now(),
          kasiteltavaAvain: data.data.kasiteltava.avain,
          laskuAvain: data.data.juurilasku.avain,
          uudelleenyrityksia: 0
        }
        return this._firebaseLemonaid.firestoreSetData(merkintaUri, merkinta).then(result => {
          this.router.navigate(['laskutus/laskut', data.data.juurilasku.avain, data.data.kasiteltava.avain, 'katsele'])
        })
      }).catch(err => {
        this.errorHandler.handleError(err)
      })

    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
