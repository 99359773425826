import { Injectable } from '@angular/core'
import { NewFeature, NewFeatureUserActionOption, UserSettings } from '../../_jaettu/model/kayttaja'
import { FirebaseLemonaid } from './firebase-lemonaid.service'
import { Observable, switchMap, of as observableOf } from 'rxjs'
import { KayttajaService } from './kayttaja.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'


@Injectable()
export class UserSettingsService {

  userSettingsObservable: Observable<UserSettings>

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _kayttajaService: KayttajaService
  ) {
    this.userSettingsObservable = this._kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (!kayttajanTiedot?.asiakasAvain) {
          return observableOf<UserSettings>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<UserSettings>(this.getUserSettingsUri(kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid)).listen()
      }),
      lemonShare()
    )
  }

  getUserSettingsUri(asiakasAvain: string, kayttajaAvain: string) {
    return 'customers/' + asiakasAvain + '/customers-users/' + kayttajaAvain + '/customer-user-settings/' + kayttajaAvain
  }

  async setActionAndFeatureSeen(seenFeature: NewFeature, action: NewFeatureUserActionOption, asiakasAvain: string, kayttajaAvain: string, lemonaidFirestore: FirebaseLemonaid) {
    const toSave: UserSettings = {
      asiakasAvain: asiakasAvain,
      kayttajaAvain: kayttajaAvain,
      seen: {},
      userAction: {}
    }

    toSave.seen[seenFeature] = true
    toSave.userAction[seenFeature] = action

    const newFeaturesUri = this.getUserSettingsUri(asiakasAvain, kayttajaAvain)
    return lemonaidFirestore.firestoreSetData(newFeaturesUri, toSave, { merge: true })
  }

  async setFeatureSeen(seenFeature: NewFeature, asiakasAvain: string, kayttajaAvain: string, lemonaidFirestore: FirebaseLemonaid) {
    const toSave: UserSettings = {
      asiakasAvain: asiakasAvain,
      kayttajaAvain: kayttajaAvain,
      seen: {}
    }

    toSave.seen[seenFeature] = true

    const newFeaturesUri = this.getUserSettingsUri(asiakasAvain, kayttajaAvain)
    return lemonaidFirestore.firestoreSetData(newFeaturesUri, toSave, { merge: true })
  }

  async setValittuMaksukoodi(valittuMaksukoodi: UserSettings['vm'], asiakasAvain: string, kayttajaAvain: string, lemonaidFirestore: FirebaseLemonaid) {
    const toSave: UserSettings = {
      asiakasAvain: asiakasAvain,
      kayttajaAvain: kayttajaAvain,
      vm: valittuMaksukoodi
    }
    const userSettingsUri = this.getUserSettingsUri(asiakasAvain, kayttajaAvain)
    return lemonaidFirestore.firestoreSetData(userSettingsUri, toSave, { merge: true })
  }
}
