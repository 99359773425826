import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { LaskuKatseleComponentData } from '../_angular/_resolvers/lasku.resolve'
import { LaskuService } from '../_angular/service/lasku/lasku.service'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  template: ''
})
export class LaskuTeeTilaJaIndeksiUudelleenComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private laskuService: LaskuService,
    private errorHandler: ErrorHandler
  ) {

  }

  ngOnInit() {
    this.route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: { data: LaskuKatseleComponentData }) => {

      this.laskuService.paivitaTilaJaIndeksoiUudelleen(data.data.juurilasku).then(result => {
        this.router.navigate(['laskutus/laskut', data.data.juurilasku.avain, data.data.kasiteltava.avain, 'katsele'])
      }).catch(err => {
        this.errorHandler.handleError(err)
      })

    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
