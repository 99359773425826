<div *ngIf="kuvavirheAvain" class="red-text kuvavirhe" style="text-align: center; padding-left: 10vw; padding-right: 10vw;">{{kuvavirheAvain | translate: kuvavirheParametrit | async}}</div>

<div class="starting-text dark-gray-text">{{'tositteet.selvitettavat.kirjanpitajasi-on-merkannut' | translate | async}}</div>

<div class="kortti-container">

  <ng-container *ngFor="let kuitti of naytettavatKuititObservable | async; trackBy: trackKuitti">
    <div class="kuitti-laatikko lemon-mat-elevation-z4" (click)="katsele(kuitti)">

      <div class="kuva-container" [class.yksityisotto]="kuitti.naytaYksityisottoNappi">
        <div app-tosite-kuva [url]="kuitti.ensimmainenKuva" [thumbnail]="true"></div>
        <button class="muokkaa-btn secondarybutton mat-elevation-z4" mat-button (click)="muokkaa($event, kuitti)">
          <span class="btn-text">{{'yleiset.muokkaa' | translate | async}}</span>
        </button>
        <button *ngIf="kuitti.naytaYksityisottoNappi" class="yksityisotoksi-btn primarybutton mat-elevation-z4" mat-button (click)="merkitseYksityisotoksi($event, kuitti)" style="padding-right: 8px;">
          <span class="btn-text">{{'tositteet.selvitettavat.merkitse-yksityisotoksi' | translate | async}}</span>
        </button>
      </div>

      <div class="tiedot-container">
        <div class="pvm dark-gray-text">{{ kuitti.localPvm | lokaalipvm | async }}</div>
        <div class="summa light-blue-text">{{ kuitti.summa | lmoney: 'EUR' | async }}</div>
        <div class="viiva light-gray-background"></div>
        <div class="el-kommentti" *ngIf="!kuitti?.w && (kuitti?.el || kuitti?.seller)">{{kuitti.el || kuitti.seller}}</div>
        <div class="selite-container">
          <div *ngIf="kuitti.w" class="dark-gray-text">{{ kuitti.w }}</div>
          <div *ngIf="!kuitti.w && kuitti.c" class="dark-gray-text">{{ kuitti.c }}</div>
          <div class="bottom-container">
            <div class="dark-gray-text selite">{{ kuitti.selite }}</div>
            <div *ngIf="kuitti.m" class="selv-kommentti">{{ kuitti.m }}</div>
          </div>
        </div>
      </div>

      <mat-icon role="img" class="red-dot" aria-hidden="true"></mat-icon>
    </div>
  </ng-container>
</div>

<div class="ladataan" *ngIf="showLoading && (lataaObservable | async)">
  <div lemon-loading [show]="true"></div>
</div>