<form (ngSubmit)="save()" [formGroup]="form" novalidate>

  <h3>{{'laskuasetukset.pankkiyhteyden-tiedot' | translate | async}}</h3>
  <div class="asetusrypas">
    <mat-form-field>
      <mat-label>{{ 'laskuasetukset.iban' | translate | async }}</mat-label>
      <input type="text" formControlName="iban" matInput required />
      <mat-error>{{fieldErrors.iban}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'laskuasetukset.pankki' | translate | async }}</mat-label>
      <input type="text" formControlName="pankki" matInput required />
      <mat-hint>{{'laskuasetukset.iban-bic-autofill' | translate | async}}</mat-hint>
      <mat-error>{{fieldErrors.pankki}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'laskuasetukset.bic' | translate | async }}</mat-label>
      <input type="text" formControlName="bic" matInput required />
      <mat-hint>{{'laskuasetukset.iban-bic-autofill' | translate | async}}</mat-hint>
      <mat-error>{{fieldErrors.bic}}</mat-error>
    </mat-form-field>
  </div>

  <h3>{{'laskuasetukset.asiakaspalvelun-yhteystiedot' | translate | async}}</h3>
  <div class="asetusrypas">
    <mat-form-field>
      <mat-label>{{ 'laskuasetukset.aspaemail' | translate | async }}</mat-label>
      <input type="text" formControlName="aspaemail" matInput required />
      <mat-error>{{fieldErrors.aspaemail}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'laskuasetukset.aspapuh' | translate | async }}</mat-label>
      <input type="text" formControlName="aspapuh" matInput />
      <mat-error>{{fieldErrors.aspapuh}}</mat-error>
    </mat-form-field>
  </div>

  <h3>{{'laskuasetukset.ohjeet' | translate | async}}</h3>
  <div class="asetusrypas">
    <mat-form-field>
      <mat-label>{{ 'laskuasetukset.ohjeet' | translate | async }}</mat-label>
      <textarea rows="4" formControlName="ohjeet" matInput cdkTextareaAutosize></textarea>
      <mat-error>{{fieldErrors.ohjeet}}</mat-error>
    </mat-form-field>
  </div>

  <h3>{{'laskuasetukset.logo.otsikko' | translate | async}}</h3>
  <div class="asetusrypas">

    <mat-checkbox style="margin-left: auto;" formControlName="piilotaYrityksenNimiLaskulla">{{'laskuasetukset.piilota-yrityksen-nimi' | translate | async}}</mat-checkbox>
    <mat-hint style="font-size: 70%; margin-bottom: 20px; display: block;">{{'laskuasetukset.piilota-yrityksen-nimi-hint' | translate | async}}</mat-hint>

    <ngx-file-drop (click)="naytaTiedostonValintaDialogi()" [class.laskulogon-lataus-virhe]="kuvavirheAvain" dropZoneClassName="laskulogon-vaihto-container" (onFileDrop)="kasitteleLogo($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      <ng-template ngx-file-drop-content-tmp>
        <div style="position: relative;">
          <img [src]="logo" style="max-height:125px; max-width:125px;" />
          <div lemon-loading [show]="optimoidaanKuvaa"></div>
        </div>
        <p *ngIf="!kuvavirheAvain" [innerHtml]="kuvanInfotekstinAvain | translate | async"></p>
        <p *ngIf="kuvavirheAvain">{{kuvavirheAvain | translate: kuvavirheParametrit | async}}</p>
        <button *ngIf="naytaPoista()" (click)="poistaKuva($event)" mat-button style="min-width: 0; padding: 0; text-decoration: underline;">
          {{'laskuasetukset.logo.poista' | translate | async}}
        </button>
      </ng-template>
    </ngx-file-drop>

    <input style="visibility:hidden;" (change)="fileChanged($event)" type="file" accept="image/*" #fileInput [placeholder]="'laskuasetukset.logo.lataa' | translate | async" />

  </div>

  <div class="asetusnappulat">
    <button class="primarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
  </div>
</form>