<div class="list-page" style="max-width: 1200px;">

  <ng-container *ngIf="tilikaudetObservable | async; let tilikaudet; else eitilikausia">

    <form [formGroup]="form">
      <mat-form-field style="max-width: 180px; margin-left: 10px; margin-right: 10px;">
        <mat-label>{{ 'reports-lemonaid.tilikausi' | translate | async }}</mat-label>
        <mat-select [compareWith]="compareWithKey" formControlName="selectedTilikausi" required>
          <mat-option *ngFor="let tilikausi of tilikaudet" [value]="tilikausi">
            {{ tilikausi?.loppuu?.year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="tab-container">

      <mat-tab-group #tabGroup mat-stretch-tabs animationDuration="0ms" style="margin-top: 2em; overflow: hidden;">

        <!-- <ng-container *ngIf="isTilinpaatosVisibleObservable | async"> -->
        <mat-tab [label]="'reports-lemonaid.tilinpaatos-ja-hallituksen-poytakirja' | translate | async">
          <app-reports-tilinpaatos [selectedTilikausiObservable]="selectedTilikausiObservable" [tilinpaatosSnapshotObservable]="tilinpaatosSnapshotObservable" [tilinpaatosUsersObservable]="tilinpaatosUsersObservable"></app-reports-tilinpaatos>
        </mat-tab>
        <!-- </ng-container> -->

        <mat-tab [label]="'reports-lemonaid.yhtiokokouksen-poytakirja' | translate | async">
          <app-reports-yhtiokokouksen-poytakirja [selectedTilikausiObservable]="selectedTilikausiObservable" [tilinpaatosSnapshotObservable]="tilinpaatosSnapshotObservable" [tilinpaatosUsersObservable]="tilinpaatosUsersObservable"></app-reports-yhtiokokouksen-poytakirja>
        </mat-tab>

      </mat-tab-group>

    </div>

  </ng-container>

</div>

<ng-template #eitilikausia>
  <div style="padding: 70px 20px; text-align: center;">{{'reports-lemonaid.ei-tilikausia-text' | translate | async }}</div>
</ng-template>