import { Component, Inject, OnInit, ErrorHandler, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'

import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { WindowSizeService } from 'app/_jaettu-angular/service/window.service'
import { ErrorResponse, LocalDate } from 'app/_shared-core/model/common'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { UusiSopimusDialogService } from 'app/_angular/service/uusi-sopimus-dialog.service'
import { AllowedDialogsService } from 'app/_angular/service/allowed-dialogs.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { DateService } from 'app/_shared-core/service/date.service'

import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { MatStepper } from '@angular/material/stepper'
import { PalvelukuvauksenPdfRequest, PalvelukuvauksenPdfResponse, SopimuksenHyvaksynnanRequest, SopimusPdfRequest, SopimusPdfResponse } from 'app/_jaettu/model/sopimus'
import { TuettuKieli } from 'app/_shared-core/model/common'

import * as base64 from 'base64-js'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export interface UusiSopimusDialogData {
  sopimuksenAvain: string
  kuukausiHinta: number
  hintaMuuttuu: boolean
  sopimusPaattyy: LocalDate
  kieli: TuettuKieli
}

@Component({
  templateUrl: './uusi-sopimus.dialog.html',
  styleUrls: ['./uusi-sopimus.dialog.css']
})
export class UusiSopimusDialog implements OnInit {

  @ViewChild('horizStepper', { read: MatStepper }) horizStepper: MatStepper
  @ViewChild('verticStepper', { read: MatStepper }) verticStepper: MatStepper

  sopimusFormGroup: UntypedFormGroup

  commonError: string
  cellphone: boolean = true
  hyvaksytty: boolean = false
  kuukausihinta: string
  sopimuksenLoppupaiva: string
  hintaEiMuutu: boolean

  private _ngUnsubscribe = new Subject<void>()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UusiSopimusDialogData,
    private _dialogRef: MatDialogRef<UusiSopimusDialog>,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _windowSizeService: WindowSizeService,
    private _allowedDialogsService: AllowedDialogsService,
    private _translationService: LemonTranslationService,
    private _validationService: FormValidationService,
    private _uusiSopimusDialogService: UusiSopimusDialogService,
    private _currencyService: CurrencyService,
    private _dateService: DateService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _fileSaverService: FileSaverService
  ) {
    _dialogRef.disableClose = true
  }

  ngOnInit() {
    // Preheat
    this._firebaseLemonaid.functionsCall<string, void>('sopimusHyvaksynnanTallennus', '')

    this._dialogRef.beforeClosed().pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this._allowedDialogsService.allowAll()
    })

    this.cellphone = window.innerWidth < 670 || window.innerHeight < 670
    this._windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this.cellphone = window.innerWidth < 670 || window.innerHeight < 670
    })

    this.sopimusFormGroup = new UntypedFormGroup({
      'hyvaksy': new UntypedFormControl(false, [Validators.requiredTrue])
    })

    this.hintaEiMuutu = !this.data.hintaMuuttuu
    this.sopimuksenLoppupaiva = this._dateService.muotoilePaikallinenPaiva(this.data.sopimusPaattyy, this._translationService.nykyinenKieli)
    this.kuukausihinta = this.data.kuukausiHinta ? this._currencyService.formatoiRaha(this.data.kuukausiHinta, 'EUR', this._translationService.nykyinenKieli) : ''
  }

  get hyvaksy(): AbstractControl {
    return this.sopimusFormGroup.get('hyvaksy')
  }

  hyvaksyJaJatka() {

    if (!this.sopimusFormGroup.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.sopimusFormGroup)
      return
    }

    // Show loading indicator
    this._ladataanService.aloitaLataaminen()

    const req: SopimuksenHyvaksynnanRequest = {
      sopimusAvain: this.data.sopimuksenAvain,
      kieli: this.data.kieli
    }
    // Call CF
    return this._firebaseLemonaid.functionsCall<SopimuksenHyvaksynnanRequest, ErrorResponse>('sopimusHyvaksynnanTallennus', req).then(response => {

      // If no response, or response contains an error
      if (!response || response.e) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        this.hyvaksytty = true
        setTimeout(() => {
          if (this.horizStepper) {
            this.horizStepper.next()
          } else {
            this.verticStepper.next()
          }
        }, 10)
      }

    }).catch(error => {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(error)
    }).finally(() => {
      // Stop loading indicator
      this._ladataanService.lopetaLataaminen()
    })
  }

  postpone() {
    this._uusiSopimusDialogService.postpone()
    this._dialogRef.close()
  }

  async downloadSopimus() {

    this._ladataanService.aloitaLataaminen()

    const req: SopimusPdfRequest = {
      versio: 'uusin',
      kieli: this._translationService.nykyinenKieli || 'fi'
    }
    return this._firebaseLemonaid.functionsCall<SopimusPdfRequest, SopimusPdfResponse>('sopimusAnnaSopimuksenPdf', req).then(response => {
      if (!response || response.e || !response.base64String) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        this._downloadSopimusPdf(response.base64String)
      }
    }).catch(err => this._errorHandler.handleError(err)).finally(() => this._ladataanService.lopetaLataaminen())

  }

  private _downloadSopimusPdf(base64File: string) {
    const output = base64.toByteArray(base64File)
    const fileName = 'Tilitoimisto Lemon Tree Oy Toimeksiantosopimus.pdf'
    this._fileSaverService.saveAs(new Blob([output], { type: 'application/pdf' }), fileName)
  }

  async downloadPalvelukuvaus() {

    this._ladataanService.aloitaLataaminen()

    const req: PalvelukuvauksenPdfRequest = {
      kieli: this._translationService.nykyinenKieli || 'fi'
    }
    return this._firebaseLemonaid.functionsCall<PalvelukuvauksenPdfRequest, PalvelukuvauksenPdfResponse>('sopimusAnnaPalvelukuvausPdf', req).then(response => {
      if (!response || response.e || !response.base64String) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        this._downloadPalvelukuvausPdf(response.base64String)
      }
    }).catch(err => this._errorHandler.handleError(err)).finally(() => this._ladataanService.lopetaLataaminen())

  }

  private _downloadPalvelukuvausPdf(base64File: string) {
    const output = base64.toByteArray(base64File)
    const fileName = 'Tilitoimisto Lemon Tree Oy Palvelukuvaus.pdf'
    this._fileSaverService.saveAs(new Blob([output], { type: 'application/pdf' }), fileName)
  }


  sulje() {
    this._dialogRef.close()
  }

}
