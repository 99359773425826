import { Component, OnInit, AfterViewInit, OnDestroy, ErrorHandler, Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'

import { Subject, Observable, combineLatest, BehaviorSubject, ReplaySubject, timer, of } from 'rxjs'
import { takeUntil, map, delayWhen, distinctUntilChanged, switchMap, delay } from 'rxjs/operators'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { Roolit } from 'app/_jaettu/model/kayttaja'
import { ValtuutaKayttajaPalkkausfihinPyynto, ValtuutaKayttajaPalkkausfihinVastaus } from 'app/_jaettu/model/palkkausfi'

import { environment } from '../../environments/environment'
import { EnvironmentType } from '../app.environment'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'

export type TuoteProperties = 'nro' | 'asiakas' | 'pvm' | 'erapvm' | 'summa' | 'avoinna' | 'tila' | 'merkitse' | undefined

@Component({
  templateUrl: './palkkausfi.component.html',
  styleUrls: ['./palkkausfi.component.css']
})
export class PalkkausfiComponent implements OnInit, AfterViewInit, OnDestroy {

  // @ViewChild('palkkausfi') iframe: ElementRef

  private ngUnsubscribe = new Subject<void>()
  private iframeErrorSubject: BehaviorSubject<number | null> = new BehaviorSubject(null)
  iframeSrcObservable: ReplaySubject<SafeUrl> = new ReplaySubject(1)
  iframeLataaminenValmisObservable: Observable<boolean>
  iframeErrorObservable: Observable<number | null> = this.iframeErrorSubject.pipe(
    distinctUntilChanged(),
    delay(10)
  )

  constructor(
    private _route: ActivatedRoute,
    private _palkkausFiService: PalkkausFiService,
    private _domSanitizer: DomSanitizer,
    private _translationService: LemonTranslationService
  ) { }

  ngOnInit() {
    this.iframeLataaminenValmisObservable = this.iframeSrcObservable.pipe(
      delayWhen(url => {
        return !!url ? timer(2000) : of(undefined)
      }),
      map(url => {
        return !!url
      }),
      distinctUntilChanged()
    )
  }

  ngAfterViewInit() {

    const tokenObservable = this._palkkausFiService.accessTokenObservable.pipe(
      map(tokenTiedot => {
        if (tokenTiedot) {
          if (tokenTiedot.error) {
            this.iframeErrorSubject.next(tokenTiedot.error)
            return null
          }
          return tokenTiedot.palkkausFiTiedot
        }
        this.iframeErrorSubject.next(null)
        return null
      })
    )

    combineLatest([this._route.queryParams, tokenObservable, this._translationService.currentLanguageObservable]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([params, token, kieli]) => {
      // console.log(params.s, token)

      if (params && token) {

        let paikka = ''
        // if (params.s === 'yleiskuva') {
        //   paikka = ''
        // } else
        if (params.s === 'laskelmat') {
          paikka = '/calc'
        } else if (params.s === 'listat') {
          paikka = '/payroll'
        } else if (params.s === 'tyontekijat') {
          paikka = '/workers'
        } else if (params.s === 'raportit') {
          paikka = '/reports'
        } else if (params.s === 'asetukset') {
          paikka = '/settings'
        } else if (params.s === 'info') {
          paikka = '/info'
        }

        paikka += '/&access_token=' + token.asiakkaanPalkkausfiToken

        const useProd = environment.environment === EnvironmentType.PRODUCTION || environment.environment === EnvironmentType.BETA
        const embeddedDomain = useProd ? 'https://lemontree.palkkaus.fi' : 'https://test-lemontree.palkkaus.fi'
        const kaytettavaUrlAlku = embeddedDomain + '/' + '?lang=' + kieli + '#'

        const sanitized = this._domSanitizer.bypassSecurityTrustResourceUrl(kaytettavaUrlAlku + paikka)
        this.iframeSrcObservable.next(sanitized)

      } else {
        this.iframeSrcObservable.next(null)
      }

    })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}

@Injectable()
export class PalkkausFiService {

  accessTokenObservable: BehaviorSubject<ValtuutaKayttajaPalkkausfihinVastaus> = new BehaviorSubject(null)
  private uusiTokenObservable: BehaviorSubject<boolean> = new BehaviorSubject(true)
  private tokenUusiTimeout = null

  constructor(
    private kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private errorHandler: ErrorHandler
  ) {
    combineLatest([this.kayttajaService.kayttajaObservable, this.uusiTokenObservable]).pipe(
      switchMap(([kayttaja, uusiToken]) => {
        if (kayttaja && kayttaja.roolit && kayttaja.roolit[Roolit.PALKAT]) {
          // console.log('AUTENTIKOIDAAN, ' + kaytettavaFunktio)
          const pyynto: ValtuutaKayttajaPalkkausfihinPyynto = {}
          return this._firebaseLemonaid.functionsCall<ValtuutaKayttajaPalkkausfihinPyynto, ValtuutaKayttajaPalkkausfihinVastaus>('palkkausFiAnnaToken', pyynto).catch(err => {
            this.errorHandler.handleError(err)
            const errorRet: ValtuutaKayttajaPalkkausfihinVastaus = { error: err, palkkausFiTiedot: null }
            return errorRet
          })
        }
        return of<ValtuutaKayttajaPalkkausfihinVastaus>(null)
      })
    ).subscribe({
      next: valtuutusvastaus => {
        if (this.tokenUusiTimeout !== null) {
          clearTimeout(this.tokenUusiTimeout)
          this.tokenUusiTimeout = null
        }
        if (valtuutusvastaus && valtuutusvastaus.palkkausFiTiedot) {
          const aikaaJaljella = valtuutusvastaus.palkkausFiTiedot.asiakkaanPalkkausfiTokenVanheneeMillis - new Date().getTime()
          const del = aikaaJaljella - 20 * 60 * 1000 // 20 minuuttia ennen kuin vanhenee
          const kaytettavaDelay = del > 0 ? del : 0
          // console.log('Uusitaan ' + kaytettavaDelay / 1000 + ' sekunnin kuluttua.')
          this.tokenUusiTimeout = setTimeout(() => {
            // console.log('Ajetaan uusiminen')
            this.tokenUusiTimeout = null
            this.uusiTokenObservable.next(true)
          }, kaytettavaDelay)
        }
        this.accessTokenObservable.next(valtuutusvastaus)
      },
      error: error => {
        this.errorHandler.handleError(error)
        this.accessTokenObservable.next({ error: error, palkkausFiTiedot: null })
      }
    })
  }

}
