import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'

import { AsiakasComponentDataResolve } from '../../_angular/_resolvers/lasku.resolve'

import { LaskunAsiakas } from '../../_jaettu/model/lasku'

import { AsiakkaatFirestoreDataSource } from './asiakkaat.firestore.datasource'

import { Observable, combineLatest } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'

export type AsiakasProperties = 'nimi' | 'ytunnus' | 'katuosoite' | 'postinro' | 'postitoimipaikka' | 'maa' | undefined

@Component({
  selector: 'app-asiakkaat',
  templateUrl: './asiakkaat.component.html',
  styleUrls: ['./asiakkaat.component.css']
})
export class AsiakkaatComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) _paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) _sort: MatSort

  form: UntypedFormGroup
  dataSource: AsiakkaatFirestoreDataSource | null
  propertiesToDisplay: AsiakasProperties[] = ['nimi', 'ytunnus', 'katuosoite', 'postinro', 'postitoimipaikka', 'maa']
  name = 'u' + Math.random()

  naytaEiYhtaanObservable: Observable<boolean>
  naytettavatAsiakkaatObservable: Observable<LaskunAsiakas[]>
  lataaObservable: Observable<boolean>

  constructor(
    private _router: Router,
    private _asiakasComponentDataResolve: AsiakasComponentDataResolve,
    private _dataSource: AsiakkaatFirestoreDataSource
  ) {

    this.dataSource = _dataSource
    this.lataaObservable = this._dataSource.lataaObservable
    this.naytettavatAsiakkaatObservable = this._dataSource.asiakkaatObservable
    this.naytaEiYhtaanObservable = combineLatest([this._dataSource.rivienMaaraObservable, this._dataSource.lataaObservable]).pipe(
      map(([rivienMaara, lataa]) => {
        if (lataa) {
          return false
        }
        return rivienMaara < 1
      })
    )

    this.form = new UntypedFormGroup({
      'nimi': new UntypedFormControl(this._dataSource.getSearch(), [])
    })

    this.form.get('nimi').valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      console.log(value)
      if (value) {
        const val = value.trim().toLowerCase()
        if (val.length > 2) {
          this._dataSource.changeSearch(val)
        } else {
          this._dataSource.changeSearch('')
        }
      } else {
        this._dataSource.changeSearch('')
      }
    })

  }

  ngOnInit() {

    const currentSort = this._dataSource.getSort()
    this._sort.sort({
      id: currentSort.active,
      disableClear: true,
      start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    })
    this._sort.sortChange.subscribe(sort => {
      this._dataSource.changeSort(sort)
    })

    this._paginator.page.subscribe(page => {
      this._dataSource.changePage(page)
    })

  }

  muokkaa(asiakas: LaskunAsiakas) {
    this._asiakasComponentDataResolve.asetaOlemassaolevaData(asiakas)
    this._router.navigate(['/laskutus/asiakkaat/', asiakas.avain])
  }

  lisaa() {
    this._router.navigate(['/laskutus/asiakkaat/', 'uusi'])
  }

}
