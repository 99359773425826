import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

export interface AreYouSureDialogData {
  rightAction: string
  text: string
  header?: string
  leftAction?: string
}

@Component({
  templateUrl: './tilinpaatos-are-you-sure.dialog.html'
})
export class TilinpaatosAreYouSureDialog implements OnInit {

  form: UntypedFormGroup

  constructor(
    private _dialogRef: MatDialogRef<TilinpaatosAreYouSureDialog>,
    private _formValidationService: FormValidationService
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      'agree': new UntypedFormControl(null, Validators.requiredTrue)
    })

  }

  get agree() {
    return this.form.get('agree')
  }

  replyTrue() {
    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }
    this._dialogRef.close(true)
  }

}
