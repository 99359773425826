import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { KayttajaService } from '../service/kayttaja.service'

import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

@Injectable()
export class AuthGuard  {

  constructor(
    private router: Router,
    private kayttajaService: KayttajaService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.kayttajaService.kayttajanTiedotObservable.pipe(
      map(kayttajanTiedot => !!kayttajanTiedot),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/kirjaudu'])
          return false
        }
        return true
      })
    )
  }

}
