import { Component, ErrorHandler, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { Lasku, LaskuBase } from '../../_jaettu/model/lasku'

export interface LaskuPerintaSpostiDialogData {
  asetukset: any
  juurilasku: Lasku
  kasiteltava: LaskuBase
}

@Component({
  selector: 'lasku-perinta-sposti-dialog',
  templateUrl: './lasku-perinta.sposti.dialog.html',
  styleUrls: ['./lasku-perinta.sposti.dialog.css']
})

export class LaskuPerintaSpostiDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LaskuPerintaSpostiDialogData,
    private dialogRef: MatDialogRef<LaskuPerintaSpostiDialog>,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _laskuService: LaskuService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  peruuta() {
    this._router.navigate(['/laskutus/laskut'])
  }

  async send() {
    this._ladataanService.aloitaLataaminen()

    try {
      await this._laskuService.merkitseLaskunPerintaLahetetyksiSpostilla(this.data.juurilasku, this.data.kasiteltava).then(result => {
        this.peruuta()
      }).catch(err => {
        this._errorHandler.handleError(err)
      })
    } catch (error) {
      this._errorHandler.handleError(error)
      this.dialogRef.close(true)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this.dialogRef.close(true)
    }
  }

}
