import { TuettuKieli } from '../../_shared-core/model/common'

export class KayttoehdotUriService {

  // Käyttöehdot
  getKayttoehdotRealtimeLatestKeyUri(): string {
    return 'kayttoehdot/viimeisinavain'
  }
  getKayttoehdotRealtimeLatestTextUri(kieli: string | TuettuKieli): string {
    return '/kayttoehdot/tekstit/' + kieli
  }
  getKayttoehdotRealtimeApprovalsCollectionUri(kayttajaAvain: string): string {
    return '/kayttoehdot/hyvaksynnat/' + kayttajaAvain
  }
}
