const p = (e) => {
  let t;
  const r = /* @__PURE__ */ new Set(), n = (i, u) => {
    const a = typeof i == "function" ? i(t) : i;
    if (!Object.is(a, t)) {
      const d = t;
      t = (u != null ? u : typeof a != "object") ? a : Object.assign({}, t, a), r.forEach((m) => m(t, d));
    }
  }, o = () => t, g = { setState: n, getState: o, subscribe: (i) => (r.add(i), () => r.delete(i)), destroy: () => {
    r.clear();
  } };
  return t = e(n, o, g), g;
}, h = (e) => e ? p(e) : p, x = {
  locateFile: (e, t) => {
    var n;
    const r = (n = e.match(/_(.+?)\.wasm$/)) == null ? void 0 : n[1];
    return r ? `https://fastly.jsdelivr.net/npm/@sec-ant/zxing-wasm@2.1.5/dist/${r}/${e}` : t + e;
  }
}, l = h()(() => ({
  zxingModuleWeakMap: /* @__PURE__ */ new WeakMap(),
  zxingModuleOverrides: x
}));
function B(e) {
  l.setState({
    zxingModuleOverrides: e
  });
}
function w(e, t = l.getState().zxingModuleOverrides) {
  const { zxingModuleWeakMap: r } = l.getState(), n = r.get(
    e
  );
  if (n && Object.is(t, l.getState().zxingModuleOverrides))
    return n;
  {
    l.setState({
      zxingModuleOverrides: t
    });
    const o = e(t);
    return r.set(e, o), o;
  }
}
function y() {
  l.setState({
    zxingModuleWeakMap: /* @__PURE__ */ new WeakMap()
  });
}
const b = [
  "Aztec",
  "Codabar",
  "Code128",
  "Code39",
  "Code93",
  "DataBar",
  "DataBarExpanded",
  "DataMatrix",
  "EAN-13",
  "EAN-8",
  "ITF",
  "Linear-Codes",
  "Matrix-Codes",
  "MaxiCode",
  "MicroQRCode",
  "None",
  "PDF417",
  "QRCode",
  "UPC-A",
  "UPC-E"
], z = [
  "Cp437",
  "ISO-8859-1",
  "ISO-8859-2",
  "ISO-8859-3",
  "ISO-8859-4",
  "ISO-8859-5",
  "ISO-8859-6",
  "ISO-8859-7",
  "ISO-8859-8",
  "ISO-8859-9",
  "ISO-8859-10",
  "ISO-8859-11",
  "ISO-8859-13",
  "ISO-8859-14",
  "ISO-8859-15",
  "ISO-8859-16",
  "SJIS",
  "Shift_JIS",
  "Cp1250",
  "windows-1250",
  "Cp1251",
  "windows-1251",
  "Cp1252",
  "windows-1252",
  "Cp1256",
  "windows-1256",
  "UTF-16BE",
  "UTF-16LE",
  "UTF-32BE",
  "UTF-32LE",
  "UnicodeBigUnmarked",
  "UnicodeBig",
  "UTF-8",
  "ASCII",
  "US-ASCII",
  "Big5",
  "GB2312",
  "GB18030",
  "EUC-CN",
  "GBK",
  "EUC-KR",
  "BINARY"
], f = {
  tryHarder: !0,
  formats: [],
  maxSymbols: 255
}, S = {
  format: "QRCode",
  charset: "UTF-8",
  quietZone: 10,
  width: 200,
  height: 200,
  eccLevel: -1
};
async function A(e, {
  tryHarder: t = f.tryHarder,
  formats: r = f.formats,
  maxSymbols: n = f.maxSymbols
} = f, o) {
  const s = await w(
    o,
    l.getState().zxingModuleOverrides
  ), { size: c } = e, g = new Uint8Array(await e.arrayBuffer()), i = s._malloc(c);
  s.HEAP8.set(g, i);
  const u = s.readBarcodesFromImage(
    i,
    c,
    t,
    E(r),
    n
  );
  s._free(i);
  const a = [];
  for (let d = 0; d < u.size(); ++d) {
    const m = u.get(d);
    a.push({
      ...m,
      format: M(m.format)
    });
  }
  return a;
}
async function U(e, {
  tryHarder: t = f.tryHarder,
  formats: r = f.formats,
  maxSymbols: n = f.maxSymbols
} = f, o) {
  const s = await w(
    o,
    l.getState().zxingModuleOverrides
  ), {
    data: c,
    width: g,
    height: i,
    data: { byteLength: u }
  } = e, a = s._malloc(u);
  s.HEAP8.set(c, a);
  const d = s.readBarcodesFromPixmap(
    a,
    g,
    i,
    t,
    E(r),
    n
  );
  s._free(a);
  const m = [];
  for (let I = 0; I < d.size(); ++I) {
    const O = d.get(I);
    m.push({
      ...O,
      format: M(O.format)
    });
  }
  return m;
}
async function F(e, {
  format: t = S.format,
  charset: r = S.charset,
  quietZone: n = S.quietZone,
  width: o = S.width,
  height: s = S.height,
  eccLevel: c = S.eccLevel
} = S, g) {
  const u = (await w(
    g,
    l.getState().zxingModuleOverrides
  )).writeBarcodeToImage(
    e,
    t,
    r,
    n,
    o,
    s,
    c
  ), { image: a, error: d } = u;
  return a ? {
    image: new Blob([new Uint8Array(a)], {
      type: "image/png"
    }),
    error: ""
  } : {
    image: null,
    error: d
  };
}
function E(e) {
  return e.join("|");
}
function M(e) {
  const t = C(e);
  let r = 0, n = b.length - 1;
  for (; r <= n; ) {
    const o = Math.floor((r + n) / 2), s = b[o], c = C(s);
    if (c === t)
      return s;
    c < t ? r = o + 1 : n = o - 1;
  }
  return "None";
}
function C(e) {
  return e.toLowerCase().replace(/_-\[\]/g, "");
}
export {
  b as Z,
  U as a,
  z as b,
  S as c,
  f as d,
  w as g,
  y as p,
  A as r,
  B as s,
  F as w
};
