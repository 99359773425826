import { Component, OnInit, ErrorHandler, Inject, Input } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { NetsAuthMethod, TunnistautuminenService } from 'app/_angular/service/tunnistautuminen.service'

import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { ActionAfterAuthentication, StartNewStrongAuthenticationSession, StrongAuthenticationSessionInfo } from 'app/_jaettu/model/tunnistaminen'


import { environment } from 'environments/environment'
import { Kayttaja } from 'app/_jaettu/model/kayttaja'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  selector: '[tunnistautuminen-nets-pankit]',
  templateUrl: './pankit.component.html',
  styleUrls: ['./pankit.component.css']
})
export class TunnistautuminenNetsPankitComponent implements OnInit {

  @Input() actionAfterSuccess: ActionAfterAuthentication
  @Input() kieli: TuettuKieli

  private _sessionPromise: Promise<StrongAuthenticationSessionInfo> = null
  private _kayttajaPromise: Promise<Kayttaja> = null

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _kirjautunutKayttajaService: KayttajaService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _tunnistautuminenService: TunnistautuminenService,
    protected _firebaseLemonaid: FirebaseLemonaid
  ) {
    this._kayttajaPromise = this._kirjautunutKayttajaService.getKayttaja()
  }

  ngOnInit() {
    this._sessionPromise = this._haeUusiTunnistautumissessio()
  }

  protected _haeUusiTunnistautumissessio(): Promise<StrongAuthenticationSessionInfo> {
    const request: StartNewStrongAuthenticationSession = { action: this.actionAfterSuccess }
    return this._firebaseLemonaid.functionsCall<StartNewStrongAuthenticationSession, StrongAuthenticationSessionInfo>('tunnistautuminenNetsAloita', request)
  }

  private async _tunnistaudu(authMethod: NetsAuthMethod): Promise<void> {
    this._ladataanService.aloitaLataaminen()
    const [info, kayttaja] = await Promise.all([this._sessionPromise, this._kayttajaPromise])
    this._tunnistautuminenService.startClientSideAuthenticationSession(this.actionAfterSuccess, info.avain, info.state)
    const redirectUrl = this._tunnistautuminenService.getNetsEidentUrl(environment.environment, authMethod, info.stateHash, info.nonce, this.kieli || 'fi')
    const netsEnv = this._tunnistautuminenService.getNetsEnvironment(environment.environment)
    await this._tunnistautuminenService.saveHistory(kayttaja, 'redirect', redirectUrl, netsEnv)
      // Ignore error if log save fails
      .catch(err => {
        this._errorHandler.handleError(new Error('Strong auth redirect log save failed ' + err?.message || 'unknown-error'))
      })

    this._document.location.href = redirectUrl
  }

  async tunnistauduMobiilivarmenne() {
    await this._tunnistaudu('fi_mobiilivarmenne')
  }

  async tunnistauduOp() {
    await this._tunnistaudu('opbank')
  }

  async tunnistauduNordea() {
    await this._tunnistaudu('nordea')
  }

  async tunnistauduDanske() {
    await this._tunnistaudu('danske')
  }

  async tunnistauduHandels() {
    await this._tunnistaudu('handelsbanken')
  }

  async tunnistauduAlands() {
    await this._tunnistaudu('aland')
  }

  async tunnistauduS() {
    await this._tunnistaudu('sbank')
  }

  async tunnistauduAktia() {
    await this._tunnistaudu('aktia')
  }

  async tunnistauduPop() {
    await this._tunnistaudu('popbank')
  }

  async tunnistauduSaasto() {
    await this._tunnistaudu('savingsbank')
  }

  async tunnistauduOmaSaasto() {
    await this._tunnistaudu('omasp')
  }

}
