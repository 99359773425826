import { Component, OnInit, AfterContentChecked, ErrorHandler, HostListener, ViewChild, AfterViewInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormValidationService, FieldErrors } from '../_jaettu-angular/service/form-validation.service'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'

import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from '../vaihda-kieli.dialog'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'
import { PalautaSalasanaPyynto, PalautaSalasanaVastaus } from 'app/_jaettu/model/common'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  templateUrl: './reset.component.html',
  styleUrls: ['./login.component.css']
})
export class ResetComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @ViewChild(MatInput) passwordInput: MatInput

  loginForm: UntypedFormGroup
  fieldErrors: FieldErrors = {}
  commonError: string
  success = false
  online = true
  year = new Date().getFullYear()

  // private mode: 'resetPassword' | 'recoverEmail' | 'verifyEmail' = null
  private oobCode: string = null

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    // https://lemonaid-9ef4a.firebaseapp.com/__/auth/action?mode=resetPassword&oobCode=dho90ylQKXxQuO8GEoikZeoxYL5h3Aq7fW_AirIjHOgAAAFiTlaaXQ&apiKey=AIzaSyCf2xZVo-4u5HeQmDp5lSM7asfPpJAEW9g
    this._route.queryParams.subscribe(params => {
      // this.mode = params['mode']
      this.oobCode = params['oobCode']
    })
  }

  ngAfterContentChecked() {
    this.fieldErrors = this._validationService.updateValidationStatus('login', this.loginForm)
  }

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      'password': new UntypedFormControl('', [Validators.required, LemonaidValidators.passwordComplexityValidator])
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.passwordInput.focus() }, 0)
  }

  virheet() {
    if (this.loginForm) {
      const pwdField = this.loginForm.get('password')
      if (pwdField) {
        return pwdField.errors || {}
      }
    }
    return {}
  }

  setLoginProcessStarted() {
    this._ladataanService.aloitaLataaminen()
  }

  setLoginProcessEnded() {
    this._ladataanService.lopetaLataaminen()
  }

  @HostListener('document:keydown.esc')
  back() {
    this._router.navigate(['/kirjaudu'])
  }

  @HostListener('document:keydown.enter')
  defaultAction() {
    if (this.success) {
      this.login()
    } else {
      this.changePassword()
    }
  }

  changePassword() {

    if (!this.loginForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
      return
    }

    this.setLoginProcessStarted()
    const theCode = this.oobCode || 'no'

    this.commonError = null
    this.fieldErrors = {}

    const req: PalautaSalasanaPyynto = {
      newPassword: this.loginForm.value.password,
      oobCode: theCode
    }
    this._firebaseLemonaid.functionsCall<PalautaSalasanaPyynto, PalautaSalasanaVastaus>('passwordResetChangePassword', req).then(result => {
      const code = result.e
      if (code) {
        switch (code) {
          case 'auth/expired-action-code':
            this.commonError = this._translationService.lokalisoi('reset.virheet.koodi-vanhentunut')
            break
          case 'auth/invalid-action-code':
            this.commonError = this._translationService.lokalisoi('reset.virheet.koodi-ei-oikea')
            break
          case 'auth/user-disabled':
            this.commonError = this._translationService.lokalisoi('reset.virheet.kayttaja-inaktiivinen')
            break
          case 'auth/user-not-found':
            this.commonError = this._translationService.lokalisoi('reset.virheet.kayttaja-ei-loydy')
            break
          case 'auth/weak-password':
            this.commonError = this._translationService.lokalisoi('reset.virheet.salasana-heikko')
            break
          default:
            this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
        }
        this.ngAfterContentChecked()
        this._validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
        this.setLoginProcessEnded()
      } else {
        this.success = true
        this.setLoginProcessEnded()
      }

    }).catch(err => {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      this._errorHandler.handleError(err)
      this.setLoginProcessEnded()
    })

  }

  login() {
    this._kayttajaService.logout().then(() => {
      this._router.navigate(['/kirjaudu'])
    })
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this._dialog.open(VaihdaKieliDialog, { data: data })
  }

}
