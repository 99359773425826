<style>
  .all {
    /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
    max-height: 95vh;
  }
</style>

<div class="all" [class.iso]="!cellphone" mat-dialog-content>

  <mat-horizontal-stepper [linear]="true" [@.disabled]="true" *ngIf="!cellphone" #horizStepper>

    <mat-step [label]="'start'" [editable]="false">
      <ng-template matStepLabel>{{ 'yleiset.tervetuloa' | translate | async }}</ng-template>
      <div class="teksti">
        <div class="light-blue-text otsikko">{{'yleiset.tervetuloa' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
        <div class="dynamic-spacer"></div>
        <div class="teksti-big">{{'lemonaid-sopimus-dialog.valmis-kaytettavaksi' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div>{{'lemonaid-sopimus-dialog.ennen-aloittamista' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="button-container">
          <button class="primarybutton" mat-button matStepperNext>{{'yleiset.jatka' | translate | async }}</button>
        </div>
      </div>
    </mat-step>

    <mat-step *ngIf="naytaTunnistaminen" [label]="'tunnistaminen'" [editable]="false" [completed]="strongAuthResult === 'success' && tunnistautujanTiedotAnnettu">
      <ng-template matStepLabel>
        <div (click)="saveCurrentStep($event)">
          {{'lemonaid-sopimus-dialog.tunnistaminen' | translate | async }}
        </div>
      </ng-template>

      <div class="teksti">
        <div class="light-blue-text otsikko" [innerHtml]="'lemonaid-sopimus-dialog.asiakkaan-tunnistaminen' | translate | async"></div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <ng-container *ngIf="strongAuthResult === 'start' || strongAuthResult === 'error' || strongAuthResult === 'error-cancel'">
          <div [innerHtml]="'lemonaid-sopimus-dialog.rahanpesulaki-velvoittaa-tunnistamaan' | translate | async"></div>
          <div class="dynamic-spacer"></div>
          <div *ngIf="strongAuthResult === 'start'" [innerHtml]="'lemonaid-sopimus-dialog.tunnistaudu-pankkitunnuksilla' | translate | async"></div>
          <div *ngIf="strongAuthResult === 'error' || strongAuthResult === 'error-cancel'" class="lemon-error" [innerHtml]="'lemonaid-sopimus-dialog.tunnistautuminen-epaonnistui' | translate | async"></div>
          <div class="dynamic-spacer"></div>
          <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
          <div class="dynamic-spacer"></div>
          <div tunnistautuminen-nets-pankit [actionAfterSuccess]="'continue-welcome'" [kieli]="kieli"></div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
        </ng-container>

        <ng-container *ngIf="strongAuthResult === 'success'">

          <ng-container *ngIf="!tunnistautujanTiedotAnnettu">

            <div [innerHtml]="'lemonaid-sopimus-dialog.anna-lisatiedot' | translate | async"></div>

            <form [formGroup]="tunnistautujaForm" class="tunnistautuja">
              <mat-form-field class="tunnistautuja-short">
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
                <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
              </mat-form-field>
              <mat-form-field class="tunnistautuja-short" style="margin: 0 25px;">
                <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
                <mat-select formControlName="kansallisuus" required>
                  <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                    {{ kansa.nimi }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="tunnistautujaForm.get('kansallisuus'); let n">
                    <ng-container *ngIf="n.touched">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="tunnistautuja-long">
                <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
                <mat-select formControlName="pep" required>
                  <mat-option [value]="true">
                    {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                  </mat-option>
                  <mat-option [value]="false">
                    {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="tunnistautujaForm.get('pep'); let n">
                    <ng-container *ngIf="n.touched">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </form>

            <div class="form-comment-container">
              <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
            </div>

            <div class="dynamic-spacer"></div>

            <div style="font-weight: bold;">{{'lemonaid-sopimus-dialog.kyc.pep-miksi-kerataan' | translate | async}}</div>
            <div class="dynamic-spacer"></div>
            <div style="margin: 0 auto; width:85%;">{{'lemonaid-sopimus-dialog.kyc.pep-naita-tuntemistietoja' | translate | async}}</div>

            <div class="dynamic-spacer"></div>
            <div class="dynamic-spacer"></div>

            <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>

            <div class="button-container">
              <button (click)="tallennaTunnistautujaLisatiedot()" class="primarybutton" mat-button>{{'yleiset.jatka' | translate | async }}</button>
            </div>

          </ng-container>

          <ng-container *ngIf="tunnistautujanTiedotAnnettu">

            <div class="dynamic-spacer"></div>

            <div [innerHtml]="'lemonaid-sopimus-dialog.tunnistautuminen-onnistui' | translate | async"></div>

            <div class="dynamic-spacer"></div>
            <div class="button-container">
              <button class="primarybutton" mat-button matStepperNext>{{'yleiset.jatka' | translate | async }}</button>
            </div>
            <div class="dynamic-spacer"></div>
          </ng-container>

        </ng-container>
      </div>
    </mat-step>

    <mat-step *ngIf="naytaSopimus" [stepControl]="sopimusFormGroup" [editable]="false" [label]="'sopimus'">
      <ng-template matStepLabel>
        <div (click)="saveCurrentStep($event)">
          {{'lemonaid-sopimus-dialog.toimeksiantosopimus-nonbreak' | translate | async }}
        </div>
      </ng-template>
      <form #sopimusForm="ngForm" [formGroup]="sopimusFormGroup" class="teksti">

        <div class="dynamic-spacer"></div>
        <div class="light-blue-text otsikko" [innerHtml]="'lemonaid-sopimus-dialog.toimeksiantosopimus-break' | translate | async"></div>
        <div class="dynamic-spacer"></div>
        <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
        <div class="dynamic-spacer"></div>
        <div>{{'lemonaid-sopimus-dialog.palvelumme-sisaltaa' | translate | async }}</div>
        <div class="dynamic-spacer-puolet"></div>
        <div>{{'lemonaid-sopimus-dialog.lue-tarkempi-erittely' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>

        <div class="price-text">{{'lemonaid-sopimus-dialog.kuukausihinta-alkaessa' | translate | async }}: <span class="light-blue-text otsikko-numero" style="vertical-align: middle; padding: 0 2vw;">{{ hintaPalvelunAlkaessaObservable | async }}
            /{{'lemonaid-sopimus-dialog.kuukaudessa' | translate | async }}</span></div>
        <div class="dynamic-spacer"></div>
        <div class="price-text">{{'lemonaid-sopimus-dialog.ensimmainen-kuukausi' | translate | async }}: <span class="light-blue-text otsikko-numero" style="vertical-align: middle; padding: 0 2vw;">{{ ensimmainenLaskutettavaKuukausi }}</span></div>
        <div class="dynamic-spacer"></div>
        <div class="teksti-small">{{'lemonaid-sopimus-dialog.aiempiin-laskutetaan-tuntiperusteisesti' | translate | async }}</div>

        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>

        <div style="text-align: left;">
          <div>{{'lemonaid-sopimus-dialog.lukenut-ja-hyvaksyn' | translate | async }}</div>
          <div class="dynamic-spacer-puolet"></div>
          <mat-checkbox class="hyvaksy-liitteet-check" formControlName="hyvaksy"></mat-checkbox>
          <a class="link-like" (click)="downloadSopimus()">{{'lemonaid-sopimus-dialog.toimeksiantosopimuksen' | translate | async }}</a>
          <mat-error *ngIf="hyvaksy?.errors?.required && (hyvaksy?.touched || sopimusForm?.submitted)" style="margin-left: 45px; font-size: 14px;">{{ 'lemonaid-sopimus-dialog.hyvaksy-required' | translate | async }}</mat-error>
          <div class="liitteet-container">
            <a class="link-like" (click)="downloadPalvelukuvaus()">{{'lemonaid-sopimus-dialog.palvelukuvaus' | translate | async }}</a>
            <!-- <div><a target="_BLANK" href="/assets/sopimukset/palvelukuvaus_19.12.2019.pdf">{{'lemonaid-sopimus-dialog.palvelukuvaus' | translate | async }}</a></div> -->
            <div><a target="_BLANK" href="/assets/sopimukset/ysetal2018.pdf">{{'lemonaid-sopimus-dialog.yleiset-sopimusehdot' | translate | async }} TAL2018</a></div>
            <div><a target="_BLANK" href="/assets/sopimukset/shktal2018.pdf">{{'lemonaid-sopimus-dialog.sopimus-henkilotietojen' | translate | async }} TAL2018</a></div>
          </div>
        </div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="button-container">
          <button class="primarybutton" mat-button (click)="tallennaHyvaksynta()">{{'lemonaid-sopimus-dialog.hyvaksy-ja-jatka' | translate | async }}</button>
        </div>
      </form>
    </mat-step>

    <mat-step *ngIf="naytaTuntemistiedot" [stepControl]="tuntemistiedotFormGroup" [editable]="false" [label]="'tuntemistiedot'">
      <ng-template matStepLabel>
        <div (click)="saveCurrentStep($event)">
          {{'lemonaid-sopimus-dialog.tuntemistiedot' | translate | async }}
        </div>
      </ng-template>
      <form #tunnistamisForm="ngForm" [formGroup]="tuntemistiedotFormGroup" class="teksti tunnistamistiedot">

        <div class="kyc">

          <div class="light-blue-text otsikko">
            {{'lemonaid-sopimus-dialog.kyc.yhtion-tunteminen' | translate | async }}
          </div>

          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>

          <div style="text-align: center;" [innerHtml]="'lemonaid-sopimus-dialog.rahanpesulaki-velvoittaa-keramaan' | translate | async"></div>
          <div class="dynamic-spacer"></div>

          <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
          <div class="dynamic-spacer"></div>
          <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.osakkaat' | translate | async }}</div>
          <div class="dynamic-spacer"></div>
          <div>{{'lemonaid-sopimus-dialog.kyc.anna-osakkeiden-tiedot' | translate | async }}</div>
          <div class="dynamic-spacer"></div>

          <div class="lemon-error" *ngIf="osakkaatFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-osakas' | translate | async }}</div>

          <div formArrayName="osakkaat">
            <div *ngFor="let osakasFormGroup of osakasFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">

              <div class="kyc-checkbox-large">
                <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
                </mat-checkbox>
              </div>

              <mat-radio-group class="kyc-radio" formControlName="onkoYritys" [style.display]="osakasFormGroup.get('onkoYritys').disabled ? 'none': 'flex'">
                <mat-radio-button style="margin: 5px;" [value]="false">{{'lemonaid-sopimus-dialog.kyc.osakas-on-luonnollinen' | translate | async }}</mat-radio-button>
                <mat-radio-button style="margin: 5px;" [value]="true">{{'lemonaid-sopimus-dialog.kyc.osakas-on-yritys' | translate | async }}</mat-radio-button>
              </mat-radio-group>

              <div class="kyc-single-row">
                <mat-form-field class="right-margin-in-large-view">
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-error>
                    <ng-container *ngIf="osakasFormGroup.get('nimi'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="right-margin-in-large-view">
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu-ytunnus' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-error>
                    <ng-container *ngIf="osakasFormGroup.get('hetu'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-required' | translate | async }}</ng-container>
                        <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-invalid' | translate | async }}</ng-container>
                        <ng-container *ngIf="n.errors?.ownytunnus">{{ 'lemonaid-sopimus-dialog.kyc.oma-ytunnus' | translate | async}}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="right-margin-in-large-view" [style.display]="osakasFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" [matDatepicker]="picker1" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-error>
                    <ng-container *ngIf="osakasFormGroup.get('birthDate'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
                <mat-datepicker #picker1></mat-datepicker>

                <mat-form-field>
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara' | translate | async }}</mat-label>
                  <input decimalNumberField matInput formControlName="osakkeita" [numberOfDecimals]="0" autocomplete="nothing" required data-lpignore="true">
                  <mat-error>
                    <ng-container *ngIf="osakasFormGroup.get('osakkeita'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara-puuttuu' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="kyc-single-row">

                <mat-form-field class="right-margin-in-large-view">
                  <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
                  <mat-select formControlName="kansallisuus" required>
                    <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                      {{ kansa.nimi }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <ng-container *ngIf="osakasFormGroup.get('kansallisuus'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
                  <mat-select formControlName="pep" required>
                    <mat-option [value]="true">
                      {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                    </mat-option>
                    <mat-option [value]="false">
                      {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <ng-container *ngIf="osakasFormGroup.get('pep'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-comment-container">
                <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
              </div>

              <button mat-icon-button (click)="poistaOsakas(i); $event.preventDefault();">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaOsakas(); $event.preventDefault();">
              <!-- {{'yleiset.jatka' | translate | async }} -->
              {{'lemonaid-sopimus-dialog.kyc.lisaa-osakas' | translate | async }}
            </button>
          </div>

          <div class="dynamic-spacer"></div>
          <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.hallitus-ja-toimitusjohtaja' | translate | async }}</div>
          <div class="dynamic-spacer"></div>
          <div>{{'lemonaid-sopimus-dialog.kyc.anna-paattajien-tiedot' | translate | async }}</div>
          <div class="dynamic-spacer"></div>

          <div class="lemon-error" *ngIf="hallitusFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-hallituksen-jasenen' | translate | async }}</div>

          <div formArrayName="hallitus">
            <div *ngFor="let hallitusFormGroup of hallitusFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">
              <div class="kyc-checkbox-large">
                <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
                </mat-checkbox>
              </div>

              <div class="kyc-single-row">
                <mat-form-field class="right-margin-in-large-view">
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-error>
                    <ng-container *ngIf="hallitusFormGroup.get('nimi'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="right-margin-in-large-view">
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-error>
                    <ng-container *ngIf="hallitusFormGroup.get('hetu'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-required' | translate | async }}</ng-container>
                        <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-invalid' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="right-margin-in-large-view" [style.display]="hallitusFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" [matDatepicker]="picker2" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-error>
                    <ng-container *ngIf="hallitusFormGroup.get('birthDate'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
                <mat-datepicker #picker2></mat-datepicker>

                <mat-form-field>
                  <mat-label>{{'lemonaid-sopimus-dialog.kyc.rooli' | translate | async }}</mat-label>
                  <mat-select formControlName="rooli" required>
                    <mat-option *ngFor="let rooli of hallitusRoolitObservable | async" [value]="rooli.avain">
                      {{ rooli.nimi }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <ng-container *ngIf="hallitusFormGroup.get('rooli'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.rooli-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

              </div>

              <div class="kyc-single-row">

                <mat-form-field class="right-margin-in-large-view">
                  <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
                  <mat-select formControlName="kansallisuus" required>
                    <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                      {{ kansa.nimi }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <ng-container *ngIf="hallitusFormGroup.get('kansallisuus'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
                  <mat-select formControlName="pep" required>
                    <mat-option [value]="true">
                      {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                    </mat-option>
                    <mat-option [value]="false">
                      {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <ng-container *ngIf="hallitusFormGroup.get('pep'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

              </div>

              <div class="form-comment-container">
                <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
              </div>

              <button mat-icon-button (click)="poistaHallituksenJasen(i); $event.preventDefault();">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaHallituksenJasen(); $event.preventDefault();">
              <!-- {{'yleiset.jatka' | translate | async }} -->
              {{'lemonaid-sopimus-dialog.kyc.lisaa-jasen' | translate | async}}
            </button>
          </div>

          <div class="dynamic-spacer"></div>
          <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.edunsaajat' | translate | async}}</div>
          <div class="dynamic-spacer"></div>
          <div>{{'lemonaid-sopimus-dialog.kyc.edunsaajien-tiedot' | translate | async}}</div>
          <div class="dynamic-spacer"></div>
          <!-- <div class="dynamic-spacer"></div> -->

          <div formArrayName="muut">
            <div *ngFor="let saajaFormGroup of saajaFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">
              <div class="kyc-checkbox-large">
                <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
                </mat-checkbox>
              </div>

              <div class="kyc-single-row">
                <mat-form-field class="right-margin-in-large-view">
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-error>
                    <ng-container *ngIf="saajaFormGroup.get('nimi'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-error>
                    <ng-container *ngIf="saajaFormGroup.get('hetu'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-required' | translate | async }}</ng-container>
                        <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-invalid' | translate | async }}</ng-container>
                        <ng-container *ngIf="n.errors?.ownytunnus"></ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="left-margin-in-large-view" [style.display]="saajaFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
                  <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
                  <input [name]="namename" type="text" [matDatepicker]="picker3" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-error>
                    <ng-container *ngIf="saajaFormGroup.get('birthDate'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
                <mat-datepicker #picker3></mat-datepicker>

              </div>

              <div class="kyc-single-row">

                <mat-form-field class="right-margin-in-large-view">
                  <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
                  <mat-select formControlName="kansallisuus" required>
                    <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                      {{ kansa.nimi }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <ng-container *ngIf="saajaFormGroup.get('kansallisuus'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
                  <mat-select formControlName="pep" required>
                    <mat-option [value]="true">
                      {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                    </mat-option>
                    <mat-option [value]="false">
                      {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <ng-container *ngIf="saajaFormGroup.get('pep'); let n">
                      <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                        <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

              </div>

              <div class="form-comment-container">
                <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
              </div>

              <button mat-icon-button (click)="poistaEdunsaaja(i); $event.preventDefault();">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaEdunsaaja(); $event.preventDefault();">
              <!-- {{'yleiset.jatka' | translate | async }} -->
              {{'lemonaid-sopimus-dialog.kyc.lisaa-edunsaaja' | translate | async}}
            </button>
          </div>

          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
          <div>
            <!-- {{'lemonaid-sopimus-dialog.lahetamme-valtakirjan-spostitse' | translate | async }} -->
            {{'lemonaid-sopimus-dialog.kyc.edunsaajalla-tarkoitetaan' | translate | async}}
          </div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>

          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
          <div style="font-weight: bold;">{{'lemonaid-sopimus-dialog.kyc.miksi-tietoja-kerataan' | translate | async}}</div>
          <div class="dynamic-spacer"></div>
          <div>
            <!-- {{'lemonaid-sopimus-dialog.lahetamme-valtakirjan-spostitse' | translate | async }} -->
            {{'lemonaid-sopimus-dialog.kyc.naita-tuntemistietoja' | translate | async}}
          </div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
          <div>
            {{'lemonaid-sopimus-dialog.kyc.jos-muutoksia' | translate | async}}
          </div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>

        </div>

        <div class="button-container">
          <button class="primarybutton" mat-button (click)="tallennaKyc()">{{'yleiset.jatka' | translate | async }}</button>
        </div>

      </form>
    </mat-step>
    <mat-step [label]="'valmis'">
      <ng-template matStepLabel>
        <div (click)="saveCurrentStep($event)">
          {{'yleiset.valmis' | translate | async }}
        </div>
      </ng-template>
      <div class="teksti">

        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <svg style="height: calc(76px + 1vw);" xmlns="http://www.w3.org/2000/svg" viewBox="4 4 22 22">
          <defs>
            <style>
              .cls-1 {
                fill: rgb(62, 203, 244);
              }
            </style>
          </defs>
          <path class="cls-1" d="M15 25.62A10.62 10.62 0 1 1 25.62 15 10.63 10.63 0 0 1 15 25.62ZM15 5.23A9.77 9.77 0 1 0 24.77 15 9.78 9.78 0 0 0 15 5.23Z"></path>
          <rect class="cls-1" x="13.26" y="10.91" width="0.85" height="2.93"></rect>
          <rect class="cls-1" x="15.89" y="10.91" width="0.85" height="2.93"></rect>
          <path class="cls-1" d="M15 20.08a3.22 3.22 0 0 1-3.22-3.22h0.85a2.37 2.37 0 1 0 4.74 0h0.85A3.22 3.22 0 0 1 15 20.08Z"></path>
        </svg>
        <div class="light-blue-text otsikko">{{'yleiset.valmista' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
        <div class="dynamic-spacer"></div>
        <div class="teksti-big">{{'lemonaid-sopimus-dialog.sopimus-hyvaksytty' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="button-container">
          <!-- <button class="secondarybutton" mat-button matStepperPrevious>Takaisin</button> -->
          <button class="primarybutton" mat-button (click)="valmis()">{{'yleiset.ok' | translate | async }}</button>
        </div>

      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <mat-vertical-stepper [linear]="true" *ngIf="cellphone" #verticStepper>
    <mat-step [label]="'start'" [editable]="false">
      <ng-template matStepLabel>
        {{'yleiset.tervetuloa' | translate | async }}
      </ng-template>
      <div class="teksti">
        <div class="light-blue-text otsikko">{{'yleiset.tervetuloa' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
        <div class="dynamic-spacer"></div>
        <div class="teksti-big">{{'lemonaid-sopimus-dialog.valmis-kaytettavaksi' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div>{{'lemonaid-sopimus-dialog.ennen-aloittamista' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="button-container">
          <button class="primarybutton" mat-button matStepperNext>{{'yleiset.jatka' | translate | async }}</button>
        </div>
      </div>
    </mat-step>

    <mat-step *ngIf="naytaTunnistaminen" [label]="'tunnistaminen'" [editable]="false" [completed]="strongAuthResult === 'success' && tunnistautujanTiedotAnnettu">
      <ng-template matStepLabel>
        <div (click)="saveCurrentStep($event)">
          {{'lemonaid-sopimus-dialog.tunnistaminen' | translate | async }}
        </div>
      </ng-template>

      <div class="teksti">
        <div class="light-blue-text otsikko" [innerHtml]="'lemonaid-sopimus-dialog.asiakkaan-tunnistaminen' | translate | async"></div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <ng-container *ngIf="strongAuthResult === 'start' || strongAuthResult === 'error' || strongAuthResult === 'error-cancel'">
          <div [innerHtml]="'lemonaid-sopimus-dialog.rahanpesulaki-velvoittaa-tunnistamaan' | translate | async"></div>
          <div class="dynamic-spacer"></div>
          <div *ngIf="strongAuthResult === 'start'" [innerHtml]="'lemonaid-sopimus-dialog.tunnistaudu-pankkitunnuksilla' | translate | async"></div>
          <div *ngIf="strongAuthResult === 'error' || strongAuthResult === 'error-cancel'" class="lemon-error" [innerHtml]="'lemonaid-sopimus-dialog.tunnistautuminen-epaonnistui' | translate | async"></div>
          <div class="dynamic-spacer"></div>
          <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
          <div class="dynamic-spacer"></div>
          <div tunnistautuminen-nets-pankit [actionAfterSuccess]="'continue-welcome'" [kieli]="kieli"></div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
        </ng-container>
        <ng-container *ngIf="strongAuthResult === 'success'">

          <ng-container *ngIf="!tunnistautujanTiedotAnnettu">

            <div [innerHtml]="'lemonaid-sopimus-dialog.anna-lisatiedot' | translate | async"></div>
            <div class="dynamic-spacer"></div>

            <form [formGroup]="tunnistautujaForm" class="tunnistautuja">
              <mat-form-field>
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
                <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
                <mat-select formControlName="kansallisuus" required>
                  <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                    {{ kansa.nimi }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="tunnistautujaForm.get('kansallisuus'); let n">
                    <ng-container *ngIf="(n.touched && n.dirty)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
                <mat-select formControlName="pep" required>
                  <mat-option [value]="true">
                    {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                  </mat-option>
                  <mat-option [value]="false">
                    {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="tunnistautujaForm.get('pep'); let n">
                    <ng-container *ngIf="(n.touched && n.dirty)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </form>

            <div class="form-comment-container">
              <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
            </div>

            <div class="dynamic-spacer"></div>
            <div class="dynamic-spacer"></div>

            <div style="font-weight: bold; text-align: left;">{{'lemonaid-sopimus-dialog.kyc.pep-miksi-kerataan' | translate | async}}</div>
            <div class="dynamic-spacer"></div>
            <div style="text-align: left;">{{'lemonaid-sopimus-dialog.kyc.pep-naita-tuntemistietoja' | translate | async}}</div>

            <div class="dynamic-spacer"></div>
            <div class="dynamic-spacer"></div>

            <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>

            <div class="button-container">
              <button (click)="tallennaTunnistautujaLisatiedot()" class="primarybutton" mat-button>{{'yleiset.jatka' | translate | async }}</button>
            </div>

            <div class="dynamic-spacer"></div>
          </ng-container>

          <ng-container *ngIf="tunnistautujanTiedotAnnettu">
            <div [innerHtml]="'lemonaid-sopimus-dialog.tunnistautuminen-onnistui' | translate | async"></div>
            <div class="dynamic-spacer"></div>
            <div class="button-container">
              <button class="primarybutton" mat-button matStepperNext>{{'yleiset.jatka' | translate | async }}</button>
            </div>
            <div class="dynamic-spacer"></div>
          </ng-container>

        </ng-container>
      </div>
    </mat-step>

    <mat-step *ngIf="naytaSopimus" [stepControl]="sopimusFormGroup" [editable]="false" [label]="'sopimus'">
      <ng-template matStepLabel>
        <div (click)="saveCurrentStep($event)" [innerHtml]="'lemonaid-sopimus-dialog.toimeksiantosopimus-break' | translate | async">
        </div>
      </ng-template>
      <form #sopimusForm="ngForm" [formGroup]="sopimusFormGroup" class="teksti">

        <div class="dynamic-spacer"></div>
        <div class="light-blue-text otsikko" [innerHtml]="'lemonaid-sopimus-dialog.toimeksiantosopimus-break' | translate | async"></div>
        <div class="dynamic-spacer"></div>
        <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
        <div class="dynamic-spacer"></div>
        <div>{{'lemonaid-sopimus-dialog.palvelumme-sisaltaa' | translate | async }}</div>
        <div class="dynamic-spacer-puolet"></div>
        <div>{{'lemonaid-sopimus-dialog.lue-tarkempi-erittely' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>

        <div class="price-text">{{'lemonaid-sopimus-dialog.kuukausihinta-alkaessa' | translate | async }}: <span class="light-blue-text otsikko-numero" style="vertical-align: middle; padding: 0 2vw;">{{ hintaPalvelunAlkaessaObservable | async }}
            /{{'lemonaid-sopimus-dialog.kuukaudessa' | translate | async }}</span></div>
        <div class="dynamic-spacer"></div>
        <div class="price-text">{{'lemonaid-sopimus-dialog.ensimmainen-kuukausi' | translate | async }}: <span class="light-blue-text otsikko-numero" style="vertical-align: middle; padding: 0 2vw;">{{ ensimmainenLaskutettavaKuukausi }}</span></div>
        <div class="dynamic-spacer"></div>
        <div class="teksti-small">{{'lemonaid-sopimus-dialog.aiempiin-laskutetaan-tuntiperusteisesti' | translate | async }}</div>

        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>

        <div style="text-align: left;">
          <div>{{'lemonaid-sopimus-dialog.lukenut-ja-hyvaksyn' | translate | async }}</div>
          <div class="dynamic-spacer-puolet"></div>
          <mat-checkbox formControlName="hyvaksy"></mat-checkbox>
          <a class="link-like" (click)="downloadSopimus()" style="padding-left: 2vw;">{{'lemonaid-sopimus-dialog.toimeksiantosopimuksen' | translate | async }}</a>
          <mat-error *ngIf="hyvaksy?.errors?.required && (hyvaksy?.touched || sopimusForm?.submitted)" style="padding-left: 2vw; font-size:11.5px;">{{'lemonaid-sopimus-dialog.hyvaksy-required' | translate | async }}</mat-error>
          <div class="liitteet-container">
            <a class="link-like" (click)="downloadPalvelukuvaus()">{{'lemonaid-sopimus-dialog.palvelukuvaus' | translate | async }}</a>
            <div><a target="_BLANK" href="/assets/sopimukset/ysetal2018.pdf">{{'lemonaid-sopimus-dialog.yleiset-sopimusehdot' | translate | async }} TAL2018</a></div>
            <div><a target="_BLANK" href="/assets/sopimukset/shktal2018.pdf">{{'lemonaid-sopimus-dialog.sopimus-henkilotietojen' | translate | async }} TAL2018</a></div>
          </div>
        </div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="button-container">
          <button class="primarybutton" mat-button (click)="tallennaHyvaksynta()">{{'lemonaid-sopimus-dialog.hyvaksy-ja-jatka' | translate | async }}</button>
        </div>
      </form>
    </mat-step>

    <mat-step *ngIf="naytaTuntemistiedot" [stepControl]="tuntemistiedotFormGroup" [editable]="false" [label]="'tuntemistiedot'">
      <ng-template matStepLabel>
        <div (click)="saveCurrentStep($event)">
          {{'lemonaid-sopimus-dialog.tuntemistiedot' | translate | async }}
        </div>
      </ng-template>
      <form #tunnistamisForm="ngForm" [formGroup]="tuntemistiedotFormGroup" class="teksti tunnistamistiedot">

        <div class="kyc">

          <div class="light-blue-text otsikko">
            {{'lemonaid-sopimus-dialog.kyc.yhtion-tunteminen' | translate | async }}
          </div>

          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>

          <div style="text-align: center;" [innerHtml]="'lemonaid-sopimus-dialog.rahanpesulaki-velvoittaa-keramaan' | translate | async"></div>
          <div class="dynamic-spacer"></div>

          <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
          <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.osakkaat' | translate | async }}</div>
          <div class="dynamic-spacer"></div>
          <div>{{'lemonaid-sopimus-dialog.kyc.anna-osakkeiden-tiedot' | translate | async }}</div>
          <div class="dynamic-spacer"></div>

          <div class="lemon-error" *ngIf="osakkaatFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-osakas' | translate | async }}</div>

          <div formArrayName="osakkaat">
            <div *ngFor="let osakasFormGroup of osakasFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">

              <div class="kyc-checkbox-cellphone">
                <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
                </mat-checkbox>
              </div>

              <mat-form-field>
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
                <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-error>
                  <ng-container *ngIf="osakasFormGroup.get('nimi'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu-ytunnus' | translate | async }}</mat-label>
                <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-error>
                  <ng-container *ngIf="osakasFormGroup.get('hetu'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-required' | translate | async }}</ng-container>
                      <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-invalid' | translate | async }}</ng-container>
                      <ng-container *ngIf="n.errors?.ownytunnus">{{ 'lemonaid-sopimus-dialog.kyc.oma-ytunnus' | translate | async}}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <mat-form-field [style.display]="osakasFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
                <input [name]="namename" type="text" [matDatepicker]="picker1" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-error>
                  <ng-container *ngIf="osakasFormGroup.get('birthDate'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-datepicker #picker1></mat-datepicker>

              <mat-form-field>
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara' | translate | async }}</mat-label>
                <input decimalNumberField matInput formControlName="osakkeita" [numberOfDecimals]="0" autocomplete="nothing" required data-lpignore="true">
                <mat-error>
                  <ng-container *ngIf="osakasFormGroup.get('osakkeita'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara-puuttuu' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
                <mat-select formControlName="kansallisuus" required>
                  <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                    {{ kansa.nimi }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="osakasFormGroup.get('kansallisuus'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
                <mat-select formControlName="pep" required>
                  <mat-option [value]="true">
                    {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                  </mat-option>
                  <mat-option [value]="false">
                    {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="osakasFormGroup.get('pep'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <div class="form-comment-container">
                <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
              </div>

              <button mat-icon-button (click)="poistaOsakas(i); $event.preventDefault();">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaOsakas(); $event.preventDefault();">
              <!-- {{'yleiset.jatka' | translate | async }} -->
              {{'lemonaid-sopimus-dialog.kyc.lisaa-osakas' | translate | async }}
            </button>
          </div>

          <div class="dynamic-spacer"></div>
          <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.hallitus-ja-toimitusjohtaja' | translate | async }}</div>
          <div class="dynamic-spacer"></div>
          <div>{{'lemonaid-sopimus-dialog.kyc.anna-paattajien-tiedot' | translate | async }}</div>
          <div class="dynamic-spacer"></div>

          <div class="lemon-error" *ngIf="hallitusFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-hallituksen-jasenen' | translate | async }}</div>

          <div formArrayName="hallitus">
            <div *ngFor="let hallitusFormGroup of hallitusFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">

              <div class="kyc-checkbox-cellphone">
                <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
                </mat-checkbox>
              </div>

              <mat-form-field>
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
                <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-error>
                  <ng-container *ngIf="hallitusFormGroup.get('nimi'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu' | translate | async }}</mat-label>
                <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-error>
                  <ng-container *ngIf="hallitusFormGroup.get('hetu'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-required' | translate | async }}</ng-container>
                      <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-invalid' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <mat-form-field [style.display]="hallitusFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
                <input [name]="namename" type="text" [matDatepicker]="picker2" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-error>
                  <ng-container *ngIf="hallitusFormGroup.get('birthDate'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-datepicker #picker2></mat-datepicker>

              <mat-form-field>
                <mat-label>{{'lemonaid-sopimus-dialog.kyc.rooli' | translate | async }}</mat-label>
                <mat-select formControlName="rooli" required>
                  <mat-option *ngFor="let rooli of hallitusRoolitObservable | async" [value]="rooli.avain">
                    {{ rooli.nimi }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="hallitusFormGroup.get('rooli'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.rooli-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
                <mat-select formControlName="kansallisuus" required>
                  <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                    {{ kansa.nimi }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="hallitusFormGroup.get('kansallisuus'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
                <mat-select formControlName="pep" required>
                  <mat-option [value]="true">
                    {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                  </mat-option>
                  <mat-option [value]="false">
                    {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="hallitusFormGroup.get('pep'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <div class="form-comment-container">
                <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
              </div>

              <button mat-icon-button (click)="poistaHallituksenJasen(i); $event.preventDefault();">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaHallituksenJasen(); $event.preventDefault();">
              <!-- {{'yleiset.jatka' | translate | async }} -->
              {{'lemonaid-sopimus-dialog.kyc.lisaa-jasen' | translate | async}}
            </button>
          </div>

          <div class="dynamic-spacer"></div>
          <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.edunsaajat' | translate | async}}</div>
          <div class="dynamic-spacer"></div>
          <div>{{'lemonaid-sopimus-dialog.kyc.edunsaajien-tiedot' | translate | async}}</div>
          <div class="dynamic-spacer"></div>
          <!-- <div class="dynamic-spacer"></div> -->

          <div formArrayName="muut">
            <div *ngFor="let saajaFormGroup of saajaFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">

              <div class="kyc-checkbox-cellphone">
                <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
                </mat-checkbox>
              </div>

              <mat-form-field>
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
                <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-error>
                  <ng-container *ngIf="saajaFormGroup.get('nimi'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu' | translate | async }}</mat-label>
                <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-error>
                  <ng-container *ngIf="saajaFormGroup.get('hetu'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-required' | translate | async }}</ng-container>
                      <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-invalid' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <mat-form-field [style.display]="saajaFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
                <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
                <input [name]="namename" type="text" [matDatepicker]="picker3" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-error>
                  <ng-container *ngIf="saajaFormGroup.get('birthDate'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-datepicker #picker3></mat-datepicker>

              <mat-form-field>
                <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
                <mat-select formControlName="kansallisuus" required>
                  <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                    {{ kansa.nimi }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="saajaFormGroup.get('kansallisuus'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
                <mat-select formControlName="pep" required>
                  <mat-option [value]="true">
                    {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                  </mat-option>
                  <mat-option [value]="false">
                    {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <ng-container *ngIf="saajaFormGroup.get('pep'); let n">
                    <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                      <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                    </ng-container>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <div class="form-comment-container">
                <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>
              </div>

              <button mat-icon-button (click)="poistaEdunsaaja(i); $event.preventDefault();">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaEdunsaaja(); $event.preventDefault();">
              <!-- {{'yleiset.jatka' | translate | async }} -->
              {{'lemonaid-sopimus-dialog.kyc.lisaa-edunsaaja' | translate | async}}
            </button>
          </div>

          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
          <div>
            <!-- {{'lemonaid-sopimus-dialog.lahetamme-valtakirjan-spostitse' | translate | async }} -->
            {{'lemonaid-sopimus-dialog.kyc.edunsaajalla-tarkoitetaan' | translate | async}}
          </div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>

          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
          <div style="font-weight: bold;">{{'lemonaid-sopimus-dialog.kyc.miksi-tietoja-kerataan' | translate | async}}</div>
          <div class="dynamic-spacer"></div>
          <div>
            <!-- {{'lemonaid-sopimus-dialog.lahetamme-valtakirjan-spostitse' | translate | async }} -->
            {{'lemonaid-sopimus-dialog.kyc.naita-tuntemistietoja' | translate | async}}
          </div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>
          <div>
            {{'lemonaid-sopimus-dialog.kyc.jos-muutoksia' | translate | async}}
          </div>
          <div class="dynamic-spacer"></div>
          <div class="dynamic-spacer"></div>

        </div>

        <div class="button-container">
          <button class="primarybutton" mat-button (click)="tallennaKyc()">{{'yleiset.jatka' | translate | async }}</button>
        </div>

      </form>
    </mat-step>

    <mat-step [label]="'valmis'">
      <ng-template matStepLabel>
        <span (click)="saveCurrentStep($event)">
          {{'yleiset.valmis' | translate | async }}
        </span>
      </ng-template>
      <div class="teksti">

        <div class="dynamic-spacer"></div>
        <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
        <div class="dynamic-spacer"></div>
        <svg style="height: calc(76px + 1vw);" xmlns="http://www.w3.org/2000/svg" viewBox="4 4 22 22">
          <defs>
            <style>
              .cls-1 {
                fill: rgb(62, 203, 244);
              }
            </style>
          </defs>
          <path class="cls-1" d="M15 25.62A10.62 10.62 0 1 1 25.62 15 10.63 10.63 0 0 1 15 25.62ZM15 5.23A9.77 9.77 0 1 0 24.77 15 9.78 9.78 0 0 0 15 5.23Z"></path>
          <rect class="cls-1" x="13.26" y="10.91" width="0.85" height="2.93"></rect>
          <rect class="cls-1" x="15.89" y="10.91" width="0.85" height="2.93"></rect>
          <path class="cls-1" d="M15 20.08a3.22 3.22 0 0 1-3.22-3.22h0.85a2.37 2.37 0 1 0 4.74 0h0.85A3.22 3.22 0 0 1 15 20.08Z"></path>
        </svg>
        <div class="light-blue-text otsikko">{{'yleiset.valmista' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="teksti-big">{{'lemonaid-sopimus-dialog.sopimus-hyvaksytty' | translate | async }}</div>
        <div class="dynamic-spacer"></div>
        <div class="dynamic-spacer"></div>
        <div class="red-text common-error" *ngIf="commonError">{{commonError}}</div>
        <div class="button-container">
          <button class="primarybutton" mat-button (click)="valmis()">{{'yleiset.ok' | translate | async }}</button>
        </div>

      </div>
    </mat-step>
  </mat-vertical-stepper>

</div>