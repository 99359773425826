<mat-dialog-content>

  <lemon-dialog-header>
    <div>{{'maksut.merkitse-maksetuksi' | translate | async}}</div>
  </lemon-dialog-header>

  <form [formGroup]="form">

    <!-- <pre>{{tositteenPvm?.errors | json}}</pre> -->
    <mat-form-field style="width: 230px; margin: 0 auto; display: block;">
      <mat-label>{{'maksut.maksupaivamaara' | translate | async}}</mat-label>
      <input numberDatePicker matInput formControlName="tositteenPvm" [name]="namename" [matDatepicker]="pickerDay" [min]="minDate" [max]="maxDate" autocomplete="1" data-lpignore="true" required>
      <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
      <mat-datepicker #pickerDay></mat-datepicker>
    </mat-form-field>
    <ng-container *ngIf="tositteenPvm; let n">
      <ng-container *ngIf="(n?.dirty || n?.touched)">
        <mat-error *ngIf="n.errors?.required">{{ 'kuitit.muokkaa.validation.pvm.required' | translate | async }}</mat-error>
        <mat-error *ngIf="n.errors?.matDatepickerParse">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerParse' | translate | async }}</mat-error>
        <mat-error *ngIf="n.errors?.matDatepickerMax">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMax' | translate | async }}</mat-error>
        <mat-error *ngIf="n.errors?.matDatepickerMin">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMin' | translate | async }}</mat-error>
      </ng-container>
    </ng-container>

  </form>

</mat-dialog-content>

<mat-dialog-actions style="margin: 30px; margin-top: 12.5px; justify-content: center;">
  <button class="secondarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
  <button class="primarybutton" mat-button (click)="tallenna()">{{'maksut.merkitse' | translate | async}}</button>
</mat-dialog-actions>