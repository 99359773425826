import { Component, ErrorHandler, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { ErrorResponse } from 'app/_shared-core/model/common'
import { firstValueFrom } from 'rxjs'

@Component({
  templateUrl: './sopimus-tauolla.component.html',
  styleUrls: ['./sopimus-tauolla.component.css']
})
export class SopimusTauollaComponent implements OnInit {

  form: FormGroup
  ilmoitusLahetetty: boolean = false
  commonError: string

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _kayttajaService: KayttajaService,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _errorHandler: ErrorHandler
  ) { }

  ngOnInit() { }

  async lopetaTauko() {
    this._ladataanService.aloitaLataaminen()

    try {
      const kayttaja = await firstValueFrom(this._kayttajaService.kayttajaObservable)

      if (!kayttaja) {
        return
      }

      const response = await this._firebaseLemonaid.functionsCall<string, ErrorResponse>('sopimuksenTaukoHalutaanLopettaa', '')

      if (!response || response.e) {
        throw new Error('Sopimuksen tauon lopettamisen pyynnön lähettäminen epäonnistui: ' + response?.e || 'No response')
      }
      this.ilmoitusLahetetty = true

    } catch (error: any) {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

}
