<div style="text-align: center; min-width: 300px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      {{ 'reports-lemonaid.allekirjoita-tilinpaatos' | translate | async }}
    </lemon-dialog-header>

    <div>
      {{ 'reports-lemonaid.olen-kaynyt-lapi' | translate | async }}
    </div>

    <div style="max-width: 500px; margin: 20px auto;">
      {{ 'reports-lemonaid.tilinpaatos-pitaa-rekisteroida' | translate | async }}
    </div>
    <div style="max-width: 350px; margin: 0 auto;">
      <form [formGroup]="form">
        <mat-checkbox formControlName="agree" [class.mat-form-field-invalid]="agree?.errors?.required || agree?.errors?.required">
        </mat-checkbox>
        <span style="font-size: 15px; max-width: 20px; margin-left: 5px;">{{ 'reports-lemonaid.valtuutan-lemon-treen' | translate | async }}
        </span>
        <mat-error *ngIf="(agree?.errors?.required && (agree?.dirty || agree?.touched))">
          {{'reports-lemonaid.valtuutan-lemon-treen-required' | translate | async}}
        </mat-error>
      </form>
    </div>
  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>{{ 'yleiset.peruuta' | translate | async }}</button>
    <span style="display: inline-block; width: 8vw; max-width: 4em;"></span>
    <button (click)="replyTrue()" class="primarybutton" mat-button> {{ 'reports-lemonaid.allekirjoita' | translate | async }}</button>
  </div>
</div>