import { Component, Inject, ViewChild, ErrorHandler, AfterViewInit, OnInit, HostListener } from '@angular/core'
import { UntypedFormGroup, AbstractControl, UntypedFormControl, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { LemonTranslationService } from './_jaettu-angular/service/lemon-translation.service'
import { FormValidationService } from './_jaettu-angular/service/form-validation.service'
import { LadataanService } from './_jaettu-angular/service/ladataan.service'

import { VaihdaSalasanaPyynto, VaihdaSalasanaVastaus, VaihdaSalasanaVirhe } from './_jaettu/model/common'
import { LemonaidValidators } from './_angular/_validator/LemonaidValidators'
import { FirebaseLemonaid } from './_angular/service/firebase-lemonaid.service'

export interface VaihdaSalasanaDialogData { }

@Component({
  templateUrl: './vaihda-salasana.dialog.html',
  styleUrls: ['./vaihda-salasana.dialog.css']
})
export class VaihdaSalasanaDialog implements OnInit, AfterViewInit {

  @ViewChild('oldpasswordInput', { read: MatInput }) oldpasswordInput: MatInput

  loginForm: UntypedFormGroup
  commonError: string
  success = false
  virheet: any = {}
  uusiSalasana: string = null
  vanhaSalasana: string = null

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: VaihdaSalasanaDialogData,
    private dialogRef: MatDialogRef<VaihdaSalasanaDialog>,
    private _firebaseLemonaid: FirebaseLemonaid,
    private validationService: FormValidationService,
    private errorHandler: ErrorHandler,
    private ladataanService: LadataanService,
    private translationService: LemonTranslationService
  ) { }

  ngOnInit(): void {

    this.loginForm = new UntypedFormGroup({
      'oldpassword': new UntypedFormControl('', [Validators.required]),
      'newpassword': new UntypedFormControl('', [Validators.required, LemonaidValidators.passwordComplexityValidator])
    })
    this.loginForm.updateValueAndValidity()

    this.oldpassword.valueChanges.subscribe(value => {
      this.vanhaSalasana = value
    })
    this.newpassword.valueChanges.subscribe(value => {
      this.uusiSalasana = value
      this.virheet = this.newpassword.errors || {}
    })

    this.virheet = this.newpassword.errors || {}

  }

  get newpassword(): AbstractControl {
    return this.loginForm.get('newpassword')
  }

  get oldpassword(): AbstractControl {
    return this.loginForm.get('oldpassword')
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.oldpasswordInput.focus() }, 0)
  }

  @HostListener('document:keydown.enter')
  defaultAction() {
    if (!this.success) {
      this.changePassword()
    } else {
      this.dialogRef.close()
    }
  }

  changePassword() {

    if (!this.loginForm.valid) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
      return
    }

    this.ladataanService.aloitaLataaminen()

    const data: VaihdaSalasanaPyynto = {
      uusiSalasana: this.loginForm.get('newpassword').value,
      vanhaSalasana: this.loginForm.get('oldpassword').value
    }

    this._firebaseLemonaid.functionsCall<VaihdaSalasanaPyynto, VaihdaSalasanaVastaus>('vaihdaSalasana', data).then(vastaus => {
      this.commonError = ''
      if ((vastaus.virheet && vastaus.virheet.length > 0) || vastaus.virhekoodi) {
        if (vastaus.virhekoodi) {
          this.kasitteleVirhe({ code: vastaus.virhekoodi })
        }
        if (vastaus.virheet && vastaus.virheet.length > 0) {
          this.kasitteleOmaVirhe(vastaus.virheet)
        }
      } else {
        this.success = true
      }
      this.ladataanService.lopetaLataaminen()
    }).catch(err => {
      this.kasitteleVirhe(err)
      this.ladataanService.lopetaLataaminen()
    })

  }

  private kasitteleOmaVirhe(errors: VaihdaSalasanaVirhe[]) {
    if (errors && errors.length > 0) {
      for (const error of errors) {
        if (error === VaihdaSalasanaVirhe.UUSI_SALASANA_EI_SISALLA_NUMEROA) {
          this.commonError += this.translationService.lokalisoi('reset.validation.number') + ' '
        } else if (error === VaihdaSalasanaVirhe.UUSI_SALASANA_EI_SISALLA_PIENTA_KIRJAINTA) {
          this.commonError += this.translationService.lokalisoi('reset.validation.lettersmall') + ' '
        } else if (error === VaihdaSalasanaVirhe.UUSI_SALASANA_EI_SISALLA_SUURTA_KIRJAINTA) {
          this.commonError += this.translationService.lokalisoi('reset.validation.letterbig') + ' '
        } else if (error === VaihdaSalasanaVirhe.UUSI_SALASANA_LIIAN_LYHYT) {
          this.commonError += this.translationService.lokalisoi('reset.validation.min') + ' '
        } else if (error === VaihdaSalasanaVirhe.UUSI_SALASANA_LIIAN_VAHAN_YKSILOLLISIA_MERKKEJÄ) {
          this.commonError += this.translationService.lokalisoi('reset.validation.differentchars') + ' '
        } else if (error === VaihdaSalasanaVirhe.UUSI_SALASANA_PUUTTUU) {
          // EI VOI KOSKAAN TAPAHTUA?
        } else if (error === VaihdaSalasanaVirhe.VANHA_SALASANA_PUUTTUU) {
          // EI VOI KOSKAAN TAPAHTUA?
        }
      }
    }
  }

  private kasitteleVirhe(err: any) {
    const code = err.code
    if (code) {
      switch (code) {
        case 'auth/expired-action-code':
          this.commonError = this.translationService.lokalisoi('reset.virheet.koodi-vanhentunut')
          break
        case 'auth/invalid-action-code':
          this.commonError = this.translationService.lokalisoi('reset.virheet.koodi-ei-oikea')
          break
        case 'auth/user-disabled':
          this.commonError = this.translationService.lokalisoi('reset.virheet.kayttaja-inaktiivinen')
          break
        case 'auth/user-not-found':
          this.commonError = this.translationService.lokalisoi('reset.virheet.kayttaja-ei-loydy')
          break
        case 'auth/weak-password':
          this.commonError = this.translationService.lokalisoi('reset.virheet.salasana-heikko')
          break
        case 'auth/wrong-password':
          this.oldpassword.setErrors({ 'wrongpassword': true })
          break
        default:
          this.commonError = this.translationService.lokalisoi('yleiset.tuntematon-virhe')
          this.errorHandler.handleError(err)
      }
      this.validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
    } else {
      this.commonError = this.translationService.lokalisoi('yleiset.tuntematon-virhe')
      this.errorHandler.handleError(err)
    }
  }

}
