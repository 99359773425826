import { Component, OnInit, OnDestroy, ErrorHandler, HostListener } from '@angular/core'
import { Router, ActivatedRoute, Data } from '@angular/router'
import { SafeUrl } from '@angular/platform-browser'

import { MatDialog } from '@angular/material/dialog'

import { FirestoreTosite, FirestoreTositteenKuva, FirestoreTositteenAlkuperainenTiedosto, PaymentStatus } from '../_jaettu/model/tosite'
import { TositeKopioija } from '../_jaettu/service/tosite/tosite.kopioija'
import { DateService } from '../_shared-core/service/date.service'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { TositeKuvaCacheService } from '../tositteet/kuvat/tosite-kuva-cache.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { KayttajaService } from '../_angular/service/kayttaja.service'
import { TositeService } from '../_angular/service/tosite/tosite.service'
import { TositeKatseleComponentData } from '../_angular/_resolvers/tosite.resolve'

// import { TositePoistaDialog, TositePoistaDialogData } from '../poista-dialog/tosite.poista.dialog'
// import { TositeLukittuDialog } from '../tosite-lukittu.dialog'

import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, of } from 'rxjs'
import { map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { MatSnackBar } from '@angular/material/snack-bar'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { MaksutSharedStateService } from './maksut-shared-state.service'
import { MaksuKatseleComponentDataResolve, MaksuKatseleComponentExistingData } from 'app/_angular/_resolvers/maksu.resolve'
import { MaksutFirestoreDataSource } from './maksut.firestore.datasource'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { LaskunQrKoodinCharacterSet, LaskunQrKoodinOsat, ViitenumeroService, VirtuaaliviivakoodinOsat } from 'app/_shared-core/service/viitenumero.service'
import { Big } from 'big.js'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MaksutMaksutapaDialog, MaksutMaksutapaDialogData } from './maksut-maksutapa.dialog'
import { LocalDate, NumberDate } from 'app/_shared-core/model/common'
import { Asiakas, KayttajanTiedot, KirjanpidonPeruste, UserSettings } from 'app/_jaettu/model/kayttaja'
import { VasenValikkoService } from 'app/_jaettu-angular/service/vasen-valikko.service'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { MaksutMaksupaivaDialog, MaksutMaksupaivaDialogData } from './maksut-maksupaiva.dialog'
import { AsiakasJaettuLemonaidService } from 'app/_jaettu/service/asiakas-jaettu-lemonaid.service'
import { UserSettingsService } from 'app/_angular/service/user-settings.service'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'
import { IbanService } from 'app/_shared-core/service/iban.service'
import { BicService } from 'app/_shared-core/service/bic.service'

type ViewType = 'vastaanotto' | 'hyvaksynta' | 'maksaminen' | 'hyvaksynta-ja-maksaminen' | 'lukossa'
export interface FirestoreKuitinLatausKuva extends FirestoreTositteenKuva {
  ladataan: boolean
  safeUrl: SafeUrl
}

export interface MaksunTiedotFormInterface {
  erapvm: FormControl<Date>
  summa: FormControl<number>
  viesti: FormControl<string>
  lisatiedot: FormControl<string>
  viite: FormControl<string>
  iban: FormControl<string>
  bic: FormControl<string>
  saaja: FormControl<string>
}

interface Hyvaksyntatieto {
  placeholder: boolean
  text: string
}

@Component({
  templateUrl: './maksut-katsele.component.html',
  styleUrls: ['./maksut-katsele.component.css']
})
export class MaksutKatseleComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  lahde: 'osto' | 'myynti' | 'palkka' | 'tiliote'

  // tosite: FirestoreTosite = null
  kuvat: string[] = null
  tositeObservable: Observable<FirestoreTosite>
  alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[] = null
  maksuHyvaksyttyObservable: Observable<boolean>

  naytaHylkaaNappiObservable: Observable<boolean>
  naytaHylkaamisenPeruminenNappiObservable: Observable<boolean>
  naytaHyvaksyNappiObservable: Observable<boolean>
  naytaHyvaksynnanPeruminenNappiObservable: Observable<boolean>
  naytaMaksettuMuuallaNappiVainVastaanottoObservable: Observable<boolean>
  naytaMaksettuMuuallaNappiMaksutKaytossaObservable: Observable<boolean>
  naytaMaksettuMuuallaPeruminenNappiObservable: Observable<boolean>
  naytaMuokkaaMaksuaNappiObservable: Observable<boolean>
  naytaKasiteltyNappiObservable: Observable<boolean>
  naytaKasittelynPeruminenNappiObservable: Observable<boolean>

  private _maksuaMuokataanSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  maksuaMuokataanObservable: Observable<boolean> = this._maksuaMuokataanSubject.asObservable()
  logItemsObservable: Observable<Hyvaksyntatieto[]>
  kooditObservable: Observable<{ viiva: string, qr: string }>
  viewTypeObservable: Observable<ViewType>
  pagesObservable: Observable<{ curr: number, total: number }>

  showSumma: boolean = false

  viiteControl = new FormControl<string>(null)
  viestiControl = new FormControl<string>(null, [Validators.maxLength(60), this._validateViestiTaiViite(this.viiteControl)])
  maksunTiedotForm: FormGroup<MaksunTiedotFormInterface>

  activeTabIndex: number

  private _dataSource: MaksutFirestoreDataSource
  private _currentViewType: ViewType

  showLisatiedotCheckMark: boolean

  namename = 'apijnhytfcxswer' + Math.random()

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _dialog: MatDialog,
    private _translationService: LemonTranslationService,
    private _ladataanService: LadataanService,
    private _dateService: DateService,
    private _tositeService: TositeService,
    private _tositeKopioija: TositeKopioija,
    private _tositeKuvaCacheService: TositeKuvaCacheService,
    private _kayttajaService: KayttajaService,
    private _timestampService: TimestampService,
    private _formValidationService: FormValidationService,
    private _maksutSharedStateService: MaksutSharedStateService,
    private _katseleComponentDataResolve: MaksuKatseleComponentDataResolve,
    private _viitenumeroService: ViitenumeroService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _vasenValikkoService: VasenValikkoService,
    private _breakpointObserver: BreakpointObserver,
    private _asiakasJaettuService: AsiakasJaettuLemonaidService,
    private _userSettingsService: UserSettingsService,
    private _ibanService: IbanService,
    private _bicService: BicService
  ) {
    this._dataSource = this._maksutSharedStateService.getDataSource()
    this.viiteControl.setValidators([this._validateViestiTaiViite(this.viestiControl), this._validateViitenumero])
    this.maksunTiedotForm = new FormGroup({
      erapvm: new FormControl<Date>(null, Validators.required),
      summa: new FormControl<number>(null, [Validators.required, Validators.min(0.01), Validators.max(999999.99)]),
      viite: this.viiteControl,
      viesti: this.viestiControl,
      lisatiedot: new FormControl<string>(null),
      iban: new FormControl<string>(null, [Validators.required, this._validateIban]),
      bic: new FormControl<string>(null, [Validators.required, this._validateBic]),
      saaja: new FormControl<string>(null, [Validators.required])
    })
    this.viiteControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this.viestiControl.updateValueAndValidity({ emitEvent: false })
    })
    this.viestiControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this.viiteControl.updateValueAndValidity({ emitEvent: false })
    })
  }

  @HostListener('document:keydown.arrowright')
  async navigoiSeuraavaan() {
    if (this._maksuaMuokataanSubject.value) {
      return
    }
    const seuraavaMaksu = await this._dataSource.siirrySeuraavaan()
    if (seuraavaMaksu) {
      const existing: MaksuKatseleComponentExistingData = {
        tosite: seuraavaMaksu
      }
      this._katseleComponentDataResolve.asetaOlemassaolevaData(existing)
      this._router.navigate(['/maksut/', seuraavaMaksu.avain])
    }
  }

  @HostListener('document:keydown.arrowleft')
  async navigoiEdelliseen() {
    if (this._maksuaMuokataanSubject.value) {
      return
    }
    const edellinenMaksu = await this._dataSource.siirryEdelliseen()
    if (edellinenMaksu) {
      const existing: MaksuKatseleComponentExistingData = {
        tosite: edellinenMaksu
      }
      this._katseleComponentDataResolve.asetaOlemassaolevaData(existing)
      this._router.navigate(['/maksut/', edellinenMaksu.avain])
    }
  }

  ngOnInit() {

    this._breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      takeUntil(this._ngUnsubscribe),
      map(br => br.matches)
    ).subscribe(isSmallScreen => this._vasenValikkoService.paivitaAuki(!isSmallScreen))

    this._userSettingsService.userSettingsObservable.pipe(
      take(1)
    ).subscribe(settings => {
      if (settings?.vm === 'qr') {
        this.activeTabIndex = 0
      } else if (settings?.vm === 'viiva') {
        this.activeTabIndex = 1
      } else if (settings?.vm === 'virtuaaliviiva') {
        this.activeTabIndex = 2
      }
    })

    this.pagesObservable = combineLatest([
      this._dataSource.nykyinenIndeksiObservable,
      this._dataSource.valittujenRivienMaaraObservable
    ]).pipe(
      map(([nykyinen, maara]) => {
        return { curr: nykyinen, total: maara }
      })
    )

    this.tositeObservable = combineLatest([this._route.data, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      switchMap(([routeData, kayttaja]) => {
        return this._dataSource.asetaNykyisenKuitinIndex(routeData?.data?.tosite).then(() => {
          return [routeData, kayttaja] as [Data, KayttajanTiedot]
        })
      }),
      switchMap(([routeData, kayttaja]) => {
        if (!routeData?.data?.tosite?.avain || !kayttaja?.asiakasId) {
          return of<FirestoreTosite>(null)
        }
        const componentData = routeData.data as TositeKatseleComponentData
        return this._firebaseLemonaid.firestoreDoc<FirestoreTosite>('kuitit/' + kayttaja.asiakasId + '/kuitit/' + componentData.tosite.avain).listen().pipe(
          startWith(componentData.tosite)
        )
      }),
      lemonShare()
    )

    if (!this._dataSource.isInitialized()) {
      this.peruuta()
      return
    }

    const apixConfigObservable = this._kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja) {
          return of<ApixReceivedInvoiceConfig>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttaja.asiakasAvain + '/apix-received-invoice-config/' + kayttaja.asiakasAvain).listen()
      })
    )

    this.viewTypeObservable = combineLatest([
      this._kayttajaService.nykyinenAsiakasObservable,
      this._kayttajaService.kayttajaObservable,
      this.tositeObservable,
      apixConfigObservable
    ]).pipe(
      map(([asiakas, kayttaja, tosite, apixConfig]) => {

        let viewType: ViewType = 'lukossa'

        if (!asiakas || !kayttaja?.roolit || !tosite || !apixConfig) {
          return viewType
        }

        viewType = 'vastaanotto'

        if (apixConfig.paymentIsActive) {
          if (kayttaja.roolit.MAKSUT_HYVAKSYJA) {
            viewType = 'hyvaksynta'
          }
          if (kayttaja.roolit.MAKSUT_MAKSAJA) {
            viewType = 'maksaminen'
          }
          if (kayttaja.roolit.MAKSUT_HYVAKSYJA && kayttaja.roolit.MAKSUT_MAKSAJA) {
            viewType = 'hyvaksynta-ja-maksaminen'
          }
        }

        if (
          tosite.paymentStatus === PaymentStatus.ASIAKAS_HYLKASI ||
          tosite.paymentStatus === PaymentStatus.MAKSETTU ||
          tosite.paymentStatus === PaymentStatus.MAKSETTU_TOISAALLA
        ) {
          viewType = 'lukossa'
        }
        return viewType
      }),
      tap<ViewType>(viewType => this._currentViewType = viewType)
    )

    const virtuaaliviivakoodiObservable = this.tositeObservable.pipe(
      map(maksu => {
        if (!maksu) {
          return null
        }
        const params: VirtuaaliviivakoodinOsat = {
          erapvm: this._getDateOrCurrentIfInPast(maksu.dueDate),
          iban: maksu.iban,
          summa: new Big(maksu.sum),
          viitenumero: maksu.viitenumero
        }
        return this._viitenumeroService.luoSuomalainenVirtuaaliviivakoodi(params)
      })
    )

    const qrkoodiObservable = this.tositeObservable.pipe(
      map(maksu => {
        if (!maksu) {
          return null
        }
        const params: LaskunQrKoodinOsat = {
          serviceTag: 'BCD',
          version: '001',
          charset: LaskunQrKoodinCharacterSet.UTF8,
          identificationCode: 'SCT',
          bic: maksu.bic,
          iban: maksu.iban,
          maksunSaajaNimi: maksu.seller,
          valuutta: maksu.currency || 'EUR',
          summa: maksu.sum,
          purposeCode: null,
          viitenumero: maksu.viitenumero,
          erapaiva: this._getDateOrCurrentIfInPast(maksu.dueDate),
          remittanceInfo: null,
          lisatiedot: null
        }
        return this._viitenumeroService.luoSuomalainenQrKoodi(params)
      })
    )

    this.kooditObservable = combineLatest([virtuaaliviivakoodiObservable, qrkoodiObservable]).pipe(
      map(([viiva, qr]) => {
        return { viiva: viiva, qr: qr }
      })
    )

    this.maksuHyvaksyttyObservable = combineLatest([this.tositeObservable, this._kayttajaService.nykyinenAsiakasObservable]).pipe(
      map(([tosite, asiakas]) => {
        if (!tosite || !asiakas) {
          return false
        }
        if (!asiakas.paymentsNeedsApproval || tosite.paymentStatus === PaymentStatus.MAKSUSSA || tosite.paymentStatus === PaymentStatus.MAKSETTU_TOISAALLA || tosite.paymentStatus === PaymentStatus.ASIAKAS_HYLKASI || tosite.paymentStatus === PaymentStatus.MAKSETTU) {
          return true
        }
        return this._onkoTositeHyvaksytty(tosite, asiakas)
      })
    )

    this.logItemsObservable = combineLatest([
      this.tositeObservable,
      this._translationService.currentLanguageObservable,
      this._kayttajaService.nykyinenAsiakasObservable
    ]).pipe(
      map(([tosite, kieli, asiakas]) => {

        if (!tosite || !kieli || !asiakas) {
          return []
        }

        const amountOfApprovalsRequired = asiakas.paymentsNumberOfApprovalsRequired ?? 1
        const approvals = Object.values(tosite.einvoiceApprovals ?? {}).sort((a, b) => a.approved.toMillis() - b.approved.toMillis())

        const tiedot: Hyvaksyntatieto[] = []
        if (asiakas.paymentsNeedsApproval) {
          for (let approvalNumber = 0; approvalNumber < amountOfApprovalsRequired; approvalNumber++) {
            if (approvals.length > approvalNumber && approvals[approvalNumber]) {
              const approval = approvals[approvalNumber]
              const text = this._translationService.lokalisoiKielella('maksut.katsele.hyvaksytty', kieli) + ' ' + this._dateService.muotoilePaivaJaAikaDate(approval.approved.toDate(), kieli) + ' ' + approval.firstName + ' ' + approval.lastName
              tiedot.push({ placeholder: false, text: text })
            } else {
              tiedot.push({ placeholder: true, text: (approvalNumber + 1) + '. ' + this._translationService.lokalisoiKielella('maksut.katsele.hyvaksynta-puuttuu', kieli) })
            }
          }
        } else {
          for (const approval of approvals) {
            const text = this._translationService.lokalisoiKielella('maksut.katsele.hyvaksytty', kieli) + ' ' + this._dateService.muotoilePaivaJaAikaDate(approval.approved.toDate(), kieli) + ' ' + approval.firstName + ' ' + approval.lastName
            tiedot.push({ placeholder: false, text: text })
          }
        }

        return tiedot

      })
    )

    combineLatest([this.maksuaMuokataanObservable, this.viewTypeObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([muokataan, viewType]) => {
      if (
        muokataan && (
          viewType === 'maksaminen' ||
          viewType === 'hyvaksynta' ||
          viewType === 'hyvaksynta-ja-maksaminen'
        )
      ) {
        this.erapvm.enable()
        this.summa.enable()
        this.viesti.enable()
        this.viite.enable()
        this.bic.enable()
        this.iban.enable()
        this.saaja.enable()
      } else {
        this.erapvm.disable()
        this.summa.disable()
        this.viesti.disable()
        this.viite.disable()
        this.bic.disable()
        this.iban.disable()
        this.saaja.disable()
      }
    })

    combineLatest([this._kayttajaService.kayttajanTiedotObservable, this.tositeObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([kayttajanTiedot, tosite]) => {

      if (!tosite) {
        return
      }

      await this._startMaksunTiedotForm(tosite)

      this.kuvat = this._tositeKuvaCacheService.annaKuvienUrlit(tosite, kayttajanTiedot, 'full')
      this.alkuperaiset = this.annaAlkuperaiset(tosite)

    })

    this.naytaHyvaksyNappiObservable = combineLatest([
      this.tositeObservable,
      this._kayttajaService.kayttajanTiedotObservable,
      this.viewTypeObservable,
      this.maksuHyvaksyttyObservable
    ]).pipe(
      map(([tosite, kayttaja, viewType, hyvaksytty]) => {
        if (!viewType || !tosite?.avain || !kayttaja?.uid) {
          return false
        }
        if (hyvaksytty) {
          return false
        }
        if (viewType === 'hyvaksynta' || viewType === 'hyvaksynta-ja-maksaminen') {
          if (
            tosite.paymentStatus === PaymentStatus.AVOIN ||
            tosite.paymentStatus === PaymentStatus.ERAANTYNYT ||
            tosite.paymentStatus === PaymentStatus.PANKKI_HYLKASI
          ) {
            if (!tosite.einvoiceApprovals || !tosite.einvoiceApprovals[kayttaja.uid]) {
              return true
            }
          }
        }
        return false
      })
    )
    this.naytaHyvaksynnanPeruminenNappiObservable = combineLatest([
      this.tositeObservable,
      this._kayttajaService.kayttajanTiedotObservable
    ]).pipe(
      map(([tosite, kayttaja]) => {
        if (!tosite || !kayttaja) {
          return false
        }
        if (
          tosite.paymentStatus === PaymentStatus.AVOIN ||
          tosite.paymentStatus === PaymentStatus.ERAANTYNYT ||
          tosite.paymentStatus === PaymentStatus.PANKKI_HYLKASI
        ) {
          if (tosite.einvoiceApprovals && kayttaja.uid) {
            return !!tosite.einvoiceApprovals[kayttaja.uid]
          }
        }
        return false
      })
    )

    this.naytaKasiteltyNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        if (!tosite) {
          return false
        }
        return (tosite.paymentStatus === PaymentStatus.AVOIN || tosite.paymentStatus === PaymentStatus.ERAANTYNYT) && tosite.sum <= 0
      })
    )
    this.naytaKasittelynPeruminenNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        if (!tosite) {
          return false
        }
        return tosite.paymentStatus === PaymentStatus.KASITELTY && tosite.sum <= 0
      })
    )

    this.naytaHylkaaNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        if (!tosite) {
          return false
        }
        return tosite.paymentStatus === PaymentStatus.AVOIN || tosite.paymentStatus === PaymentStatus.ERAANTYNYT || tosite.paymentStatus === PaymentStatus.PANKKI_HYLKASI
      })
    )
    this.naytaHylkaamisenPeruminenNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        if (!tosite) {
          return false
        }
        return tosite.paymentStatus === PaymentStatus.ASIAKAS_HYLKASI
      })
    )
    this.naytaMaksettuMuuallaNappiVainVastaanottoObservable = combineLatest([
      this.tositeObservable,
      this.viewTypeObservable
    ]).pipe(
      map(([tosite, viewType]) => {
        if (!tosite || !viewType) {
          return false
        }
        const naytaMaksettuMuuallaTilat = [PaymentStatus.AVOIN, PaymentStatus.ERAANTYNYT, PaymentStatus.PANKKI_HYLKASI]
        return viewType === 'vastaanotto' && naytaMaksettuMuuallaTilat.includes(tosite.paymentStatus) && tosite.sum > 0
      })
    )
    this.naytaMaksettuMuuallaNappiMaksutKaytossaObservable = combineLatest([
      this.tositeObservable,
      this.viewTypeObservable,
      this.maksuHyvaksyttyObservable
    ]).pipe(
      map(([tosite, viewType, hyvaksytty]) => {
        if (!tosite || !viewType) {
          return false
        }
        const naytaMaksettuMuuallaTilat = [PaymentStatus.AVOIN, PaymentStatus.ERAANTYNYT, PaymentStatus.PANKKI_HYLKASI]
        return hyvaksytty && (viewType === 'maksaminen' || viewType === 'hyvaksynta-ja-maksaminen') && naytaMaksettuMuuallaTilat.includes(tosite.paymentStatus) && tosite.sum > 0
      })
    )

    this.naytaMaksettuMuuallaPeruminenNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        if (!tosite) {
          return false
        }
        return tosite.paymentStatus === PaymentStatus.MAKSETTU_TOISAALLA && tosite.sum > 0
      })
    )

    this.naytaMuokkaaMaksuaNappiObservable = combineLatest([
      this.tositeObservable,
      this.viewTypeObservable
    ]).pipe(
      map(([tosite, viewType]) => {
        if (!tosite || !viewType || viewType === 'lukossa' || viewType === 'vastaanotto') {
          return false
        }
        return tosite.paymentStatus === PaymentStatus.AVOIN || tosite.paymentStatus === PaymentStatus.ERAANTYNYT || tosite.paymentStatus === PaymentStatus.PANKKI_HYLKASI
      })
    )

  }

  private _onkoTositeHyvaksytty(tosite: FirestoreTosite, asiakas: Asiakas): boolean {
    return Object.values(tosite?.einvoiceApprovals ?? {}).length >= (asiakas?.paymentsNumberOfApprovalsRequired ?? 1)
  }

  private _validateViestiTaiViite(other: AbstractControl): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      if (
        (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') &&
        (other.value == null || other.value === undefined || other.value.trim() === '')
      ) {
        return { viestitaiviite: true }
      }
      return null
    }
  }

  private _validateIban: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._ibanService.isValidIban(ctrl.value)) {
      return { invalidiban: true }
    }
  }

  private _validateBic: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._bicService.validoiBic(ctrl.value)) {
      return { invalidbic: true }
    }
  }

  private _validateViitenumero: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._viitenumeroService.onkoViitenumeroValidi(ctrl.value)) {
      return { viitenumero: true }
    }
    return null
  }

  private _getDateOrCurrentIfInPast(date: NumberDate): LocalDate {
    if (this._dateService.compareNumberDates(date, '<', this._dateService.currentNumberDate())) {
      return this._dateService.currentLocalDate()
    }
    return this._dateService.numberToLocalDate(date)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }


  get erapvm() {
    return this.maksunTiedotForm.get('erapvm')
  }
  get summa() {
    return this.maksunTiedotForm.get('summa')
  }
  get viite() {
    return this.maksunTiedotForm.get('viite')
  }
  get viesti() {
    return this.maksunTiedotForm.get('viesti')
  }
  get lisatiedot() {
    return this.maksunTiedotForm.get('lisatiedot')
  }
  get iban() {
    return this.maksunTiedotForm.get('iban')
  }
  get bic() {
    return this.maksunTiedotForm.get('bic')
  }
  get saaja() {
    return this.maksunTiedotForm.get('saaja')
  }

  // lataaKuitti() {
  //   this._ladataanService.aloitaLataaminen()
  //   return this._httpService.getBinary('/kuittiLataaPdf?a=' + encodeURIComponent('/api/1/kuitit/lataa/pdf/' + this.tosite.avain), LEMONAID_CF_API).then(result => {
  //     const nimi = this._tositeService.annaPdfTiedostonNimi(this.tosite, this._translationService.nykyinenKieli, this.lahde)
  //     this._ladataanService.lopetaLataaminen()
  //     saveAs(result, nimi, { autoBom: false })
  //   }).catch(err => {
  //     this._ladataanService.lopetaLataaminen()
  //     this._errorHandler.handleError(err)
  //   })
  // }

  async peruuta() {
    const segments = await firstValueFrom(this._route.url)
    segments.pop()

    const tosite = await firstValueFrom(this.tositeObservable)
    const maksetut = [PaymentStatus.ASIAKAS_HYLKASI, PaymentStatus.MAKSETTU, PaymentStatus.MAKSETTU_TOISAALLA, PaymentStatus.KASITELTY]
    const queryParams = maksetut.includes(tosite?.paymentStatus) ? { tab: 'maksetut' } : {}

    this._router.navigate(segments.map(segment => segment.path), {
      queryParams: queryParams
    })
  }

  private annaAlkuperaiset(tosite: FirestoreTosite): FirestoreTositteenAlkuperainenTiedosto[] {
    if (tosite.alkuperaiset) {
      const alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[] = []
      for (const alkuperaisenAvain of Object.keys(tosite.alkuperaiset)) {
        const alkuperainen = tosite.alkuperaiset[alkuperaisenAvain] as FirestoreTositteenAlkuperainenTiedosto
        alkuperaiset.push(alkuperainen)
      }
      return alkuperaiset
    }
    return null
  }

  private _saveInFlight = false
  async merkitseKasitellyksi(tosite: FirestoreTosite) {
    try {
      // const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      // const peruste = this._asiakasJaettuService.annaKirjanpidonPeruste(asiakas, this._dateService.currentLocalDate())

      if (this._saveInFlight) {
        return
      }
      this._saveInFlight = true

      /** Use copy since the save may be cancelled in the dialog */
      const copy = this._tositeKopioija.kopioiTosite(tosite)
      copy.paymentStatus = PaymentStatus.KASITELTY
      copy.paymentMaksutapa = null

      // const data: MaksutMaksupaivaDialogData = { kuitti: copy, kirjanpidonPeruste: peruste }
      // this._dialog.open<MaksutMaksupaivaDialog, MaksutMaksupaivaDialogData>(MaksutMaksupaivaDialog, { data: data })

      this._ladataanService.aloitaLataaminen()
      try {
        // if (this.data.kirjanpidonPeruste === KirjanpidonPeruste.MAKSU) {
        //   const paymentDateLocal = this._dateService.numberToLocalDate(this._kuitti.paymentDate)
        //   const paymentDateTs = this._timestampService.localDateToTimestamp(paymentDateLocal)

        //   this._kuitti.p = this._kuitti.paymentDate
        //   this._kuitti.pvm = paymentDateTs
        //   this._kuitti.localPvm = paymentDateLocal
        // }
        await this._tositeService.tallennaKuitti(copy)
      } catch (err) {
        this._errorHandler.handleError(err)
        this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
      } finally {
        this._saveInFlight = false
        this._ladataanService.lopetaLataaminen()
      }

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    }
  }

  async maksettuMuuallaMaksutKaytossa(tosite: FirestoreTosite) {
    try {
      const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const peruste = this._asiakasJaettuService.annaKirjanpidonPeruste(asiakas, this._dateService.currentLocalDate())

      /** Use copy since the save may be cancelled in the dialog */
      const copy = this._tositeKopioija.kopioiTosite(tosite)
      copy.paymentStatus = PaymentStatus.MAKSETTU_TOISAALLA
      copy.paymentMaksutapa = null
      const data: MaksutMaksupaivaDialogData = { kuitti: copy, kirjanpidonPeruste: peruste }

      this._dialog.open<MaksutMaksupaivaDialog, MaksutMaksupaivaDialogData>(MaksutMaksupaivaDialog, { data: data })

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    }
  }

  async maksettuYrityksenTililtaVainVastaanotto(tosite: FirestoreTosite) {

    try {
      const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const peruste = this._asiakasJaettuService.annaKirjanpidonPeruste(asiakas, this._dateService.currentLocalDate())

      /** Use copy since the save may be cancelled in the dialog */
      const copy = this._tositeKopioija.kopioiTosite(tosite)
      copy.paymentStatus = PaymentStatus.MAKSETTU_TOISAALLA
      copy.paymentMaksutapa = null
      const data: MaksutMaksupaivaDialogData = { kuitti: copy, kirjanpidonPeruste: peruste }

      this._dialog.open<MaksutMaksupaivaDialog, MaksutMaksupaivaDialogData>(MaksutMaksupaivaDialog, { data: data })

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    }
  }

  async maksettuOmallaRahallaVainVastaanotto(tosite: FirestoreTosite) {

    try {
      const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const peruste = this._asiakasJaettuService.annaKirjanpidonPeruste(asiakas, this._dateService.currentLocalDate())

      /** Use copy since the save may be cancelled in the dialog */
      const copy = this._tositeKopioija.kopioiTosite(tosite)
      copy.paymentStatus = PaymentStatus.MAKSETTU_TOISAALLA
      // copy.paymentMaksutapa = null
      const data: MaksutMaksutapaDialogData = { kuitti: copy, milla: 'omalla', kirjanpidonPeruste: peruste }
      this._dialog.open(MaksutMaksutapaDialog, { data: data })
    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    }

  }

  async maksettuToisaallaPeru(tosite: FirestoreTosite) {
    this._ladataanService.aloitaLataaminen()
    try {

      const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const peruste = this._asiakasJaettuService.annaKirjanpidonPeruste(asiakas, this._dateService.currentLocalDate())

      const eraantynyt = this._dateService.compareNumberDates(tosite.dueDate, '<', this._dateService.currentNumberDate())
      tosite.paymentStatus = eraantynyt ? PaymentStatus.ERAANTYNYT : PaymentStatus.AVOIN
      tosite.paymentMaksutapa = null
      tosite.paymentDate = null

      if (peruste === KirjanpidonPeruste.MAKSU) {
        const invoiceDateLocal = this._dateService.numberToLocalDate(tosite.invoiceDate)
        const invoiceDateTs = this._timestampService.localDateToTimestamp(invoiceDateLocal)

        tosite.p = tosite.invoiceDate
        tosite.pvm = invoiceDateTs
        tosite.localPvm = invoiceDateLocal
      }

      await this._tositeService.tallennaKuitti(tosite)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  async approvePayment(event?: KeyboardEvent) {
    if (this._maksuaMuokataanSubject.value) {
      return
    }
    if (event?.target) {
      const asHtmlElement = event.target as HTMLElement
      if (asHtmlElement.tagName === 'TEXTAREA' || asHtmlElement.tagName === 'INPUT') {
        return
      }
    }

    this._ladataanService.aloitaLataaminen()

    try {

      if (this._currentViewType === 'hyvaksynta' || this._currentViewType === 'hyvaksynta-ja-maksaminen') {

        const tosite = await firstValueFrom(this.tositeObservable)
        const kayttaja = await this._kayttajaService.getKayttaja()

        if (!tosite.einvoiceApprovals) {
          tosite.einvoiceApprovals = {}
        }

        tosite.einvoiceApprovals[kayttaja.avain] = {
          approved: this._timestampService.now(),
          firstName: kayttaja.etunimi,
          lastName: kayttaja.sukunimi
        }

        await this._tositeService.tallennaKuitti(tosite)

      }

      if (event) {
        this.navigoiSeuraavaan()
      }

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  async revokePaymentApproval(tosite: FirestoreTosite) {

    this._ladataanService.aloitaLataaminen()

    try {

      const kayttaja = await this._kayttajaService.getKayttaja()

      if (!tosite.einvoiceApprovals) {
        tosite.einvoiceApprovals = {}
      }

      if (tosite.einvoiceApprovals[kayttaja.avain]) {
        delete tosite.einvoiceApprovals[kayttaja.avain]
      }

      await this._tositeService.tallennaKuitti(tosite)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  async rejectPayment(tosite: FirestoreTosite) {
    this._ladataanService.aloitaLataaminen()

    try {

      tosite.paymentStatus = PaymentStatus.ASIAKAS_HYLKASI
      tosite.poistettu = true
      await this._tositeService.tallennaKuitti(tosite)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  async rejectPaymentPeru(tosite: FirestoreTosite) {
    this._ladataanService.aloitaLataaminen()

    try {
      tosite.paymentStatus = tosite.dueDate < this._dateService.currentNumberDate() ? PaymentStatus.ERAANTYNYT : PaymentStatus.AVOIN
      tosite.poistettu = false
      await this._tositeService.tallennaKuitti(tosite)
    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  cancelPaymentDetailsChanges(tosite: FirestoreTosite) {
    this._maksuaMuokataanSubject.next(false)

    const maksutiedot = this._tositeKopioija.annaMaksutiedot(tosite)
    this.erapvm.setValue(this._dateService.numberToDate(maksutiedot.dueDate))
    this.summa.setValue(maksutiedot.amount)
    this.viite.setValue(maksutiedot.reference)
    this.viesti.setValue(maksutiedot.message)
    this.lisatiedot.setValue(tosite.selite)
    this.iban.setValue(maksutiedot.iban)
    this.bic.setValue(maksutiedot.bic)
    this.saaja.setValue(maksutiedot.maksunSaajanNimi)

  }

  async savePaymentDetailsChanges(tosite: FirestoreTosite) {

    if (!this.maksunTiedotForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.maksunTiedotForm)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {

      const maksutiedot = this._tositeKopioija.annaMaksutiedot(tosite)

      maksutiedot.dueDate = this._dateService.dateToNumber(this.erapvm.value)
      maksutiedot.amount = this.summa.value
      maksutiedot.reference = this.viite.value ?? null
      maksutiedot.message = this.viesti.value ?? null
      tosite.selite = this.lisatiedot.value?.trim() ?? null
      maksutiedot.iban = this.iban.value ?? null
      maksutiedot.bic = this.bic.value ?? null
      maksutiedot.maksunSaajanNimi = this.saaja.value ?? null

      tosite.maksutiedot = maksutiedot

      await this._tositeService.tallennaKuitti(tosite)
      this.setMaksuaMuokataan(false)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private async _startMaksunTiedotForm(tosite: FirestoreTosite) {
    const maksutiedot = this._tositeKopioija.annaMaksutiedot(tosite)
    this.erapvm.setValue(this._dateService.numberToDate(maksutiedot.dueDate))
    this.summa.setValue(maksutiedot.amount ?? null)
    this.viite.setValue(maksutiedot.reference ?? null)
    this.viesti.setValue(maksutiedot.message ?? null)
    this.lisatiedot.setValue(tosite.selite ?? null)
    this.iban.setValue(maksutiedot.iban ?? null)
    this.bic.setValue(maksutiedot.bic ?? null)
    this.saaja.setValue(maksutiedot.maksunSaajanNimi ?? null)
  }

  async tabGroupIndexChanged(event: number) {
    const valittuMaksukoodi: UserSettings['vm'] = this._mapTabsToMaksukoodit(event)

    /** Don't return, run in background  */
    firstValueFrom(this._userSettingsService.userSettingsObservable).then(settings => {
      if (settings?.vm === valittuMaksukoodi) {
        return
      }
      return this._kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
        return this._userSettingsService.setValittuMaksukoodi(valittuMaksukoodi, kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid, this._firebaseLemonaid)
      })
    })

  }

  private _mapTabsToMaksukoodit(idx: number): UserSettings['vm'] {
    if (idx === 0) { return 'qr' }
    if (idx === 1) { return 'viiva' }
    if (idx === 2) { return 'virtuaaliviiva' }
    return null
  }

  setMaksuaMuokataan(state: boolean) {
    this._maksuaMuokataanSubject.next(state)
  }

  async saveLisatiedotChange(tosite: FirestoreTosite) {

    try {
      const change = this.lisatiedot.value?.trim() ?? null
      if (change === (tosite.selite ?? null)) {
        return
      }

      tosite.selite = change

      await this._tositeService.tallennaKuitti(tosite)

      this.showLisatiedotCheckMark = true
      setTimeout(() => {
        this.showLisatiedotCheckMark = false
      }, 1500)

    } catch (err) {
      this._errorHandler.handleError(err)
    }
  }

}
