import { ErrorHandler, Injectable } from '@angular/core'

// export interface AsiakaskohtainenCache<T> {
//   cid: number | string,
//   data: T
// }

@Injectable()
export class LocalStorageService {

  constructor(
    private errorHandler: ErrorHandler
  ) { }

  setToLocalStorage<T>(cacheKey: string, item: T) {
    try {
      if (window && window.localStorage) {
        window.localStorage.setItem(cacheKey, JSON.stringify(item))
      }
    } catch (err) {
      console.log('Virhe tallennettaessa local storage tietoa avaimella ', cacheKey)
      this.errorHandler.handleError(err)
    }
  }

  getFromLocalStorage<T>(cacheKey: string): T | null {
    try {
      if (window && window.localStorage) {
        const cacheStr = window.localStorage.getItem(cacheKey)
        if (cacheStr) {
          return JSON.parse(cacheStr) as T
        }
      }
    } catch (err) {
      console.log('Virhe haettaessa local storage tietoa avaimella ', cacheKey)
      this.errorHandler.handleError(err)
    }
    return null
  }

  setToSessionStorage<T>(cacheKey: string, item: T) {
    try {
      if (window && window.sessionStorage) {
        window.sessionStorage.setItem(cacheKey, JSON.stringify(item))
      }
    } catch (err) {
      console.log('Virhe tallennettaessa session storage tietoa avaimella ', cacheKey)
      this.errorHandler.handleError(err)
    }
  }

  deleteFromSessionStorage(cacheKey: string) {
    try {
      if (window && window.sessionStorage) {
        window.sessionStorage.removeItem(cacheKey)
      }
    } catch (err) {
      console.log('Virhe tallennettaessa session storage tietoa avaimella ', cacheKey)
      this.errorHandler.handleError(err)
    }
  }

  getFromSessionStorage<T>(cacheKey: string): T | null {
    try {
      if (window && window.sessionStorage) {
        const cacheStr = window.sessionStorage.getItem(cacheKey)
        if (cacheStr) {
          return JSON.parse(cacheStr) as T
        }
      }
    } catch (err) {
      console.log('Virhe haettaessa session storage tietoa avaimella ', cacheKey)
      this.errorHandler.handleError(err)
    }
    return null
  }

  // setToCache<T>(cacheKey: string, item: T, kayttajanTiedot: KayttajanTiedot) {
  //   try {
  //     if (window && window.localStorage) {
  //       const data: AsiakaskohtainenCache<T> = { cid: kayttajanTiedot.asiakasId, data: item }
  //       window.localStorage.setItem(cacheKey, JSON.stringify(data))
  //     }
  //   } catch (err) {
  //     console.log('Virhe tallennettaessa local storage tietoa avaimella ', cacheKey)
  //     this.errorHandler.handleError(err)
  //   }
  // }

  // getFromCache<T>(cacheKey: string, kayttajanTiedot: KayttajanTiedot): T | null {
  //   try {
  //     if (window && window.localStorage) {
  //       const cacheStr = window.localStorage.getItem(cacheKey)
  //       if (cacheStr) {
  //         const obj = JSON.parse(cacheStr) as AsiakaskohtainenCache<T>
  //         if (obj.cid.toString() === kayttajanTiedot.asiakasId + '') {
  //           return obj.data
  //         }
  //       }
  //     }
  //   } catch (err) {
  //     console.log('Virhe haettaessa local storage tietoa avaimella ', cacheKey)
  //     this.errorHandler.handleError(err)
  //   }
  //   return null
  // }

}
