<div class="all" mat-dialog-content style="padding: 0 6vw;">
  <div class="big-card">
    <div class="light-blue-text title-text" [innerHTML]="'viia-login-required.login-ok.yhdistetty-onnistuneesti' | translate | async"></div>
    <div class="inner-container">
      <div>{{ 'viia-login-required.login-ok.valtuutus-on-voimassa' | translate | async }}</div>
    </div>
    <div (click)="expandExtraText()" class="gray-text lue-lisaa" style="text-align: center;">{{ 'viia-login-required.lue-lisaa' | translate | async }}</div>
    <div class="button-container">
      <button class="primarybutton" (click)="sulje()" mat-button>{{ 'yleiset.jatka' | translate | async }}</button>
    </div>
    <div #selitys class="gray-text valtuutus-extra-text" [hidden]="!extraTextExpanded" [innerHTML]="'viia-login-required.valtuutus-pitka-selitus' | translate | async"></div>
  </div>
</div>