import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, AfterViewInit, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { LoginService, LoginInputData } from '../_angular/service/login.service'
import { VersionTarkistusPalvelu } from '../_angular/service/version-tarkistus.palvelu'

import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { SaveNumberAndSendSmsRequest } from '../_jaettu/model/login'
import { ErrorResponse } from '../_shared-core/model/common'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from '../vaihda-kieli.dialog'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  templateUrl: './add-number.component.html',
  styleUrls: ['./login.component.css']
})
export class AddNumberComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatInput, { static: true }) puhelinInput: MatInput

  puhelinnumeroForm: UntypedFormGroup
  commonError: string
  online = true
  year = new Date().getFullYear()
  inputReceived: LoginInputData

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _validationService: FormValidationService,
    private _translationService: LemonTranslationService,
    private _versionTarkistaja: VersionTarkistusPalvelu,
    private _loginService: LoginService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _versionTarkistusPalvelu: VersionTarkistusPalvelu,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    // Preheat
    this._firebaseLemonaid.functionsCall<string, void>('loginTallennaPuhelinOngoingiinJaLahettaaSms', '')
  }

  ngOnInit(): void {

    this._loginService.currentLoginCredentialsObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(data => {
      this.inputReceived = data
      if (
        !this.inputReceived ||
        !this.inputReceived.email ||
        !this.inputReceived.password ||
        !this.inputReceived.loginCodeId
      ) {
        this.commonError = this._translationService.lokalisoi('login.login-out-of-date')
      }
    })

    this._versionTarkistaja.sovelluksenVersioObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(versiotiedot => {
      this._versionTarkistaja.tarkistaVersio(versiotiedot)
    })

    this.puhelinnumeroForm = new UntypedFormGroup({
      'puhelin': new UntypedFormControl('+358 ', [
        Validators.required,
        Validators.minLength(10),
        LemonaidValidators.phoneNumberValidator
      ])
    })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.puhelinInput.focus() }, 0)
  }

  public get puhelinFormControl(): AbstractControl {
    return this.puhelinnumeroForm.get('puhelin')
  }
  setLoginProcessStarted() {
    this._ladataanService.aloitaLataaminen()
  }

  setLoginProcessEnded() {
    this._ladataanService.lopetaLataaminen()
  }

  back() {
    this._router.navigate(['/kirjaudu'])
  }

  @HostListener('document:keydown.enter')
  tallenna() {
    if (!this.puhelinnumeroForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.puhelinnumeroForm)
      return
    }
    this.setLoginProcessStarted()
    const puhelinData: SaveNumberAndSendSmsRequest = {
      email: this.inputReceived.email,
      password: this.inputReceived.password,
      puhelinnumero: String(this.puhelinnumeroForm.get('puhelin').value).replace(/\s/g, ''),
      loginCodeId: this.inputReceived.loginCodeId,
      valittuKieli: this._translationService.nykyinenKieli,
      env: 'w',
      ver: this._versionTarkistusPalvelu.annaVersio(),
      device: navigator.platform,
      os: navigator.userAgent
    }

    this._firebaseLemonaid.functionsCall<SaveNumberAndSendSmsRequest, ErrorResponse>('loginTallennaPuhelinOngoingiinJaLahettaaSms', puhelinData).then(errorResponse => {

      if (!errorResponse) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
        this._errorHandler.handleError('no-response-or-no-data')
        this.setLoginProcessEnded()
        return
      }

      if (errorResponse.e) {
        this.setLoginProcessEnded()
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
        this._errorHandler.handleError(errorResponse.e)
      } else {
        this.setLoginProcessEnded()
        this._router.navigate(['/kirjaudu-vahvista'])
      }

    }).catch(err => {
      this.setLoginProcessEnded()
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      this._validationService.merkitseKokoLomakeKosketuksi(this.puhelinnumeroForm)
      this._errorHandler.handleError(err)
    })
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this._dialog.open(VaihdaKieliDialog, { data: data })
  }
}
