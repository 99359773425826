<picture>
  <source srcset="/assets/taustat/login_720x512_crushed.webp 720w, /assets/taustat/login_1440x1024_crushed.webp 1440w, /assets/taustat/login_2880x2048_crushed.webp 2880w, /assets/taustat/login_4320x3072_crushed.webp 4320w" type="image/webp" sizes="100vw">
  <source srcset="/assets/taustat/login_720x512_crushed.png 720w, /assets/taustat/login_1440x1024_crushed.png 1440w, /assets/taustat/login_2880x2048_crushed.png 2880w, /assets/taustat/login_4320x3072_crushed.png 4320w" type="image/png" sizes="100vw">
  <img src="/assets/taustat/login_720x512_crushed.png" class="tausta">
</picture>

<div class="all-container">

  <mat-nav-list style="position: absolute; left: 4px; top: 4px; padding-top: 0;">
    <mat-list-item (click)="vaihdaKieli()">
      <div style="text-align: center; width: 100%; color:black;">Kieli /<!-- Språk /--> Language</div>
    </mat-list-item>
  </mat-nav-list>

  <div class="login-container">
    <form [formGroup]="loginForm" novalidate>
      <div class="mat-elevation-z3 top-corners-rounded bottom-corners-rounded">
        <div class="top-corners-rounded" style="padding: 2px;">
          <div class="valkoinen logo-container top-corners-rounded" style="font-size: 28px;">
            {{ 'change.vaihda' | translate | async }}
          </div>
        </div>
        <div class="content-container">
          <div class="login-controls">
            <div *ngIf="!success" style="max-width: 300px; width: 80%;">
              <div class="top-login-spacer-top"></div>
              <div class="red-text common-error" *ngIf="commonError">{{commonError}}</div>
              <div class="top-login-spacer-bottom"></div>
              <mat-form-field>
                <mat-label>{{'change.old-password' | translate | async}}</mat-label>
                <input #oldpassword name="current-password" type="password" formControlName="oldpassword" autocomplete="current-password" matInput required />
                <i matPrefix class="fa fa-lock fa-kuva" aria-hidden="true"></i>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{'change.new-password' | translate | async}}</mat-label>
                <input name="new-password" type="password" formControlName="newpassword" autocomplete="new-password" matInput required />
                <i matPrefix class="fa fa-lock fa-kuva" aria-hidden="true"></i>
              </mat-form-field>
              <div class="vihjeet">
                <div [class.oikein]="!virheet.number" [class.green-text]="!virheet.number">{{ 'reset.validation.number' | translate | async }}</div>
                <div [class.oikein]="!virheet.lettersmall" [class.green-text]="!virheet.lettersmall">{{ 'reset.validation.lettersmall' | translate | async }}</div>
                <div [class.oikein]="!virheet.letterbig" [class.green-text]="!virheet.letterbig">{{ 'reset.validation.letterbig' | translate | async }}</div>
                <div [class.oikein]="!virheet.differentchars" [class.green-text]="!virheet.differentchars">{{ 'reset.validation.differentchars' | translate | async }}</div>
                <div [class.oikein]="!virheet.min" [class.green-text]="!virheet.min">{{ 'reset.validation.min' | translate | async }}</div>
              </div>
            </div>
            <div style="padding: 5px;" class="green-text" *ngIf="success">{{ 'reset.onnistui' | translate | async }}</div>
          </div>
          <div class="button-container">
            <div>
              <button type="button" *ngIf="!success" class="secondarybutton" mat-button (click)="back()">{{ 'yleiset.peruuta' | translate | async }}</button>
              <span *ngIf="!success" class="button-spacer"></span>
              <button type="button" *ngIf="!success" class="primarybutton" mat-button (click)="changePassword()" [disabled]="!loginForm.valid">{{ 'reset.vaihda' | translate | async }}</button>
              <button type="button" *ngIf="success" class="primarybutton" mat-button (click)="back()">{{ 'yleiset.ok' | translate | async }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <span class="disclaimer">© TILITOIMISTO LEMON TREE OY {{year}}</span>

</div>