import { Component, OnInit, AfterViewInit, AfterContentChecked, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'

import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { LaskunListaustietorivi } from '../../_jaettu/model/lasku'
import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'

export interface LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialogData {
  rivi: LaskunListaustietorivi
}

@Component({
  selector: 'lasku-haluatko-varmasti-perua-maksetuksi-merkitsemisen-dialog',
  templateUrl: './lasku.haluatko-varmasti-perua-maksetuksi-merkitsemisen.dialog.html'
})
export class LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog implements OnInit, AfterViewInit, AfterContentChecked {

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialogData,
    private dialogRef: MatDialogRef<LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog>,
    private validationService: FormValidationService,
    private laskuService: LaskuService,
    private errorHandler: ErrorHandler,
    private sharedLaskuService: LaskuSharedService,
    private currencyService: CurrencyService
  ) {

  }

  ngAfterContentChecked() {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  async peru() {
    await this.laskuService.merkitseLaskuTaysinMaksetuksi(this.data.rivi)
    this.dialogRef.close()
  }

}
