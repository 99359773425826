<div *ngIf="tilinpaatosViewDataObservable | async as tilinpaatosViewData else loadingmetadata">

  <ng-container *ngIf="tilinpaatosViewData.tilinpaatosHasBeenExported else notdoneyet">
    <ng-container *ngIf="{ viewData: (poytakirjaViewDataObservable | async) } as poytakirjaViewData">
      <div>
        <button *ngIf="tilinpaatosViewData.pdfPages.length" mat-button class="secondarybutton" style="margin: 25px" (click)="downloadPdf('tilinpaatos')">{{'reports-lemonaid.lataa-tilinpaatos' | translate | async}}</button>
        <button *ngIf="poytakirjaViewData.viewData?.pdfPages?.length" mat-button class="secondarybutton" style="margin: 25px" (click)="downloadPdf('poytakirja')">{{'reports-lemonaid.lataa-poytakirja' | translate | async}}</button>

        <div *ngFor="let page of tilinpaatosViewData.pdfPages" class="lemon-mat-elevation-z4" style="margin: 1em;">
          <img *ngIf="page.getImageObservable() | async as kuva else loadingimage" [src]="kuva" style="width: 100%" />
        </div>
      </div>

      <ng-container *ngIf="poytakirjaViewData.viewData">
        <div>
          <div *ngFor="let page of poytakirjaViewData.viewData.pdfPages" class="lemon-mat-elevation-z4" style="margin: 1em;">
            <img *ngIf="page.getImageObservable() | async as kuva else loadingimage" [src]="kuva" style="width: 100%" />
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="tilinpaatosViewData.editMode">
        <div class="heading light-blue-text">{{ 'reports-lemonaid.allekirjoitukset' | translate | async}}</div>

        <p [innerHTML]="'reports-lemonaid.tilinpaatoksen-allekirjoittaa' | translate | async"></p>

        <p>{{ 'reports-lemonaid.hyvaksymisesta-on-laadittava' | translate | async}}</p>

        <p>{{ 'reports-lemonaid.alla-on-listattuna' | translate | async}}</p>

        <form [formGroup]="form">
          <p style="font-weight: bold; margin: 20px 0;">{{ 'reports-lemonaid.tilinpaatoksen-allekirjoittavat-henkilot' | translate | async }}</p>

          <div formArrayName="boardMembers">
            <div class="signer-row" *ngFor="let boardMember of boardMembersControls; let i = index" [formGroupName]="i">

              <!-- <mat-checkbox class="space-on-right" formControlName="selected"></mat-checkbox> -->

              <div [class.disabled-user]="boardMember.disabled" style="width: 200px;" class="space-on-right">{{ boardMember.get('name').value }}</div>

              <mat-form-field class="space-on-right" style="width: 250px;">
                <mat-label>{{ 'reports-lemonaid.rooli' | translate | async }}</mat-label>
                <mat-select formControlName="role" required>
                  <mat-option *ngFor="let role of allBoardMemberRolesObservable | async" [value]="role.key">
                    {{ role.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-error style="max-width: 400px; font-size: 12px;" *ngIf="boardMember.disabled && boardMember?.get('role')?.value !== 'varajasen'">{{ 'reports-lemonaid.no-lemonaid-user' | translate | async }}</mat-error>

            </div>
          </div>

          <p style="font-weight: bold; margin: 20px 0;">{{ 'reports-lemonaid.hyvaksymispoytakirjan-tiedot' | translate | async }}</p>

          <div style="display: flex; flex-direction: row;">
            <mat-form-field style="width: 10rem">
              <mat-label>{{ 'reports-lemonaid.pvm' | translate | async }}</mat-label>
              <input matInput [matDatepicker]="dp" formControlName="boardMinutesDate">
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>

            <mat-form-field style="width: 15rem; margin-left: 4.5rem;">
              <mat-label>{{ 'reports-lemonaid.poytakirjan-numero' | translate | async }}</mat-label>
              <input type="text" formControlName="boardMinutesNumber" matInput required />
            </mat-form-field>
          </div>
        </form>

        <div class="red-text common-error" style="margin-top: 20px; text-align: center;" *ngIf="commonError">{{commonError}}</div>

        <div class="button-container">
          <button mat-button class="primarybutton" style="padding-left: 1rem; padding-right: 1rem;" [disabled]="tilinpaatosSigningDisabledObservable | async" (click)="approveSigners()">{{ 'reports-lemonaid.hyvaksy-valinta' | translate | async}}</button>
        </div>

      </ng-container>

      <div *ngIf="!tilinpaatosViewData.editMode && !tilinpaatosViewData.signed">

        <div class="heading light-blue-text">{{ 'reports-lemonaid.allekirjoitukset' | translate | async}}</div>

        <div class="btn-on-right-container" *ngIf="tilinpaatosMissingSignaturesObservable | async">
          <button mat-button class="gray-text-btn" (click)="editSigners()">{{ 'reports-lemonaid.muokkaa-allekirjoittajia' | translate | async }}</button>
        </div>

        <div class="signers-container">
          <div *ngFor="let signer of approvedSignersObservable | async">
            <!-- Top text if signed -->
            <div *ngIf="!!signer.signedAt">
              <div style="display: flex; flex-direction: row; color: gray">
                <mat-icon style="font-size: 40px; width: 2.5rem; height: 2.5rem">lock</mat-icon>
                <div>
                  <div>{{ 'reports-lemonaid.allekirjoitettu-sahkoisesti' | translate | async }}</div>
                  <div>{{ signer.name }} {{ signer.signedAt | ltimestamp | async }} </div>
                </div>
              </div>
            </div>

            <!-- Top text if not signed and current user -->
            <div *ngIf="!signer.signedAt && signer.isCurrentUser">
              <div style="display: flex; flex-direction: row; color: gray">
                <button mat-button class="primarybutton" style="padding-left: 1rem; padding-right: 1rem;" (click)="saveSignature()">{{ 'reports-lemonaid.allekirjoita' | translate | async}}</button>
              </div>
            </div>

            <!-- Top text if not signed and not current user -->
            <div *ngIf="!signer.signedAt && !signer.isCurrentUser">
              <div style="display: flex; flex-direction: row; color: gray; height: 44px; align-items: center; ">
                <div class="red-text">{{ 'reports-lemonaid.allekirjoitus-puuttuu' | translate | async}}</div>
              </div>
            </div>


            <!-- Border and underline text -->
            <div class="underline-info">
              <div>{{ signer.name }} </div>
              <div style="white-space: pre-wrap;">{{ signer.rolesLocalized }}</div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
  </ng-container>

</div>

<ng-template #notdoneyet>
  <div style="padding: 70px 20px; text-align: center;">{{ 'reports-lemonaid.tilinpaatos-ei-viela-tehty' | translate | async}}</div>
</ng-template>

<ng-template #loadingmetadata>
  <div style="text-align: center; padding: 50px 0;">
    <mat-spinner style="display: inline-block;"></mat-spinner>
    <div style="text-align: center; padding: 20px 0;">{{ 'reports-lemonaid.tilinpaatoksen-tietoja-ladataan' | translate | async}}</div>
  </div>
</ng-template>

<ng-template #loadingimage>
  <div style="text-align: center; padding: 50px 0;">
    <mat-spinner style="display: inline-block;"></mat-spinner>
  </div>
</ng-template>

<!-- <ng-template #loadingdocument>
  <div style="text-align: center; padding: 50px 0;">
    <mat-spinner style="display: inline-block;"></mat-spinner>
    <div style="text-align: center; padding: 20px 0;">{{ 'reports-lemonaid.tilinpaatos-asiakirjaa-ladataan' | translate | async }}</div>
  </div>
</ng-template> -->