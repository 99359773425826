import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'

import { AsiakkaanTuote } from '../../_jaettu/model/lasku'
import { TuoteComponentDataResolve } from '../../_angular/_resolvers/lasku.resolve'

import { TuotteetFirestoreDataSource } from './tuotteet.firestore.datasource'

import { Observable, combineLatest } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'

export type TuoteProperties = 'hinta' | 'nimi' | undefined

@Component({
  selector: 'app-tuotteet',
  templateUrl: './tuotteet.component.html',
  styleUrls: ['./tuotteet.component.css']
})
export class TuotteetComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) _paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) _sort: MatSort

  form: UntypedFormGroup
  propertiesToDisplay: TuoteProperties[] = ['nimi', 'hinta']
  name = 'e' + Math.random()

  naytaEiYhtaanObservable: Observable<boolean>
  naytettavatTuotteetObservable: Observable<AsiakkaanTuote[]>
  lataaObservable: Observable<boolean>

  dataSource: TuotteetFirestoreDataSource

  constructor(
    private _tuoteComponentDataResolve: TuoteComponentDataResolve,
    private _router: Router,
    private _dataSource: TuotteetFirestoreDataSource
  ) {

    this.dataSource = this._dataSource
    this.lataaObservable = this._dataSource.lataaObservable
    this.naytettavatTuotteetObservable = this._dataSource.asiakkaanTuotteetObservable
    this.naytaEiYhtaanObservable = combineLatest([this._dataSource.rivienMaaraObservable, this._dataSource.lataaObservable]).pipe(
      map(([rivienMaara, lataa]) => {
        if (lataa) {
          return false
        }
        return rivienMaara < 1
      })
    )

    this.form = new UntypedFormGroup({
      'nimi': new UntypedFormControl(this._dataSource.getSearch(), [])
    })

    this.form.get('nimi').valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        const val = value.trim().toLowerCase()
        if (val.length > 2) {
          this._dataSource.changeSearch(val)
        } else {
          this._dataSource.changeSearch(null)
        }
      } else {
        this._dataSource.changeSearch(null)
      }
    })

  }

  ngOnInit() {

    const currentSort = this._dataSource.getSort()
    this._sort.sort({
      id: currentSort.active,
      disableClear: true,
      start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    })
    this._sort.sortChange.subscribe(sort => {
      this._dataSource.changeSort(sort)
    })

    this._paginator.page.subscribe(page => {
      this._dataSource.changePage(page)
    })

  }

  muokkaa(tuote: AsiakkaanTuote) {
    this._tuoteComponentDataResolve.asetaOlemassaolevaData(tuote)
    this._router.navigate(['/laskutus/tuotteet/', tuote.$key])
  }

  lisaa() {
    this._router.navigate(['/laskutus/tuotteet/', 'uusi'])
  }

}
