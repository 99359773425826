import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'

import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { AsiakkaanTuote } from '../../_jaettu/model/lasku'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'

import { TuotePoistoVarmistusDialog, TuotePoistoVarmistusDialogData } from './tuote-poisto-varmistus.dialog'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-tuote',
  templateUrl: './tuote.component.html',
  styleUrls: ['./tuote.component.css']
})
export class TuoteComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild(MatInput, { static: true }) nimiInput: MatInput
  @ViewChild(MatInput, { static: true }) hintaInput: MatInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  fieldErrors: FieldErrors = {}

  tuote: AsiakkaanTuote = null
  name = 'asfsfssf' + Math.random()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private validationService: FormValidationService,
    private errorHandler: ErrorHandler,
    private ladataanService: LadataanService
  ) {
    this.tuote = laskuKopioija.annaUusiTuote()
  }

  ngAfterContentChecked() {
    this.fieldErrors = this.validationService.updateValidationStatus('tuote', this.form)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    // Create form
    this.form = new UntypedFormGroup({
      'nimi': new UntypedFormControl('', [Validators.required]),
      'hinta': new UntypedFormControl('', [])
    })

    // Bind to changes
    this.form.get('nimi').valueChanges.subscribe(value => {
      if (this.tuote) {
        this.tuote.nimi = this.validationService.processValue(value)
      }
    })
    this.form.get('hinta').valueChanges.subscribe(value => {
      if (this.tuote) {
        this.tuote.hinta = value
      }
    })

    // Prepare edit
    this.route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: { tuote: AsiakkaanTuote }) => {
      this.form.reset()
      this.tuote = this.laskuKopioija.copyTuoteMuokattavaksi(data.tuote)
      this.form.get('nimi').setValue(this.tuote.nimi)
      this.form.get('hinta').setValue(this.tuote.hinta)
    })

    this.nimiInput.focus()

  }

  poista() {
    const data: TuotePoistoVarmistusDialogData = {
      tuote: this.tuote
    }
    this.dialog.open(TuotePoistoVarmistusDialog, { data: data }).afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/laskutus/tuotteet'])
      }
    })
  }

  save() {

    if (!this.form.valid) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this.ladataanService.aloitaLataaminen()
    this.laskuService.updateTuotteet([this.tuote]).then(result => {
      this.ladataanService.lopetaLataaminen()
      this.router.navigate(['/laskutus/tuotteet'])
    }).catch(err => {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
    })

  }

}
