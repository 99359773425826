import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { MatDialog } from '@angular/material/dialog'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from '../vaihda-kieli.dialog'

@Component({
  templateUrl: './no-settings.component.html',
  styleUrls: ['./login.component.css']
})
export class NoSettingsComponent {

  year = new Date().getFullYear()

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) { }

  palaaSovellukseen() {
    this.router.navigate(['/'])
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this.dialog.open(VaihdaKieliDialog, { data: data })
  }

}
