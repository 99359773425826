import { Component, OnDestroy, Input, ErrorHandler, ChangeDetectionStrategy } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { HttpHeaders } from '@angular/common/http'

import { TositeKuvaCacheService } from './tosite-kuva-cache.service'
import { LEMONAID_CF_API, LemonHttpService } from 'app/_angular/service/lemon-http.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { firstValueFrom } from 'rxjs'

interface SafeUrlAndObjectUrl {
  safeUrl: SafeUrl
  objectUrl: string
}

@Component({
  selector: '[app-tosite-kuva]',
  templateUrl: './tosite-kuva.component.html',
  styleUrls: ['./tosite-kuva.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TositeKuvaComponent implements OnDestroy {

  private _url: string = null
  @Input()
  set url(url: string) {
    const toCheck = url ?? null
    if (this._url !== toCheck) {
      this.ladataan = true
      this._url = toCheck
      this.urlToUse = this._transform(this._url)
      // console.log(this._url)
    }
  }
  get url(): string {
    return this._url
  }

  @Input() expandable: boolean = false
  @Input() thumbnail: boolean = false

  urlToUse: Promise<SafeUrlAndObjectUrl> = null
  ladataan = true
  isExpanded: boolean = false

  constructor(
    private _errorHandler: ErrorHandler,
    private _httpService: LemonHttpService,
    private _sanitizer: DomSanitizer,
    private _tositeKuvaCacheService: TositeKuvaCacheService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) { }

  ngOnDestroy(): Promise<void> {
    return this._destroyObjectUrl()
  }

  onLoad(event: Event) {
    this.ladataan = false
  }

  onError(event: Event) {
    console.log('error', event)
    if (this.urlToUse === null) {
      this.ladataan = true
      const returnValue: SafeUrlAndObjectUrl = {
        objectUrl: null,
        safeUrl: '/assets/noimage.png'
      }
      this.urlToUse = Promise.resolve(returnValue)
    }
  }

  public setExpandedState() {
    if (this.expandable) {
      this.isExpanded = !this.isExpanded
    }
  }

  private _getToken(): Promise<string> {
    return firstValueFrom(this._firebaseLemonaid.authUserObservable).then(user => {
      if (user) {
        return this._firebaseLemonaid.authGetIdToken(user)
      }
      return null
    })
  }

  private _destroyObjectUrl(): Promise<void> {
    if (this.urlToUse) {
      return this.urlToUse.then(urli => {
        if (urli && urli.objectUrl) {
          // console.log('destory', urli.objectUrl)
          URL.revokeObjectURL(urli.objectUrl)
        }
        // this.urlToUse = null
      }).catch(err => {
        this._errorHandler.handleError(err)
        // this.urlToUse = null
      })
    }
    return Promise.resolve()
  }

  private async _getBlob(url: string): Promise<Blob> {

    // console.log('Try find!', url)

    const fromAddedCache = this._tositeKuvaCacheService.addedImageCache.get(url)
    if (fromAddedCache) {
      // console.log('FOUND!', fromAddedCache.annaKuvanPromise())
      return fromAddedCache.annaKuvanPromise()
    }

    const fromNetworkCache = this._tositeKuvaCacheService.networkImageCache.get(url)
    if (fromNetworkCache) {
      return fromNetworkCache
    }

    // Get security token
    const token = await this._getToken()

    // Headers
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-L': token
    })

    // Fetch from network
    if (this.thumbnail) {
      const b = await this._httpService.getBinary('/kuittiLataaSkaalattuKuva?a=' + encodeURIComponent(url), LEMONAID_CF_API)
      this._tositeKuvaCacheService.networkImageCache.set(url, b)
      return b
    }

    const blobby = await this._httpService.getBinary('/kuittiLataaKuva?a=' + encodeURIComponent(url), LEMONAID_CF_API)
    this._tositeKuvaCacheService.networkImageCache.set(url, blobby)
    return blobby

  }

  private async _transform(url: string): Promise<SafeUrlAndObjectUrl> {
    try {

      // Destroy old object url if one exists
      await this._destroyObjectUrl()

      if (url.startsWith('/assets/')) {
        const ret: SafeUrlAndObjectUrl = { objectUrl: null, safeUrl: url }
        return ret
      }

      const blobby = await this._getBlob(url)

      // Create object url + sanitize
      const objectUrl = URL.createObjectURL(blobby)
      const returnValue: SafeUrlAndObjectUrl = {
        objectUrl: objectUrl,
        safeUrl: this._sanitizer.bypassSecurityTrustUrl(objectUrl)
      }
      // console.log('for', url, returnValue)
      return returnValue

    } catch (err) {
      this._errorHandler.handleError(err)
      const returnValue: SafeUrlAndObjectUrl = {
        objectUrl: null,
        safeUrl: '/assets/noimage.png'
      }
      return returnValue
    }
  }

}
