import { AfterViewInit, Component, ErrorHandler, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { ApixDownloadLaskutustiedotPdfRequest, ApixDownloadLaskutustiedotPdfResponse, ApixReceivedInvoiceConfig, ApixReceivedInvoiceConfigUpdateRequest, ApixReceivedInvoiceConfigUpdateResponse } from 'app/_jaettu/model/apix'
import { LaskunSahkoinenOsoite } from 'app/_jaettu/model/lasku'
import { SahkoisenLaskunValittajaService, Verkkolaskuoperaattori } from 'app/_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'
import { Observable, Subject, switchMap, of as observableOf, map, takeUntil, take, firstValueFrom } from 'rxjs'

import { toByteArray } from 'base64-js'
import { NewFeature } from 'app/_jaettu/model/kayttaja'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { UserSettingsService } from 'app/_angular/service/user-settings.service'

// _sahkoisenLaskunValittajaService
interface LaskunSahkoinenOsoiteJaNimi extends LaskunSahkoinenOsoite {
  sahkoinenValittajaNimi: string
}

@Component({
  selector: 'app-laskujen-vastaanotto',
  templateUrl: './laskujen-vastaanotto.component.html',
  styleUrls: ['./laskujen-vastaanotto.component.css']
})
export class LaskujenVastaanottoComponent implements OnInit, OnDestroy, AfterViewInit {

  viewState: 'not-enabled' | 'edit-address' | 'enabled' = null

  noApixAddressYet: boolean = true

  commonError: string

  form: FormGroup

  operaattorit: Verkkolaskuoperaattori[]

  apixLaskuosoiteObservable: Observable<LaskunSahkoinenOsoiteJaNimi>
  laskujenHyvaksyntaActiveObservable: Observable<boolean>

  private _cachedApixConfig: Pick<ApixReceivedInvoiceConfig, 'forwardRequiresApproving' | 'forwardLemontreeLaskut' | 'forwardAddress'> = {
    forwardRequiresApproving: null,
    forwardLemontreeLaskut: null,
    forwardAddress: {
      sahkoinenOsoite: null,
      sahkoinenValittaja: null
    }
  }

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _saveStarted: boolean = false

  namename = 'sdfg' + Math.random()

  constructor(
    private _formValidationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _translationService: LemonTranslationService,
    private _sahkoisenLaskunValittajaService: SahkoisenLaskunValittajaService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _kayttajaService: KayttajaService,
    private _snackBar: MatSnackBar,
    private _fileSaverService: FileSaverService,
    private _userSettingsService: UserSettingsService
  ) { }

  ngOnInit(): void {

    this.form = new FormGroup({
      'serviceActive': new FormControl(null, Validators.requiredTrue),
      'valittaja': new FormControl({ value: null, disabled: true }, Validators.required),
      'osoite': new FormControl({ value: null, disabled: true }, Validators.required),
      'forwardLemontreeLaskut': new FormControl({ value: null, disabled: true }, Validators.required),
      'forwardRequiresApproving': new FormControl({ value: null, disabled: true }, Validators.required)
    })

    this.serviceActive.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(active => {
      if (active) {
        this.valittaja.enable()
        this.osoite.enable()
        this.forwardLemontreeLaskut.enable()
        this.forwardRequiresApproving.enable()
      } else {
        this.valittaja.disable()
        this.osoite.disable()
        this.forwardLemontreeLaskut.disable()
        this.forwardRequiresApproving.disable()
      }
    })


    this.valittaja.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      if (!!val) {
        this._cachedApixConfig.forwardAddress.sahkoinenValittaja = val
      }
    })

    this.osoite.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      this._cachedApixConfig.forwardAddress.sahkoinenOsoite = val
    })

    this.forwardLemontreeLaskut.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      this._cachedApixConfig.forwardLemontreeLaskut = val
    })

    this.forwardRequiresApproving.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      this._cachedApixConfig.forwardRequiresApproving = val
    })

    this.operaattorit = this._sahkoisenLaskunValittajaService.annaKaikkiValittajat()

    const apixInvoiceConfigObservable = this._kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (!kayttajanTiedot?.asiakasAvain) {
          return observableOf(null)
        }
        return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttajanTiedot.asiakasAvain + '/apix-received-invoice-config/' + kayttajanTiedot.asiakasAvain).listen()
      })
    )

    this.apixLaskuosoiteObservable = apixInvoiceConfigObservable.pipe(
      map(config => {
        if (config?.receiveActive && config?.receiveAddress) {
          const osoiteJaNimi: LaskunSahkoinenOsoiteJaNimi = {
            sahkoinenOsoite: config.receiveAddress.sahkoinenOsoite,
            sahkoinenValittaja: config.receiveAddress.sahkoinenValittaja,
            sahkoinenValittajaNimi: this._sahkoisenLaskunValittajaService.annaValittajanNimi(config.receiveAddress.sahkoinenValittaja)
          }
          return osoiteJaNimi
        }
        return null
      })
    )

    this.laskujenHyvaksyntaActiveObservable = apixInvoiceConfigObservable.pipe(
      map(config => {
        return config?.forwardActive && config?.forwardRequiresApproving
      })
    )

    apixInvoiceConfigObservable.pipe(
      take(1)
    ).subscribe(config => {
      /** View won't show anything until the data has been loaded */
      this.viewState = config?.receiveActive && config?.receiveAddress ? 'enabled' : 'not-enabled'

      this.forwardLemontreeLaskut.setValue(config?.forwardLemontreeLaskut ?? true)
      this.forwardRequiresApproving.setValue(config?.forwardRequiresApproving ?? false)

      if (config?.receiveActive) {

        this.noApixAddressYet = false // Toggle the buttons that are shown on the edit page

        // Form change will also propagate _cachedApixConfig update
        this.serviceActive.setValue(true)
        this.osoite.setValue(config.forwardAddress.sahkoinenOsoite)
        this.valittaja.setValue(config.forwardAddress.sahkoinenValittaja)
      }
    })
  }

  ngAfterViewInit(): void {
    this.updateSeen(NewFeature.LASKUJEN_VASTAANOTTO)
  }

  get serviceActive(): AbstractControl {
    return this.form.get('serviceActive')
  }

  get valittaja(): AbstractControl {
    return this.form.get('valittaja')
  }

  get osoite(): AbstractControl {
    return this.form.get('osoite')
  }

  get forwardLemontreeLaskut(): AbstractControl {
    return this.form.get('forwardLemontreeLaskut')
  }

  get forwardRequiresApproving(): AbstractControl {
    return this.form.get('forwardRequiresApproving')
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  editAddress() {
    this.viewState = 'edit-address'
  }

  backToEnabledView() {
    this.viewState = 'enabled'
  }

  async saveAddress() {

    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (this._saveStarted) {
      return
    }

    this._saveStarted = true
    this._ladataanService.aloitaLataaminen()

    try {

      const reqData: ApixReceivedInvoiceConfigUpdateRequest = {
        forwardAddress: this._cachedApixConfig.forwardAddress,
        forwardLemontreeLaskut: this._cachedApixConfig.forwardLemontreeLaskut,
        forwardRequiresApproving: this._cachedApixConfig.forwardRequiresApproving,
        paymentServiceOrdered: false
      }

      const resp = await this._firebaseLemonaid.functionsCall<ApixReceivedInvoiceConfigUpdateRequest, ApixReceivedInvoiceConfigUpdateResponse>('updateReceivedInvoiceConfig', reqData)

      if (!resp || resp.e) {
        /** Throw error to end up in the catch. */
        throw new Error('Forwarded address save failed! ' + (resp?.e || 'unknown-error'))
      }

      /** All is fine, switch view */
      this.viewState = 'enabled'

    } catch (err) {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      this._errorHandler.handleError(err)
    } finally {
      this._saveStarted = false
      this._ladataanService.lopetaLataaminen()
    }

  }

  async downloadPdf() {

    this._ladataanService.aloitaLataaminen()

    try {

      const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)

      const reqData: ApixDownloadLaskutustiedotPdfRequest = {
        kieli: 'fi'
      }

      const resp = await this._firebaseLemonaid.functionsCall<ApixDownloadLaskutustiedotPdfRequest, ApixDownloadLaskutustiedotPdfResponse>('downloadLaskutustiedotPdf', reqData)

      if (!resp || resp.e) {
        throw new Error('Laskutustiedot PDF download failed! ' + (resp?.e ?? 'No response data')) // Handle in catch
      }

      if (!resp.base64File?.length) {
        throw new Error('Laskutustiedot PDF download failed! PDF file empty')
      }

      const output = toByteArray(resp.base64File)
      const laskutustiedotLocalised = this._translationService.lokalisoiKielella('laskutustiedot-pdf.laskutustiedot', reqData.kieli)
      const fileName = laskutustiedotLocalised + ' ' + asiakas.nimi + ' (' + asiakas.ytunnus + ')' + '.pdf'
      this._fileSaverService.saveAs(new Blob([output], { type: 'application/pdf' }), fileName)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackBar.open(this._translationService.lokalisoi('tositteet.laskujen-vastaanotto.pdf-epaonnistui'), 'OK', { duration: 10 * 1000 })

    } finally {

      this._ladataanService.lopetaLataaminen()
    }

  }

  async updateSeen(seenFeature: NewFeature) {
    const kayttajanTiedot = await firstValueFrom(this._kayttajaService.kayttajanTiedotObservable)
    if (!kayttajanTiedot?.asiakasAvain) { return }

    return this._userSettingsService.setFeatureSeen(seenFeature, kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid, this._firebaseLemonaid)
  }

}
