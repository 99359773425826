import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { NgxFileDropEntry } from 'ngx-file-drop'
import { Observable, of as observableOf } from 'rxjs'
import { mergeMap, take } from 'rxjs/operators'
import { FirestoreTosite, Maksutapa } from '../../_jaettu/model/tosite'
import { TositeService } from '../service/tosite/tosite.service'


export interface TositeKatseleComponentData {
  tosite: FirestoreTosite
  tulosta: boolean
}

export interface TositeKatseleComponentExistingData {
  tosite: FirestoreTosite
}
export interface TositeLuoUusiComponentData {
  tiedostot: NgxFileDropEntry[]
  maksutapa?: Maksutapa
}

export interface TositeMuokkaaComponentData {
  tosite: FirestoreTosite | null
  onkoVielaSelvitettavia?: boolean
}

@Injectable()
export class TositeLuoUusiComponentDataResolve  {

  private existingData: TositeLuoUusiComponentData = null
  private lahdet = ['osto', 'myynti', 'palkka', 'tiliote']

  constructor(
    private router: Router
  ) { }

  asetaOlemassaolevaData(data: TositeLuoUusiComponentData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TositeLuoUusiComponentData> {

    if (this.existingData?.tiedostot) {
      return observableOf(this.existingData)
    }
    const lahde = route.url[1]
    if (lahde && this.lahdet.includes(lahde.path)) {
      this.router.navigate(['/tositteet/' + lahde.path])
    } else {
      this.router.navigate(['/tositteet/osto'])
    }
    return observableOf(null)
  }

}

@Injectable()
export class TositeMuokkaaComponentDataResolve  {

  private existingData: TositeMuokkaaComponentData = null

  constructor(
    private router: Router,
    private tositeService: TositeService
  ) {

  }

  asetaOlemassaolevaData(data: TositeMuokkaaComponentData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TositeMuokkaaComponentData> {
    const id = route.params['id']
    if (
      this.existingData !== null &&
      this.existingData.tosite &&
      this.existingData.tosite.avain === id
    ) {
      return observableOf(this.existingData)
    } else {
      return this.tositeService.annaKuittiObservable(id).pipe(
        take(1),
        mergeMap(tosite => {

          if (!tosite) {
            this.router.navigate(['/tositteet/osto'])
            return observableOf(null)
          }

          this.asetaOlemassaolevaData({ tosite: tosite })
          const tositeRoute = this.tositeService.getMaksutapaNameForRouter(tosite.maksutapa)
          this.router.navigate(['tositteet', tositeRoute, id])
          return observableOf(null)
        })
      )
    }
  }

}
@Injectable()
export class TositeKatseleComponentDataResolve  {

  private existingData: TositeKatseleComponentExistingData = null

  constructor(
    private router: Router,
    private tositeService: TositeService
  ) {

  }
  asetaOlemassaolevaData(data: TositeKatseleComponentExistingData) {
    this.existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TositeKatseleComponentData> {
    const id = route.params['id']
    const toiminto = route.params['toiminto']
    if (
      this.existingData != null &&
      this.existingData.tosite &&
      this.existingData.tosite.avain === id
    ) {
      return this.haeAsetukset(this.existingData, toiminto)
    } else {
      return this.tositeService.annaKuittiObservable(id).pipe(
        take(1),
        mergeMap(tosite => {
          if (!tosite) {
            this.router.navigate(['/tositteet/osto'])
            return observableOf(null)
          }
          this.asetaOlemassaolevaData({ tosite: tosite })
          const tositeRoute = this.tositeService.getMaksutapaNameForRouter(tosite.maksutapa)
          this.router.navigate(['tositteet', tositeRoute, id])
          return observableOf(null)
        })
      )
    }
  }

  private haeAsetukset(existingData: TositeKatseleComponentExistingData, toiminto: string): Observable<TositeKatseleComponentData> {
    const data: TositeKatseleComponentData = {
      tosite: existingData.tosite,
      tulosta: toiminto === 'tulosta'
    }
    return observableOf(data)
  }

}
