import { Laskuasetukset } from '../../_jaettu/model/lasku'

import { StringService } from '../../_shared-core/service/string.service'
import { Injectable } from '@angular/core'

@Injectable()
export class AsetustenOletuksetService {

  private static readonly OLETUS_NIMI = 'Esimerkki Oy Ab'
  private static readonly OLETUS_YTUNNUS = '00000000-0'
  private static readonly OLETUS_KATUOSOITE = 'Esimerkkikatu 000 A 000'
  private static readonly OLETUS_POSTITMP = 'Esimerkkilä'
  private static readonly OLETUS_POSTINRO = '00000'
  private static readonly OLETUS_MAA = 'FIN'


  // https://wiki.xmldation.com/Support/Nordea/Nordea/Test_Account_Numbers
  private static readonly OLETUS_IBAN = 'FI4819503000000010'
  private static readonly OLETUS_REPLYTO = 'esimerkki@esimerkki.esimerkki'

  constructor(
    private stringService: StringService
  ) { }

  poistaOletuksetAsetuksista(asetukset: Laskuasetukset) {

    // Yrityksen tiedot
    asetukset.nimi = asetukset.nimi === AsetustenOletuksetService.OLETUS_NIMI ? null : asetukset.nimi
    asetukset.ytunnus = asetukset.ytunnus === AsetustenOletuksetService.OLETUS_YTUNNUS ? null : asetukset.ytunnus
    asetukset.katuosoite = asetukset.katuosoite === AsetustenOletuksetService.OLETUS_KATUOSOITE ? null : asetukset.katuosoite
    asetukset.postitmp = asetukset.postitmp === AsetustenOletuksetService.OLETUS_POSTITMP ? null : asetukset.postitmp
    asetukset.postinro = asetukset.postinro === AsetustenOletuksetService.OLETUS_POSTINRO ? null : asetukset.postinro
    // asetukset.maakoodi = asetukset.maakoodi === AsetustenOletuksetService.OLETUS_MAA ? null : asetukset.maakoodi

    // Yhteystiedot
    if (asetukset.iban === AsetustenOletuksetService.OLETUS_IBAN) {
      asetukset.iban = null
      asetukset.bic = null
      asetukset.pankki = null
    }
    asetukset.replyto = asetukset.replyto === AsetustenOletuksetService.OLETUS_REPLYTO ? null : asetukset.replyto

  }

  // taydennaAsetuksetOletuksilla(asetukset: Laskuasetukset) {

  //   // Yrityksen tiedot
  //   asetukset.nimi = asetukset.nimi ? asetukset.nimi : AsetustenOletuksetService.OLETUS_NIMI
  //   asetukset.ytunnus = asetukset.ytunnus ? asetukset.ytunnus : AsetustenOletuksetService.OLETUS_YTUNNUS
  //   asetukset.katuosoite = asetukset.katuosoite ? asetukset.katuosoite : AsetustenOletuksetService.OLETUS_KATUOSOITE
  //   asetukset.postitmp = asetukset.postitmp ? asetukset.postitmp : AsetustenOletuksetService.OLETUS_POSTITMP
  //   asetukset.postinro = asetukset.postinro ? asetukset.postinro : AsetustenOletuksetService.OLETUS_POSTINRO
  //   asetukset.maakoodi = asetukset.maakoodi ? asetukset.maakoodi : AsetustenOletuksetService.OLETUS_MAA

  //   // Yhteystiedot
  //   asetukset.iban = asetukset.iban ? asetukset.iban : AsetustenOletuksetService.OLETUS_IBAN
  //   asetukset.replyto = asetukset.replyto ? asetukset.replyto : AsetustenOletuksetService.OLETUS_REPLYTO

  //   const laskettuBic = this.bicService.annaBicKoodi(asetukset.iban)
  //   const bic = laskettuBic ? laskettuBic : 'Ei tiedossa'

  //   const laskettuPankki = this.bicService.annaPankinNimi(asetukset.iban)
  //   const pankki = laskettuPankki ? laskettuPankki : 'Ei tiedossa'

  //   asetukset.bic = asetukset.bic ? asetukset.bic : bic
  //   asetukset.pankki = asetukset.pankki ? asetukset.pankki : pankki

  // }

  // tarkistaOnkoAsetuksetOletuksia(asetukset: Laskuasetukset): boolean {

  //   return (
  //     asetukset.nimi === AsetustenOletuksetService.OLETUS_NIMI ||
  //     asetukset.ytunnus === AsetustenOletuksetService.OLETUS_YTUNNUS ||
  //     asetukset.katuosoite === AsetustenOletuksetService.OLETUS_KATUOSOITE ||
  //     asetukset.postitmp === AsetustenOletuksetService.OLETUS_POSTITMP ||
  //     this.stringService.removeAllWhiteSpaces(asetukset.postinro) === AsetustenOletuksetService.OLETUS_POSTINRO ||
  //     // asetukset.maakoodi === AsetustenOletuksetService.OLETUS_MAA ||

  //     this.stringService.removeAllWhiteSpaces(asetukset.iban) === AsetustenOletuksetService.OLETUS_IBAN ||
  //     asetukset.replyto === AsetustenOletuksetService.OLETUS_REPLYTO ||
  //     this.tarkistaPuuttuukoLaskuasetuksia(asetukset)
  //   )

  // }

  tarkistaPuuttuukoLaskuasetuksia(asetukset: Laskuasetukset): boolean {

    this.poistaOletuksetAsetuksista(asetukset)

    return (
      !this.stringService.removeAllWhiteSpaces(asetukset.nimi) ||
      !this.stringService.removeAllWhiteSpaces(asetukset.ytunnus) ||
      !this.stringService.removeAllWhiteSpaces(asetukset.katuosoite) ||
      !this.stringService.removeAllWhiteSpaces(asetukset.postitmp) ||
      !this.stringService.removeAllWhiteSpaces(asetukset.postinro) ||
      !this.stringService.removeAllWhiteSpaces(asetukset.maakoodi) ||

      !this.stringService.removeAllWhiteSpaces(asetukset.iban) ||
      !this.stringService.removeAllWhiteSpaces(asetukset.replyto)
    )

  }

}
