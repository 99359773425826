import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { NgxFileDropEntry } from 'ngx-file-drop'
import { Observable, of as observableOf } from 'rxjs'
import { mergeMap, take } from 'rxjs/operators'
import { FirestoreTosite } from '../../_jaettu/model/tosite'
import { TositeService } from '../service/tosite/tosite.service'


export interface MaksuKatseleComponentData {
  tosite: FirestoreTosite
  tulosta: boolean
}

export interface MaksuKatseleComponentExistingData {
  tosite: FirestoreTosite
}
export interface MaksuLuoUusiComponentData {
  tiedostot: NgxFileDropEntry[]
}

export interface MaksuMuokkaaComponentData {
  tosite: FirestoreTosite
}

@Injectable()
export class MaksuLuoUusiComponentDataResolve  {

  private _existingData: MaksuLuoUusiComponentData = null

  constructor() { }

  asetaOlemassaolevaData(data: MaksuLuoUusiComponentData) {
    this._existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<MaksuLuoUusiComponentData> {
    return observableOf(this._existingData)
  }

}

@Injectable()
export class MaksuMuokkaaComponentDataResolve  {

  private _existingData: MaksuMuokkaaComponentData = null

  constructor(
    private router: Router,
    private tositeService: TositeService
  ) {

  }

  asetaOlemassaolevaData(data: MaksuMuokkaaComponentData) {
    this._existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<MaksuMuokkaaComponentData> {
    const id = route.params['id']
    if (
      this._existingData != null &&
      this._existingData.tosite &&
      this._existingData.tosite.avain === id
    ) {
      return observableOf(this._existingData)
    } else {
      return this.tositeService.annaKuittiObservable(id).pipe(
        take(1),
        mergeMap(tosite => {
          if (!tosite) {
            this.router.navigate(['/maksut'])
            return observableOf(null as any as MaksuMuokkaaComponentData)
          }
          const data: MaksuMuokkaaComponentData = { tosite: tosite }
          this.asetaOlemassaolevaData(data)
          return observableOf(data)
        })
      )
    }
  }

}
@Injectable()
export class MaksuKatseleComponentDataResolve  {

  private _existingData: MaksuKatseleComponentExistingData = null

  constructor(
    private router: Router,
    private tositeService: TositeService
  ) {

  }
  asetaOlemassaolevaData(data: MaksuKatseleComponentExistingData) {
    this._existingData = data
  }

  resolve(route: ActivatedRouteSnapshot): Observable<MaksuKatseleComponentData> {
    const id = route.params['id']
    const toiminto = route.params['toiminto']
    if (
      this._existingData != null &&
      this._existingData.tosite &&
      this._existingData.tosite.avain === id
    ) {
      return this.haeAsetukset(this._existingData, toiminto)
    } else {
      return this.tositeService.annaKuittiObservable(id).pipe(
        take(1),
        mergeMap(tosite => {
          if (!tosite) {
            this.router.navigate(['/maksut'])
            return observableOf(null as any as MaksuKatseleComponentData)
          }
          const data: MaksuKatseleComponentData = { tosite: tosite, tulosta: toiminto === 'tulosta' }
          this.asetaOlemassaolevaData(data)
          this.router.navigate(['maksut', id])
          return observableOf(data)
        })
      )
    }
  }

  private haeAsetukset(existingData: MaksuKatseleComponentExistingData, toiminto: string): Observable<MaksuKatseleComponentData> {
    const data: MaksuKatseleComponentData = {
      tosite: existingData.tosite,
      tulosta: toiminto === 'tulosta'
    }
    return observableOf(data)
  }

}
