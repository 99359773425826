import { Component, ErrorHandler } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { AllowedDialogsService } from 'app/_angular/service/allowed-dialogs.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { HolviDialogService } from 'app/_angular/service/holvi-dialog.service'
import { HolviOauthCodeSaveRequest, HolviOauthCodeSaveResponse } from 'app/_jaettu/model/holvi'
import { HolviLoginFailedDialog } from './holvi-login-failed.dialog'
import { HolviLoginCompletedDialog } from './holvi-login-completed.dialog'

@Component({
  template: ''
})
export class HolviOauthCallbackComponent {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _dialog: MatDialog,
    private _allowedDialogsService: AllowedDialogsService,
    private _kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _holviDialogService: HolviDialogService
  ) {
    this._allowedDialogsService.disableAllDialogs()
    this._ladataanService.aloitaLataaminen()

    const state = this._activatedRoute.snapshot.queryParamMap.get('state')
    const code = this._activatedRoute.snapshot.queryParamMap.get('code')
    const error = this._activatedRoute.snapshot.queryParamMap.get('error')

    this._kayttajaService.getKayttaja().then(kayttaja => {
      // return this._holviDialogService.saveHistory(kayttaja?.asiakasAvain, (code && !error) ? 'success' : 'failed', state)
    }).catch(err => {
      this._errorHandler.handleError(new Error('Holvi log save failed ' + err?.message || ''))
    })

    if (code && !error) {

      const req: HolviOauthCodeSaveRequest = {
        code: code
      }

      this._firebaseLemonaid.functionsCall<HolviOauthCodeSaveRequest, HolviOauthCodeSaveResponse>('holviOauthSaveCode', req).then(vastaus => {
        const err = vastaus.e
        this._ladataanService.lopetaLataaminen()
        if (err) {
          this._writeLoginFailToLog(error)
          this._errorHandler.handleError(error)
          this._dialog.open(HolviLoginFailedDialog)
        } else {
          this._dialog.open(HolviLoginCompletedDialog)
        }
      }).catch(err => {
        this._ladataanService.lopetaLataaminen()
        this._errorHandler.handleError(err)
        this._writeLoginFailToLog(err ? JSON.stringify(err) : 'unknown-error')
        this._dialog.open(HolviLoginFailedDialog)
      })

    } else {
      this._ladataanService.lopetaLataaminen()
      this._writeLoginFailToLog(error ?? 'Missing code')
      this._errorHandler.handleError(new Error(error ?? 'Missing code'))
      this._dialog.open(HolviLoginFailedDialog)
    }
  }

  private _writeLoginFailToLog(reason: string) {
    return this._kayttajaService.getKayttaja().then(kayttaja => {
      return this._holviDialogService.saveHistory(kayttaja?.asiakasAvain, 'Holvi Oauth return failed', reason)
    }).catch(err => {
      this._errorHandler.handleError(new Error('Holvi log save failed ' + err?.message || ''))
    })
  }

}
