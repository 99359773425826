import { Component, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { LaskunAsiakas } from '../../_jaettu/model/lasku'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

export interface AsiakasPoistoVarmistusDialogData {
  asiakas: LaskunAsiakas
}

@Component({
  templateUrl: './asiakas-poisto-varmistus.dialog.html'
})
export class AsiakasPoistoVarmistusDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AsiakasPoistoVarmistusDialogData,
    private errorHandler: ErrorHandler,
    private dialogRef: MatDialogRef<AsiakasPoistoVarmistusDialog>,
    private ladataanService: LadataanService,
    private laskuService: LaskuService
  ) {

  }

  poista() {
    this.ladataanService.aloitaLataaminen()
    this.laskuService.poistaAsiakkaat([this.data.asiakas]).then(result => {
      this.ladataanService.lopetaLataaminen()
      this.dialogRef.close(true)
    }).catch(err => {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
      this.dialogRef.close(true)
    })
  }

}
