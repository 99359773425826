<div class="list-page kapea" style="max-width: 900px;">

  <form [formGroup]="form" novalidate class="ylaosan-kontrollit">

    <div class="lisaa-nappula-container">
      <button mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4">
        <mat-icon>add</mat-icon>
        {{'tuotteet.lisaa' | translate | async}}
      </button>
    </div>

    <div class="hakukontrollit">

      <mat-form-field>
        <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
        <mat-label>{{ 'tuotteet.etsi' | translate | async }}</mat-label>
        <input type="text" [name]="name" formControlName="nimi" matInput />
      </mat-form-field>

    </div>

  </form>

  <div style="margin: 3em;">
    {{'tuotteet.vihje' | translate | async}}
  </div>

  <div style="position: relative;">
    <mat-table matSort [dataSource]="naytettavatTuotteetObservable | async" [matSortDisableClear]="true" style="min-height: 94px;">

      <!-- Column Definition: Nimi -->
      <ng-container matColumnDef="nimi">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tuotteet.nimi' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.nimi}} </mat-cell>
      </ng-container>

      <!-- Column Definition: Hinta -->
      <ng-container matColumnDef="hinta">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="100px"> {{'tuotteet.hinta' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="100px"> {{ row.hinta | lmoney | async }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="propertiesToDisplay"></mat-header-row>
      <mat-row (click)="muokkaa(row)" *matRowDef="let row; columns: propertiesToDisplay"></mat-row>

    </mat-table>
    <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="naytaEiYhtaanObservable | async">
      <div style="width: 100%; padding-top: 40px; text-align: center;">
        {{'tuotteet.hakuehdoilla-ei-yhtaan-tulosta' | translate | async}}
      </div>
    </div>
    <div lemon-loading [show]="lataaObservable | async"></div>
  </div>

  <mat-paginator #paginator [length]="dataSource?.rivienMaaraObservable | async" [pageSize]="dataSource?.getPage().pageSize" [pageIndex]="dataSource?.getPage().pageIndex" [pageSizeOptions]="[20, 50, 200]">
  </mat-paginator>

</div>