<style>
  .all {
    /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
    max-height: 95vh;
  }
</style>

<div class="all teksti-big" *ngIf="!cellphone" mat-dialog-content style="padding: 0 2vw;">
  <mat-horizontal-stepper #horizStepper [linear]="true" style="margin-top: 30px;">

    <mat-step [completed]="hyvaksytty">
      <ng-template matStepLabel>
        {{ 'lemonaid-uusi-sopimus.uusi-toimeksiantosopimus' | translate | async }}
      </ng-template>

      <div class="card" [class.big-card]="!cellphone">

        <div style="text-align: center;">
          <div class="otsikko-big light-blue-text">
            {{ 'lemonaid-uusi-sopimus.uusi-toimeksiantosopimus' | translate | async }}
          </div>

          <p>
            {{ 'lemonaid-uusi-sopimus.toimeksiantosopimus-paivittyy' | translate: { loppupaiva: sopimuksenLoppupaiva} | async }}
            {{ 'lemonaid-uusi-sopimus.uudessa-sopimuksessa-palvelun-sisalto' | translate | async }}
            <span *ngIf="hintaEiMuutu" style="font-weight: bold;">{{ 'lemonaid-uusi-sopimus.kuukausihintanne-ei-muuttuu' | translate | async }}</span>
          </p>

          <p>
            {{ 'lemonaid-uusi-sopimus.jos-sopimusta-ei-hyvaksyta' | translate: { loppupaiva: sopimuksenLoppupaiva} | async }}
          </p>

          <div style="margin: 40px 0;">
            {{ 'lemonaid-uusi-sopimus.uuden-sopimuksen-kuukausihinta' | translate | async }}:
            <span class="kuukausihinta-big light-blue-text">{{ kuukausihinta }} + {{ 'lemonaid-uusi-sopimus.alv' | translate | async }}</span>
          </div>
        </div>

        <form #sopimusForm="ngForm" [formGroup]="sopimusFormGroup">

          <div class="hyvaksy-liitteet-container">
            <div style="margin-bottom: 20px;">{{'lemonaid-sopimus-dialog.lukenut-ja-hyvaksyn' | translate | async }}</div>
            <mat-checkbox class="hyvaksy-liitteet-check" formControlName="hyvaksy"></mat-checkbox>
            <a class="link-like" (click)="downloadSopimus()">{{'lemonaid-sopimus-dialog.toimeksiantosopimuksen' | translate | async }}</a>
            <mat-error *ngIf="hyvaksy?.errors?.required && (hyvaksy?.touched || sopimusForm?.submitted)" style=" display: block; margin-left: 45px; font-size: 14px;">{{ 'lemonaid-sopimus-dialog.hyvaksy-required' | translate | async }}</mat-error>
            <div class="liitteet-container">
              <a class="link-like" (click)="downloadPalvelukuvaus()">{{'lemonaid-sopimus-dialog.palvelukuvaus' | translate | async }}</a>
              <div><a target="_BLANK" href="/assets/sopimukset/ysetal2018.pdf">{{'lemonaid-sopimus-dialog.yleiset-sopimusehdot' | translate | async }} TAL2018</a></div>
              <div><a target="_BLANK" href="/assets/sopimukset/shktal2018.pdf">{{'lemonaid-sopimus-dialog.sopimus-henkilotietojen' | translate | async }} TAL2018</a></div>
            </div>
          </div>
        </form>

      </div>

      <div class="button-container">
        <button class="secondarybutton" (click)="postpone()" mat-button>{{ 'lemonaid-uusi-sopimus.myohemmin' | translate | async }}</button>
        <button class="primarybutton" (click)="hyvaksyJaJatka()" mat-button>{{ 'lemonaid-uusi-sopimus.hyvaksy-ja-jatka' | translate | async }}</button>
      </div>
    </mat-step>

    <mat-step [editable]="false">
      <ng-template matStepLabel>
        {{ 'yleiset.valmis' | translate | async }}
      </ng-template>


      <div class="card" [class.big-card]="!cellphone">
        <div style="text-align: center;">
          <div class="otsikko-big light-blue-text">
            {{ 'lemonaid-uusi-sopimus.toimeksiantosopimus-hyvaksytty' | translate | async }}
          </div>
          <p>{{ 'lemonaid-uusi-sopimus.uusi-toimeksiantosopimus-nyt-hyvaksytty' | translate | async }}</p>
        </div>

      </div>

      <div class="button-container">
        <button (click)="sulje()" class="primarybutton" mat-button>{{ 'yleiset.jatka' | translate | async }}</button>
      </div>
    </mat-step>

  </mat-horizontal-stepper>
</div>

<div class="all" *ngIf="cellphone" mat-dialog-content>
  <mat-vertical-stepper #verticStepper [linear]="true">

    <mat-step [completed]="hyvaksytty">
      <ng-template matStepLabel>
        {{ 'lemonaid-uusi-sopimus.uusi-toimeksiantosopimus' | translate | async }}
      </ng-template>

      <div class="card">

        <div style="text-align: center;">
          <div class="otsikko-small light-blue-text" [innerHTML]="'lemonaid-uusi-sopimus.uusi-toimeksiantosopimus-break' | translate | async">
          </div>

          <p>
            {{ 'lemonaid-uusi-sopimus.toimeksiantosopimus-paivittyy' | translate: { loppupaiva: sopimuksenLoppupaiva} | async }}
            {{ 'lemonaid-uusi-sopimus.uudessa-sopimuksessa-palvelun-sisalto' | translate | async }}
            <span *ngIf="hintaEiMuutu" style="font-weight: bold;">{{ 'lemonaid-uusi-sopimus.kuukausihintanne-ei-muuttuu' | translate | async }}</span>
          </p>

          <p>
            {{ 'lemonaid-uusi-sopimus.jos-sopimusta-ei-hyvaksyta' | translate: { loppupaiva: sopimuksenLoppupaiva} | async }}
          </p>

          <div style="margin: 40px 0;">
            {{ 'lemonaid-uusi-sopimus.uuden-sopimuksen-kuukausihinta' | translate | async }}:
            <span class="kuukausihinta-small light-blue-text">{{ kuukausihinta }} + {{ 'lemonaid-uusi-sopimus.alv' | translate | async }}</span>
          </div>
        </div>

        <form #sopimusForm="ngForm" [formGroup]="sopimusFormGroup">

          <div style="line-height: 25px;">
            <div style="margin-bottom: 20px;">{{'lemonaid-sopimus-dialog.lukenut-ja-hyvaksyn' | translate | async }}</div>
            <mat-checkbox class="hyvaksy-liitteet-check" formControlName="hyvaksy"></mat-checkbox>
            <a class="link-like" (click)="downloadSopimus()">{{'lemonaid-sopimus-dialog.toimeksiantosopimuksen' | translate | async }}</a>
            <mat-error *ngIf="hyvaksy?.errors?.required && (hyvaksy?.touched || sopimusForm?.submitted)" style=" display: block; margin-left: 45px; font-size: 14px;">{{ 'lemonaid-sopimus-dialog.hyvaksy-required' | translate | async }}</mat-error>
            <div class="liitteet-container">
              <a class="link-like" (click)="downloadPalvelukuvaus()">{{'lemonaid-sopimus-dialog.palvelukuvaus' | translate | async }}</a>
              <div><a target="_BLANK" href="/assets/sopimukset/ysetal2018.pdf">{{'lemonaid-sopimus-dialog.yleiset-sopimusehdot' | translate | async }} TAL2018</a></div>
              <div><a target="_BLANK" href="/assets/sopimukset/shktal2018.pdf">{{'lemonaid-sopimus-dialog.sopimus-henkilotietojen' | translate | async }} TAL2018</a></div>
            </div>
          </div>

        </form>

      </div>

      <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>

      <div class="button-container">
        <button class="secondarybutton" (click)="postpone()" mat-button>{{ 'lemonaid-uusi-sopimus.myohemmin' | translate | async }}</button>
        <button class="primarybutton" (click)="hyvaksyJaJatka()" mat-button>{{ 'lemonaid-uusi-sopimus.hyvaksy' | translate | async }}</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        {{ 'yleiset.valmis' | translate | async }}
      </ng-template>

      <div class="card">
        <div style="text-align: center;">
          <div class="otsikko-small light-blue-text">
            {{ 'lemonaid-uusi-sopimus.toimeksiantosopimus-hyvaksytty' | translate | async }}
          </div>
          <p>{{ 'lemonaid-uusi-sopimus.uusi-toimeksiantosopimus-nyt-hyvaksytty' | translate | async }}</p>
        </div>

      </div>

      <div class="button-container">
        <button (click)="sulje()" class="primarybutton" mat-button>{{ 'yleiset.jatka' | translate | async }}</button>
      </div>
    </mat-step>

  </mat-vertical-stepper>
</div>