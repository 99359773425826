import { Component, OnInit } from '@angular/core'
import { MatTabChangeEvent } from '@angular/material/tabs'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { UserSettingsService } from 'app/_angular/service/user-settings.service'
import { NewFeature } from 'app/_jaettu/model/kayttaja'
import { Observable, firstValueFrom, map } from 'rxjs'


@Component({
  selector: 'app-asetukset',
  templateUrl: './asetukset.component.html'
})
export class AsetuksetComponent implements OnInit {

  showNewFeatureSignObservable: Observable<boolean>
  constructor(
    private _userSettingsService: UserSettingsService,
    private _kayttajaService: KayttajaService,
    private _lemonaidFirebase: FirebaseLemonaid
  ) { }
  ngOnInit(): void {

    this.showNewFeatureSignObservable = this._userSettingsService.userSettingsObservable.pipe(
      map(newFeatures => {
        if (!newFeatures?.seen[NewFeature.AUTOMAATTINEN_RESKONTRA]) {
          return true
        }
        return false
      })
    )
  }

  tabChanged(event: MatTabChangeEvent) {
    if (event.index === 3) {
      firstValueFrom(this.showNewFeatureSignObservable).then(isShown => {
        if (!isShown) {
          return
        }
        return firstValueFrom(this._kayttajaService.kayttajanTiedotObservable).then(kayttajanTiedot => {
          if (kayttajanTiedot) {
            return this._userSettingsService.setFeatureSeen(NewFeature.AUTOMAATTINEN_RESKONTRA, kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid, this._lemonaidFirebase)
          }
        })
      })

    }
  }
}
