import { Injectable } from '@angular/core'

import { KayttajaService } from '../kayttaja.service'
import { TositeUriService } from '../../../_jaettu/service/tosite/tosite-uri.service'

import { ListausFirestoreKuitti } from './tosite-datasource.service'
import { FirestoreTositteenKuva } from '../../../_jaettu/model/tosite'

import { Observable, of as observableOf, BehaviorSubject, combineLatest } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { FirebaseLemonaid } from '../firebase-lemonaid.service'
import { lemonShare } from '../../../_jaettu-angular/_rxjs/lemon-share.operator'

@Injectable()
export class EinvoicesAngularService {

  private lataaSubject = new BehaviorSubject<boolean>(true)
  lataaObservable: Observable<boolean> = this.lataaSubject.asObservable()

  private _internalEinvoicesObservable: Observable<ListausFirestoreKuitti[]>
  unapprovedEinvoicesObservable: Observable<ListausFirestoreKuitti[]>

  constructor(
    private kayttajaService: KayttajaService,
    private firebaseLemonaid: FirebaseLemonaid,
    private tositeUriService: TositeUriService
  ) {

    this._internalEinvoicesObservable = combineLatest([
      this.kayttajaService.kayttajanTiedotObservable,
      this.kayttajaService.nykyinenAsiakasObservable
    ]).pipe(
      tap(() => this.lataaSubject.next(true)),
      switchMap(([
        kayttajanTiedot,
        asiakas
      ]) => {
        if (!kayttajanTiedot || !asiakas) {
          return observableOf<ListausFirestoreKuitti[]>([])
        }
        const kuittienUri = this.tositeUriService.annaKuittienFirestoreCollectionUri(kayttajanTiedot.asiakasId)
        return this.firebaseLemonaid.firestoreCollection<ListausFirestoreKuitti>(kuittienUri)
          .where('einvoiceApprovalStatus', '==', 'pending-approval')
          .listen()
          .pipe(
            map(kuitit => {
              const sorted = kuitit.filter(k => !k.poistettu).sort((a, b) => {
                if (a.p !== b.p) {
                  return a.p - b.p
                }
                return b.summa - a.summa
              })
              return sorted
            }),
            tap(eInvoices => {
              if (eInvoices) {
                for (const eInv of eInvoices) {

                  delete eInv['haku']

                  let kuva: FirestoreTositteenKuva = null
                  for (const kuvanAvain of Object.keys(eInv.kuvat)) {
                    const mahdollinen = eInv.kuvat[kuvanAvain]
                    if (!mahdollinen.poistettu && (kuva === null || mahdollinen.jarjestys < kuva.jarjestys)) {
                      kuva = mahdollinen
                      if (kuva.jarjestys === 1) {
                        break
                      }
                    }
                  }

                  if (!kuva) {
                    eInv.ladataan = false
                    eInv.ensimmainenKuva = '/assets/noimage-blank.png'
                    continue
                  }

                  const kuvanUrl = this.tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, eInv.kuvakansio, kuva.avain, kuva.type)
                  eInv.ensimmainenKuva = '/api/1/kuitit/kuvat/thumb/' + kuvanUrl

                }
              }
              this.lataaSubject.next(false)
            })
          )
      }),
      lemonShare()
    )

    this.unapprovedEinvoicesObservable = combineLatest([this._internalEinvoicesObservable, this.lataaObservable]).pipe(
      map(([einvoices, lataa]) => {
        if (lataa) {
          return []
        }
        return einvoices
      })
    )
  }
  unapprovedEinvoicesTotalSumAndNumberObservable(): Observable<{ maara: number, summa: number }> {
    return this.unapprovedEinvoicesObservable.pipe(
      map(tositteet => {
        const summa = tositteet.reduce((a, b) => a + b.summa, 0)
        return { maara: tositteet?.length || 0, summa: summa || 0 }
      })
    )
  }

}
