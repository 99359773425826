import { Component, OnInit, OnDestroy, Inject, ErrorHandler } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'
import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { ErrorResponse } from 'app/_shared-core/model/common'
import { CustomerBeneficiary, CustomerHallituksenJasen, CustomerOsakas, KnowYourCustomer } from 'app/_jaettu/model/kayttaja'
import { KnowYourCustomerSaveRequest } from 'app/_jaettu/model/sopimus'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { map, takeUntil, takeWhile } from 'rxjs/operators'
import { Maa, MaaService } from 'app/_jaettu-angular/service/maa.service'
import { Observable, Subject } from 'rxjs'
import { PepTekstiSharedDialog } from './pep-teksti-shared.dialog'
import { TuntemistiedotDialogService } from 'app/_angular/service/tuntemistiedot-dialog.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

export interface UudetTuntemistiedotDialogData {
  kyc?: KnowYourCustomer
}

@Component({
  templateUrl: './uudet-tuntemistiedot.dialog.html',
  styleUrls: ['./uudet-tuntemistiedot.dialog.css']
})
export class UudetTuntemistiedotDialog implements OnInit, OnDestroy {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  tuntemistiedotFormGroup: UntypedFormGroup
  kansallisuudetObservable: Observable<Maa[]>
  hallitusRoolitObservable: Observable<{ avain: Pick<CustomerHallituksenJasen, 'rooli'>, nimi: string }[]>

  namename = 'asruopeq' + Math.random()
  commonError: string

  private _tunnistamistiedot: KnowYourCustomer = {
    asiakasAvain: null,
    avain: null,
    edunsaajat: [],
    hallitus: [],
    luoja: null,
    luotu: null,
    osakkaat: [],
    paivitetty: null,
    paivittaja: null,
    poistettu: false
  }

  private _ytunnus: string

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: UudetTuntemistiedotDialogData,
    private _dialogRef: MatDialogRef<UudetTuntemistiedotDialog>,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _maaService: MaaService,
    private _dialog: MatDialog,
    private _tuntemistiedotDialogService: TuntemistiedotDialogService,
    private _dateService: DateService,
    private _kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this._dialogRef.disableClose = true
  }

  ngOnInit() {

    this._kayttajaService.nykyinenAsiakasObservable.pipe(
      takeWhile(asiakas => !asiakas, true)
    ).subscribe(asiakas => {
      if (asiakas.ytunnus) {
        this._ytunnus = asiakas.ytunnus.trim()
      }
    })

    this._kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
      if (kayttajanTiedot?.asiakasAvain) {
        this._tuntemistiedotDialogService.savePopupSeenLog(kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid)
      }
    }).catch(err => {
      console.log(err)
    })

    this.tuntemistiedotFormGroup = new UntypedFormGroup({
      'osakkaat': new UntypedFormArray([], LemonaidValidators.vahintaanYksiArrayssaValidator),
      'hallitus': new UntypedFormArray([], LemonaidValidators.vahintaanYksiArrayssaValidator),
      'muut': new UntypedFormArray([])
    })

    this.hallitusRoolitObservable = this._translationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe),
      map(kieli => {
        const kaytettyKieli = kieli === 'en' ? 'en' : 'fi'
        const roolit = [
          { avain: 'puheenjohtaja', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-puheenjohtaja', kaytettyKieli) },
          { avain: 'jasen', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-jasen', kaytettyKieli) },
          { avain: 'varajasen', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-varajasen', kaytettyKieli) },
          { avain: 'toimitusjohtaja', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.toimitusjohtaja', kaytettyKieli) }
        ] as { avain: Pick<CustomerHallituksenJasen, 'rooli'>, nimi: string }[]

        return roolit
      })

    )

    this.kansallisuudetObservable = this._maaService.kaikkiMaatObservable.pipe(
      takeUntil(this._ngUnsubscribe),
      map(kaikkiMaat => {
        const maatCopy = [...kaikkiMaat]
        // Make selecting a bit easier
        const suomi = kaikkiMaat.find(maa => maa.koodi === 'FIN')
        const ruotsi = kaikkiMaat.find(maa => maa.koodi === 'SWE')
        maatCopy.unshift({ nimi: '---', koodi: null })
        maatCopy.unshift(ruotsi)
        maatCopy.unshift(suomi)
        return maatCopy
      })
    )

    if (this._data.kyc) {
      for (const osakas of (this._data.kyc.osakkaat || [])) {
        this.lisaaOsakas(osakas)
      }
      for (const jasen of (this._data.kyc.hallitus || [])) {
        this.lisaaHallituksenJasen(jasen)
      }
      for (const edunsaaja of (this._data.kyc.edunsaajat || [])) {
        this.lisaaEdunsaaja(edunsaaja)
      }
    }
  }

  get osakkaatFormArray(): UntypedFormArray {
    return this.tuntemistiedotFormGroup.get('osakkaat') as UntypedFormArray
  }

  get osakasFormGroupit(): UntypedFormGroup[] {
    return this.osakkaatFormArray.controls as UntypedFormGroup[]
  }

  get hallitusFormArray(): UntypedFormArray {
    return this.tuntemistiedotFormGroup.get('hallitus') as UntypedFormArray
  }

  get hallitusFormGroupit(): UntypedFormGroup[] {
    return this.hallitusFormArray.controls as UntypedFormGroup[]
  }

  get saajaFormArray(): UntypedFormArray {
    return this.tuntemistiedotFormGroup.get('muut') as UntypedFormArray
  }

  get saajaFormGroupit(): UntypedFormGroup[] {
    return this.saajaFormArray.controls as UntypedFormGroup[]
  }

  poistaOsakas(index: number) {
    this.osakkaatFormArray.removeAt(index)
    this._tunnistamistiedot.osakkaat.splice(index, 1)
  }

  lisaaOsakas(firestoresta?: CustomerOsakas) {
    const osakas: CustomerOsakas = firestoresta ? firestoresta : {
      hetuTaiYtunnus: '',
      nimi: '',
      osakkeita: null,
      kansallisuus: null,
      pep: null
    }

    const osakasFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(osakas.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(osakas.hetuTaiYtunnus, [Validators.required, LemonaidValidators.finnishHetuYtunnusOrVatNumberValidator, this.isCompanysOwnYtunnusValidator]),
      'osakkeita': new UntypedFormControl(osakas.osakkeita, [Validators.required]),
      'kansallisuus': new UntypedFormControl(osakas.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(osakas.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(null),
      'onkoYritys': new UntypedFormControl(null, [Validators.required]),
      'birthDate': new UntypedFormControl({ value: osakas.birthDate ? this._dateService.localDateToDate(osakas.birthDate) : null, disabled: true }, Validators.required)
    })

    osakasFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.nimi = value || null })
    osakasFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.hetuTaiYtunnus = value || null })
    osakasFormGroup.get('osakkeita').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.osakkeita = value || null })
    osakasFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.kansallisuus = value || null })
    osakasFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.pep = value ?? null })
    osakasFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      osakas.noFinnishId = value ?? false
      if (osakas.noFinnishId) {
        osakasFormGroup.get('onkoYritys').enable()

        if (!osakasFormGroup.get('onkoYritys').value) {
          osakasFormGroup.get('birthDate').enable()
        }
        osakasFormGroup.get('hetu').removeValidators(LemonaidValidators.finnishHetuYtunnusOrVatNumberValidator)
      } else {
        osakasFormGroup.get('onkoYritys').disable()
        osakasFormGroup.get('birthDate').disable()
        osakasFormGroup.get('hetu').addValidators(LemonaidValidators.finnishHetuYtunnusOrVatNumberValidator)
      }
      osakasFormGroup.get('hetu').updateValueAndValidity()
    })

    osakasFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && osakasFormGroup.get('birthDate').valid) {
        osakas.birthDate = this._dateService.dateToLocalDate(value)
      }
    })

    osakasFormGroup.get('onkoYritys').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      osakas.onkoYritys = value ?? null
      if (value) {
        osakasFormGroup.get('birthDate').disable()
      } else {
        osakasFormGroup.get('birthDate').enable()
      }
    })

    // Note! Do not change order of these setValues
    osakasFormGroup.get('onkoYritys').setValue(osakas.onkoYritys)
    osakasFormGroup.get('noFinnishId').setValue(osakas.noFinnishId)

    this.osakkaatFormArray.push(osakasFormGroup)
    this._tunnistamistiedot.osakkaat.push(osakas)
  }

  poistaHallituksenJasen(index: number) {
    this.hallitusFormArray.removeAt(index)
    this._tunnistamistiedot.hallitus.splice(index, 1)
  }

  lisaaHallituksenJasen(firestoresta?: CustomerHallituksenJasen) {
    const jasen: CustomerHallituksenJasen = firestoresta ? firestoresta : {
      hetuTaiYtunnus: '',
      nimi: '',
      kansallisuus: null,
      pep: null,
      rooli: null
    }

    const jasenFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(jasen.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(jasen.hetuTaiYtunnus, [Validators.required, LemonaidValidators.finnishPersonalIdValidator]),
      'kansallisuus': new UntypedFormControl(jasen.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(jasen.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(null),
      'birthDate': new UntypedFormControl({ value: jasen.birthDate ? this._dateService.localDateToDate(jasen.birthDate) : null, disabled: true }, Validators.required),
      'rooli': new UntypedFormControl(jasen.rooli, [Validators.required])
    })

    jasenFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.nimi = value || null })
    jasenFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.hetuTaiYtunnus = value || null })
    jasenFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.kansallisuus = value || null })
    jasenFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.pep = value ?? null })
    jasenFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      jasen.noFinnishId = value ?? false
      if (jasen.noFinnishId) {
        jasenFormGroup.get('birthDate').enable()
        jasenFormGroup.get('hetu').removeValidators(LemonaidValidators.finnishPersonalIdValidator)
      } else {
        jasenFormGroup.get('birthDate').disable()
        jasenFormGroup.get('hetu').addValidators(LemonaidValidators.finnishPersonalIdValidator)
      }
      jasenFormGroup.get('hetu').updateValueAndValidity()
    })
    jasenFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && jasenFormGroup.get('birthDate').valid) {
        jasen.birthDate = this._dateService.dateToLocalDate(value)
      }
    })
    jasenFormGroup.get('rooli').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.rooli = value || null })


    jasenFormGroup.get('noFinnishId').setValue(jasen.noFinnishId)
    this.hallitusFormArray.push(jasenFormGroup)
    this._tunnistamistiedot.hallitus.push(jasen)
  }

  poistaEdunsaaja(index: number) {
    this.saajaFormArray.removeAt(index)
    this._tunnistamistiedot.edunsaajat.splice(index, 1)
  }

  lisaaEdunsaaja(firestoresta?: CustomerBeneficiary) {
    const saaja: CustomerBeneficiary = firestoresta ? firestoresta : {
      hetuTaiYtunnus: '',
      nimi: '',
      kansallisuus: null,
      pep: null
    }

    const saajaFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(saaja.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(saaja.hetuTaiYtunnus, [Validators.required, LemonaidValidators.finnishPersonalIdValidator]),
      'kansallisuus': new UntypedFormControl(saaja.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(saaja.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(null),
      'birthDate': new UntypedFormControl({ value: saaja.birthDate ? this._dateService.localDateToDate(saaja.birthDate) : null, disabled: true }, Validators.required)
    })

    saajaFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.nimi = value || null })
    saajaFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.hetuTaiYtunnus = value || null })
    saajaFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.kansallisuus = value || null })
    saajaFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.pep = value ?? null })
    saajaFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      saaja.noFinnishId = value ?? false
      if (saaja.noFinnishId) {
        saajaFormGroup.get('birthDate').enable()
        saajaFormGroup.get('hetu').removeValidators(LemonaidValidators.finnishPersonalIdValidator)
      } else {
        saajaFormGroup.get('birthDate').disable()
        saajaFormGroup.get('hetu').addValidators(LemonaidValidators.finnishPersonalIdValidator)
      }
      saajaFormGroup.get('hetu').updateValueAndValidity()
    })
    saajaFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && saajaFormGroup.get('birthDate').valid) {
        saaja.birthDate = this._dateService.dateToLocalDate(value)
      }
    })

    saajaFormGroup.get('noFinnishId').setValue(saaja.noFinnishId)

    this.saajaFormArray.push(saajaFormGroup)
    this._tunnistamistiedot.edunsaajat.push(saaja)
  }

  tallennaKyc() {
    if (!this.tuntemistiedotFormGroup.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.tuntemistiedotFormGroup)
      return
    }

    // Show loading indicator
    this._ladataanService.aloitaLataaminen()

    // Remove unnecessary data (birthDate & onkoYritys fields are disabled when the osakas has a Finnish hetu/y-tunnus)
    for (const osakas of this._tunnistamistiedot.osakkaat) {
      if (!osakas.noFinnishId) {
        osakas.birthDate = null
        osakas.onkoYritys = null
      }
    }

    // Call CF
    const tallennusData: KnowYourCustomerSaveRequest = {
      kyc: this._tunnistamistiedot
    }
    return this._firebaseLemonaid.functionsCall<KnowYourCustomerSaveRequest, ErrorResponse>('knowYourCustomerTallennus', tallennusData).then(response => {

      // Stop loading indicator
      this._ladataanService.lopetaLataaminen()

      // If no response, or response contains an error
      if (!response || response.e) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        // All good, close dialog
        this._dialogRef.close()
      }

    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(error)
    })

  }

  postpone() {
    this._tuntemistiedotDialogService.postpone()
    this._dialogRef.close()
  }

  openPepText() {
    this._dialog.open(PepTekstiSharedDialog)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  isCompanysOwnYtunnusValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!!this._ytunnus &&
      this._ytunnus === control.value?.trim()
    ) {
      return { ownytunnus: true }
    }
    return null
  }
}
