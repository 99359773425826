import { EnvironmentType, Environment } from '../app/app.environment'
import { LemonaidFirebaseConfig } from '../app/_jaettu-angular/base-firebase.service'

export const lemonaidFirebaseConfig: LemonaidFirebaseConfig = {
  apiKey: 'AIzaSyDZyAXlW9fQf-8Y4yYOAj1w3r2lKFsfthQ',
  authDomain: 'eu-lemonaid.firebaseapp.com',
  databaseURL: 'https://eu-lemonaid-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'eu-lemonaid',
  storageBucket: 'eu-lemonaid.appspot.com',
  messagingSenderId: '749820318095',
  appId: '1:749820318095:web:57f3c04012c48c03df7bfa',
  measurementId: 'G-QCVP293B7L',
  functionsRegion: 'europe-west1'
}

// USA
// apiKey: 'AIzaSyCf2xZVo-4u5HeQmDp5lSM7asfPpJAEW9g',
// authDomain: 'lemonaid-9ef4a.firebaseapp.com',
// databaseURL: 'https://lemonaid-9ef4a.firebaseio.com',
// projectId: 'lemonaid-9ef4a',
// storageBucket: 'lemonaid-9ef4a.appspot.com',
// messagingSenderId: '1003840181206'

export const environment: Environment = {
  enableVersionCheck: false,
  environment: EnvironmentType.BETA,
  airbrakeEnabled: true,
  lemonaidFirebaseConfig: lemonaidFirebaseConfig,
  recaptchaSiteId: '6LdFOUghAAAAAF5ooHUynJsSZSLN0xJxgOzGsc2x',
  version: 'BETA'
}
