import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ErrorHandler } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatDialog, MatDialogConfig, MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Platform } from '@angular/cdk/platform'

import { LaskutFirestoreDataSource, LaskunLokalisoituTila, Hakukriteerit } from './laskut.firestore.datasource'
import { LaskuService } from '../_angular/service/lasku/lasku.service'
import { VersionTarkistusPalvelu } from '../_angular/service/version-tarkistus.palvelu'
import { LaskuKatseleComponentDataResolve, LaskuKatseleComponentExistingData, LaskutComponentData } from '../_angular/_resolvers/lasku.resolve'
import { LaskuSummatService } from '../_angular/service/lasku/lasku-summat.service'
import { KayttajaService } from '../_angular/service/kayttaja.service'

import { WindowSizeService } from '../_jaettu-angular/service/window.service'
import { LemonKuukausiDateAdapter } from '../_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { LoadingConfig } from '../_jaettu-angular/_components/loading.component'

import { FirestoreIndeksoija } from '../_jaettu/service/firestore.indeksoija'
import { DateService } from '../_shared-core/service/date.service'
import { LaskunTila, LaskunAsiakas, LaskunListaustietorivi, LaskunListaustyyppi, Lasku } from '../_jaettu/model/lasku'

import { LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog, LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialogData } from './dialogit/lasku.haluatko-varmasti-perua-maksetuksi-merkitsemisen.dialog'

import { Subject, iif, Observable, combineLatest, EMPTY, of as observableOf, firstValueFrom } from 'rxjs'
import { debounceTime, takeUntil, switchMap, throttleTime, map, startWith, take, distinctUntilChanged } from 'rxjs/operators'
import { AsetustenOletuksetService } from 'app/_angular/service/asetusten-oletukset.service'
import { LaskuTaydennaAsetuksetDialog } from './dialogit/lasku-taydenna-asetukset.dialog'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { LaskuKommenttiDialog, LaskuKommenttiDialogData } from './dialogit/lasku-kommentti.dialog'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'
import { BankCustomerConsent, BankDialogType, DialogToShowMetadata } from 'app/_jaettu/model/banks'
import { AiiaDialogData, ViiaLoginRequiredDialog } from 'app/banks/viia/viia-login-required.dialog'
import { HolviLoginRequiredDialog, HolviLoginRequiredDialogData } from 'app/banks/holvi/holvi-login-required.dialog'
import { StringService } from 'app/_shared-core/service/string.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

// export type TuoteProperties = 'nro' | 'asiakas' | 'pvm' | 'erapvm' | 'summa' | 'avoinna' | 'tila' | 'merkitse' | undefined

interface YhteensaRivi {
  valuutta: string
  avoinna: number
  laskutettu: number
}

@Component({
  selector: 'app-laskut',
  templateUrl: './laskut.component.html',
  styleUrls: ['./laskut.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] },
  ]
})
export class LaskutComponent implements OnInit, AfterViewInit, OnDestroy {

  // @ViewChild('laskutaulu', { static: true }) laskutaulu: ElementRef
  @ViewChild(MatPaginator, { static: true }) _paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  private _ngUnsubscribe = new Subject<void>()
  private _taydennaAsetuksetDialogi: MatDialogRef<LaskuTaydennaAsetuksetDialog> = null
  private _kommenttiDialogi: MatDialogRef<LaskuKommenttiDialog> = null

  form: UntypedFormGroup

  // propertiesToDisplay: TuoteProperties[] = []
  laskunTilatObservable: Observable<LaskunLokalisoituTila[]>
  tableViewOptionsObservable: Observable<{ showAddButton: boolean, showCommentsColumn: boolean }>

  nakyvatRivitObservable: Observable<LaskunListaustietorivi[]>
  yhteensaRivitObservable: Observable<YhteensaRivi[]>

  private _bankConsentObservable: Observable<BankCustomerConsent>
  showBankAuthNeededObservable: Observable<boolean>

  naytaEiYhtaanObservable: Observable<boolean>
  lataaObservable: Observable<boolean>
  name = 'asdf' + Math.random()

  countToAvoinna: number = 0
  countToEraantynyt: number = 0
  avoinnaTo: number = 0
  avoinnaFrom: number = 0
  eraantynytTo: number = 0
  eraantynytFrom: number = 0
  valuutta: string = ''

  private _alustaSummat = true
  private _pauser = new Subject<boolean>()

  loadingSettings: LoadingConfig = {
    fullScreenBackdrop: false,
    backdropBackgroundColour: 'rgba(0,0,0,0.15)',
    backdropBorderRadius: '4px',
    primaryColour: 'rgba(255, 255, 255, 1)',
    secondaryColour: 'rgba(255, 255, 255, 1)', // rgba(252, 238, 32, .15)
    tertiaryColour: 'rgba(255, 255, 255, 1)'
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private laskuService: LaskuService,
    private kayttajaService: KayttajaService,
    private laskuSummatService: LaskuSummatService,
    private firestoreIndeksoija: FirestoreIndeksoija,
    private laskuKatseleComponentDataResolve: LaskuKatseleComponentDataResolve,
    private windowSizeService: WindowSizeService,
    private translationService: LemonTranslationService,
    private dateAdapter: DateAdapter<Date>,
    private _dataSource: LaskutFirestoreDataSource,
    private versionTarkistaja: VersionTarkistusPalvelu,
    private asetustenOletuksetService: AsetustenOletuksetService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _bankConsentUriService: BankConsentUriService,
    private _stringService: StringService,
    private _ladataanService: LadataanService
  ) {
    this.lataaObservable = _dataSource.lataaObservable
    this.naytaEiYhtaanObservable = combineLatest([this._dataSource.rivienMaaraObservable, this._dataSource.lataaObservable]).pipe(
      map(([rivienMaara, lataa]) => {
        if (lataa) {
          return false
        }
        return rivienMaara < 1
      })
    )
  }

  ngOnInit() {

    this.versionTarkistaja.sovelluksenVersioObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(versiotiedot => {
      this.versionTarkistaja.tarkistaVersio(versiotiedot)
    })

    this.asetaListauksenKolumnit()

    this.translationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kieli => {
      this.dateAdapter.setLocale(kieli)
    })

    // this.laskuService.asetuksetObservable.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(asetukset => {
    //   if (asetukset && !asetukset.summahuomautusPiilotettu) {
    //     this.naytaHuomautus = true
    //   } else {
    //     this.naytaHuomautus = false
    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    this._pauser.pipe(
      switchMap(aktiivinen => {
        return iif(() => aktiivinen, this.laskuSummatService.summatObservable, EMPTY)
      }),
      throttleTime(1100, undefined, { leading: true, trailing: true }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(summat => {
      // console.log('summat', JSON.stringify(summat))
      if (summat) {

        // Mikä valuutta
        this.valuutta = summat.valuutta

        // Mistä rullataan
        this.avoinnaFrom = summat.avoinnaAlkuperainen
        this.eraantynytFrom = summat.eraantynytAlkuperainen

        // Mihin rullataan
        if (this._alustaSummat) {
          this._alustaSummat = false
          setTimeout(() => {
            this.avoinnaTo = summat.avoinna
            this.eraantynytTo = summat.eraantynyt
          }, 150)
        } else {
          this.avoinnaTo = summat.avoinna
          this.eraantynytTo = summat.eraantynyt
        }

      }
    }, error => {
      this.errorHandler.handleError(error)
    })
    this._pauser.next(true)

    this.laskunTilatObservable = this.dataSource.laskunTilatObservable
    this.tableViewOptionsObservable = this.kayttajaService.kayttajanTiedotObservable.pipe(
      map(kayttaja => {
        return { showAddButton: kayttaja?.asiakasId !== '9', showCommentsColumn: kayttaja?.asiakasId === '9' }
      }),
      startWith({ showAddButton: true, showCommentsColumn: false })
    )

    this._bankConsentObservable = this.kayttajaService.kayttajanTiedotObservable.pipe(
      distinctUntilChanged((prev, curr) => {
        return prev?.asiakasAvain !== curr?.asiakasAvain
      }),
      switchMap(kayttajanTiedot => {
        if (!kayttajanTiedot?.asiakasAvain) {
          return observableOf<BankCustomerConsent>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<BankCustomerConsent>(this._bankConsentUriService.getBankConsentUri(kayttajanTiedot.asiakasAvain)).listen()
      })
    )

    this.showBankAuthNeededObservable = this.laskuService.asetuksetObservable
      .pipe(
        switchMap(laskuasetukset => {
          if (!laskuasetukset?.maksupReskontraActive) {
            return observableOf<boolean>(false)
          }
          return this._bankConsentObservable.pipe(
            map(bankConsent => {
              if (!bankConsent || bankConsent.dialogCompletePreventReShow?.length) {
                return false
              }
              return bankConsent.dialogsToShow?.some(d => d.ibansMissing?.includes(this._stringService.removeAllWhiteSpaces(laskuasetukset.iban)))
            })
          )
        })
      )

    // Luo form, HUOM! Default arvot laitetaan hakutiedotObservable subscribessa
    this.form = new UntypedFormGroup({
      'nimiFirestore': new UntypedFormControl(null, []),
      'vuosikk': new UntypedFormControl(null, []),
      // 'vuosikkkohde': new FormControl( vuosiKk.kohde, [] ),
      'tila': new UntypedFormControl(null, [])
    })

    // Jos hakutiedot muuttuvat, muuta käyttöliittymää sen mukaan
    this.dataSource.hakutiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(hakutiedot => {

      if (this.form.get('nimiFirestore').value !== hakutiedot.hakukriteerit.vapaahaku) {
        this.form.get('nimiFirestore').setValue(hakutiedot.hakukriteerit.vapaahaku, { emitModelToViewChange: true, emitViewToModelChange: false })
      }

      this._setKk(hakutiedot.hakukriteerit)
      // if (this.form.get('vuosikkkohde').value !== hakutiedot.hakukriteerit.vuosikk.kohde) {
      // this.form.get('vuosikkkohde'.setValue(vuosiKk.kohde, { emitModelToViewChange: true, emitViewToModelChange: false })
      // }
      if (this.form.get('tila').value !== hakutiedot.hakukriteerit.tila) {
        this.form.get('tila').setValue(hakutiedot.hakukriteerit.tila, { emitModelToViewChange: true, emitViewToModelChange: false })
      }
    })

    // this.form.get('vuosikkkohde').valueChanges.subscribe((value: 'p' | 'e') => {
    //   this.dataSource.vuosiKkKohde = value
    // })
    this.form.get('vuosikk').valueChanges.subscribe((value: Date) => {
      console.log('asetettu vuosikk; ', value)
      if (value) {
        this.dataSource.setVuosiKk(value.getFullYear(), value.getMonth())
      } else {
        this.dataSource.setVuosiKk(null, null)
      }
    })
    this.form.get('tila').valueChanges.subscribe((value: LaskunTila) => {
      this.dataSource.tila = value
    })
    this.form.get('nimiFirestore').valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        const val = this.firestoreIndeksoija.poistaValimerkitJaValilyonnit(value)
        if (val.length > 2) {
          this.dataSource.search = value
        } else {
          this.dataSource.search = null
        }
      } else {
        this.dataSource.search = null
      }
    })

    const currentSort = this.dataSource.sort
    this.sort.sort({
      id: currentSort.active,
      disableClear: true,
      start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    })
    this.sort.sortChange.subscribe(sort => {
      this.dataSource.sort = sort
    }, error => {
      this.errorHandler.handleError(error)
    })

    this._paginator.page.subscribe(page => {
      this.dataSource.page = page
    }, error => {
      this.errorHandler.handleError(error)
    })

    this.route.data.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe((data: { data: LaskutComponentData }) => {
      if (data && data.data && data.data.nollaaHakuasetukset) {
        this.dataSource.resetSearchToDefaults()
      }
    }, error => {
      this.errorHandler.handleError(error)
    })


    this.nakyvatRivitObservable = this.dataSource.dataSourceTransformed.connect(null)
    this.yhteensaRivitObservable = this.nakyvatRivitObservable.pipe(
      map(laskut => {
        const valuuttaMap: { [valuuttaKey: string]: YhteensaRivi } = {}
        for (const lasku of laskut) {
          let valuutanYhteensaRivi = valuuttaMap[lasku.valuutta]
          if (!valuutanYhteensaRivi) {
            valuutanYhteensaRivi = {
              avoinna: 0,
              laskutettu: 0,
              valuutta: lasku.valuutta
            }
            valuuttaMap[lasku.valuutta] = valuutanYhteensaRivi
          }
          if (lasku.tila !== LaskunTila.luonnos && (lasku.tyyppi === LaskunListaustyyppi.TAVALLINEN || lasku.tyyppi === LaskunListaustyyppi.VANHEMPI)) {
            // console.log('Lasketaan avoinna ' + lasku.nro, lasku.avoinna)
            valuutanYhteensaRivi.avoinna += lasku.avoinna
          }
          valuutanYhteensaRivi.laskutettu += lasku.summa
          // console.log('Lasketaan laskutettu ' + lasku.nro, lasku.summa)
        }
        return Object.keys(valuuttaMap).map(a => valuuttaMap[a]).sort((a, b) => { return a.laskutettu - b.laskutettu })
      }),
      lemonShare()
    )

  }

  private _setKk(hakukriteerit: Hakukriteerit) {
    const vuosiKk2 = hakukriteerit.vuosikk
    if (
      vuosiKk2 &&
      vuosiKk2.kk !== null &&
      vuosiKk2.kk !== undefined &&
      vuosiKk2.vuosi !== null &&
      vuosiKk2.vuosi !== undefined
    ) {
      const date2 = new Date(vuosiKk2.vuosi, vuosiKk2.kk, 1)
      if (!this.form.get('vuosikk').value) {
        this.form.get('vuosikk').setValue(date2, { emitModelToViewChange: true, emitViewToModelChange: false })
      } else {
        const existingDate = this.form.get('vuosikk').value as Date
        if (existingDate.getFullYear() !== date2.getFullYear() || existingDate.getMonth() !== date2.getMonth()) {
          this.form.get('vuosikk').setValue(date2, { emitModelToViewChange: true, emitViewToModelChange: false })
        }
      }
    } else {
      if (this.form.get('vuosikk').value !== null) {
        this.form.get('vuosikk').setValue(null, { emitModelToViewChange: true, emitViewToModelChange: false })
      }
    }
  }

  get dataSource(): LaskutFirestoreDataSource {
    return this._dataSource
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.form.get('vuosikk').setValue(normalizedMonth)
    datepicker.close()
  }

  ngAfterViewInit() {
    this.windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(koko => {
      setTimeout(() => {
        this.asetaListauksenKolumnit()
      }, 250)
    })
  }

  private asetaListauksenKolumnit() {

    // this.naytaNro = true
    // this.naytaPvm = true
    // this.naytaSumma = true

    // const leveys = this.laskutaulu.nativeElement.offsetWidth
    // // if (tablenKoko < 500) {
    // //   this.propertiesToDisplay = [               'asiakas',         'erapvm',         'avoinna', 'merkitse']
    // // } else
    // if (leveys < 600) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'erapvm', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    //   this.naytaPvm = false
    //   this.naytaSumma = false
    // } else if (leveys < 700) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'erapvm', 'summa', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    //   this.naytaPvm = false
    // } else if (leveys < 800) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'pvm', 'erapvm', 'summa', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    // } else {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'pvm', 'erapvm', 'summa', 'avoinna', 'merkitse']
    // }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  getDisplayFn() {
    return (val) => this.display(val)
  }

  private display(laskunAsiakas: LaskunAsiakas): string {
    if (laskunAsiakas && laskunAsiakas.nimi) {
      return laskunAsiakas.nimi
    }
    return ''
  }

  onCountToEnd() {
    this.laskuSummatService.numerotRullattiin()
  }

  muokkaa(rivi: LaskunListaustietorivi) {

    const existing: LaskuKatseleComponentExistingData = {
      juurilasku: rivi.juurilasku,
      kasiteltava: rivi.kasiteltava
    }
    this.laskuKatseleComponentDataResolve.asetaOlemassaolevaData(existing)
    this.router.navigate(['/laskutus/laskut/', rivi.juuriAvain, rivi.avain, 'katsele'])

  }

  lisaa() {

    this.laskuService.asetuksetObservable.pipe(
      take(1)
    ).subscribe(asetukset => {
      if (asetukset) {
        if (this.asetustenOletuksetService.tarkistaPuuttuukoLaskuasetuksia(asetukset)) {
          this.naytaTaydennaAsetuksetDialogi()
        } else {
          // Sulje dialogi, jos sellainen oli
          if (this._taydennaAsetuksetDialogi) {
            this._taydennaAsetuksetDialogi.close()
            this._taydennaAsetuksetDialogi = null
          }
          this.router.navigate(['/laskutus/laskut/', 'uusi', 'uusi'])
        }
      }
    })

  }

  private naytaTaydennaAsetuksetDialogi() {
    if (!this._taydennaAsetuksetDialogi) {
      const kaytettavaLeveys = window.innerWidth
      const kaytettavaKorkeus = window.innerHeight
      const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
      const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 750 ? '85vh' : '92vh'

      const settings: MatDialogConfig = {
        maxWidth: '900px',
        maxHeight: korkeus,
        width: leveys,
        data: {
          mobiili: kaytettavaLeveys < 670
        }
      }

      this._taydennaAsetuksetDialogi = this._dialog.open(LaskuTaydennaAsetuksetDialog, settings)
      this._taydennaAsetuksetDialogi.afterClosed().subscribe(result => {
        this._taydennaAsetuksetDialogi = null
        if (result === 'onnistui') {
          this.router.navigate(['/laskutus/laskut/', 'uusi', 'uusi'])
        }
      })
    }
  }

  async merkitseMaksetuksi(rivi: LaskunListaustietorivi) {
    if (this.vertaaEkaPienempiKuinToka(0, rivi.avoinna)) {
      await this.laskuService.merkitseLaskuTaysinMaksetuksi(rivi)
    } else {
      const data: LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialogData = {
        rivi: rivi
      }
      const settings: MatDialogConfig = {
        data: data,
        panelClass: 'ilman-paddingia',
        maxWidth: '1600px'
        // ,
        // maxHeight: korkeus,
        // width: leveys
      }
      this._pauser.next(false)
      this._dialog.open(LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog, settings)
        .afterClosed()
        .subscribe(() => {
          this._pauser.next(true)
        })
    }
  }

  vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) === Math.round(kaksi * 100)
  }

  vertaaEriSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) !== Math.round(kaksi * 100)
  }

  vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
    return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  }

  modifyComment(lasku: Lasku) {

    const kaytettavaLeveys = window.innerWidth
    const kaytettavaKorkeus = window.innerHeight
    const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
    const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 750 ? '85vh' : '92vh'

    const settings: MatDialogConfig<LaskuKommenttiDialogData> = {
      maxWidth: '900px',
      maxHeight: korkeus,
      width: leveys,
      data: { lasku: lasku }
    }
    if (this._kommenttiDialogi) {
      return
    }
    this._kommenttiDialogi = this._dialog.open(LaskuKommenttiDialog, settings)
    this._kommenttiDialogi.afterClosed().subscribe(result => {
      this._kommenttiDialogi = null
      if (result === 'onnistui') { /* NOOP */ }
    })

  }

  async openBankConsentDialog() {
    try {
      this._ladataanService.aloitaLataaminen()
      const consentPromise = firstValueFrom(this._bankConsentObservable)
      const laskuasetuksetPromise = firstValueFrom(this.laskuService.asetuksetObservable)

      const [consent, laskuasetukset] = await Promise.all([consentPromise, laskuasetuksetPromise])
      const dialogMetadata = laskuasetukset?.iban ? consent?.dialogsToShow?.find(metadata => metadata.ibansMissing?.includes(this._stringService.removeAllWhiteSpaces(laskuasetukset.iban))) : null
      if (!dialogMetadata) {
        return
      }

      if (dialogMetadata.dialogToShow === BankDialogType.GENERAL || dialogMetadata.dialogToShow === BankDialogType.GENERAL_WO_HOLVI || dialogMetadata.dialogToShow === BankDialogType.PSD2_RENEW) {
        return this._openAiiaDialog(dialogMetadata)
      }
      if (dialogMetadata.dialogToShow === BankDialogType.HOLVI_RENEW) {
        return this._openHolviDialog(dialogMetadata)
      }
      throw new Error('Unknown dialog type ' + dialogMetadata.dialogToShow)

    } catch (err) {
      this.errorHandler.handleError(new Error('Failed to open bank dialog: ' + err?.message))

    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private _openAiiaDialog(dialogToShow: DialogToShowMetadata) {
    const settings: MatDialogConfig<AiiaDialogData> = {
      data: {
        dialogToShowMetadata: dialogToShow ?? null
      }
    }
    return this._dialog.open<ViiaLoginRequiredDialog, AiiaDialogData>(ViiaLoginRequiredDialog, settings)
  }

  private _openHolviDialog(dialogToShow: DialogToShowMetadata) {
    const settings: MatDialogConfig<HolviLoginRequiredDialogData> = {
      data: {}
    }
    return this._dialog.open<HolviLoginRequiredDialog, HolviLoginRequiredDialogData>(HolviLoginRequiredDialog, settings)
  }

}
