import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { PostmarkBounceWebHookData } from '../../_jaettu/model/lasku'

export interface LaskuKokoVirheDialogData {
  virhe: PostmarkBounceWebHookData
}

@Component({
  selector: 'lasku-koko-virhe-dialog',
  templateUrl: './lasku.koko-virhe.dialog.html'
})
export class LaskuKokoVirheDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LaskuKokoVirheDialogData,
    private dialogRef: MatDialogRef<LaskuKokoVirheDialog>
  ) {

  }

  ngOnInit() {

  }

}
