import { Injectable } from '@angular/core'
import { AiiaOauthConnectRequest, AiiaOauthConnectResponse, ViiaConsentChangeReason } from 'app/_jaettu/model/aiia'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { FirebaseLemonaid } from './firebase-lemonaid.service'
import { BankConsentLog } from 'app/_jaettu/model/banks'
import { Timestamp } from 'firebase/firestore'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'

@Injectable()
export class AiiaDialogService {
  private _aiiaFlowCompletedAt: Date = null
  private _postponeStarted: Date = null

  constructor(
    private _timestampService: TimestampService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _bankConsentUriService: BankConsentUriService
  ) { }

  setAiiaFlowCompleted() {
    this._aiiaFlowCompletedAt = new Date()
  }

  aiiaFlowCompletedRecently(): boolean {
    if (!this._aiiaFlowCompletedAt) {
      return false
    }
    const oneHourInMilliseconds = 60 * 60 * 1000
    return new Date().getTime() - this._aiiaFlowCompletedAt.getTime() < oneHourInMilliseconds
  }

  postpone() {
    this._postponeStarted = new Date()
  }

  postponedRecently(): boolean {
    if (!this._postponeStarted) {
      return false
    }
    const oneHourInMilliseconds = 60 * 60 * 1000
    return new Date().getTime() - this._postponeStarted.getTime() < oneHourInMilliseconds
  }

  async fetchAiiaOauthStartUrl(asiakasAvain: string, data?: AiiaOauthConnectRequest): Promise<string> {
    const resp = await this._firebaseLemonaid.functionsCall<AiiaOauthConnectRequest, AiiaOauthConnectResponse>('aiiaOauthConnectStartProd', data)
    if (!resp || resp.e) {
      await this.saveHistory(asiakasAvain, 'supervised login redirect failed', resp?.e)
      throw new Error(resp ? resp.e : 'unknown-error')
    }
    await this.saveHistory(asiakasAvain, 'supervised login redirect successful', null)
    return resp.redirectUrl
  }

  saveHistory(asiakasAvain: string, changedReason: ViiaConsentChangeReason, details: string) {
    const logData: BankConsentLog = {
      asiakasAvain: asiakasAvain,
      source: 'aiia',
      changedTime: Timestamp.now(),
      changedReason: changedReason,
      details: details ?? null
    }
    const avain = this._firebaseLemonaid.firestoreCreateId()
    const uri = this._bankConsentUriService.getBankConsentLogUri(asiakasAvain, avain)

    return this._firebaseLemonaid.firestoreSetData(uri, logData)
  }

}
