<div mat-dialog-content>
  <lemon-dialog-header>
    Valitse kieli / Choose language
    <!-- Ändra språk /-->
  </lemon-dialog-header>
  <mat-radio-group [(ngModel)]="kieli" cdkFocusRegionstart>
    <mat-radio-button value="fi" style="display: block; margin: 0 1em;">Suomi</mat-radio-button>
    <!-- <mat-radio-button value="sv" style="display: block; margin: 0 1em;">Svenska</mat-radio-button> -->
    <mat-radio-button value="en" style="display: block; margin: 0 1em;">English</mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
</div>