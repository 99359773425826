import { Component, OnInit, AfterViewInit, AfterContentChecked, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'

import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { Lasku, LaskuBase } from '../../_jaettu/model/lasku'
import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'


export interface LaskuHaluatkoVarmastiMitatoidaLaskunDialogData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
}

@Component({
  selector: 'lasku-haluatko-varmasti-mitatoida-laskun-dialog',
  templateUrl: './lasku.haluatko-varmasti-mitatoida-laskun.dialog.html'
})
export class LaskuHaluatkoVarmastiMitatoidaLaskunDialog implements OnInit, AfterViewInit, AfterContentChecked {

  voidaanMitatoida = false

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: LaskuHaluatkoVarmastiMitatoidaLaskunDialogData,
    private dialogRef: MatDialogRef<LaskuHaluatkoVarmastiMitatoidaLaskunDialog>,
    private validationService: FormValidationService,
    private laskuService: LaskuService,
    private errorHandler: ErrorHandler,
    private sharedLaskuService: LaskuSharedService,
    private currencyService: CurrencyService,
    private ladataanService: LadataanService
  ) {
    this.voidaanMitatoida = this.vertaaYhtaSuuret(data.kasiteltava.summa, data.kasiteltava.avoinna)
    console.log(this.voidaanMitatoida, data.kasiteltava.summa, data.kasiteltava.avoinna)
  }

  ngAfterContentChecked() {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  async mitatoi() {
    try {
      this.ladataanService.aloitaLataaminen()
      const kasiteltava = await this.laskuService.mitatoiLasku(this.data.juurilasku)
      this.ladataanService.lopetaLataaminen()
      this.dialogRef.close(kasiteltava)
    } catch (err) {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
    }
  }

  vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) === Math.round(kaksi * 100)
  }

  vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
    return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  }

}
