import { Component, Inject, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'

import { Lasku } from '../../_jaettu/model/lasku'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { Subject } from 'rxjs'

export interface LaskuKommenttiDialogData {
  lasku: Lasku
}

@Component({
  templateUrl: './lasku-kommentti.dialog.html'
})
export class LaskuKommenttiDialog implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  namename = 'ogiujhnmfogiueufmbgjshfaklk' + Math.random()
  kommenttiForm: UntypedFormGroup = null

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: LaskuKommenttiDialogData,
    private dialogRef: MatDialogRef<LaskuKommenttiDialog>,
    private laskuService: LaskuService,
    private ladataanService: LadataanService,
    private errorHandler: ErrorHandler
  ) {
    // Luo form
    this.kommenttiForm = new UntypedFormGroup({
      'kommentti': new UntypedFormControl(data.lasku.kommentti ?? '', [])
    })
  }

  ngOnInit() {

  }

  get kommentti(): UntypedFormControl {
    return this.kommenttiForm.get('kommentti') as UntypedFormControl
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  tallenna() {
    this.ladataanService.aloitaLataaminen()
    this.laskuService.tallennaKommentti(this.data.lasku, this.kommentti.value).then(() => {
      this.ladataanService.lopetaLataaminen()
      this.dialogRef.close('onnistui')
    }).catch(err => {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
    })
  }

}
