import { Component, OnInit, AfterViewInit, AfterContentChecked, Inject, ViewChild, ErrorHandler } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'

import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { Lasku, LaskuBase, LaskuReskontra } from '../../_jaettu/model/lasku'
import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

export interface LaskuMerkitseOsittainMaksetuksiDialogData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  reskontra: LaskuReskontra[]
}

@Component({
  selector: 'lasku-merkitse-osittain-maksetuksi-dialog',
  templateUrl: './lasku.merkitse-osittain-maksetuksi.dialog.html'
})
export class LaskuMerkitseOsittainMaksetuksiDialog implements OnInit, AfterViewInit, AfterContentChecked {

  @ViewChild('maksettuMaaraInput', { read: MatInput, static: true }) maksettuMaaraInput: MatInput

  form: UntypedFormGroup
  fieldErrors: FieldErrors = {}
  kasiteltava: LaskuBase = null

  maksettuMaara: number = 0
  avoinna: number = 0

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: LaskuMerkitseOsittainMaksetuksiDialogData,
    private errorHandler: ErrorHandler,
    private dialogRef: MatDialogRef<LaskuMerkitseOsittainMaksetuksiDialog>,
    private validationService: FormValidationService,
    private laskuService: LaskuService,
    private sharedLaskuService: LaskuSharedService,
    private currencyService: CurrencyService,
    private ladataanService: LadataanService
  ) {
    this.kasiteltava = data.kasiteltava
    const summatJaTila = this.sharedLaskuService.annaLaskunSummatJaTilaLaskulleReskontralla(data.juurilasku, data.reskontra)
    this.avoinna = this.currencyService.muutaBigDecimalRahaksi(summatJaTila.avoinna)
    this.maksettuMaara = this.avoinna
  }

  ngAfterContentChecked() {
    this.fieldErrors = this.validationService.updateValidationStatus('lasku.merkitse-osittain-maksetuksi', this.form)
  }

  ngOnInit() {

    this.form = new UntypedFormGroup({
      'avoinna': new UntypedFormControl({ value: this.avoinna, disabled: true }),
      'maksettuMaara': new UntypedFormControl(this.maksettuMaara, [Validators.required]),
      'avoimeksijaa': new UntypedFormControl({ value: 0, disabled: true })
    })

    this.form.get('maksettuMaara').valueChanges.subscribe(value => {
      this.maksettuMaara = value
      this.form.get('avoimeksijaa').setValue(this.avoinna - this.maksettuMaara)
    })

  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.maksettuMaaraInput) {
        this.maksettuMaaraInput.focus()
      }
    }, 25)
  }

  async lisaaMaksettuaSummaa() {
    this.ladataanService.aloitaLataaminen()
    this.laskuService.lisaaReskontraMerkinta(this.data.juurilasku, this.maksettuMaara).then(() => {
      this.dialogRef.close()
      this.ladataanService.lopetaLataaminen()
    }).catch(error => {
      this.errorHandler.handleError(error)
      this.ladataanService.lopetaLataaminen()
    })
  }

}
