<div class="list-page kapea tositteet-listaus-page" [class.dragging]="isDragging" style="padding: 0;">

  <form [formGroup]="form" novalidate class="ylaosan-kontrollit">

    <div class="lisaa-nappula-container">
      <button mat-button (click)="naytaTiedostonValintaDialogi()" type="button" class="primarybutton mat-elevation-z4">
        <i class="icon ion-ios-cloud-upload mat-icon"></i>
        {{'tositteet.palkka.listaus.lisaa' | translate | async}}
      </button>
    </div>

    <div class="hakukontrollit">

      <mat-form-field>
        <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
        <mat-label>{{ 'tositteet.palkka.listaus.etsi' | translate | async }}</mat-label>
        <input type="text" formControlName="nimiFirestore" matInput />
      </mat-form-field>

    </div>

  </form>

  <div *ngIf="kuvavirheAvain" class="red-text" style="text-align: center; padding-left: 10vw; padding-right: 10vw;">{{kuvavirheAvain | translate: kuvavirheParametrit | async}}</div>

  <div style="padding-top: 20px;">

    <div *ngIf="showSelvitettavatAndMainMaksutapaObservable | async" style="display: flex; width: 100%; flex-flow: row wrap; align-items: top; justify-content: center; padding-top: 25px;">

      <app-selvitettavat-maksutapa style="margin: 20px; align-self: flex-start;" [insertValues]="selvitettavienTiedotObservable | async" [selvitettavatValittu]="selvitettavatValittuObservable | async" (valitsiSelvitettavat)="selvitettavatValittu()">
      </app-selvitettavat-maksutapa>

      <ngx-file-drop #droppi *ngIf="(maksutapaJaKuvaObservable | async) as maksutapaJaKuva" dropZoneClassName="maksutapa-container" [class.valittu-maksutapa]="maksutapaJaKuva.valittu && !isDragging" (click)="valitseMainMaksutapa()" (onFileOver)="fileOver ($event, maksutapaJaKuva)" (onFileDrop)="fileDrop ($event)" (onFileLeave)="fileLeave($event, maksutapaJaKuva)" class="droppi">
        <ng-template ngx-file-drop-content-tmp>
          <div class="maksutapa-laatikko" [class.lemon-mat-elevation-z4]="!isDragging">

            <div class="kuittien-maara-maksutavalle" *ngIf="!isDragging" [class.light-gray-text]="!maksutapaJaKuva.valittu" [class.light-blue-text]="maksutapaJaKuva.valittu">
              {{ maksutapaJaKuva.lukumaara }}
            </div>

            <img [src]="maksutapaJaKuva.kuva | async" [class.light-gray-background]="(!maksutapaJaKuva.valittu && !isDragging) || (!droppi.isDraggingOverDropZone && isDragging)" [class.light-blue-background]="( maksutapaJaKuva.valittu && !isDragging) || ( droppi.isDraggingOverDropZone && isDragging)" />

            <div class="maksutavan-nimi-container" [class.light-gray-text]="(!maksutapaJaKuva.valittu && !isDragging) || (!droppi.isDraggingOverDropZone && isDragging)" [class.light-blue-text]="( maksutapaJaKuva.valittu && !isDragging) || ( droppi.isDraggingOverDropZone && isDragging)">
              <span style="display: inline-block; vertical-align: middle;">
                {{maksutapaJaKuva.maksutapa.l}}
              </span>
            </div>

          </div>

          <div class="maksutapa-summa" *ngIf="!isDragging" [class.light-gray-text]="!maksutapaJaKuva.valittu" [class.light-blue-text]=" maksutapaJaKuva.valittu">
            {{ maksutapaJaKuva.summa | lmoney: 'EUR' | async }}
          </div>

          <div class="veda-tanne light-blue-text" *ngIf="isDragging">
            {{'kuitit.veda-tanne' | translate | async}}
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>

    <div *ngIf="isDragging && !(showSelvitettavatAndMainMaksutapaObservable | async)" style="margin-top: 40px;">
      <ngx-file-drop #droppi dropZoneClassName="drag-container" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (onFileDrop)="fileDrop($event)" class="droppi">
        <ng-template ngx-file-drop-content-tmp>
          <div class="drag-laatikko" [style.background-color]="droppi.isDraggingOverDropZone && isDragging ? 'lightgray' : 'white'">
            <div>{{'tositteet.palkka.listaus.rahaa-tanne' | translate | async}}</div>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>

    <app-selvitettavat-tositteet *ngIf="(showSelvitettavatAndMainMaksutapaObservable | async) && (selvitettavatValittuObservable | async)" [showLoading]="false"></app-selvitettavat-tositteet>

  </div>

  <div *ngIf="(!(showSelvitettavatAndMainMaksutapaObservable | async) ||  !(selvitettavatValittuObservable | async))">

    <div class="kortti-container">
      <ng-container *ngFor="let kuitti of naytettavatKuititObservable | async; trackBy: trackKuitti">
        <div class="kuitti-laatikko lemon-mat-elevation-z4" (click)="katsele(kuitti)">

          <mat-icon *ngIf="vihreaPilvi(kuitti); else keltsupilvi" style="position: absolute; right: 5px; top: 5px;" class="green-text">cloud_done</mat-icon>
          <ng-template #keltsupilvi>
            <mat-icon style="position: absolute; right: 8px; top: 5px;" class="yellow-text flashit">cloud_upload</mat-icon>
          </ng-template>

          <div app-tosite-kuva [url]="kuitti.ensimmainenKuva" class="kuva-container" [thumbnail]="true"></div>

          <div class="tiedot-container">
            <div class="pvm dark-gray-text">{{ kuitti.localPvm | lokaalipvm | async }}</div>
            <!-- <div class="summa light-blue-text">{{ kuitti.summa | lmoney: 'EUR' | async }}</div> -->
            <div class="viiva light-gray-background"></div>
            <div class="selite dark-gray-text">{{ kuitti.selite }}</div>
          </div>

        </div>
      </ng-container>
    </div>

    <div class="table-ladataan" style="position: relative; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="naytaEiYhtaanObservable | async">
      <div class="dark-gray-text" style="width: 100%; padding-top: 50px; text-align: center; margin: 10px 40px;">
        {{'tositteet.palkka.listaus.ei-tositteita' | translate | async}}
      </div>
    </div>
    <div class="table-ladataan" style="position: relative; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="lataaObservable | async">
      <div class="dark-gray-text" style="width: 100%; padding-top: 50px; text-align: center; margin: 10px 40px; position:relative;">
        <div lemon-loading [show]="true"></div>
      </div>
    </div>
  </div>

</div>

<input style="visibility:hidden; height: 1px;" (change)="fileChanged($event)" type="file" #fileInput multiple="multiple" />
<!-- accept="image/*,application/pdf" -->