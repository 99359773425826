import { Component, ErrorHandler, Inject, Input } from '@angular/core'
import { TunnistautuminenNetsPankitComponent } from './pankit.component'
import { PaymentDueType, StartNewStrongAuthenticationSessionForPayments, StrongAuthenticationSessionInfo } from 'app/_jaettu/model/tunnistaminen'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { TunnistautuminenService } from 'app/_angular/service/tunnistautuminen.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { DOCUMENT } from '@angular/common'

export interface MaksutVahvistusData {
  maksut: string[]
  dueType: PaymentDueType
  paymentConfigId: string
}

@Component({
  selector: '[tunnistautuminen-nets-maksujen-vahvistus-pankit]',
  templateUrl: './pankit.component.html',
  styleUrls: ['./pankit.component.css']
})
export class TunnistautuminenNetsMaksujenVahvistusComponent extends TunnistautuminenNetsPankitComponent {

  @Input() maksutVahvistusData: MaksutVahvistusData

  constructor(
    @Inject(DOCUMENT) private __document: Document,
    private __kirjautunutKayttajaService: KayttajaService,
    private __ladataanService: LadataanService,
    private __errorHandler: ErrorHandler,
    private __tunnistautuminenService: TunnistautuminenService,
    protected __firebaseLemonaid: FirebaseLemonaid
  ) {
    super(__document, __kirjautunutKayttajaService, __ladataanService, __errorHandler, __tunnistautuminenService, __firebaseLemonaid)
  }

  protected override _haeUusiTunnistautumissessio(): Promise<StrongAuthenticationSessionInfo> {
    const request: StartNewStrongAuthenticationSessionForPayments = {
      action: this.actionAfterSuccess,
      maksut: this.maksutVahvistusData.maksut,
      dueType: this.maksutVahvistusData.dueType,
      paymentConfigId: this.maksutVahvistusData.paymentConfigId
    }
    return this.__firebaseLemonaid.functionsCall<StartNewStrongAuthenticationSessionForPayments, StrongAuthenticationSessionInfo>('tunnistautuminenNetsAloitaMaksut', request)
  }

}
