<div class="idp-container">
  <button mat-flat-button (click)="tunnistauduMobiilivarmenne()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/mv_70x70.png" alt=""></span>
    <span class="organization-name">Mobiilivarmenne</span>
  </button>
  <button mat-flat-button (click)="tunnistauduOp()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/osuuspankki_button_127x70.png" alt=""></span>
    <span class="organization-name">Osuuspankki</span>
  </button>
  <button mat-flat-button (click)="tunnistauduNordea()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/nordea_button_164x70.png" alt=""></span>
    <span class="organization-name">Nordea</span>
  </button>
  <button mat-flat-button (click)="tunnistauduDanske()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/danske_bank_button_70x70.png" alt=""></span>
    <span class="organization-name">Danske Bank</span>
  </button>
  <button mat-flat-button (click)="tunnistauduHandels()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/handelsbanken_button_190x70.png" alt=""></span>
    <span class="organization-name">Handelsbanken</span>
  </button>
  <button mat-flat-button (click)="tunnistauduAlands()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/ab_logo_green.png" alt=""></span>
    <span class="organization-name">Ålandsbanken</span>
  </button>
  <button mat-flat-button (click)="tunnistauduS()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/spankki_button_150x70.png" alt=""></span>
    <span class="organization-name">S-Pankki</span>
  </button>
  <button mat-flat-button (click)="tunnistauduAktia()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/Aktia20_logo.png" alt=""></span>
    <span class="organization-name">Aktia</span>
  </button>
  <button mat-flat-button (click)="tunnistauduPop()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/pop_button_60x70.png" alt=""></span>
    <span class="organization-name">POP Pankki</span>
  </button>
  <button mat-flat-button (click)="tunnistauduSaasto()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/saastopankki_button_60x70.png" alt=""></span>
    <span class="organization-name">Säästöpankki</span>
  </button>
  <button mat-flat-button (click)="tunnistauduOmaSaasto()">
    <span class="organization-logo"><img src="/assets/tunnistautuminen/omasaastopankki_button_103x70.png" alt=""></span>
    <span class="organization-name">Oma Säästöpankki</span>
  </button>
</div>