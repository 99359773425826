import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { AbstractControl, UntypedFormGroup, UntypedFormControl, Validators, ValidationErrors } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'
import { MatChipInputEvent } from '@angular/material/chips'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { MaaService, Maa } from '../../_jaettu-angular/service/maa.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { LaskunAsiakas } from '../../_jaettu/model/lasku'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'
import { SahkoisenLaskunValittajaService } from '../../_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'

import { AsiakasPoistoVarmistusDialogData, AsiakasPoistoVarmistusDialog } from './asiakas-poisto-varmistus.dialog'

import { Subject, Observable } from 'rxjs'
import { takeUntil, startWith, map } from 'rxjs/operators'
import { Verkkolaskuoperaattori } from '../../_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'


@Component({
  selector: 'app-asiakas',
  templateUrl: './asiakas.component.html',
  styleUrls: ['./asiakas.component.css']
})
export class AsiakasComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild(MatInput, { static: true }) nimiInput: MatInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  fieldErrors: FieldErrors = {}

  maat: Maa[] = []
  asiakas: LaskunAsiakas = null
  namename: string = 'assf' + Math.random()
  verkkolaskuoperaattorit: Observable<Verkkolaskuoperaattori[]>

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private validationService: FormValidationService,
    private errorHandler: ErrorHandler,
    private maaService: MaaService,
    private ladataanService: LadataanService,
    private sahkoisenLaskunValittajaService: SahkoisenLaskunValittajaService
  ) {
    this.asiakas = this.laskuKopioija.annaUusiAsiakas()
  }

  ngAfterContentChecked() {
    this.fieldErrors = this.validationService.updateValidationStatus('asiakas', this.form)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    // Create form
    this.form = new UntypedFormGroup({
      // 'asiakastyyppi': new FormControl('', [ Validators.required ] ),
      'nimi': new UntypedFormControl('', [Validators.required]),
      'ytunnus': new UntypedFormControl('', []),
      'katuosoite': new UntypedFormControl('', [Validators.required]),
      'postitoimipaikka': new UntypedFormControl('', [Validators.required]),
      'postinro': new UntypedFormControl('', [Validators.required]),
      'maa': new UntypedFormControl('', [Validators.required]),
      'lisaavastaanottaja': new UntypedFormControl('', [this.validateEmail]),
      'elaskuosoite': new UntypedFormControl('', []),
      'elaskuvalittaja': new UntypedFormControl('', [])
    })

    this.verkkolaskuoperaattorit = this.form.get('elaskuvalittaja').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    )

    // Bind to changes
    // this.form.get('asiakastyyppi').valueChanges.subscribe(value => {this.asiakas.asiakastyyppi = this.validationService.processValue(value)})
    this.form.get('nimi').valueChanges.subscribe(value => { this.asiakas.nimi = this.validationService.processValue(value) })
    this.form.get('ytunnus').valueChanges.subscribe(value => { this.asiakas.ytunnus = this.validationService.processValue(value) })
    this.form.get('katuosoite').valueChanges.subscribe(value => { this.asiakas.katuosoite = this.validationService.processValue(value) })
    this.form.get('postitoimipaikka').valueChanges.subscribe(value => { this.asiakas.postitmp = this.validationService.processValue(value) })
    this.form.get('postinro').valueChanges.subscribe(value => { this.asiakas.postinro = this.validationService.processValue(value) })
    this.form.get('maa').valueChanges.subscribe(value => { this.asiakas.maa = this.validationService.processValue(value) })
    this.form.get('lisaavastaanottaja').valueChanges.subscribe((value: string) => {
      if (value && (value.indexOf(',') !== -1 || value.indexOf(' ') !== -1)) {
        this.lisaaEmail(this.form.get('lisaavastaanottaja'))
      }
    })

    this.form.get('elaskuosoite').valueChanges.subscribe(value => {
      this.asiakas.sahkoinenosoite.sahkoinenOsoite = this.validationService.processValue(value)
    })
    this.form.get('elaskuvalittaja').valueChanges.subscribe(value => {
      let val = null
      if (value) {
        if (value instanceof String || typeof value === 'string') {
          val = value
        } else if ((value as Verkkolaskuoperaattori).tunnus) {
          val = (value as Verkkolaskuoperaattori).tunnus
        }
      }
      // console.log('Asetetaan valittaja', val)
      this.asiakas.sahkoinenosoite.sahkoinenValittaja = val
    })

    this.maaService.kaikkiMaatJaVeroalueetObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(maat => {
      this.maat = maat
    })

    this.route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: { asiakas: LaskunAsiakas }) => {
      this.form.reset()
      this.asiakas = this.laskuKopioija.copyAsiakas(data.asiakas)
      if (!this.asiakas.sahkoinenosoite) {
        this.asiakas.sahkoinenosoite = {
          sahkoinenOsoite: null,
          sahkoinenValittaja: null
        }
      }
      this.alustaLomakkeenTiedot(this.asiakas)
    })

    this.nimiInput.focus()

  }

  private alustaLomakkeenTiedot(asiakas: LaskunAsiakas) {

    // this.form.get('asiakastyyppi').setValue(asiakas.asiakastyyppi)
    this.form.get('nimi').setValue(asiakas.nimi)
    this.form.get('ytunnus').setValue(asiakas.ytunnus)
    this.form.get('katuosoite').setValue(asiakas.katuosoite)
    this.form.get('postitoimipaikka').setValue(asiakas.postitmp)
    this.form.get('postinro').setValue(asiakas.postinro)
    this.form.get('maa').setValue(asiakas.maa)

    if (asiakas.sahkoinenosoite) {
      const valittajaTunnus = asiakas.sahkoinenosoite.sahkoinenValittaja
      const haettuValittaja: Verkkolaskuoperaattori = this.sahkoisenLaskunValittajaService.annaValittaja(valittajaTunnus)
      this.form.get('elaskuvalittaja').setValue(haettuValittaja || valittajaTunnus || null)
      this.form.get('elaskuosoite').setValue(asiakas.sahkoinenosoite.sahkoinenOsoite)
    } else {
      this.form.get('elaskuosoite').setValue('')
      this.form.get('elaskuvalittaja').setValue(null)
    }

  }

  private _filter(value: string | Verkkolaskuoperaattori): Verkkolaskuoperaattori[] {
    if (value === '' || !value) {
      return []
    }
    let filterValue = ''
    if (value instanceof String || typeof value === 'string') {
      filterValue = value.toLowerCase()
    } else {
      filterValue = value && value.nimi ? value.nimi.toLowerCase() : ''
    }
    return this.sahkoisenLaskunValittajaService.annaKaikkiValittajat().filter(operaattori => operaattori.nimi.toLowerCase().includes(filterValue) || operaattori.tunnus.toLowerCase().includes(filterValue))
  }

  valittajaDisplayFn(operaattori?: Verkkolaskuoperaattori): string | undefined {
    if (operaattori && operaattori.nimi) {
      return operaattori.nimi + ' (' + operaattori.tunnus + ')'
    }
    if (operaattori instanceof String || typeof operaattori === 'string') {
      return operaattori as any as string
    }
    return undefined
  }

  save() {

    if (!this.form.valid) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this.ladataanService.aloitaLataaminen()
    this.laskuService.updateAsiakkaat([this.asiakas]).then(result => {
      this.ladataanService.lopetaLataaminen()
      this.router.navigate(['/laskutus/asiakkaat'])
    }).catch(err => {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
    })
  }

  poista() {
    const data: AsiakasPoistoVarmistusDialogData = {
      asiakas: this.asiakas
    }
    this.dialog.open(AsiakasPoistoVarmistusDialog, { data: data }).afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/laskutus/asiakkaat'])
      }
    })
  }

  lisaa() {
    const field = this.form.get('lisaavastaanottaja')
    field.updateValueAndValidity()
    this.lisaaEmail(field)
  }

  private lisaaEmail(field: AbstractControl) {
    if (!field.valid) {
      return
    }
    if ((field.value || '').trim()) {
      let arvo = this.validationService.processValue(field.value.trim())
      arvo = arvo.replace(/,/g, '').trim()
      if (!this.asiakas.laskunVastaanottajat) {
        this.asiakas.laskunVastaanottajat = []
      }
      this.asiakas.laskunVastaanottajat.push(arvo)
    }
    field.setValue('')
  }

  lisaaAsiakas(event: MatChipInputEvent): void {
    this.lisaa()
  }

  poistaAsiakas(vastaanottaja: string): void {
    const index = this.asiakas.laskunVastaanottajat.indexOf(vastaanottaja)
    if (index >= 0) {
      this.asiakas.laskunVastaanottajat.splice(index, 1)
    }
  }

  validateEmail = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

}
