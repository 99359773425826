<div [class.valittu-maksutapa]="selvitettavatValittu" (click)="valitseSelvitettavat()">
  <div class="maksutapa-laatikko lemon-mat-elevation-z4">
    <div class="kuittien-maara-maksutavalle" [class.light-gray-text]="!selvitettavatValittu" [class.red-text]="selvitettavatValittu">
      {{ data.maara }}
    </div>
    <img [class.light-gray-background]="!selvitettavatValittu" [class.red-background]="selvitettavatValittu" [src]="data.kuva | async" />

    <div class="maksutavan-nimi-container" [class.light-gray-text]="!selvitettavatValittu" [class.red-text]="selvitettavatValittu">
      <span style="display: inline-block; vertical-align: middle;">
        {{data.nimi}}
      </span>
    </div>
  </div>
  <div class="maksutapa-summa" [class.light-gray-text]="!selvitettavatValittu" [class.red-text]="selvitettavatValittu">
    {{ data.summa | lmoney: 'EUR' | async }}
  </div>
</div>