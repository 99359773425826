
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { VasenValikkoService } from 'app/_jaettu-angular/service/vasen-valikko.service'

import { Observable, of } from 'rxjs'
import { delay } from 'rxjs/operators'


@Injectable()
export class ValikkoKiinniGuard  {

  constructor(
    private vasenValikkoService: VasenValikkoService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.vasenValikkoService.paivitaAuki(false)
    return of(true).pipe(
      delay(1)
    )
  }

}

@Injectable()
export class ValikkoAukiGuard  {

  constructor(
    private vasenValikkoService: VasenValikkoService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.vasenValikkoService.paivitaAuki(true)
    return of(true).pipe(
      delay(1)
    )
  }

}
