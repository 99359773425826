import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { AsetustenOletuksetService } from '../../_angular/service/asetusten-oletukset.service'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { Maa, MaaService } from '../../_jaettu-angular/service/maa.service'

import { Laskuasetukset } from '../../_jaettu/model/lasku'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'

import { Subject, Observable, of, combineLatest } from 'rxjs'
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators'
import { BankCustomerConsent } from 'app/_jaettu/model/banks'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'

interface MainForm {
  reskontra: FormControl<boolean>
}

@Component({
  selector: 'app-reskontra-asetukset',
  templateUrl: './reskontra-asetukset.component.html'
})
export class ReskontraAsetuksetComponent implements OnInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) nimiInput: MatInput

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  form: FormGroup<MainForm>
  fieldErrors: FieldErrors = {}

  asetukset: Laskuasetukset = null
  maat: Observable<Maa[]>

  activationDisabled: boolean = false

  constructor(
    private _laskuService: LaskuService,
    private _validationService: FormValidationService,
    private _laskuKopioija: LaskuKopioija,
    private _asetuksetOletuksetService: AsetustenOletuksetService,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _maaService: MaaService,
    private _kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _bankConsentUriService: BankConsentUriService
  ) {
    this.maat = this._maaService.kaikkiMaatObservable
  }

  ngOnInit() {

    // Create form
    this.form = new FormGroup<MainForm>({
      'reskontra': new FormControl<boolean>(null)
    })

    this.form.get('reskontra').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => { this.asetukset.maksupReskontraActive = value })

    const bankConsentObservable = this._kayttajaService.kayttajanTiedotObservable.pipe(
      distinctUntilChanged((prev, curr) => {
        return prev?.asiakasAvain !== curr?.asiakasAvain
      }),
      switchMap(kayttajanTiedot => {
        if (!kayttajanTiedot?.asiakasAvain) {
          return of<BankCustomerConsent>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<BankCustomerConsent>(this._bankConsentUriService.getBankConsentUri(kayttajanTiedot.asiakasAvain)).listen()
      })
    )

    // Load data
    combineLatest([
      this._laskuService.asetuksetObservable,
      bankConsentObservable
    ]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([asetukset, consent]) => {
      if (asetukset) {
        this.asetukset = this._laskuKopioija.copyAsetukset(asetukset)
        this._asetuksetOletuksetService.poistaOletuksetAsetuksista(this.asetukset)
      } else {
        this.asetukset = {
          nimi: null,
          ytunnus: null,
          katuosoite: null,
          postitmp: null,
          postinro: null,
          maakoodi: 'FIN',
          date: null,
          replyto: null,
          puhelin: null,
          bic: null,
          iban: null,
          pankki: null,
          base64Logo: null,
          ohjeet: null,
          slogan: null,
          piilotaYrityksenNimiLaskulla: false,
          summahuomautusPiilotettu: false,
          muistutusSpostiasetukset: {},
          spostiasetukset: {},
          tervetuloaNahty: true,
          maksupReskontraActive: true
        }
      }

      this.asetukset.tervetuloaNahty = true

      if (consent) {
        this.activationDisabled = false
        this.form.get('reskontra').setValue(asetukset?.maksupReskontraActive ?? false)
      } else {
        this.activationDisabled = true
        this.form.get('reskontra').setValue(false)
      }
    })
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  save() {
    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()
    this._laskuService.updateLaskuasetukset(this.asetukset).then(() => {
      const lokalisoitu = this._translationService.lokalisoi('laskuasetukset.tallennettu-onnistuneesti')
      this._snackbar.open(lokalisoitu, 'OK', { duration: 5000, verticalPosition: 'top' })
      this._ladataanService.lopetaLataaminen()
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
  }

}
