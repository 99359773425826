<div class="list-page" style="max-width: 1200px; margin-bottom: 6em;">

  <form class="laskelmat-controls" [formGroup]="laskelmatDateAndSearchForm">

    <div class="filter-bar">

      <div class="date-chip-group">
        <button *ngFor="let tilikausi of naytettavatTilikaudetObservable | async" class="date-chip" [class.green-chip]="naytettavaTilikausi?.labelHover !== tilikausi.labelHover && !!tilikausi.lukittu" [title]="tilikausi?.labelHover || null" [class.date-chip-selected]="naytettavaTilikausi?.labelHover === tilikausi.labelHover" type="button" mat-button (click)="valitseTilikausi(tilikausi)">
          {{ tilikausi?.label }}
        </button>
      </div>

      <div class="form-boxes" style="width: 228px;">
        <div class="rounded-input" style="padding: 0 5px 0 8px;">
          <input #alkaaInput type="text" [name]="name" [matDatepicker]="alkaaPicker" formControlName="alkaa" matInput data-lpignore="true" required style="width: 70px;" />
          <button mat-icon-button (click)="alkaaPicker.open()" style="width: 20px; height: 20px; line-height: 20px;">
            <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 16px; top: 0; left: 0; right: 0; bottom: 0; position: absolute;">calendar_today</mat-icon>
          </button>
          <!-- <mat-datepicker-toggle matSuffix [for]="alkaaPicker"></mat-datepicker-toggle> -->
          <mat-datepicker #alkaaPicker startView="year"></mat-datepicker>
        </div>
        <div style="margin: 0 5px;">-</div>
        <div class="rounded-input" style="padding: 0 5px 0 8px;">
          <input type="text" [name]="name" [matDatepicker]="loppuuPicker" formControlName="loppuu" matInput data-lpignore="true" required style="width: 70px;" />
          <button mat-icon-button (click)="loppuuPicker.open()" style="width: 20px; height: 20px; line-height: 20px;">
            <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 16px; top: 0; left: 0; right: 0; bottom: 0; position: absolute;">calendar_today</mat-icon>
          </button>
          <!-- <mat-datepicker-toggle matSuffix [for]="loppuuPicker"></mat-datepicker-toggle> -->
          <mat-datepicker #loppuuPicker startView="year"></mat-datepicker>
        </div>

      </div>
    </div>

    <div class="filter-bar">

      <ng-container *ngIf="naytettavaTilikausi">
        <div class="date-chip-group">
          <button class="date-chip" [title]="mm?.numberMonth | numbermonthtomonthyear | async" [class.date-chip-selected]="dateBtnsSelectedMonth === mm.numberMonth" [class.green-chip]="dateBtnsSelectedMonth !== mm.numberMonth && kuukausienStatuksetMap[mm.numberMonth] === 'ok'" *ngFor="let mm of naytettavaTilikausi.months" type="button" mat-button (click)="valitseKuukausi(mm)">
            {{ mm?.label }}
          </button>
        </div>
      </ng-container>

    </div>

  </form>


  <mat-tab-group #tabGroup mat-stretch-tabs animationDuration="0ms" style="margin-top: 2em; overflow: hidden;">

    <mat-tab [label]="'raportit.tuloslaskelma' | translate | async">
      <div class="pdf-btn-row" *ngIf="selectedTab === 'tulos'">
        <button mat-button (click)="openPdfSelect()">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" style="height: 20px;  vertical-align: middle;">
            <path d="M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          </svg> Lataa PDF
        </button>
        <div class="hidden-select">
          <mat-select #pdfSelect>
            <mat-option *ngFor="let pdf of pdfList" (click)="downloadPdf(pdf.type, pdf.kieli)">
              {{ pdf.tag }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div *ngIf="selectedTab === 'tulos'" app-kirjanpito-tuloslaskelma [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" class="raportti-container"></div>
    </mat-tab>

    <mat-tab [label]="'raportit.tase' | translate | async">
      <div class="pdf-btn-row" *ngIf="selectedTab === 'tase'">
        <button mat-button (click)="openPdfSelect()">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" style="height: 20px;  vertical-align: middle;">
            <path d="M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          </svg> Lataa PDF
        </button>
        <div class="hidden-select">
          <mat-select #pdfSelect>
            <mat-option *ngFor="let pdf of pdfList" (click)="downloadPdf(pdf.type, pdf.kieli)">
              {{ pdf.tag }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div *ngIf="selectedTab === 'tase'" app-kirjanpito-tase [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" class="raportti-container"></div>
    </mat-tab>

    <mat-tab [label]="'raportit.lyhyt-tase-ja-tulos' | translate | async">
      <div class="pdf-btn-row" *ngIf="selectedTab === 'lyhyt-tase-ja-tulos'">
        <button mat-button (click)="openPdfSelect()">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" style="height: 20px;  vertical-align: middle;">
            <path d="M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          </svg> Lataa PDF
        </button>
        <div class="hidden-select">
          <mat-select #pdfSelect>
            <mat-option *ngFor="let pdf of pdfList" (click)="downloadPdf(pdf.type, pdf.kieli)">
              {{ pdf.tag }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div *ngIf="selectedTab === 'lyhyt-tase-ja-tulos'" app-kirjanpito-virallinen-tase [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" class="raportti-container"></div>
      <div *ngIf="selectedTab === 'lyhyt-tase-ja-tulos'" app-kirjanpito-virallinen-tulos [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" class="raportti-container" style="margin-top: 6em;"></div>
    </mat-tab>

    <!-- <mat-tab [label]="'raportit.paakirja' | translate | async">
      <div *ngIf="selectedTab === 'paakirja'" app-kirjanpito-paakirja [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" class="raportti-container"></div>
    </mat-tab> -->

    <mat-tab [label]="'raportit.brutto-tuloslaskelma' | translate | async">
      <div class="pdf-btn-row" *ngIf="selectedTab === 'bruttotuloslaskelma'">
        <button mat-button (click)="openPdfSelect()">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" style="height: 20px;  vertical-align: middle;">
            <path d="M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          </svg> Lataa PDF
        </button>
        <div class="hidden-select">
          <mat-select #pdfSelect>
            <mat-option *ngFor="let pdf of pdfList" (click)="downloadPdf(pdf.type, pdf.kieli)">
              {{ pdf.tag }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div *ngIf="selectedTab === 'bruttotuloslaskelma'" app-kirjanpito-bruttotuloslaskelma [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" class="raportti-container"></div>
    </mat-tab>

  </mat-tab-group>



</div>