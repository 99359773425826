import { Component, OnInit, OnDestroy, Inject, ErrorHandler, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core'
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog'

import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { KayttajaService } from '../../_angular/service/kayttaja.service'
import { BicService } from '../../_shared-core/service/bic.service'


import { Subject, Observable } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { StringService } from 'app/_shared-core/service/string.service'
import { IbanService } from 'app/_shared-core/service/iban.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { AiiaDialogService } from 'app/_angular/service/aiia-dialog.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { DialogToShowMetadata } from 'app/_jaettu/model/banks'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'

export interface AiiaDialogData
// extends Pick<ViiaHaeIbanitLemonaidResponse, 'ibanitJaProviderIdt'>
{  // ibanitJaProviderIdt and the ViiaHaeIbanitLemonaidResponse will be deprecated
  dialogToShowMetadata: DialogToShowMetadata
}

interface Account {
  pankki: string
  bic: string
  iban: string
  nimi?: string
}

@Component({
  templateUrl: './viia-login-required.dialog.html',
  styleUrls: ['./viia-login.dialog.css']
})
export class ViiaLoginRequiredDialog implements OnInit, OnDestroy {

  @ViewChild('selitys', { static: true }) selitysRef: ElementRef

  nonAccessibleIbansObservable: Observable<Account[]>
  accessibleIbansObservable: Observable<Account[]>
  valtuutusTextsObservable: Observable<string>

  valtuutusTitle: string
  valtuutusStartText: string
  valtuutusEndText: string
  lueLisaa: string

  commonError: string
  extraTextExpanded: boolean = false

  private _buttonWasPushed: boolean
  private _ngUnsubscribe = new Subject<void>()

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AiiaDialogData,
    private _dialogRef: MatDialogRef<ViiaLoginRequiredDialog>,
    private _translationService: LemonTranslationService,
    private _kayttajaService: KayttajaService,
    private _bicService: BicService,
    private _stringService: StringService,
    private _ibanService: IbanService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _cd: ChangeDetectorRef,
    private _aiiaDialogService: AiiaDialogService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _bankConsentUriService: BankConsentUriService
  ) { }

  ngOnInit() {

    this._ladataanService.aloitaLataaminen()
    this._dialogRef.disableClose = true

    // this.postponeAllowedObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
    //   switchMap(asiakas => {
    //     if (!asiakas) {
    //       return observableOf<boolean>(false)
    //     }
    //     return this._firebaseLemonaid.firestoreDoc<BankCustomerConsent>(this._bankConsentUriService.getBankConsentUri(asiakas.asiakasAvain)).listen().pipe(
    //       map(consent => {
    //         if (!consent) {
    //           return true
    //         }
    //         const dialog = consent.dialogsToShow?.find(d => d.dialogToShow === BankDialogType.PSD2_RENEW)
    //         return this._viiaDialogPostponeService.isPostponingStillAllowed(dialog?.connectionLost)
    //       })
    //     )
    //   })
    // )

    // Create observable that emits the ibans we already have access to
    this.accessibleIbansObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas) {
          return null
        }

        const ibans = this._data?.dialogToShowMetadata?.ibansOk
        if (!ibans?.length) {
          return null
        }

        if (!ibans?.length) {
          return null
        }

        return ibans.map(iban => {
          const noWhitespaceIban = this._stringService.removeAllWhiteSpaces(iban) || ''
          const pankki = this._bicService.annaPankinNimi(noWhitespaceIban) || ''
          const bic = this._bicService.annaBicKoodi(noWhitespaceIban) || ''
          return {
            pankki: pankki,
            bic: bic,
            iban: this._ibanService.formatoiIban(noWhitespaceIban)
          }
        })
      })
    )

    // Create observable that emits the ibans we still don't have access to
    this.nonAccessibleIbansObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas) {
          return null
        }

        const ibansMissing = this._data?.dialogToShowMetadata?.ibansMissing

        if (!ibansMissing?.length) {
          return null
        }
        return ibansMissing.map(iban => {
          const noWhitespaceIban = this._stringService.removeAllWhiteSpaces(iban) || ''
          const pankki = this._bicService.annaPankinNimi(noWhitespaceIban) || ''
          const bic = this._bicService.annaBicKoodi(noWhitespaceIban) || ''
          return {
            pankki: pankki,
            bic: bic,
            iban: this._ibanService.formatoiIban(noWhitespaceIban)
          }
        })
      })
    )

    this.nonAccessibleIbansObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(accounts => {
      this._ladataanService.lopetaLataaminen()
      if (!accounts || !accounts.length) {
        this.valtuutusTitle = this._translationService.lokalisoi('viia-login-required.ei-tileja.yhdista-yrityksesi-pankkitili')
        this.valtuutusStartText = this._translationService.lokalisoi('viia-login-required.ei-tileja.palvelun-kayto-edellyttaa')
        this.valtuutusEndText = ''
      } else {
        this.valtuutusTitle = this._translationService.lokalisoi('viia-login-required.on-tileja.yhteys-vanhenemassa')
        this.valtuutusStartText = this._translationService.lokalisoi('viia-login-required.on-tileja.yhteys-ei-ole-aktiivinen')
        this.valtuutusEndText = this._translationService.lokalisoi('viia-login-required.on-tileja.valtuutuksen-antaminen')
      }
    })

  }

  async goToAiia() {

    if (this._buttonWasPushed) {
      return
    }
    this._buttonWasPushed = true

    this._ladataanService.aloitaLataaminen()

    try {
      const tiedot = await this._kayttajaService.getKayttajanTiedot()
      const redirectUrl = await this._aiiaDialogService.fetchAiiaOauthStartUrl(tiedot.asiakasAvain, { dialogToShowMetadata: this._data?.dialogToShowMetadata })

      if (!redirectUrl) {
        throw new Error('No redirectUrl to connect to Aiia')
      }
      location.href = redirectUrl

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._buttonWasPushed = false
      this._ladataanService.lopetaLataaminen()
    }
  }

  async postpone() {
    this._aiiaDialogService.postpone()
    this._dialogRef.close()
  }

  expandExtraText() {
    this.extraTextExpanded = !this.extraTextExpanded
    this._cd.detectChanges()
    if (this.extraTextExpanded && this.selitysRef) {
      setTimeout(() => {
        this.selitysRef?.nativeElement?.scrollIntoView({ behavior: 'smooth' }, 10)
      })
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
