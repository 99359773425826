import { Pipe, PipeTransform } from '@angular/core'

import { LemonTranslationService } from '../service/lemon-translation.service'
import { MaaService } from '../service/maa.service'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Pipe({
  name: 'lmaa'
})
export class LemonMaaPipe implements PipeTransform {

  constructor(
    private lemonTranslationService: LemonTranslationService,
    private maaService: MaaService
  ) {}

  transform(avain: string): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!avain || !kieli) {
          return ''
        }
        return this.maaService.getName(avain, kieli)
      })
    )
  }

}

@Pipe({
  name: 'lmaalocale'
})
export class LemonMaaWithlocalePipe implements PipeTransform {

  constructor(
    private maaService: MaaService
  ) {}

  transform(avain: string, kieli: string): string {
    if (!avain || !kieli) {
      return ''
    }
    return this.maaService.getName(avain, kieli)
  }

}
