import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { Roolit, Kayttaja } from '../../_jaettu/model/kayttaja'

import { KayttajaService } from '../service/kayttaja.service'

import { Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { TositeService } from '../service/tosite/tosite.service'
import { MYYNTILASKU_MAKSUTAPA_ID, TILIOTETOSITE_MAKSUTAPA_ID, PALKKATOSITE_MAKSUTAPA_ID } from 'app/_jaettu/model/tosite'

@Injectable()
export class RoleGuard {

  constructor(
    private router: Router,
    private kayttajaService: KayttajaService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.kayttajaService.kayttajaObservable.pipe(
      map(kayttaja2 => {
        const sallitutRoolit = route.data.sallitutRoolit as [Roolit]
        if (sallitutRoolit && kayttaja2 && kayttaja2.roolit) {
          for (const allowedRole of sallitutRoolit) {
            if (kayttaja2.roolit[allowedRole]) {
              return true
            }
          }
        }
        this.router.navigate(['/eioikeuksia'])
        return false
      })
    )
  }
}

@Injectable()
export class RoleByTositeTypeGuard {

  constructor(
    private router: Router,
    private kayttajaService: KayttajaService,
    private tositeService: TositeService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const id = route.params['id']
    return combineLatest([this.tositeService.annaKuittiObservable(id), this.kayttajaService.kayttajaObservable]).pipe(
      map(([tosite, kayttaja]) => {

        if (tosite && kayttaja && kayttaja.roolit) {
          // Ignore specific role check for selvitettävät
          if (tosite.x && this.userHasAtLeastOneTositeRole(kayttaja)) {
            return true
          }
          const roleNeededForThisTosite = this.convertMaksutapaToRooli(tosite.maksutapa)
          if (kayttaja.roolit[roleNeededForThisTosite]) {
            return true
          }

        }
        this.router.navigate(['/eioikeuksia'])
        return false
      })
    )

  }
  private userHasAtLeastOneTositeRole(kayttaja: Kayttaja) {
    const sallitutRoolit = [Roolit.TOSITTEET_OSTO, Roolit.TOSITTEET_MYYNTI, Roolit.TOSITTEET_TILI, Roolit.TOSITTEET_PALKKA]
    for (const allowedRole of sallitutRoolit) {
      if (kayttaja.roolit[allowedRole]) {
        return true
      }
    }
  }
  private convertMaksutapaToRooli(maksutapaId: number) {
    if (maksutapaId === MYYNTILASKU_MAKSUTAPA_ID) {
      return Roolit.TOSITTEET_MYYNTI
    }
    if (maksutapaId === TILIOTETOSITE_MAKSUTAPA_ID) {
      return Roolit.TOSITTEET_TILI
    }
    if (maksutapaId === PALKKATOSITE_MAKSUTAPA_ID) {
      return Roolit.TOSITTEET_PALKKA
    }
    return Roolit.TOSITTEET_OSTO
  }
}
