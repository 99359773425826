import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChild, AfterViewInit, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { FormValidationService, FieldErrors } from '../_jaettu-angular/service/form-validation.service'
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { KayttajaService } from '../_angular/service/kayttaja.service'
import { VersionTarkistusPalvelu } from '../_angular/service/version-tarkistus.palvelu'
import { LoginService, LoginInputData } from '../_angular/service/login.service'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { LoginRequest, LoginResponse } from '../_jaettu/model/login'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from '../vaihda-kieli.dialog'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {

  @ViewChild(MatInput, { static: true }) emailInput: MatInput

  loginForm: UntypedFormGroup
  fieldErrors: FieldErrors = {}
  commonError: string
  betaOffLimitsError: string
  online = true
  year = new Date().getFullYear()
  inputToPassOn: LoginInputData

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _loginInflight: boolean = false

  constructor(
    private _kayttajaService: KayttajaService,
    private _router: Router,
    private _dialog: MatDialog,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _versionTarkistaja: VersionTarkistusPalvelu,
    private _loginService: LoginService,
    private _versionTarkistusPalvelu: VersionTarkistusPalvelu,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this._loginService.clearCredentials()
    // Preheat
    this._firebaseLemonaid.functionsCall<string, void>('loginSalasanallaTallennaOngoing', '')
  }

  ngAfterContentChecked() {
    this.fieldErrors = this._validationService.updateValidationStatus('login', this.loginForm)
  }

  private emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null
    }
    return Validators.email(control)
  }

  ngOnInit(): void {

    this._loginService.currentLoginCredentialsObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(data => this.inputToPassOn = data)

    this._versionTarkistaja.sovelluksenVersioObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(versiotiedot => {
      this._versionTarkistaja.tarkistaVersio(versiotiedot)
    })

    this._kayttajaService.kayttajanTiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kayttajanTiedot => {
      if (kayttajanTiedot) {
        this.setLoginProcessEnded()
        this._router.navigate(['/'])
      }
    })

    this.loginForm = new UntypedFormGroup({
      'email': new UntypedFormControl('', [Validators.required, this.emailValidator]),
      'password': new UntypedFormControl('', Validators.required)
    })
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.emailInput.focus() }, 0)
  }

  setLoginProcessStarted() {
    this._ladataanService.aloitaLataaminen()
  }

  setLoginProcessEnded() {
    this._ladataanService.lopetaLataaminen()
    this._loginInflight = false
  }

  forgot() {
    this._router.navigate(['/unohdin'])
  }

  @HostListener('document:keydown.enter')
  login() {

    if (this._loginInflight) {
      return
    }
    this._loginInflight = true

    if (!this.loginForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
      this._loginInflight = false
      return
    }

    this.setLoginProcessStarted()
    const payload: LoginRequest = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      valittuKieli: this._translationService.nykyinenKieli,
      env: 'w',
      ver: this._versionTarkistusPalvelu.annaVersio(),
      device: navigator.platform,
      os: navigator.userAgent
    }

    if (environment.environment === EnvironmentType.BETA) {
      payload.beta = true
    }

    this._firebaseLemonaid.functionsCall<LoginRequest, LoginResponse>('loginSalasanallaTallennaOngoing', payload).then(data => {

      if (!data) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
        this._errorHandler.handleError('no-response-or-no-data')
        this.setLoginProcessEnded()
        return
      }

      const error = data.e

      const loginDataTransfer: LoginInputData = {
        email: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
        loginCodeId: data.loginCodeId
      }

      if (error) {
        this.setLoginProcessEnded()
        switch (error) {
          case 'auth/no-phone-number':
            this._loginService.changeInput(loginDataTransfer)
            this._router.navigate(['/lisaa-puhelin'])
            break
          case 'auth/user-not-found':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('email').setErrors({ 'user-not-found': true })
            break
          case 'auth/invalid-email':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('email').setErrors({ 'invalid-email': true })
            break
          case 'auth/user-disabled':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('email').setErrors({ 'user-disabled': true })
            break
          case 'auth/user-inactive':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('email').setErrors({ 'user-disabled': true })
            break
          case 'auth/wrong-password':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('password').setErrors({ 'wrong-password': true })
            break
          case 'auth/auth-failed':
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.loginForm.get('password').setErrors({ 'auth-failed': true })
            break
          case 'beta-off-limits':
            this.betaOffLimitsError = this._translationService.lokalisoi('login.beta-off-limits')
            this._errorHandler.handleError(error)
            break
          default:
            this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
            this._errorHandler.handleError(error)
        }
        this.ngAfterContentChecked()
        this._validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
      } else {
        this.setLoginProcessEnded()
        this._loginService.changeInput(loginDataTransfer)
        this._router.navigate(['/kirjaudu-vahvista'])
      }

    }).catch(error => {
      this.setLoginProcessEnded()
      this.commonError = typeof error === 'string' ? error : this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      this._errorHandler.handleError(error)
    })

  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this._dialog.open(VaihdaKieliDialog, { data: data })
  }

}
