<div class="all" mat-dialog-content style="padding: 0 6vw;">
  <div class="big-card">
    <div class="red-text title-text" [innerHTML]="'viia-login-required.login-failed.virhe' | translate | async"></div>

    <div class="inner-container red-text">
      <div>{{ 'viia-login-required.login-failed.yhdistely-epaonnistui' | translate | async }}</div>
    </div>
    <div class="button-container">
      <button class="primarybutton" (click)="sulje()" mat-button>{{ 'yleiset.jatka' | translate | async }}</button>
    </div>
  </div>
</div>