import { NumberDate } from "../../_shared-core/model/common";
import { DateService } from "../../_shared-core/service/date.service";
import { LemonaidPaymentConfig } from "../model/kayttaja";

export class MaksutService {
  constructor(
    private _dateService: DateService,
  ) { }

  onkoMaksutiliVoimassa(
    paymentConfig: LemonaidPaymentConfig,
    currentNumberDate: NumberDate = this._dateService.currentNumberDate()
  ): boolean {
    if (!paymentConfig || !paymentConfig.start) {
      return false
    }
    return this._dateService.onkoNumberKahdenValissa(currentNumberDate, paymentConfig.start, paymentConfig.end || 993112)
  }
}
