import { ErrorHandler, Injectable } from '@angular/core'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { KnowYourCustomerSeenLog } from 'app/_jaettu/model/kayttaja'
import { KycUriService } from 'app/_jaettu/service/kyc-uri.service'
import { FirebaseLemonaid } from './firebase-lemonaid.service'

@Injectable()
export class TuntemistiedotDialogService {
  private _postponeStarted: Date = null

  constructor(
    private _timestampService: TimestampService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _kycUriService: KycUriService,
    private _errorHandler: ErrorHandler
  ) { }

  savePopupSeenLog(asiakasAvain: string, kayttajaAvain: string): Promise<void> {
    const logData: KnowYourCustomerSeenLog = {
      kayttajaAvain: kayttajaAvain,
      seenAt: this._timestampService.now()
    }
    const uri = this._kycUriService.getCustomerKycSeenLogDocUri(asiakasAvain)
    return this._firebaseLemonaid.firestoreSetData<KnowYourCustomerSeenLog>(uri, logData).catch(err => {
      this._errorHandler.handleError(new Error('KYC log save failed! ' + JSON.stringify(err)))
    })
  }

  postpone() {
    this._postponeStarted = new Date()
  }

  postponedRecently(): boolean {
    if (!this._postponeStarted) {
      return false
    }
    const oneHourInMilliseconds = 60 * 60 * 1000
    return new Date().getTime() - this._postponeStarted.getTime() < oneHourInMilliseconds
  }

}
