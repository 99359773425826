<style>
  .all {
    /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
    max-height: 95vh;
  }
</style>

<div class="all teksti" mat-dialog-content>


  <div class="light-blue-text otsikko">
    {{'lemonaid-sopimus-dialog.kyc.yhtion-tunteminen' | translate | async }}
  </div>

  <div class="kyc-content">

    <div class="dynamic-spacer"></div>
    <div class="dynamic-spacer"></div>

    <div [innerHtml]="'lemonaid-sopimus-dialog.rahanpesulaki-velvoittaa-keramaan' | translate | async"></div>
    <div class="dynamic-spacer"></div>

    <div [innerHtml]="'lemonaid-sopimus-dialog.yhtiosi-tuntemistiedot' | translate | async"></div>

    <form #tunnistamisForm="ngForm" [formGroup]="tuntemistiedotFormGroup" style="text-align: left;">

      <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.osakkaat' | translate | async }}</div>
      <div>{{'lemonaid-sopimus-dialog.kyc.anna-osakkeiden-tiedot' | translate | async }}</div>

      <div class="lemon-error" *ngIf="osakkaatFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-osakas' | translate | async }}</div>
      <div formArrayName="osakkaat">
        <div *ngFor="let osakasFormGroup of osakasFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">

          <div class="kyc-checkbox">
            <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
            </mat-checkbox>
          </div>

          <mat-radio-group class="kyc-radio" formControlName="onkoYritys" [style.display]="osakasFormGroup.get('onkoYritys').disabled ? 'none': 'flex'">
            <mat-radio-button style="margin: 5px;" [value]="false">{{'lemonaid-sopimus-dialog.kyc.osakas-on-luonnollinen' | translate | async }}</mat-radio-button>
            <mat-radio-button style="margin: 5px;" [value]="true">{{'lemonaid-sopimus-dialog.kyc.osakas-on-yritys' | translate | async }}</mat-radio-button>
          </mat-radio-group>

          <div class="kyc-single-row">
            <mat-form-field class="right-margin-in-large-view">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('nimi'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="right-margin-in-large-view">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu-ytunnus' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('hetu'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-invalid' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.ownytunnus">{{ 'lemonaid-sopimus-dialog.kyc.oma-ytunnus' | translate | async}}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>

            <!-- <div class="right-margin-in-large-view" style="align-self: center;" [style.display]="!osakasFormGroup.get('noFinnishId').value ? 'none': 'inline-block'">
              <mat-checkbox formControlName="onkoYritys">{{'lemonaid-sopimus-dialog.kyc.yritys' | translate | async }}
              </mat-checkbox>
            </div> -->

            <mat-form-field class="right-margin-in-large-view" [style.display]="osakasFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
              <input [name]="namename" type="text" [matDatepicker]="picker1" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('birthDate'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-datepicker #picker1></mat-datepicker>

            <mat-form-field>
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara' | translate | async }}</mat-label>
              <input decimalNumberField matInput formControlName="osakkeita" [numberOfDecimals]="0" autocomplete="nothing" required data-lpignore="true">
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('osakkeita'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara-puuttuu' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="kyc-single-row">

            <mat-form-field class="right-margin-in-large-view">
              <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
              <mat-select formControlName="kansallisuus" required>
                <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                  {{ kansa.nimi }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('kansallisuus'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
              <mat-select formControlName="pep" required>
                <mat-option [value]="true">
                  {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                </mat-option>
                <mat-option [value]="false">
                  {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('pep'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>

          </div>

          <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>


          <button mat-icon-button (click)="poistaOsakas(i); $event.preventDefault();">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaOsakas(); $event.preventDefault();">
          <!-- {{'yleiset.jatka' | translate | async }} -->
          {{'lemonaid-sopimus-dialog.kyc.lisaa-osakas' | translate | async }}
        </button>
      </div>

      <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.hallitus-ja-toimitusjohtaja' | translate | async }}</div>
      <div>{{'lemonaid-sopimus-dialog.kyc.anna-paattajien-tiedot' | translate | async }}</div>

      <div class="lemon-error" *ngIf="hallitusFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-hallituksen-jasenen' | translate | async }}</div>

      <div formArrayName="hallitus">
        <div *ngFor="let hallitusFormGroup of hallitusFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">

          <div class="kyc-checkbox">
            <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
            </mat-checkbox>
          </div>

          <div class="kyc-single-row">
            <mat-form-field class="right-margin-in-large-view">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('nimi'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="right-margin-in-large-view">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('hetu'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-invalid' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="right-margin-in-large-view" [style.display]="hallitusFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
              <input [name]="namename" type="text" [matDatepicker]="picker2" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('birthDate'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-datepicker #picker2></mat-datepicker>

            <mat-form-field>
              <mat-label>{{'lemonaid-sopimus-dialog.kyc.rooli' | translate | async }}</mat-label>
              <mat-select formControlName="rooli" required>
                <mat-option *ngFor="let rooli of hallitusRoolitObservable | async" [value]="rooli.avain">
                  {{ rooli.nimi }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('rooli'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.rooli-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="kyc-single-row">
            <mat-form-field class="right-margin-in-large-view">
              <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
              <mat-select formControlName="kansallisuus" required>
                <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                  {{ kansa.nimi }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('kansallisuus'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
              <mat-select formControlName="pep" required>
                <mat-option [value]="true">
                  {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                </mat-option>
                <mat-option [value]="false">
                  {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('pep'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>

          <button mat-icon-button (click)="poistaHallituksenJasen(i); $event.preventDefault();">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaHallituksenJasen(); $event.preventDefault();">
          <!-- {{'yleiset.jatka' | translate | async }} -->
          {{'lemonaid-sopimus-dialog.kyc.lisaa-jasen' | translate | async}}
        </button>
      </div>

      <div class="kyc-otsikko">{{'lemonaid-sopimus-dialog.kyc.edunsaajat' | translate | async}}</div>
      <div>{{'lemonaid-sopimus-dialog.kyc.edunsaajien-tiedot' | translate | async}}</div>

      <div formArrayName="muut">
        <div *ngFor="let saajaFormGroup of saajaFormGroupit; let i = index;" [formGroupName]="i" class="kyc-item">

          <div class="kyc-checkbox">
            <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
            </mat-checkbox>
          </div>

          <div class="kyc-single-row">
            <mat-form-field class="right-margin-in-large-view">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="saajaFormGroup.get('nimi'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="saajaFormGroup.get('hetu'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.invalid">{{'lemonaid-sopimus-dialog.kyc.hetu-invalid' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="left-margin-in-large-view" [style.display]="saajaFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
              <input [name]="namename" type="text" [matDatepicker]="picker3" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-error>
                <ng-container *ngIf="saajaFormGroup.get('birthDate'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-datepicker #picker3></mat-datepicker>

          </div>

          <div class="kyc-single-row">

            <mat-form-field class="right-margin-in-large-view">
              <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
              <mat-select formControlName="kansallisuus" required>
                <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                  {{ kansa.nimi }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="saajaFormGroup.get('kansallisuus'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
              <mat-select formControlName="pep" required>
                <mat-option [value]="true">
                  {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                </mat-option>
                <mat-option [value]="false">
                  {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="saajaFormGroup.get('pep'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>

          </div>
          <div (click)="openPepText()" class="form-comment">{{'lemonaid-sopimus-dialog.kyc.kuka-on-pep' | translate | async }}</div>

          <button mat-icon-button (click)="poistaEdunsaaja(i); $event.preventDefault();">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <button class="primarybutton kyc-lisaa" mat-button (click)="lisaaEdunsaaja(); $event.preventDefault();">
          <!-- {{'yleiset.jatka' | translate | async }} -->
          {{'lemonaid-sopimus-dialog.kyc.lisaa-edunsaaja' | translate | async}}
        </button>
      </div>
      <div class="dynamic-spacer"></div>
      <div class="dynamic-spacer"></div>

      <div>
        <!-- {{'lemonaid-sopimus-dialog.lahetamme-valtakirjan-spostitse' | translate | async }} -->
        {{'lemonaid-sopimus-dialog.kyc.edunsaajalla-tarkoitetaan' | translate | async}}
      </div>
      <div class="dynamic-spacer"></div>
      <div class="dynamic-spacer"></div>

      <div style="font-weight: bold;">{{'lemonaid-sopimus-dialog.kyc.miksi-tietoja-kerataan' | translate | async}}</div>
      <div class="dynamic-spacer"></div>

      <div>
        <!-- {{'lemonaid-sopimus-dialog.lahetamme-valtakirjan-spostitse' | translate | async }} -->
        {{'lemonaid-sopimus-dialog.kyc.naita-tuntemistietoja' | translate | async}}
      </div>
      <div>
        {{'lemonaid-sopimus-dialog.kyc.jos-muutoksia' | translate | async}}
      </div>
    </form>

  </div>
  <div class="dynamic-spacer"></div>
  <div class="dynamic-spacer"></div>

  <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
  <div class="dynamic-spacer"></div>

  <div class="button-container">
    <button class="secondarybutton" mat-button (click)="postpone()">{{'yleiset.myohemmin' | translate | async }}</button>
    <button class="primarybutton" mat-button (click)="tallennaKyc()">{{'yleiset.tallenna' | translate | async }}</button>
  </div>
  <div class="dynamic-spacer"></div>

</div>