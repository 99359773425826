import { Injectable, ErrorHandler } from '@angular/core'

import { LaskuService } from './lasku.service'
import { KayttajaService } from '../kayttaja.service'

import { LaskunAsiakasTypeaheadBase, AsiakasTypeaheadResult, TuoteTypeaheadResult, LaskunTuoteTypeaheadBase } from '../../../_jaettu-angular/service/lasku/typeahead.service'
import { FirestoreIndeksoija } from '../../../_jaettu/service/firestore.indeksoija'
import { TypeaheadAsiakas, TypeaheadTuote, LaskunAsiakas, AsiakkaanTuote } from '../../../_jaettu/model/lasku'

import { BehaviorSubject, firstValueFrom, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

interface KasiteltyTypeaheadAsiakas extends TypeaheadAsiakas {
  lowerNimi: string
  lowerYtunnus: string
}

@Injectable()
export class LaskunAsiakasTypeahead implements LaskunAsiakasTypeaheadBase {

  private typeaheadAsiakkaatSubject = new BehaviorSubject<KasiteltyTypeaheadAsiakas[]>([])

  constructor(
    private kayttajaService: KayttajaService,
    private laskuService: LaskuService,
    private errorHandler: ErrorHandler,
    private laskuIndeksoija: FirestoreIndeksoija
  ) {
    this.kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (kayttajanTiedot) {
          return this.laskuService.getTypeaheadAsiakkaat(kayttajanTiedot)
        }
        return of<TypeaheadAsiakas[]>([])
      }),
      map(asiakkaat => {
        if (asiakkaat) {
          return asiakkaat.map<KasiteltyTypeaheadAsiakas>(asiakas => {
            const kasitelty = asiakas as KasiteltyTypeaheadAsiakas
            kasitelty.lowerNimi = this.laskuIndeksoija.poistaValimerkitJaValilyonnit(asiakas.nimi)
            kasitelty.lowerYtunnus = this.laskuIndeksoija.poistaValimerkitJaValilyonnit(asiakas.ytunnus)
            return kasitelty
          })
        }
        return []
      })
    ).subscribe(asiakkaat => {
      this.typeaheadAsiakkaatSubject.next(asiakkaat || [])
    }, err => { this.errorHandler.handleError(err) })
  }

  public annaAsiakas(typeaheadAsiakas: TypeaheadAsiakas): Promise<LaskunAsiakas | null> {
    return firstValueFrom(this.laskuService.getAsiakasObservable(typeaheadAsiakas.avain))
  }

  public haeJaSuodata(value: string): Promise<AsiakasTypeaheadResult> {
    const sanat = this.laskuIndeksoija.poistaValimerkitJaNormalisoiValilyonnit(value).split(' ')
    const val = this.laskuIndeksoija.poistaValimerkitJaValilyonnit(value)
    return firstValueFrom(this.typeaheadAsiakkaatSubject).then(asiakkaat => {

      const suodatetut: { asiakas: TypeaheadAsiakas, pisteet: number }[] = []

      if (val && val !== '') {
        for (const asiakas of asiakkaat) {
          if (!asiakas) { continue }

          let pisteet = 0

          const lowercaseNimi = asiakas.lowerNimi
          const lowercaseYtunnus = asiakas.lowerYtunnus

          for (const sana of sanat) {
            if (lowercaseYtunnus.startsWith(sana)) {
              pisteet += 2
            } else if (lowercaseYtunnus.indexOf(sana) > -1) {
              pisteet++
            }
            if (lowercaseNimi.startsWith(sana)) {
              pisteet += 2
            } else if (lowercaseNimi.indexOf(sana) > -1) {
              pisteet++
            }
          }

          if (lowercaseNimi.startsWith(val)) {
            pisteet += 4
          } else if (lowercaseYtunnus.indexOf(val) > -1) {
            pisteet += 2
          }

          if (lowercaseNimi.startsWith(val)) {
            pisteet += 4
          } else if (lowercaseNimi.indexOf(val) > -1) {
            pisteet += 2
          }

          if (lowercaseNimi === val || lowercaseYtunnus === val) {
            pisteet += 100
          }

          if (pisteet > 0) {
            suodatetut.push({ asiakas: asiakas, pisteet: pisteet })
          }

        }
      }

      const loytyneet = suodatetut.sort((a, b) => {
        return b.pisteet - a.pisteet
      })

      // console.log('Löytyi', loytyneet.length)

      const result: AsiakasTypeaheadResult = {
        suodatetut: (loytyneet.length < 51 ? loytyneet : loytyneet.slice(0, 50)).map(a => a.asiakas)
      }
      return result

    })
  }

}

type KasiteltyTypeaheadTuote = TypeaheadTuote & {
  lowerNimi: string
}

@Injectable()
export class LaskunTuoteTypeahead implements LaskunTuoteTypeaheadBase {

  private typeaheadAsiakkaatSubject = new BehaviorSubject<KasiteltyTypeaheadTuote[]>([])

  constructor(
    private kayttajaService: KayttajaService,
    private laskuService: LaskuService,
    private errorHandler: ErrorHandler,
    private laskuIndeksoija: FirestoreIndeksoija
  ) {
    this.kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (kayttajanTiedot) {
          return this.laskuService.getTypeaheadTuotteet(kayttajanTiedot)
        }
        return of<TypeaheadTuote[]>([])
      }),
      map(tuotteet => {
        if (tuotteet) {
          return tuotteet.map<KasiteltyTypeaheadTuote>(asiakas => {
            const kasitelty = asiakas as KasiteltyTypeaheadTuote
            kasitelty.lowerNimi = this.laskuIndeksoija.poistaValimerkitJaValilyonnit(asiakas.nimi)
            return kasitelty
          })
        }
        return []
      })
    ).subscribe(tuotteet => {
      this.typeaheadAsiakkaatSubject.next(tuotteet || [])
    }, err => { this.errorHandler.handleError(err) })
  }

  public getTuote(avain: string): Promise<AsiakkaanTuote> {
    return this.laskuService.getTuote(avain)
  }

  public haeJaSuodata(value: string): Promise<TuoteTypeaheadResult> {
    const sanat = this.laskuIndeksoija.poistaValimerkitJaNormalisoiValilyonnit(value).split(' ')
    const val = this.laskuIndeksoija.poistaValimerkitJaValilyonnit(value)
    return firstValueFrom(this.typeaheadAsiakkaatSubject).then(tuotteet => {

      let loytynyt: TypeaheadTuote = null
      let suodatetut: { tuote: TypeaheadTuote, pisteet: number }[] = []

      if (val && val !== '') {
        for (const tuote of tuotteet) {
          if (!tuote) { continue }

          const lowercaseNimi = tuote.lowerNimi

          if (lowercaseNimi === val) {
            loytynyt = tuote
            suodatetut = []
            break
          }

          let pisteet = 0

          for (const sana of sanat) {
            if (lowercaseNimi.startsWith(sana)) {
              pisteet += 2
            } else if (lowercaseNimi.indexOf(sana) > -1) {
              pisteet++
            }
          }

          if (lowercaseNimi.startsWith(val)) {
            pisteet += 4
          } if (lowercaseNimi.indexOf(val) > -1) {
            pisteet += 2
          }

          if (lowercaseNimi === val) {
            pisteet += 100
          }

          if (pisteet > 0) {
            suodatetut.push({ tuote: tuote, pisteet: pisteet })
          }
        }
      }

      const loytyneet = suodatetut.sort((a, b) => {
        return b.pisteet - a.pisteet
      })

      const result: TuoteTypeaheadResult = {
        loytynyt: loytynyt,
        suodatetut: (loytyneet.length < 51 ? loytyneet : loytyneet.slice(0, 50)).map(a => a.tuote)
      }
      return result

    })
  }

}
