import { AbstractControl, ValidationErrors, ValidatorFn, UntypedFormArray } from '@angular/forms'
import { CodeCheckService } from '../../_shared-core/service/code-check.service'
import { BicService } from 'app/_shared-core/service/bic.service'
import { IbanService } from 'app/_shared-core/service/iban.service'

export class LemonaidValidators {

  private static codeCheckService = new CodeCheckService()
  private static bicService = new BicService()
  private static ibanService = new IbanService(this.bicService)

  static phoneNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const nmb = control.value
      if (LemonaidValidators.codeCheckService.isValidPhoneNumber(nmb) === false) {
        return { invalid: true }
      }
      if (LemonaidValidators.codeCheckService.isValidInternationalPhoneNumber(nmb) === false) {
        return { notinternational: true }
      }
      if (
        LemonaidValidators.codeCheckService.isValidInternationalPhoneNumber(nmb) &&
        LemonaidValidators.codeCheckService.isPhoneNumberWithFinnishCountryCode(nmb) &&
        LemonaidValidators.codeCheckService.finnishPhoneNumberContainsErroneusLeadingZero(nmb)
      ) {
        return { leadingzero: true }
      }
      if (
        LemonaidValidators.codeCheckService.isValidInternationalPhoneNumber(nmb) &&
        LemonaidValidators.codeCheckService.isPhoneNumberWithFinnishCountryCode(nmb) &&
        LemonaidValidators.codeCheckService.isValidFinnishPhoneNumberInInternationalForm(nmb) === false
      ) {
        return { invalid: true }
      }
    }
    return null
  }

  // TODO: Delete & change all instances to use /_jaettu-angular/_validators/FormValidator.ts
  static finnishHetuYtunnusOrVatNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const hetu = control.value
      if (
        LemonaidValidators.codeCheckService.isValidFinnishPersonalId(hetu) === false &&
        LemonaidValidators.codeCheckService.isValidVatNumber(hetu) === false &&
        LemonaidValidators.codeCheckService.isValidYTunnus(hetu) === false
      ) {
        return { invalid: true }
      }
    }
    return null
  }

  // TODO: Delete & change all instances to use /_jaettu-angular/_validators/FormValidator.ts
  static vahintaanYksiArrayssaValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof UntypedFormArray) {
      const array = control as UntypedFormArray
      if (!array.controls || array.controls.length < 1) {
        return { min: true }
      }
      return null
    }
    throw new Error('This validator can only be used with FormArrays.')
  }

  static finnishPersonalIdValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const hetu = control.value
      if (LemonaidValidators.codeCheckService.isValidFinnishPersonalId(hetu) === false) {
        return { invalid: true }
      }
    }
    return null
  }

  static passwordComplexityValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    const value = control.value || ''
    const errors: any = {}

    if (value.length < 10) {
      errors['min'] = true
    }

    const map: any = {}
    for (const c of value) {
      if (Object.keys(map).includes(c)) {
        map[c] = map[c] + 1
      } else {
        map[c] = 1
      }
    }

    if (Object.keys(map).length < 5) {
      errors['differentchars'] = true
    }

    const numericCheck = /[1234567890]+/gi
    if (!numericCheck.test(value)) {
      errors['number'] = true
    }

    const letterSmallCheck = /[abcdefghijklmnopqrstuvwxyzäöå]+/g
    if (!letterSmallCheck.test(value)) {
      errors['lettersmall'] = true
    }

    const letterBigCheck = /[ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÅ]+/g
    if (!letterBigCheck.test(value)) {
      errors['letterbig'] = true
    }

    return Object.keys(errors).length > 0 ? errors : null

  }

  static bicValidator: ValidatorFn = (c: AbstractControl): ValidationErrors | null => {
    // console.log(c.value, this.ibanService.isValidIban(c.value))
    if (c && c.value && !LemonaidValidators.bicService.validoiBic(c.value)) {
      return { 'bic': true }
    }
    return null
  }

  static ibanValidator: ValidatorFn = (c: AbstractControl): ValidationErrors | null => {
    // console.log(c.value, this.ibanService.isValidIban(c.value))
    if (c && c.value && !LemonaidValidators.ibanService.isValidIban(c.value)) {
      return { 'iban': true }
    }
    return null
  }

}
