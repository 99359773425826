<div style="text-align: center; max-width: 580px;">
  <div mat-dialog-content>

    <lemon-dialog-header [naytaSuljeRuksi]="false">
      {{'reports-lemonaid.tilinpaatos-allekirjoitettu' | translate | async }}
    </lemon-dialog-header>

    <div>
      {{ text | translate | async }}
    </div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0; margin-bottom: 20px;">
    <button *ngIf="button === 'jatka'" class="primarybutton" mat-button mat-dialog-close>{{'yleiset.jatka' | translate | async }}</button>
    <button *ngIf="button === 'avaa-poytakirja'" class="primarybutton" mat-button (click)="avaaPoytakirja()">{{ 'reports-lemonaid.avaa-yhtiokokouksen-poytakirja' | translate | async }}</button>
  </div>
</div>