<div class="all" mat-dialog-content style="padding: 0 6vw;">
  <div class="big-card">
    <div class="light-blue-text title-text" [innerHTML]="'holvi-oauth.completed.valtuutus-onnistui' | translate | async"></div>
    <div class="inner-container">
      <div>{{ 'holvi-oauth.completed.valtuutus-annettiin-onnistuneesti' | translate | async }}</div>
    </div>
    <div (click)="expandExtraText()" class="gray-text lue-lisaa" style="text-align: center;">{{ 'holvi-oauth.lue-lisaa' | translate | async }}</div>
    <div class="button-container">
      <button class="primarybutton" (click)="sulje()" mat-button>{{ 'yleiset.jatka' | translate | async }}</button>
    </div>
    <div #selitys class="gray-text valtuutus-extra-text" [hidden]="!extraTextExpanded" [innerHTML]="'holvi-oauth.valtuutus-pitka-selitus' | translate | async"></div>
  </div>
</div>