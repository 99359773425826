<div class="all" mat-dialog-content style="padding: 0 6vw;">
  <div class="big-card">
    <div class="light-blue-text title-text" [innerHTML]="valtuutusTitle"></div>
    <div class="inner-container">
      <div class="text-box">
        <div style="margin-bottom: 40px;">
          {{ valtuutusStartText }}
        </div>

        <div *ngIf="accessibleIbansObservable | async; let accounts" style="margin-bottom: 40px;">
          <div class="bold-text">
            {{ 'viia-login-required.on-tileja.valtuutus-kunnossa' | translate | async }}
          </div>
          <table>
            <tbody>
              <tr *ngFor="let account of accounts" class="smaller-on-mobile">
                <td>{{ account.pankki }}</td>
                <td style="text-align: right;">{{ account.iban }}</td>
                <td *ngIf="account.nimi" style="text-align: right;">{{ '(' + account?.nimi + ')' }}</td>
                <!-- <td class="hide-on-mobile">{{ account.bic }}</td> -->
                <td style="width: 25px; font-size: 22px; text-align: right;">
                  <!-- <i class="icon ion-ios-checkmark-outline" style="font-size: 43px;"></i> -->
                  <!-- <i class="fa fa-check-square-o" aria-hidden="true"></i> -->
                  <mat-icon [inline]="true" style="vertical-align: bottom;" class="green-text">link</mat-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="nonAccessibleIbansObservable | async; let accountsWithoutConsent">
          <div class="bold-text">
            {{ 'viia-login-required.on-tileja.valtuutus-puuttuu' | translate | async }}
          </div>
          <table>
            <tbody>
              <tr *ngFor="let account of accountsWithoutConsent" class="smaller-on-mobile">
                <td>{{ account.pankki }}</td>
                <td style="text-align: right;">{{ account.iban }}</td>
                <td *ngIf="account.nimi" style="text-align: right;">{{ '(' + account.nimi +')' }}</td>
                <!-- <td class="hide-on-mobile">{{ account.bic }}</td> -->
                <td style="width: 25px; font-size: 22px; text-align: right;">
                  <!-- <i class="icon ion-ios-checkmark-outline" style="font-size: 43px;"></i> -->
                  <!-- <i class="fa fa-check-square-o" aria-hidden="true"></i> -->
                  <mat-icon [inline]="true" style="vertical-align: bottom;" class="red-text">link_off</mat-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>{{ valtuutusEndText }} </div>
        <div (click)="expandExtraText()" class="gray-text lue-lisaa">{{ 'viia-login-required.lue-lisaa' | translate | async }}</div>
        <div class="red-text common-error" *ngIf="commonError">{{commonError}}</div>
      </div>
      <div class="button-container">
        <button class="secondarybutton" (click)="postpone()" mat-button>{{ 'yleiset.myohemmin' | translate | async}}</button>
        <button class="primarybutton" (click)="goToAiia()" mat-button>{{ 'yleiset.jatka' | translate | async }}</button>
      </div>
    </div>
    <div #selitys class="gray-text valtuutus-extra-text" [hidden]="!extraTextExpanded" [innerHTML]="'viia-login-required.valtuutus-pitka-selitus' | translate | async"></div>
  </div>
</div>