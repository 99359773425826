import { ErrorHandler, Injectable } from '@angular/core'
import { ViitenumeroService, LaskunQrKoodinOsat, VirtuaaliviivakoodinOsat } from 'app/_shared-core/service/viitenumero.service'
import { readBarcodesFromImageFile, setZXingModuleOverrides } from './index'

export interface QrTaiViivakoodi {
  qr?: LaskunQrKoodinOsat
  viiva?: VirtuaaliviivakoodinOsat
}

/**
 * Imlementation is currently done using https://github.com/Sec-ant/zxing-wasm-build
 */
@Injectable()
export class LemonBarcodeDetector {

  constructor(
    private _errorHandler: ErrorHandler,
    private _viitenumeroService: ViitenumeroService
  ) {
    setZXingModuleOverrides({
      locateFile: (path, prefix) => {
        // console.log('WHAT?', prefix, path)
        return '/assets/' + path
      }
    })
  }

  async detect(file: Blob): Promise<QrTaiViivakoodi> {

    const time = Date.now()
    try {

      const results = await readBarcodesFromImageFile(file, {
        formats: ['Code128', 'QRCode'],
        tryHarder: true
      })

      // console.log('results', results)

      for (const res of results ?? []) {
        if (res.format === 'QRCode') {
          const parsedQr = this._viitenumeroService.parsiLaskunQrKoodi(res.text)
          if (parsedQr) {
            return { qr: parsedQr }
          }
        }
      }

      for (const res of results ?? []) {
        if (res.format === 'Code128') {
          const parsedViiva = this._viitenumeroService.parsiSuomalainenVirtuaaliviivakoodi(res.text)
          if (parsedViiva) {
            return { viiva: parsedViiva }
          }
        }
      }

      return null

    } catch (err) {
      this._errorHandler.handleError(err)
      return null
    } finally {
      console.log('Took ' + (Date.now() - time) + 'ms to scan codes.')
    }

  }

}
