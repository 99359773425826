import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FORCE_TAB_CHANGE_CHANNEL, ReportsForcedTabChange } from './tilinpaatokset.component'

export interface TilinpaatosSignedDialogData {
  tilintarkastetaan: boolean
  isLastSigner: boolean
}

@Component({
  templateUrl: './tilinpaatos-signed.dialog.html'
})
export class TilinpaatosSignedDialog implements OnInit {

  text: string
  button: 'jatka' | 'avaa-poytakirja' = 'jatka'

  _reportsForceTabChangeChannel: BroadcastChannel

  constructor(
    private _dialogRef: MatDialogRef<TilinpaatosSignedDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: TilinpaatosSignedDialogData
  ) {
    this._dialogRef.disableClose = true
  }

  ngOnInit() {
    this._reportsForceTabChangeChannel = new BroadcastChannel(FORCE_TAB_CHANGE_CHANNEL)

    this.text = this._getCorrectText(this._data.tilintarkastetaan, this._data.isLastSigner)

    this.button = !this._data.tilintarkastetaan && this._data.isLastSigner ? 'avaa-poytakirja' : 'jatka'
  }


  private _getCorrectText(tilintarkastetaan: boolean, isLastSigner: boolean) {
    if (tilintarkastetaan) {
      if (isLastSigner) {
        return 'reports-lemonaid.tilitarkastetaan.tilinpaatos-valmis-tilintarkastettavaksi'
      }
      return 'reports-lemonaid.tilitarkastetaan.tilinpaatos-allekirjoitettu-osaltasi'
    }
    if (isLastSigner) {
      return 'reports-lemonaid.ei-tilitarkasteta.tilinpaatos-valmis-vahvistettavaksi'
    }
    return 'reports-lemonaid.ei-tilitarkasteta.tilinpaatos-allekirjoitettu-osaltasi'

  }

  avaaPoytakirja() {
    const message: ReportsForcedTabChange = 'yhtiokokouksen-poytakirja'
    this._reportsForceTabChangeChannel.postMessage(message)

    this._dialogRef.close()
  }
}

