/**
 * Rejection errors that could be helpful for the user.
 * Add more known reasons when necessary.
 */
export enum PaymentRejectionReason {
  UNKNOWN = 1,

  WS_AGREEMENT = 2,
  INSUFFICIENT_FUNDS = 3,
  LIMIT_EXCEEDED = 4,
  // ...
}

/**
 * Additional rejection information from the payments project that can be exposed to UI
 */
export interface PaymentRejection {
  reason: PaymentRejectionReason
  /** Used for PaymentRejectionReason.UNKNOWN cases and contains the raw description of the error. */
  rawText?: string
}
