import { ErrorHandler, Injectable, OnDestroy } from '@angular/core'

import { VirtuaaliviivakoodinOsat, ViitenumeroService } from '../../_shared-core/service/viitenumero.service'
import { PDFiumDocument, PDFiumLibrary } from '@lemontree/pdfium-wasm'
import { StringService } from 'app/_shared-core/service/string.service'

export interface PdfConversionJobSettingsWasm {
  document: PDFiumDocument
}


@Injectable()
export class PdfService implements OnDestroy {

  private _library: Promise<PDFiumLibrary>

  constructor(
    private errorHandler: ErrorHandler,
    private viitenumeroService: ViitenumeroService,
    private _stringService: StringService
  ) {
    this._library = PDFiumLibrary.init()
  }

  ngOnDestroy(): void {
    if (this._library) {
      this._library.then(lib => {
        lib.destroy()
        this._library = null
      })
    }
  }

  public async yritaParsiaPdf(fileAsUint8Array: Uint8Array): Promise<PdfConversionJobSettingsWasm | null> {
    try {
      // const startInit = Date.now()
      const library = await this._library
      // console.log('Took ' + (Date.now() - startInit) + ' ms to init wasm library.')

      // const startDocumentLoad = Date.now()
      const document = await library.loadDocument(fileAsUint8Array)
      // console.log('Took ' + (Date.now() - startDocumentLoad) + ' ms to to load document.')

      return {
        document: document
      }
    } catch (error) {
      // console.error(error)
      this.errorHandler.handleError(error)
      return null
    }
  }

  public async etsiViivakoodiPdfsta(settingsWasm: PdfConversionJobSettingsWasm): Promise<VirtuaaliviivakoodinOsat | null> {
    const virtuaaliviivakoodiRegexp = /([0-9]{20,99})/g
    for (const page of settingsWasm.document.pages()) {
      const str = page.getText()
      page.destroy()
      const words = str?.replace(/\s+/g, ' ')?.split(' ') ?? []
      for (const word of words) {
        let match = null
        while (match = virtuaaliviivakoodiRegexp.exec(word)) {
          if (match.length > 1) {
            const purettuViivakoodi = this.viitenumeroService.parsiSuomalainenVirtuaaliviivakoodi(match[1])
            if (purettuViivakoodi && purettuViivakoodi.summa) {
              return purettuViivakoodi
            }
          }
        }
      }
    }
    return null
  }

  public async extractAllText(settingsWasm: PdfConversionJobSettingsWasm): Promise<string[]> {
    const texts: string[] = []
    for (const page of settingsWasm.document.pages()) {
      texts.push(this._stringService.normalizeWhitespace(page.getText() ?? ''))
      page.destroy()
    }
    return texts
  }

}
