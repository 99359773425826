import { Injectable } from '@angular/core'

import { TuettuKieli } from 'app/_shared-core/model/common'
import { EnvironmentType } from '../../app.environment'
import { LocalStorageService } from 'app/_jaettu-angular/service/local-storage.service'
import { Router } from '@angular/router'
import { ActionAfterAuthentication, NetsEnvironment, StrongAuthenticationSessionHistory, StrongAuthenticationSessionStep } from 'app/_jaettu/model/tunnistaminen'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { Kayttaja } from 'app/_jaettu/model/kayttaja'
import { FirebaseLemonaid } from './firebase-lemonaid.service'

type NetsAuthenticationResult = 'success' | 'error' | 'error-cancel'
export type NetsAuthMethod = 'fi_mobiilivarmenne' | 'nordea' | 'opbank' | 'danske' | 'handelsbanken' | 'aland' | 'sbank' | 'aktia' | 'popbank' | 'savingsbank' | 'omasp'
interface TunnistautuminenClientSession {
  action: ActionAfterAuthentication
  state: string
  sessionAvain: string
  startedAtMillis: number
}

@Injectable()
export class TunnistautuminenService {

  private _currentSessionKey = 'tunnistauminen-session'
  private _tunnistautuminenState: NetsAuthenticationResult = null
  private _tunnistautuminenAction: ActionAfterAuthentication = null

  constructor(
    private _localStorageService: LocalStorageService,
    private _router: Router,
    private _timestampService: TimestampService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) { }

  navigateByAction(action: ActionAfterAuthentication) {
    this._tunnistautuminenAction = action
    if (action === 'continue-welcome') {
      this._router.navigate(['/'])
    } else if (action === 'continue-standalone-dialog') {
      this._router.navigate(['/'])
    } else if (action === 'continue-payment-approval') {
      this._router.navigate(['/maksut'])
    } else {
      throw new Error('unknown action ' + action)
    }
  }

  setTunnistautuminenState(state: NetsAuthenticationResult) {
    this._tunnistautuminenState = state
  }

  getTunnistautuminenState(): NetsAuthenticationResult {
    return this._tunnistautuminenState
  }

  getTunnistautuminenAction(): ActionAfterAuthentication {
    return this._tunnistautuminenAction
  }

  public startClientSideAuthenticationSession(action: ActionAfterAuthentication, sessionAvain: string, state: string) {
    this._localStorageService.setToSessionStorage<TunnistautuminenClientSession>(this._currentSessionKey, {
      action: action,
      state: state,
      sessionAvain: sessionAvain,
      startedAtMillis: new Date().getTime()
    })
  }

  public getClientSideAuthenticationSession(): TunnistautuminenClientSession {
    return this._localStorageService.getFromSessionStorage<TunnistautuminenClientSession>(this._currentSessionKey)
  }

  public endClientSideAuthenticationSession() {
    this._localStorageService.deleteFromSessionStorage(this._currentSessionKey)
    this._tunnistautuminenState = null
    this._tunnistautuminenAction = null
  }

  getNetsEnvironment(environment: EnvironmentType): NetsEnvironment {
    if (environment === EnvironmentType.PRODUCTION) {
      return 'prod'
    } else if (environment === EnvironmentType.BETA) {
      return 'beta'
    } else if (environment === EnvironmentType.DEV) {
      return 'dev'
    } else if (environment === EnvironmentType.LOCAL_DEV) {
      return 'local-dev'
    }
    throw new Error('Unknown environment ' + environment)
  }

  private getNetsRedirectUrl(environment: EnvironmentType): string {
    if (environment === EnvironmentType.PRODUCTION) {
      return 'https://lemonaid.lemontree.fi/tunnistautuminen/nets-tarkista'
    } else if (environment === EnvironmentType.BETA) {
      return 'https://beta.lemonaid.lemontree.fi/tunnistautuminen/nets-tarkista'
    } else if (environment === EnvironmentType.DEV) {
      return 'https://dev.lemonaid.lemontree.fi/tunnistautuminen/nets-tarkista'
    } else if (environment === EnvironmentType.LOCAL_DEV) {
      return 'https://local-dev.lemonaid.lemontree.fi:4001/tunnistautuminen/nets-tarkista'
    }
    throw new Error('Unknown environment ' + environment)
  }

  private getNetsBaseUrl(environment: EnvironmentType): string {
    if (environment === EnvironmentType.PRODUCTION || environment === EnvironmentType.BETA) {
      return 'https://www.ident.nets.eu/its/index.html'
    } else if (environment === EnvironmentType.DEV || environment === EnvironmentType.LOCAL_DEV) {
      return 'https://www.ident-preprod1.nets.eu/its/index.html'
    }
    throw new Error('Unknown environment ' + environment)
  }

  /**
   *
   * @param environment
   * @param authMethod
   * @param state Docs say 2017-06-12-15.12.43.345354
   * @param nonce Docs say 386145d332342
   */
  public getNetsEidentUrl(environment: EnvironmentType, authMethod: NetsAuthMethod, state: string, nonce: string, kieli: TuettuKieli): string {

    const clientId = 'TIWHVYEPDKNY'
    const redirectUri = this.getNetsRedirectUrl(environment)
    const baseUrl = this.getNetsBaseUrl(environment)
    const uiLocales = kieli === 'fi' ? 'fi-FI' : 'en-GB'
    const amrValue = authMethod === 'fi_mobiilivarmenne' ? 'fi_mobiilivarmenne' : 'fi_tupas'

    let url = baseUrl + '?client_id=' + clientId + '&response_type=code&wi=r&redirect_uri=' + encodeURIComponent(redirectUri) + '&scope=openid+ssn+profile&amr_values=' + amrValue + '&state=' + state + '&nonce=' + nonce + '&uiLocales' + uiLocales

    if (authMethod !== 'fi_mobiilivarmenne') {
      url += '&forcebank=' + authMethod
    }

    return url

  }

  saveHistory(kayttaja: Kayttaja, step: StrongAuthenticationSessionStep, details: string, env: NetsEnvironment): Promise<any> {

    if (kayttaja) {
      const historyData: StrongAuthenticationSessionHistory = {
        asiakasAvain: kayttaja.asiakasAvain,
        kayttajaAvain: kayttaja.avain,
        logTime: this._timestampService.now(),
        step: step,
        details: details,
        env: env
      }
      const avain = this._firebaseLemonaid.firestoreCreateId()
      const uri = 'customers/' + kayttaja.asiakasAvain + '/customers-users/' + kayttaja.avain + '/user-auth-history/' + avain
      return this._firebaseLemonaid.firestoreSetData(uri, historyData)
    }
    // else {
    //   const historyData: StrongAuthenticationSessionHistory = {
    //     asiakasAvain: null,
    //     kayttajaAvain: null,
    //     logTime: this._timestampService.now(),
    //     step: step,
    //     details: details,
    //     env: env
    //   }
    //   const avain = this._lemonaidFirestore.createId()
    //   const ref = 'nets-strong-auth-log/' + avain
    //   const doc = this._lemonaidFirestore.doc(ref)
    //   return doc.set(historyData)
    // }

  }

}
