import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChild } from '@angular/core'
import {
  AbstractControl,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators
} from '@angular/forms'

import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'

import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { AsetustenOletuksetService } from '../../_angular/service/asetusten-oletukset.service'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { Laskuasetukset, LaskuasetuksetSpostille, LaskunSahkopostipohja } from '../../_jaettu/model/lasku'
import { TuettuKieli } from '../../_shared-core/model/common'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-emailpohja',
  templateUrl: './emailpohja.component.html'
})
export class EmailPohjaComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild(MatInput) replyToInput: MatInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  private tuetutKielet: TuettuKieli[] = ['fi', 'en' /* , 'sv' */]

  form: UntypedFormGroup
  fieldErrors: FieldErrors = {}

  asetukset: Laskuasetukset = null

  constructor(
    private laskuService: LaskuService,
    private validationService: FormValidationService,
    private translationService: LemonTranslationService,
    private laskuKopioija: LaskuKopioija,
    private asetuksetOletuksetService: AsetustenOletuksetService,
    private errorHandler: ErrorHandler,
    private snackbar: MatSnackBar,
    private ladataanService: LadataanService
  ) {

  }

  ngAfterContentChecked() {
    this.fieldErrors = this.validationService.updateValidationStatus('laskuasetukset', this.form)
  }

  private lisaaKieli(kieli: TuettuKieli, asetukset: LaskuasetuksetSpostille, muistutusasetukset: LaskuasetuksetSpostille) {

    const kielenSpostiasetukset = new UntypedFormGroup({
      'aihe': new UntypedFormControl(asetukset.aihe, [Validators.required]),
      'otsikko': new UntypedFormControl(asetukset.otsikko, [Validators.required]),
      'teksti': new UntypedFormControl(asetukset.teksti, []),
      'maihe': new UntypedFormControl(muistutusasetukset.aihe, [Validators.required]),
      'motsikko': new UntypedFormControl(muistutusasetukset.otsikko, [Validators.required]),
      'mteksti': new UntypedFormControl(muistutusasetukset.teksti, [])
    })

    kielenSpostiasetukset.get('maihe').valueChanges.subscribe(value => { muistutusasetukset.aihe = this.validationService.processValue(value) })
    kielenSpostiasetukset.get('motsikko').valueChanges.subscribe(value => { muistutusasetukset.otsikko = this.validationService.processValue(value) })
    kielenSpostiasetukset.get('mteksti').valueChanges.subscribe(value => { muistutusasetukset.teksti = this.validationService.processValue(value) })
    kielenSpostiasetukset.get('aihe').valueChanges.subscribe(value => { asetukset.aihe = this.validationService.processValue(value) })
    kielenSpostiasetukset.get('otsikko').valueChanges.subscribe(value => { asetukset.otsikko = this.validationService.processValue(value) })
    kielenSpostiasetukset.get('teksti').valueChanges.subscribe(value => { asetukset.teksti = this.validationService.sanitizeWhitespace(value) })

    const spostiasetukset = this.form.get('spostiasetukset') as UntypedFormArray

    spostiasetukset.push(kielenSpostiasetukset)

  }

  ngOnInit() {

    // Create form
    this.form = new UntypedFormGroup({
      // 'replyto': new FormControl('', [ Validators.required, Validators.email ]),
      'slogan': new UntypedFormControl('', []),
      'spostiasetukset': new UntypedFormArray([])
    })

    // Bind to changes
    // this.form.get('replyto').valueChanges.subscribe(value => {this.asetukset.replyto = value})
    this.form.get('slogan').valueChanges.subscribe(value => { this.asetukset.slogan = value })
    // this.form.get('template').valueChanges.subscribe(value => {this.asetukset.template = value})

    // Load data
    this.laskuService.asetuksetObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(asetukset => {
      // this.form.reset()
      if (asetukset) {
        this.asetukset = this.laskuKopioija.copyAsetukset(asetukset)
        this.asetuksetOletuksetService.poistaOletuksetAsetuksista(this.asetukset)
      } else {
        this.asetukset = {
          nimi: null,
          ytunnus: null,
          katuosoite: null,
          postitmp: null,
          postinro: null,
          maakoodi: 'FIN',
          date: null,
          replyto: null,
          puhelin: null,
          bic: null,
          iban: null,
          pankki: null,
          base64Logo: null,
          ohjeet: null,
          slogan: null,
          piilotaYrityksenNimiLaskulla: false,
          summahuomautusPiilotettu: false,
          muistutusSpostiasetukset: {},
          spostiasetukset: {},
          tervetuloaNahty: true
        }
      }

      this.asetukset.tervetuloaNahty = true

      if (!this.asetukset.spostiasetukset) {
        this.asetukset.spostiasetukset = {}
      }

      if (!this.asetukset.muistutusSpostiasetukset) {
        this.asetukset.muistutusSpostiasetukset = {}
      }

      this.form.controls['spostiasetukset'] = new UntypedFormArray([])
      for (const tuettuKieli of this.tuetutKielet) {
        let spostiasetukset: LaskuasetuksetSpostille = this.asetukset.spostiasetukset[tuettuKieli]
        let muistutusSpostiasetukset: LaskuasetuksetSpostille = this.asetukset.muistutusSpostiasetukset[tuettuKieli]

        if (!spostiasetukset) {
          spostiasetukset = {
            aihe: this.translationService.lokalisoiKielella('lasku.spostipohjat.oletukset.sposti.aihe', tuettuKieli),
            kieli: tuettuKieli,
            otsikko: this.translationService.lokalisoiKielella('lasku.spostipohjat.oletukset.sposti.otsikko', tuettuKieli),
            teksti: this.translationService.lokalisoiKielella('lasku.spostipohjat.oletukset.sposti.teksti', tuettuKieli),
            template: LaskunSahkopostipohja.PERINTEINEN
          }
          this.asetukset.spostiasetukset[tuettuKieli] = spostiasetukset
        }

        if (!muistutusSpostiasetukset) {
          muistutusSpostiasetukset = {
            aihe: this.translationService.lokalisoiKielella('lasku.spostipohjat.oletukset.muistutussposti.aihe', tuettuKieli),
            kieli: tuettuKieli,
            otsikko: this.translationService.lokalisoiKielella('lasku.spostipohjat.oletukset.muistutussposti.otsikko', tuettuKieli),
            teksti: this.translationService.lokalisoiKielella('lasku.spostipohjat.oletukset.muistutussposti.teksti', tuettuKieli),
            template: LaskunSahkopostipohja.MUISTUTUS_PERINTEINEN
          }
          this.asetukset.muistutusSpostiasetukset[tuettuKieli] = muistutusSpostiasetukset
        }

        // console.log(spostiasetukset, muistutusSpostiasetukset)

        this.lisaaKieli(tuettuKieli, spostiasetukset, muistutusSpostiasetukset)
      }

      // this.form.get('replyto').setValue(this.asetukset.replyto)
      // this.form.get('template').setValue(this.asetukset.template)
      this.form.get('slogan').setValue(this.asetukset.slogan)

    }, err => {
      this.errorHandler.handleError(err)
    })
  }

  get asetusControllit(): AbstractControl[] {
    const spostiasetukset = this.form.get('spostiasetukset') as UntypedFormArray
    return spostiasetukset.controls
  }

  otsikko(i: number): string {
    const kieli = this.tuetutKielet[i]
    return this.translationService.lokalisoi('kieli.' + kieli)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  save() {
    if (this.validationService.onkoLomakkeessaVirheita(this.form)) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.form)
    } else {
      this.ladataanService.aloitaLataaminen()
      this.laskuService.updateLaskuasetukset(this.asetukset).then(() => {
        const lokalisoitu = this.translationService.lokalisoi('laskuasetukset.tallennettu-onnistuneesti')
        this.snackbar.open(lokalisoitu, 'OK', { duration: 5000, verticalPosition: 'top' })
        this.ladataanService.lopetaLataaminen()
      }).catch(err => {
        this.ladataanService.lopetaLataaminen()
        this.errorHandler.handleError(err)
      })
    }
  }

}
