<div class="detail-page">

  <form (ngSubmit)="save()" [formGroup]="form" novalidate>

    <div style="max-width: 450px;">
      <mat-form-field>
        <mat-label>{{ 'tuote.nimi' | translate | async }}</mat-label>
        <input #nimiInput type="text" formControlName="nimi" name="namename" matInput required data-lpignore="true" />
        <mat-error>{{fieldErrors.nimi}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'tuote.hinta' | translate | async }}</mat-label>
        <input decimalNumberField matInput formControlName="hinta">
        <mat-error>{{fieldErrors.hinta}}</mat-error>
      </mat-form-field>
      <div style="display: flex; justify-content: space-between;">
        <button class="secondarybutton" mat-button routerLink="/laskutus/tuotteet" type="button">{{'yleiset.peruuta' | translate | async}}</button>
        <button *ngIf="tuote?.$key && tuote?.$key !== 'uusi'" class="secondarybutton" mat-button (click)="poista()" type="button">{{'yleiset.poista' | translate | async}}</button>
        <button class="primarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
      </div>
    </div>

  </form>

</div>