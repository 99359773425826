import { Component, OnInit, OnDestroy, ErrorHandler, ElementRef, ViewChild } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'



import { Subject, Observable } from 'rxjs'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { HolviDialogService } from 'app/_angular/service/holvi-dialog.service'

export interface HolviLoginRequiredDialogData { }

interface Account {
  pankki: string
  bic: string
  iban: string
  nimi?: string
}

@Component({
  templateUrl: './holvi-login-required.dialog.html',
  styleUrls: ['./holvi-login.dialog.css']
})
export class HolviLoginRequiredDialog implements OnInit, OnDestroy {

  @ViewChild('selitys', { static: true }) selitysRef: ElementRef

  accountsWithoutConsentObservable: Observable<Account[]>
  accountsWithConsentObservable: Observable<Account[]>
  valtuutusTextsObservable: Observable<string>

  valtuutusTitle: string
  valtuutusStartText: string
  valtuutusEndText: string
  lueLisaa: string

  commonError: string
  extraTextExpanded: boolean = false

  private _buttonWasPushed: boolean
  private _ngUnsubscribe = new Subject<void>()

  constructor(
    private _dialogRef: MatDialogRef<HolviLoginRequiredDialog>,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _kayttajaService: KayttajaService,
    private _holviDialogService: HolviDialogService
  ) { }

  ngOnInit() {

    this._dialogRef.disableClose = true
  }

  async goToHolvi() {

    if (this._buttonWasPushed) {
      return
    }
    this._buttonWasPushed = true

    this._ladataanService.aloitaLataaminen()

    try {
      const tiedot = await this._kayttajaService.getKayttajanTiedot()
      const redirectUrl = await this._holviDialogService.fetchHolviOauthStartUrl(tiedot.asiakasAvain)
      if (!redirectUrl) {
        throw new Error('No redirectUrl to connect to Holvi')
      }
      location.href = redirectUrl

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._buttonWasPushed = false
      this._ladataanService.lopetaLataaminen()
    }
  }

  close() {
    this._dialogRef.close()
  }


  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
