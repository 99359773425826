import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'

import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'

import { TositeService } from '../../_angular/service/tosite/tosite.service'

import { FirestoreTosite } from '../../_jaettu/model/tosite'
import { DateService } from '../../_shared-core/service/date.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'

export interface TositePoistaDialogData {
  poistettava: FirestoreTosite
}

@Component({
  templateUrl: './tosite.poista.dialog.html',
  styleUrls: ['./tosite.poista.dialog.css']
})
export class TositePoistaDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TositePoistaDialogData,
    private dialogRef: MatDialogRef<TositePoistaDialog>,
    private errorHandler: ErrorHandler,
    private tositeService: TositeService,
    private translationService: LemonTranslationService,
    private dateService: DateService,
    private currencyService: CurrencyService
  ) { }

  ngOnInit() {

  }

  poista(): void {

    this.data.poistettava.poistettu = true
    this.tositeService.tallennaKuitti(this.data.poistettava).then(() => {
      this.dialogRef.close(true)
    }).catch(err => {
      this.errorHandler.handleError(err)
      this.dialogRef.close()
    })

  }

  annaLokalisoituLeipateksti(): Observable<string> {

    return this.translationService.currentLanguageObservable.pipe(
      map(kieli => {
        const pvm = this.dateService.muotoilePaikallinenPaiva(this.data.poistettava.localPvm, kieli)
        const summa = this.currencyService.formatoiRaha(this.data.poistettava.summa, 'EUR', kieli)
        return this.translationService.lokalisoi('kuitit.poista-dialog.body', { pvm: pvm, summa: summa })
      })
    )

  }

}
