import { Component, OnInit, AfterViewInit, OnDestroy, ErrorHandler } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Router, ActivatedRoute } from '@angular/router'

import { Subject, of } from 'rxjs'
import { takeUntil, map, switchMap } from 'rxjs/operators'

import { VaihdaKieliDialogData, VaihdaKieliDialog } from '../vaihda-kieli.dialog'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { AnnaKayttajanTiedotReq, TervetuloaAnnaKayttajanTiedotResp, LuoTiliReq, LuoTiliResp, MagicLinkErrorResponse } from '../_jaettu/model/magic-link'
import { LoginInputData, LoginService } from '../_angular/service/login.service'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  templateUrl: './tervetuloa-uusi-kayttaja.component.html',
  styleUrls: ['./tervetuloa-uusi-kayttaja.component.css', '../login/login.component.css']
})
export class TervetuloaUusiKayttajaComponent implements OnInit, AfterViewInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  userCredsForm: UntypedFormGroup
  year = new Date().getFullYear()
  commonError: string
  showForm: boolean = false
  isUsedLinkError: boolean = false
  hidePass: boolean = true

  private _luoTiliData: LuoTiliReq = {
    password: '',
    token: ''
  }
  private _userData: TervetuloaAnnaKayttajanTiedotResp
  private _luoTiliStarted: boolean

  constructor(
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _ladataanService: LadataanService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _validationService: FormValidationService,
    private _loginService: LoginService,
    private _kayttaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this._kayttaService.logout()
  }

  ngOnInit() {
    this.userCredsForm = new UntypedFormGroup({
      'etunimi': new UntypedFormControl({ value: '', disabled: true }),
      'sukunimi': new UntypedFormControl({ value: '', disabled: true }),
      'email': new UntypedFormControl({ value: '', disabled: true }),
      'password': new UntypedFormControl('', [Validators.required, LemonaidValidators.passwordComplexityValidator]),
      // 'agreeTerms': new FormControl('', [ Validators.requiredTrue ])
    })
  }

  ngAfterViewInit() {
    this._route.paramMap.pipe(
      map(paramMap => paramMap.get('token')),
      switchMap(token => {
        if (token) {
          this._luoTiliData.token = token
          this._ladataanService.aloitaLataaminen()
          const requestData: AnnaKayttajanTiedotReq = {
            token: token
          }
          return this._firebaseLemonaid.functionsCall<AnnaKayttajanTiedotReq, TervetuloaAnnaKayttajanTiedotResp>('tervetuloaAnnaKayttajaTiedot', requestData)
        }
        return of<TervetuloaAnnaKayttajanTiedotResp>(null)
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: data => {
        if (data && !data.e) {
          this.showForm = true
          this._userData = data
          this.etunimi.setValue(this._userData.etunimi)
          this.sukunimi.setValue(this._userData.sukunimi)
          this.email.setValue(this._userData.email)
        } else if (data && [MagicLinkErrorResponse.USED, MagicLinkErrorResponse.EXPIRED].includes(data.e)) {
          this.isUsedLinkError = data.e === MagicLinkErrorResponse.USED
          this.commonError = 'magic-link.' + data.e
          this._errorHandler.handleError(data.e)
        } else {
          this.showForm = true
          this.commonError = 'yleiset.tuntematon-virhe'
          this._errorHandler.handleError(new Error(data.e))
        }
        this._ladataanService.lopetaLataaminen()
      },
      error: error => {
        this.showForm = true
        this.commonError = 'yleiset.tuntematon-virhe'
        this._errorHandler.handleError(error)
      }
    })
  }

  get etunimi() {
    return this.userCredsForm.get('etunimi')
  }
  get sukunimi() {
    return this.userCredsForm.get('sukunimi')
  }
  get email() {
    return this.userCredsForm.get('email')
  }
  get password() {
    return this.userCredsForm.get('password')
  }
  // get agreeTerms() {
  //   return this.userCredsForm.get('agreeTerms')
  // }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this._dialog.open(VaihdaKieliDialog, { data: data })
  }

  virheet() {
    if (this.userCredsForm) {
      if (this.password) {
        return this.password.errors || {}
      }
    }
    return {}
  }

  routeToLogin() {
    this._router.navigate(['/kirjaudu'])
  }

  async luoTili() {
    if (this._luoTiliStarted) {
      return
    }
    if (!this.userCredsForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.userCredsForm)
      return
    }

    this._luoTiliStarted = true
    this._ladataanService.aloitaLataaminen()

    try {

      const password: string = this.password.value
      this._luoTiliData.password = password
      const response: LuoTiliResp = await this._firebaseLemonaid.functionsCall<LuoTiliReq, LuoTiliResp>('tervetuloaLuoTili', this._luoTiliData)

      this._ladataanService.lopetaLataaminen()

      if (response && !response.e) {
        const loginDataTransfer: LoginInputData = {
          email: this._userData.email,
          password: password,
          loginCodeId: response.loginCodeId
        }
        this._loginService.changeInput(loginDataTransfer)
        this._router.navigate(['/lisaa-puhelin'])
      } else if (response && [MagicLinkErrorResponse.USED, MagicLinkErrorResponse.EXPIRED].includes(response.e)) {
        this.showForm = false
        this.isUsedLinkError = response.e === MagicLinkErrorResponse.USED
        this.commonError = 'magic-link.' + response.e
        this._errorHandler.handleError(response.e)
      } else {
        this.commonError = 'yleiset.tuntematon-virhe'
        this._errorHandler.handleError(response ? response.e : 'no-response')
      }
      this._luoTiliStarted = false

    } catch (err) {
      this._ladataanService.lopetaLataaminen()
      this._luoTiliStarted = false
      this._errorHandler.handleError(err)
    }

  }
}
