import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'

import { Subject, Observable, combineLatest, firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'

import { ListausFirestoreKuitti } from '../../_angular/service/tosite/tosite-datasource.service'
import { EinvoicesAngularService } from 'app/_angular/service/tosite/einvoices-angular.service'
import { FirestoreTosite } from 'app/_jaettu/model/tosite'
import { TositeService } from 'app/_angular/service/tosite/tosite.service'
import { AreYouSureDialog } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { MatDialog } from '@angular/material/dialog'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { AreYouSureDialogData } from 'app/reports/tilinpaatos-are-you-sure.dialog'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { DateService } from 'app/_shared-core/service/date.service'
import { NumberDate } from 'app/_shared-core/model/common'
import { TositeKatseleComponentDataResolve } from 'app/_angular/_resolvers/tosite.resolve'
import { Router } from '@angular/router'
import { LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialog, LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialogData } from './laskujen-hyvaksyminen.ei-voi-hyvaksya-enaa.dialog'

export interface MaksutavanTiedot {
  summa: number
  lukumaara: number
}

@Component({
  selector: 'app-laskujen-hyvaksyminen',
  templateUrl: './laskujen-hyvaksyminen.component.html',
  styleUrls: ['./laskujen-hyvaksyminen.component.css']
})
export class LaskujenHyvaksyminenComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  showNoUnapprovedObservable: Observable<boolean>
  naytettavatKuititObservable: Observable<ListausFirestoreKuitti[]>
  lataaObservable: Observable<boolean>
  currentDate: NumberDate = this._dateService.currentNumberDate()

  constructor(
    private _router: Router,
    private _einvoicesAngularService: EinvoicesAngularService,
    private _tositeService: TositeService,
    private _dialog: MatDialog,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _translationService: LemonTranslationService,
    private _kuittiKatseleComponentDataResolver: TositeKatseleComponentDataResolve,
    private _kayttajaService: KayttajaService,
    private _timestampService: TimestampService,
    private _dateService: DateService
  ) {

  }

  ngOnInit() {


    this.lataaObservable = this._einvoicesAngularService.lataaObservable

    this.naytettavatKuititObservable = this._einvoicesAngularService.unapprovedEinvoicesObservable

    this.showNoUnapprovedObservable = combineLatest([this.naytettavatKuititObservable, this._einvoicesAngularService.lataaObservable]).pipe(
      map(([kuitit, lataa]) => {
        if (lataa) {
          return false
        }
        return !kuitit.length
      })
    )
  }

  /** Utility function for tracking inside ngFor */
  trackKuitti(index: number, kuitti: ListausFirestoreKuitti) {
    return kuitti.avain
  }

  async rejectInvoice(kuitti: FirestoreTosite, event: MouseEvent) {

    event?.preventDefault()
    event?.stopImmediatePropagation()
    event?.stopPropagation()

    try {

      const dialogData: AreYouSureDialogData = {
        header: this._translationService.lokalisoi('tositteet.laskujen-hyvaksyminen.haluatko-varmasti-hylata'),
        rightAction: this._translationService.lokalisoi('tositteet.laskujen-hyvaksyminen.kylla'),
        text: null
      }
      const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed())

      if (isSure) {
        this._ladataanService.aloitaLataaminen()
        kuitti.einvoiceApprovalStatus = 'rejected'
        await this._tositeService.tallennaKuitti(kuitti)
      }

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async approveInvoice(kuitti: FirestoreTosite, event: MouseEvent) {

    event?.preventDefault()
    event?.stopImmediatePropagation()
    event?.stopPropagation()

    this._ladataanService.aloitaLataaminen()

    try {

      const dayAfterTomorrow = this._dateService.lisaaPaiviaNumber(this._dateService.currentNumberDate(), 5)
      if (kuitti.dueDate && this._dateService.compareNumberDates(kuitti.dueDate, '<', dayAfterTomorrow)) {
        const data: LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialogData = {
          hyvaksyttava: kuitti
        }
        this._dialog.open(LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialog, { data: data })
        return
      }

      const kayttaja = await this._kayttajaService.getKayttaja()

      kuitti.einvoiceApprovalStatus = 'approved'

      if (!kuitti.einvoiceApprovals) {
        kuitti.einvoiceApprovals = {}
      }

      kuitti.einvoiceApprovals[kayttaja.avain] = {
        approved: this._timestampService.now(),
        firstName: kayttaja.etunimi,
        lastName: kayttaja.sukunimi
      }

      await this._tositeService.tallennaKuitti(kuitti, true)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  katsele(tosite: FirestoreTosite) {
    this._kuittiKatseleComponentDataResolver.asetaOlemassaolevaData({ tosite: tosite })
    this._router.navigate(['tositteet', 'laskujen-hyvaksyminen', tosite.avain])
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
