import { Component, ErrorHandler } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { ViiaHaeTokenPyynto, ViiaHaeTokenVastaus } from '../../_jaettu/model/aiia'

import { environment } from '../../../environments/environment'
import { EnvironmentType } from 'app/app.environment'
import { AiiaDialogService } from 'app/_angular/service/aiia-dialog.service'
import { ViiaLoginCompletedDialog } from './viia-login-completed.dialog'
import { ViiaLoginFailedDialog } from './viia-login-failed.dialog'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { AllowedDialogsService } from 'app/_angular/service/allowed-dialogs.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  template: ''
})
export class ViiaOauthCallbackComponent {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _dialog: MatDialog,
    private _aiiaDialogService: AiiaDialogService,
    private _allowedDialogsService: AllowedDialogsService,
    private _kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this._allowedDialogsService.disableAllDialogs()
    this._ladataanService.aloitaLataaminen()
    this._aiiaDialogService.setAiiaFlowCompleted()

    const code = this._activatedRoute.snapshot.queryParamMap.get('code')
    const consentId = this._activatedRoute.snapshot.queryParamMap.get('consentId')

    this._kayttajaService.getKayttaja().then(kayttaja => {
      return this._aiiaDialogService.saveHistory(kayttaja?.asiakasAvain, 'supervised login returned from Aiia', 'Code & consentId included: ' + (!!code && !!consentId))
    }).catch(err => {
      this._errorHandler.handleError(new Error('Aiia log save failed ' + err?.message || ''))
    })

    if (code && consentId) {

      const pyynto: ViiaHaeTokenPyynto = {
        code: code,
        consentId: consentId
      }

      const func = environment.environment === EnvironmentType.PRODUCTION ? 'viiaHaeTokenProd' : 'viiaHaeTokenBeta'
      this._firebaseLemonaid.functionsCall<ViiaHaeTokenPyynto, ViiaHaeTokenVastaus>(func, pyynto).then(vastaus => {
        const error = vastaus.e
        this._ladataanService.lopetaLataaminen()
        if (error) {
          this._writeLoginFailToLog(error)
          this._errorHandler.handleError(error)
          this._dialog.open(ViiaLoginFailedDialog)
        } else {
          this._dialog.open(ViiaLoginCompletedDialog)
        }
      }).catch(err => {
        this._ladataanService.lopetaLataaminen()
        this._errorHandler.handleError(err)
        this._writeLoginFailToLog(err ? JSON.stringify(err) : 'unknown-error')
        this._dialog.open(ViiaLoginFailedDialog)
      })

    } else {
      this._ladataanService.lopetaLataaminen()
      this._writeLoginFailToLog('Missing code or consentId. Code: ' + code + ', consentId: ' + consentId)
      this._errorHandler.handleError(new Error('Missing code or consentId. Code: ' + code + ', consentId: ' + consentId))
      this._dialog.open(ViiaLoginFailedDialog)
    }
  }

  private _writeLoginFailToLog(reason: string) {
    return this._kayttajaService.getKayttaja().then(kayttaja => {
      return this._aiiaDialogService.saveHistory(kayttaja?.asiakasAvain, 'supervised login failed', reason)
    }).catch(err => {
      this._errorHandler.handleError(new Error('Aiia log save failed ' + err?.message || ''))
    })
  }

}
