
<mat-dialog-content>

  <lemon-dialog-header>
    {{'lasku.peruuta-maksumerkinta-dialog.otsikko' | translate | async}}
  </lemon-dialog-header>

  <p style="margin: 3em 3em 2em 3em;">{{'lasku.peruuta-maksumerkinta-dialog.teksti' | translate | async}}</p>
  
</mat-dialog-content>

<mat-dialog-actions style="margin: 30px; margin-top: 12.5px; justify-content: center;">
  <button class="secondarybutton" mat-button mat-dialog-close>{{'yleiset.ei' | translate | async}}</button>
  <button class="primarybutton" mat-button (click)="peru()" cdkFocusRegionstart>
    {{'yleiset.kylla' | translate | async}}
  </button>
</mat-dialog-actions>
