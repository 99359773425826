<div class="kapea" [class.service-enabled]="viewState === 'enabled'">
  <div class="center-container">

    <div class="settings-container">
      <button mat-button class="secondarybutton" (click)="editAddress()">{{'tositteet.laskujen-vastaanotto.asetukset' | translate | async}}</button>
    </div>

    <h2 class="title">{{'tositteet.laskujen-vastaanotto.vastaanotto-ja-valityspalvelu' | translate | async}}</h2>

    <ng-container *ngIf="viewState === 'not-enabled'">
      <div class="text-part">
        <p>{{'tositteet.laskujen-vastaanotto.voit-vastaanottaa-laskuja' | translate | async}}</p>
        <p>{{'tositteet.laskujen-vastaanotto.valityspalvelu-on-ilmaista' | translate | async}}</p>
      </div>
      <div class="lisaa-nappula-container">
        <button mat-button (click)="editAddress()" type="button" class="primarybutton">
          {{'tositteet.laskujen-vastaanotto.ota-kayttoon' | translate | async}}
        </button>
      </div>
    </ng-container>

    <div *ngIf="viewState === 'edit-address'">

      <form style="margin: 20px;" [formGroup]="form">

        <mat-slide-toggle aria-label="Aktivoi palvelu" style="transform: scale(1.2); font-size: 12px; margin: 0 60px 50px; text-align: left;" formControlName="serviceActive">{{'tositteet.laskujen-vastaanotto.aktivoi-palvelu' | translate | async }}</mat-slide-toggle>

        <div class="text-part">
          {{'tositteet.laskujen-vastaanotto.ennen-palvelun-kayttoonottoa' | translate | async }}
        </div>

        <div style="text-align: left;">
          <mat-form-field>
            <mat-label>{{ 'tositteet.laskujen-vastaanotto.valittaja' | translate | async }}</mat-label>
            <mat-select formControlName="valittaja" required>
              <mat-option *ngFor="let operator of operaattorit" [value]="operator.tunnus">
                {{ operator.nimi }} ({{ operator.tunnus }})
              </mat-option>
            </mat-select>
            <mat-error *ngIf="valittaja?.errors?.required && (valittaja?.dirty || valittaja?.touched)">
              {{ 'tositteet.laskujen-vastaanotto.valittaja-required' | translate | async }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'tositteet.laskujen-vastaanotto.osoite' | translate | async }}</mat-label>
            <input type="text" [name]="namename" formControlName="osoite" matInput required data-lpignore="true" />
            <mat-error *ngIf="osoite?.errors?.required && (osoite?.dirty || osoite?.touched)">
              {{ 'tositteet.laskujen-vastaanotto.osoite-required' | translate | async }}
            </mat-error>
          </mat-form-field>

          <mat-checkbox formControlName="forwardLemontreeLaskut" class="pitka-label" style="margin-top: 1.5em;">
            {{ 'tositteet.laskujen-vastaanotto.haluan-myos-lemon-treen-laskut' | translate | async }}
          </mat-checkbox>

          <mat-checkbox formControlName="forwardRequiresApproving" class="pitka-label" style="margin-top: 1em; margin-bottom: 1.5em;">
            {{ 'tositteet.laskujen-vastaanotto.haluan-hyvaksya' | translate | async }}
          </mat-checkbox>

        </div>

      </form>

      <div class="lemon-error" *ngIf="commonError">{{ commonError }}</div>

      <div *ngIf="noApixAddressYet; else perutaTaiTallenna" class="button-container">
        <button mat-button (click)="saveAddress()" type="button" class="primarybutton">
          {{'yleiset.jatka' | translate | async}}
        </button>
      </div>
    </div>

    <ng-template #perutaTaiTallenna>
      <div class="button-container">
        <button mat-button (click)="backToEnabledView()" type="button" class="secondarybutton">
          {{'yleiset.peruuta' | translate | async}}
        </button>
        <button [disabled]="!serviceActive.value" mat-button (click)="saveAddress()" type="button" class="primarybutton">
          {{'yleiset.tallenna' | translate | async}}
        </button>
      </div>
    </ng-template>


    <ng-container *ngIf="viewState === 'enabled'">

      <div class="enabled-sign">
        <i class="fa fa-check green-text" aria-hidden="true" style="margin-right: .5em;"></i>
        <span style="color: #767676">{{'tositteet.laskujen-vastaanotto.aktiivinen' | translate | async}}</span>
      </div>

      <div class="text-part">
        <div *ngIf="(laskujenHyvaksyntaActiveObservable | async); else eiHyvaksyntaa">
          <p>{{'tositteet.laskujen-vastaanotto.osoite-tallennettu-hyvaksynta-valittu' | translate | async}}</p>
          <!-- <span class="link-like" (click)="editAddress()">{{'tositteet.laskujen-vastaanotto.tarkastele-osoitetta' | translate | async}}</span> -->
        </div>

        <ng-template #eiHyvaksyntaa>
          <p>{{'tositteet.laskujen-vastaanotto.osoite-tallennettu-ei-hyvaksyntaa' | translate | async}}</p>
          <!-- <span class="link-like" (click)="editAddress()">{{'tositteet.laskujen-vastaanotto.tarkastele-osoitetta' | translate | async}}</span> -->
        </ng-template>

        <p>{{'tositteet.laskujen-vastaanotto.alta-loydat' | translate | async}}</p>

        <div class="osoite-info" *ngIf="apixLaskuosoiteObservable | async; let apixLaskuosoite">
          <div>{{'tositteet.laskujen-vastaanotto.verkkolaskuosoite' | translate | async}}: {{ apixLaskuosoite.sahkoinenOsoite }}</div>
          <div>{{'tositteet.laskujen-vastaanotto.valittaja' | translate | async}}: {{ apixLaskuosoite.sahkoinenValittajaNimi ? apixLaskuosoite.sahkoinenValittajaNimi + ' (' + apixLaskuosoite.sahkoinenValittaja + ')' : apixLaskuosoite.sahkoinenValittaja }}</div>
        </div>

        <div>{{'tositteet.laskujen-vastaanotto.voit-myos-antaa' | translate | async}} <span class="link-like" (click)="downloadPdf()">{{'tositteet.laskujen-vastaanotto.lataa-pdf' | translate | async}}</span></div>
      </div>

    </ng-container>

    <div lemon-loading [show]="!viewState"></div>

  </div>
</div>