<style>
  .all {
    /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
    max-height: 80vh;
  }

  @media screen and (min-height: 751px) and (max-height: 1100px) {

    .all {
      /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
      max-height: 85vh;
    }

  }

  @media screen and (max-height: 750px) {

    .all {
      /* TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu */
      max-height: 92vh;
    }

  }
</style>

<div class="all" mat-dialog-content style="padding: 0 2vw">

  <div style="min-height: 200px; max-width: 400px; margin-left: auto; margin-right: auto;">

    <h2 class="light-blue-text" style="text-align: center; padding-top: 25px;">{{'laskut.muokkaa-kommenttia' | translate | async}}</h2>

    <form [formGroup]="kommenttiForm" novalidate>
      <mat-form-field>
        <mat-label>{{ 'laskut.kommentti' | translate | async }}</mat-label>
        <textarea [name]="namename" type="text" formControlName="kommentti" matInput cdkTextareaAutosize data-lpignore="true"></textarea>
      </mat-form-field>
    </form>

  </div>

  <div style="min-height: 100px; display: flex; justify-content: center; flex-direction: row; align-items: center;">
    <button class="secondarybutton" style="margin-right: 20px;" mat-button mat-dialog-close>{{'yleiset.peruuta' | translate | async}}</button>
    <button class="primarybutton" mat-button (click)="tallenna()">{{'yleiset.tallenna' | translate | async}}</button>
  </div>

</div>