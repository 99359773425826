<mat-dialog-content>

  <lemon-dialog-header>
    {{'lasku.merkitse-osittain-maksetuksi-dialog.merkitse-maksetuksi' | translate | async}}
  </lemon-dialog-header>

  <form (ngSubmit)="lisaaMaksettuaSummaa()" [formGroup]="form" novalidate style="margin: 30px;">

    <div style="display: flex; flex-flow: row wrap; justify-content: center;">
      <mat-form-field>
        <mat-label>{{ 'lasku.merkitse-osittain-maksetuksi-dialog.avoinna' | translate | async }}</mat-label>
        <input decimalNumberField matInput style="margin: 0 1vw" formControlName="avoinna" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'lasku.merkitse-osittain-maksetuksi-dialog.maksettu-maara' | translate | async }}</mat-label>
        <input decimalNumberField matInput style="margin: 0 1vw" #maksettuMaaraInput cdkFocusRegionstart formControlName="maksettuMaara" required>
        <mat-error>{{fieldErrors.maksettuMaara}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'lasku.merkitse-osittain-maksetuksi-dialog.avoimeksi-jaa' | translate | async }}</mat-label>
        <input decimalNumberField matInput style="margin: 0 1vw" formControlName="avoimeksijaa" required>
      </mat-form-field>
    </div>

  </form>

</mat-dialog-content>
<mat-dialog-actions>
  <!-- <button mat-button mat-dialog-close>{{'lasku.merkitse-osittain-maksetuksi-dialog.peruuta' | translate | async}}</button> -->
  <button class="primarybutton" mat-button (click)="lisaaMaksettuaSummaa()" [disabled]="!form.valid">{{'lasku.merkitse-osittain-maksetuksi-dialog.merkitse' | translate | async}}</button>
</mat-dialog-actions>