import { Component, OnInit, AfterViewInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'


import { Subject, takeUntil } from 'rxjs'

import { TuettuKieli } from 'app/_shared-core/model/common'
import { MaksutVahvistusData } from 'app/tunnistautuminen/nets/maksujen-vahvistus-pankit.component'

@Component({
  templateUrl: './maksut-vahvista.component.html',
  styleUrls: ['./maksut-vahvista.component.css']
})
export class MaksutVahvistaComponent implements OnInit, AfterViewInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  data: MaksutVahvistusData
  kieli: TuettuKieli = 'fi'

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _lemonTranslationService: LemonTranslationService
  ) {
    this.data = this._router.getCurrentNavigation()?.extras?.state as MaksutVahvistusData
  }

  ngOnInit() {

    if (!this.data || !this.data.maksut || this.data.maksut.length < 1 || !this.data.dueType || !this.data.paymentConfigId) {
      this._router.navigate(['maksut'])
    }

    this._lemonTranslationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(lang => this.kieli = lang)

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
