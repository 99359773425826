import { Component } from '@angular/core'


@Component({
  template: `
  <mat-dialog-content>

  <lemon-dialog-header>
   {{ 'lemonaid-sopimus-dialog.kyc.pep' | translate | async }}
  </lemon-dialog-header>

  <div style="width: 90%;
    max-width: 600px;
    margin-left: auto;
    text-align: left;
    margin-right: auto;"
    [innerHTML]="'lemonaid-sopimus-dialog.kyc.pep-teksti' | translate | async">
  </div>

  </mat-dialog-content>
  <mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="primarybutton" mat-button [mat-dialog-close]="true">{{ 'yleiset.ok' | translate | async }}</button>
  </mat-dialog-actions>
`,
})

export class PepTekstiSharedDialog { }
