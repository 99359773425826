import { Component, Inject, OnInit, OnDestroy, AfterContentInit, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef, MatDialog } from '@angular/material/dialog'

import { TunnistautuminenService } from 'app/_angular/service/tunnistautuminen.service'
import { WindowSizeService } from '../../_jaettu-angular/service/window.service'

import { Observable, Subject } from 'rxjs'
import { map, takeUntil, takeWhile } from 'rxjs/operators'
import { AllowedDialogsService } from 'app/_angular/service/allowed-dialogs.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { PepTekstiSharedDialog } from 'app/tervetuloa/pep-teksti-shared.dialog'
import { Maa, MaaService } from 'app/_jaettu-angular/service/maa.service'
import { ErrorResponse } from 'app/_shared-core/model/common'
import { TallennaUserKycRequest } from 'app/_jaettu/model/tunnistaminen'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

export interface TunnistautuminenNetsDialogData {
  kayttajaJoTunnistettu: boolean
  pepMissing: boolean
}

@Component({
  templateUrl: './tunnistautuminen-nets.dialog.html',
  styleUrls: ['./tunnistautuminen-nets.dialog.css']
})
export class TunnistautuminenNetsDialog implements OnInit, AfterContentInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  cellphone: boolean = true
  namename = 'asruopeq' + Math.random()
  commonError: string

  tunnistautuminenStepState: 'start' | 'success' | 'error' | 'error-cancel' = 'start'
  tunnistautujanTiedotAnnettu: boolean = false
  tunnistautujaForm: UntypedFormGroup
  kieli: TuettuKieli = 'fi'

  kansallisuudetObservable: Observable<Maa[]>

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: TunnistautuminenNetsDialogData,
    private _dialogRef: MatDialogRef<TunnistautuminenNetsDialog>,
    private _windowSizeService: WindowSizeService,
    private _allowedDialogsService: AllowedDialogsService,
    private _tunnistautuminenService: TunnistautuminenService,
    private _validationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService,
    private _translationService: LemonTranslationService,
    private _dialog: MatDialog,
    private _maaService: MaaService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {

    this._translationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(k => {
      this.kieli = k || 'fi'
    })

    this.cellphone = window.innerWidth < 670 || window.innerHeight < 670

    this._windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this.cellphone = window.innerWidth < 670 || window.innerHeight < 670
    })

    this._dialogRef.disableClose = true

    this.tunnistautujanTiedotAnnettu = !this._data.pepMissing
  }

  ngOnInit() {
    this.tunnistautujaForm = new UntypedFormGroup({
      'nimi': new UntypedFormControl({ value: null, disabled: true }),
      'kansallisuus': new UntypedFormControl(null, [Validators.required]),
      'pep': new UntypedFormControl(null, [Validators.required])
    })

    this.kansallisuudetObservable = this._maaService.kaikkiMaatObservable.pipe(
      map(kaikkiMaat => {
        const maatCopy = [...kaikkiMaat]
        // Make selecting a bit easier
        const suomi = kaikkiMaat.find(maa => maa.koodi === 'FIN')
        const ruotsi = kaikkiMaat.find(maa => maa.koodi === 'SWE')
        maatCopy.unshift({ nimi: '---', koodi: null })
        maatCopy.unshift(ruotsi)
        maatCopy.unshift(suomi)
        return maatCopy
      })
    )

    this._kayttajaService.kayttajaObservable.pipe(
      takeWhile(kayttaja => !kayttaja, true)
    ).subscribe(kayttaja => {
      this.tunnistautujaForm.get('nimi').setValue(kayttaja.etunimi + ' ' + kayttaja.sukunimi)
    })

    this._dialogRef.beforeClosed().pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this._allowedDialogsService.allowAll()
    })
  }

  ngAfterContentInit() {
    const tunnistautuminenState = this._tunnistautuminenService.getTunnistautuminenState()
    if (tunnistautuminenState) {
      // User has returned from strong auth
      this.tunnistautuminenStepState = tunnistautuminenState
      this._tunnistautuminenService.endClientSideAuthenticationSession()
    } else if (this._data.kayttajaJoTunnistettu && this._data.pepMissing) {
      // User has already completed strong auth, but is missing PEP data
      this.tunnistautuminenStepState = 'success'
    }
  }

  async tallennaTunnistautujaLisatiedot() {

    if (!this.tunnistautujaForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.tunnistautujaForm)
      return
    }

    // Show loading indicator
    this._ladataanService.aloitaLataaminen()

    const pyynto: TallennaUserKycRequest = {
      kansallisuus: this.tunnistautujaForm.get('kansallisuus').value,
      pep: this.tunnistautujaForm.get('pep').value
    }

    return this._firebaseLemonaid.functionsCall<TallennaUserKycRequest, ErrorResponse>('tunnistautuminenTallennaUserKyc', pyynto).then(vastaus => {
      if (vastaus?.e) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(vastaus.e)
      } else {
        this.tunnistautujanTiedotAnnettu = true
      }
    }).catch(err => {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(err)
    }).finally(() => this._ladataanService.lopetaLataaminen())

  }

  openPepText() {
    this._dialog.open(PepTekstiSharedDialog)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  stopClick(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
  }

}
