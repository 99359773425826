<form (ngSubmit)="save()" [formGroup]="form">

  <h3>{{'laskuasetukset.yleiset' | translate | async}}</h3>

  <div class="asetusrypas">
    <!-- <mat-form-field>
      <mat-label>{{ 'laskuasetukset.template' | translate | async }}</mat-label>
<input type="text" formControlName="template"  matInput required />
      <mat-error>{{fieldErrors.template}}</mat-error>
    </mat-form-field> -->

    <!-- <mat-form-field>
      <mat-label>{{ 'laskuasetukset.replyto' | translate | async }}</mat-label>
<input #replyToInput type="text" formControlName="replyto"  matInput required />
      <mat-hint>{{'laskuasetukset.replyto-hint' | translate | async}}</mat-hint>
      <mat-error>{{fieldErrors.replyto}}</mat-error>
    </mat-form-field> -->

    <mat-form-field>
      <mat-label>{{ 'laskuasetukset.slogan' | translate | async }}</mat-label>
      <input type="text" formControlName="slogan" matInput />
      <mat-hint>{{'laskuasetukset.slogan-hint' | translate | async}}</mat-hint>
      <mat-error>{{fieldErrors.slogan}}</mat-error>
    </mat-form-field>
  </div>

  <h3>{{'laskuasetukset.spostipohjat-eri-kielilla' | translate | async}}</h3>

  <mat-tab-group formArrayName="spostiasetukset" [selectedIndex]="0" style="margin-top: 2.5em;">

    <mat-tab *ngFor="let asetus of asetusControllit; let i = index" [formGroupName]="i" [label]="otsikko(i)">

      <div style="margin: 2em 1.5em 0;">
        <h4>{{'laskuasetukset.laskupohja-otsikko' | translate | async}}</h4>

        <div class="asetusrypas">
          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.aihe' | translate | async }}</mat-label>
            <input type="text" formControlName="aihe" cdkFocusRegionstart matInput required />
            <mat-error>{{fieldErrors.aihe}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.otsikko' | translate | async }}</mat-label>
            <input type="text" formControlName="otsikko" matInput required />
            <mat-error>{{fieldErrors.otsikko}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.teksti' | translate | async }}</mat-label>
            <textarea rows="4" formControlName="teksti" matInput cdkTextareaAutosize required></textarea>
            <mat-error>{{fieldErrors.teksti}}</mat-error>
          </mat-form-field>
        </div>

        <h4>{{'laskuasetukset.maksumuistutuspohja-otsikko' | translate | async}}</h4>

        <div class="asetusrypas">
          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.aihe' | translate | async }}</mat-label>
            <input type="text" formControlName="maihe" matInput required />
            <mat-error>{{fieldErrors.aihe}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.otsikko' | translate | async }}</mat-label>
            <input type="text" formControlName="motsikko" matInput required />
            <mat-error>{{fieldErrors.otsikko}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'laskuasetukset.teksti' | translate | async }}</mat-label>
            <textarea rows="4" formControlName="mteksti" matInput cdkTextareaAutosize required></textarea>
            <mat-error>{{fieldErrors.teksti}}</mat-error>
          </mat-form-field>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>

  <div class="asetusnappulat">
    <button class="primarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
  </div>

</form>