import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MatSnackBar } from '@angular/material/snack-bar'


export interface MaksutVastaanottoAloitettuDialogData { }

@Component({
  templateUrl: './maksut-vastaanotto-aloitettu.dialog.html'
})
export class MaksutVastaanottoAloitettuDialog implements OnInit {

  voidaanMitatoida = false

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MaksutVastaanottoAloitettuDialogData,
    private dialogRef: MatDialogRef<MaksutVastaanottoAloitettuDialog>,
    private _errorHandler: ErrorHandler,
    private _snackBar: MatSnackBar,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) { }

  ngOnInit() {

  }

}
