import { Component, OnInit, AfterContentChecked, ErrorHandler, HostListener, ViewChild, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormValidationService, } from '../_jaettu-angular/service/form-validation.service'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'

import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from '../vaihda-kieli.dialog'
import { VaihdaSalasanaPyynto, VaihdaSalasanaVastaus } from 'app/_jaettu/model/common'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  templateUrl: './change.component.html',
  styleUrls: ['./login.component.css']
})
export class ChangeComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @ViewChild('oldpassword', { read: MatInput, static: true }) oldPasswordInput: MatInput

  loginForm: UntypedFormGroup
  commonError: string
  success = false
  virheet: any = {}
  year = new Date().getFullYear()

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private validationService: FormValidationService,
    private errorHandler: ErrorHandler,
    private ladataanService: LadataanService,
    private translationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) { }

  ngAfterContentChecked() {
    if (this.loginForm) {
      const pwdField = this.loginForm.get('newpassword')
      if (pwdField) {
        this.virheet = pwdField.errors || {}
      }
    }
    this.virheet = {}
  }

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      'oldpassword': new UntypedFormControl('', [Validators.required]),
      'newpassword': new UntypedFormControl('', [Validators.required, LemonaidValidators.passwordComplexityValidator])
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.oldPasswordInput.focus() }, 0)
  }

  @HostListener('document:keydown.esc')
  back() {
    this.router.navigate(['/'])
  }

  @HostListener('document:keydown.enter')
  defaultAction() {
    this.changePassword()
  }

  changePassword() {

    if (!this.loginForm.valid) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
      return
    }

    this.ladataanService.aloitaLataaminen()

    const data: VaihdaSalasanaPyynto = {
      uusiSalasana: this.loginForm.get('oldpassword').value,
      vanhaSalasana: this.loginForm.get('newpassword').value
    }

    this._firebaseLemonaid.functionsCall<VaihdaSalasanaPyynto, VaihdaSalasanaVastaus>('vaihdaSalasana', data).then(vastaus => {
      this.ladataanService.lopetaLataaminen()
    }).catch(err => {
      this.kasitteleVirhe(err)
      this.ladataanService.lopetaLataaminen()
    })

  }

  private kasitteleVirhe(err: any) {
    const code = err.code
    if (code) {
      switch (code) {
        case 'auth/expired-action-code':
          this.commonError = this.translationService.lokalisoi('reset.virheet.koodi-vanhentunut')
          break
        case 'auth/invalid-action-code':
          this.commonError = this.translationService.lokalisoi('reset.virheet.koodi-ei-oikea')
          break
        case 'auth/user-disabled':
          this.commonError = this.translationService.lokalisoi('reset.virheet.kayttaja-inaktiivinen')
          break
        case 'auth/user-not-found':
          this.commonError = this.translationService.lokalisoi('reset.virheet.kayttaja-ei-loydy')
          break
        case 'auth/weak-password':
          this.commonError = this.translationService.lokalisoi('reset.virheet.salasana-heikko')
          break
        default:
          this.commonError = this.translationService.lokalisoi('yleiset.tuntematon-virhe')
          this.errorHandler.handleError(err)
      }
      this.ngAfterContentChecked()
      this.validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
    } else {
      this.commonError = this.translationService.lokalisoi('yleiset.tuntematon-virhe')
      this.errorHandler.handleError(err)
    }
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this.dialog.open(VaihdaKieliDialog, { data: data })
  }

}
