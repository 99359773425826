import { Component, OnInit, ChangeDetectionStrategy, Input, NgZone, ErrorHandler } from '@angular/core'

import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs'
import { startWith, switchMap, tap } from 'rxjs/operators'

import { Timestamp } from 'app/_shared-core/model/common'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { RaporttienHakuvaihtoehdot } from './kirjanpidon-raportit.component'
import { LemonaidKirjanpidonRaporttiRequest, LemonaidKirjanpidonRaporttiResponse, LemonaidRaporttiBruttoTuloslaskelmaData, LemonaidRaporttiVirallinenTuloslaskelmaData, LemonaidRaporttiVirallinenTuloslaskelmarivi, RaporttiType } from 'app/_jaettu/model/reports-shared'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'

@Component({
  selector: '[app-kirjanpito-virallinen-tulos]',
  templateUrl: './virallinen-tulos.component.html',
  styleUrls: ['./kirjanpidon-raportit.component.css', './virallinen-tulos.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpidonRaportitVirallinenTuloslaskelmaComponent implements OnInit {

  @Input() hakuvaihtoehdotObservable: Observable<RaporttienHakuvaihtoehdot>

  lastSucessfullyUpdated: Timestamp

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  tuloslaskelmanDataObservable: Observable<LemonaidRaporttiVirallinenTuloslaskelmaData>

  constructor(
    private _ngZone: NgZone,
    private _kayttajaService: KayttajaService,
    private _timestampService: TimestampService,
    private _errorHandler: ErrorHandler,
    private _firebase: FirebaseLemonaid,
    private _translationService: LemonTranslationService
  ) { }

  ngOnInit() {

    this.tuloslaskelmanDataObservable = combineLatest([
      this._kayttajaService.kayttajanTiedotObservable,
      this.hakuvaihtoehdotObservable.pipe(tap(() => { this._setLoadingTrue() })),
      this._translationService.currentLanguageObservable
    ]).pipe(
      switchMap(([kayttaja, hakuvaihtoehdot, kieli]) => {

        if (!hakuvaihtoehdot?.alkaa || !hakuvaihtoehdot?.loppuu || !kayttaja) {
          return of<LemonaidRaporttiBruttoTuloslaskelmaData>({
            c1Header: null,
            c2Header: null,
            c: 'c2',
            data: []
          })
        }

        const requestData: LemonaidKirjanpidonRaporttiRequest = {
          a: kayttaja.asiakasAvain,
          k: kieli ?? 'fi',
          w: RaporttiType.TULOS_VIRALLINEN,
          s: hakuvaihtoehdot.alkaa,
          e: hakuvaihtoehdot.loppuu
        }
        return this._firebase.functionsCall<LemonaidKirjanpidonRaporttiRequest, LemonaidKirjanpidonRaporttiResponse>('pyydaRaporttiLemonatorista', requestData).then(res => {
          if (res.e) {
            throw new Error(res.e)
          }
          this.lastSucessfullyUpdated = this._timestampService.now()
          this._setLoadingFalse()
          return res.data
        }).catch(err => {
          console.error('Failed to fetch report data', err)
          this._errorHandler.handleError(err)
        })
      }),
      startWith<LemonaidRaporttiVirallinenTuloslaskelmaData>({
        c1Header: null,
        c2Header: null,
        c: 'c2',
        data: []
      })
    )

  }

  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }


  trackAccountRowByAccountNumberFn(index: number, item: LemonaidRaporttiVirallinenTuloslaskelmarivi) {
    return index
  }

}
