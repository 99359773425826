import { Injectable } from '@angular/core'



@Injectable()
export class TositeKuvaService {

  // private alkuperaistenKakku: { [kuitinAvain: string]: FirestoreTositteenAlkuperainenTiedosto[] } = {}
  // private kuvienKakku: { [kuitinAvain: string]: BehaviorSubject<FirestoreTositteenMuutosKuvaMuokkaus[]> } = {}
  // private pdfKuvienKakku: { [kuitinAvain: string]: BehaviorSubject<{ [alkuperaisenAvain: string]: null[] }> } = {}

  // private tallennettujenKakku: { [kuvanAvain: string]: { kuitinAvain: string, kuva: null } } = {}

  // private lopullistenEkojenKuvienKakku: { [kuitinAvain: string]: SafeUrl } = {}

  // private voiSiivotaTallennuksenJalkeenAvaimet: string[] = []

  // public lisaaKuvaLopulliseenKakkuun(kuitti: FirestoreTosite, ekakuva: SafeUrl) {
  //   this.lopullistenEkojenKuvienKakku[kuitti.avain] = ekakuva
  // }

  // public annaKuvaLopullisestaKakusta(kuitti: FirestoreTosite): SafeUrl | null {
  //   return this.lopullistenEkojenKuvienKakku[kuitti.avain]
  // }

  // public annaKuva(kuva: FirestoreTositteenKuva) {
  //   if (kuva) {
  //     return this.tallennettujenKakku[kuva.avain]
  //   }
  //   return null
  // }

  // public annaKuitinKuvat(kuitti: FirestoreTosite): Observable<FirestoreTositteenMuutosKuvaMuokkaus[]> {
  //   return this.annaKuvienSubjectKuitille(kuitti)
  // }
  // public annaKuitinPdfKuvat(kuitti: FirestoreTosite): Observable<{ [alkuperaisenAvain: string]: FirestoreTositteenMuutosKuvaMuokkaus[] }> {
  //   return this.annaPdfKuvienSubjectKuitille(kuitti)
  // }

  // public lisaaYksiKuvaKakkuun(kuitti: FirestoreTosite, kuva: FirestoreTositteenMuutosKuvaMuokkaus) {
  //   const subject = this.annaKuvienSubjectKuitille(kuitti)
  //   const nykyisetArvot = subject.value
  //   nykyisetArvot.push(kuva)
  //   subject.next(nykyisetArvot)
  // }

  // public poistaYksiKuvaKakusta(kuitti: FirestoreTosite, kuva: FirestoreTositteenMuutosKuvaMuokkaus) {
  //   const subject = this.annaKuvienSubjectKuitille(kuitti)
  //   const nykyisetArvot = subject.value
  //   const poistettavaIndex = nykyisetArvot.findIndex(arvot => arvot.avain === kuva.avain)
  //   if (poistettavaIndex >= 0) {
  //     nykyisetArvot.splice(poistettavaIndex, 1)
  //   }
  //   subject.next(nykyisetArvot)
  // }
  // public lisaaYksiPdfKuvaKakkuun(kuitti: FirestoreTosite, kuva: FirestoreTositteenMuutosKuvaMuokkaus) {
  //   const alkuperaisenAvain = kuva.alkuperaisenAvain
  //   const pdfKuvatSubject = this.annaPdfKuvienSubjectKuitille(kuitti)
  //   let nykyisetKuvat = pdfKuvatSubject.value[alkuperaisenAvain]
  //   if (!nykyisetKuvat) {
  //     nykyisetKuvat = []
  //   }
  //   nykyisetKuvat.push(kuva)
  //   pdfKuvatSubject.next({ [alkuperaisenAvain]: nykyisetKuvat })
  // }

  // public lisaaKuviaKakkuun(kuitti: FirestoreTosite, kuvat: FirestoreTositteenMuutosKuvaMuokkaus[]) {
  //   const subject = this.annaKuvienSubjectKuitille(kuitti)
  //   const nykyisetArvot = subject.value
  //   nykyisetArvot.push(...kuvat)
  //   subject.next(nykyisetArvot)
  // }

  // public lisaaPdfKuvatKakkuun(kuitti: FirestoreTosite, kuvat: FirestoreTositteenMuutosKuvaMuokkaus[]) {
  //   const alkuperaisenAvain = kuvat[0].alkuperaisenAvain
  //   const pdfKuvatSubject = this.annaPdfKuvienSubjectKuitille(kuitti)
  //   let nykyisetKuvat = pdfKuvatSubject.value[alkuperaisenAvain]
  //   if (!nykyisetKuvat) {
  //     nykyisetKuvat = []
  //   }
  //   nykyisetKuvat.push(...kuvat)
  //   pdfKuvatSubject.value[alkuperaisenAvain] = nykyisetKuvat
  //   pdfKuvatSubject.next(pdfKuvatSubject.value)
  // }
  // public poistaYhdenPdfinKuvatKakusta(kuitti: FirestoreTosite, alkuperaisenAvain: string) {
  //   const pdfKuvatSubject = this.annaPdfKuvienSubjectKuitille(kuitti)
  //   delete pdfKuvatSubject.value[alkuperaisenAvain]
  //   pdfKuvatSubject.next(pdfKuvatSubject.value)
  // }

  // public poistaAlkuperainenKakusta(kuitti: FirestoreTosite, alkuperaisenAvain: string) {
  //   const arr = this.annaAlkuperaistenArrayKuitille(kuitti)
  //   const alkuperaisenIndex = arr.findIndex(a => a.avain === alkuperaisenAvain)
  //   if (alkuperaisenIndex >= 0) {
  //     arr.splice(alkuperaisenIndex, 1)
  //   }
  // }


  // public siirraKuvatKuitille(kuiteista: FirestoreTosite[], kuittiin: FirestoreTosite): void {
  //   const kuittiinSubject = this.annaKuvienSubjectKuitille(kuittiin)
  //   const kuittiinArray = this.annaAlkuperaistenArrayKuitille(kuittiin)
  //   const nykyisetArvot = kuittiinSubject.value
  //   for (const kuitista of kuiteista) {

  //     // Siirrä kuvat
  //     const kuitistaSubject = this.annaKuvienSubjectKuitille(kuitista)
  //     nykyisetArvot.push(...kuitistaSubject.value)

  //     // Siirrä alkuperäiset
  //     const kuitistaArray = this.annaAlkuperaistenArrayKuitille(kuitista)
  //     kuittiinArray.push(...kuitistaArray)

  //     // Muuta tämän kuitin viittaukset uuteen paikkaan, jotta myöhemmät operaatiot kohdistuvat niihin
  //     // (Samat kamat useammalla avaimella map:eissa)
  //     this.lisaaViittauksetKuitille(kuitista, kuittiinSubject, kuittiinArray)

  //   }
  //   kuittiinSubject.next(nykyisetArvot)
  // }

  // public lisaaAlkuperainen(kuitti: FirestoreTosite, alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[]) {
  //   const arr = this.annaAlkuperaistenArrayKuitille(kuitti)
  //   arr.push(...alkuperaiset)
  // }

  // public poistaTositteenTiedot(kuitti: FirestoreTosite) {
  //   delete this.alkuperaistenKakku[kuitti.kuvakansio]
  //   delete this.kuvienKakku[kuitti.kuvakansio]
  //   delete this.pdfKuvienKakku[kuitti.kuvakansio]
  // }

  // public siivoaKaikki(tallennetut: FirestoreTosite[]) {
  //   if (tallennetut && tallennetut.length > 0) {
  //     for (const tallennettu of tallennetut) {
  //       this.siivoa(tallennettu)
  //     }
  //   }
  // }

  // public siivoa(tallennettu?: FirestoreTosite) {
  //   for (const kuvakansio of this.voiSiivotaTallennuksenJalkeenAvaimet) {
  //     delete this.alkuperaistenKakku[kuvakansio]
  //     delete this.kuvienKakku[kuvakansio]
  //     delete this.pdfKuvienKakku[kuvakansio]
  //   }
  //   this.voiSiivotaTallennuksenJalkeenAvaimet = []

  //   if (tallennettu) {
  //     const subject = this.annaKuvienSubjectKuitille(tallennettu)
  //     for (const kuva of subject.value) {
  //       if (!kuva.ladataan && kuva.nakyvissa) {
  //         this.tallennettujenKakku[kuva.avain] = {
  //           kuitinAvain: tallennettu.avain,
  //           kuva: kuva
  //         }
  //       }
  //     }
  //     delete this.alkuperaistenKakku[tallennettu.kuvakansio]
  //     delete this.kuvienKakku[tallennettu.kuvakansio]
  //     delete this.pdfKuvienKakku[tallennettu.kuvakansio]
  //   }
  // }

  // public asetaKuvatKuittiin(kuitti: FirestoreTosite) {

  //   const kuittiinSubject = this.annaKuvienSubjectKuitille(kuitti)
  //   const pdfitKuitiinSubject = this.annaPdfKuvienSubjectKuitille(kuitti)
  //   const kuittiinArray = this.annaAlkuperaistenArrayKuitille(kuitti)
  //   const nykyisetArvot = kuittiinSubject.value
  //   const nykyisetArvotPdfeista = Object.values(pdfitKuitiinSubject.value).reduce((a, b) => a.concat(b), [])

  //   if (!kuitti.kuvat) {
  //     kuitti.kuvat = {}
  //   }
  //   let i = 1
  //   for (const kuva of nykyisetArvot) {
  //     if (kuva.poistettu) {
  //       continue
  //     }
  //     // Jos avain on sama kuin alkuperäinen, älä lisää sitä (löytyy jo alkuperäisistä..)
  //     if (kuva.alkuperaisenAvain === kuva.avain) {
  //       delete kuitti.kuvat[kuva.avain]
  //     } else {
  //       kuva.jarjestys = i
  //       kuitti.kuvat[kuva.avain] = kuva
  //       i++
  //     }
  //   }

  //   i = 1

  //   for (const kuva of nykyisetArvotPdfeista) {
  //     if (kuva.poistettu) {
  //       continue
  //     }
  //     // Jos avain on sama kuin alkuperäinen, älä lisää sitä (löytyy jo alkuperäisistä..)
  //     if (kuva.alkuperaisenAvain === kuva.avain) {
  //       delete kuitti.kuvat[kuva.avain]
  //     } else {
  //       kuva.jarjestys = i
  //       kuitti.kuvat[kuva.avain] = kuva
  //       i++
  //     }
  //   }

  //   if (!kuitti.alkuperaiset) {
  //     kuitti.alkuperaiset = {}
  //   }

  //   for (const alkuperainen of kuittiinArray) {
  //     // Alkuperäinen kuva siirretään alkuperäisiin
  //     kuitti.alkuperaiset[alkuperainen.avain] = alkuperainen
  //   }

  // }

  // // public onkoKuviaOptimoimatta(kuitti: FirestoreTosite) {
  // //   const kuittiinSubject = this.annaKuvienSubjectKuitille(kuitti)
  // //   const nykyisetArvot = kuittiinSubject.value
  // //   for (const arvo of nykyisetArvot) {
  // //     if (arvo.optimoidaan) {
  // //       return true
  // //     }
  // //   }
  // //   return false
  // // }

  // private lisaaViittauksetKuitille(kuitti: FirestoreTosite, subject: BehaviorSubject<FirestoreTositteenMuutosKuvaMuokkaus[]>, arr: FirestoreTositteenAlkuperainenTiedosto[]) {
  //   this.kuvienKakku[kuitti.kuvakansio] = subject
  //   this.alkuperaistenKakku[kuitti.kuvakansio] = arr
  //   this.voiSiivotaTallennuksenJalkeenAvaimet.push(kuitti.kuvakansio)
  // }

  // private annaKuvienSubjectKuitille(kuitti: FirestoreTosite): BehaviorSubject<FirestoreTositteenMuutosKuvaMuokkaus[]> {
  //   let subject = this.kuvienKakku[kuitti.kuvakansio]
  //   if (!subject) {
  //     subject = new BehaviorSubject([])
  //     this.kuvienKakku[kuitti.kuvakansio] = subject
  //   }
  //   return subject
  // }
  // private annaPdfKuvienSubjectKuitille(kuitti: FirestoreTosite): BehaviorSubject<{ [alkuperaisenAvain: string]: FirestoreTositteenMuutosKuvaMuokkaus[] }> {
  //   let subject = this.pdfKuvienKakku[kuitti.kuvakansio]
  //   if (!subject) {
  //     subject = new BehaviorSubject({})
  //     this.pdfKuvienKakku[kuitti.kuvakansio] = subject
  //   }
  //   return subject
  // }

  // private annaAlkuperaistenArrayKuitille(kuitti: FirestoreTosite): FirestoreTositteenAlkuperainenTiedosto[] {
  //   let arr = this.alkuperaistenKakku[kuitti.kuvakansio]
  //   if (!arr) {
  //     arr = []
  //     this.alkuperaistenKakku[kuitti.kuvakansio] = arr
  //   }
  //   return arr
  // }

}
