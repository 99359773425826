import { LemonaidFirebaseConfig } from './_jaettu-angular/base-firebase.service'

// eslint-disable-next-line no-shadow
export enum EnvironmentType {
  LOCAL_DEV,
  DEV,
  PRODUCTION,
  BETA
}

export interface Environment {
  enableVersionCheck: boolean
  environment: EnvironmentType
  airbrakeEnabled: boolean
  lemonaidFirebaseConfig: LemonaidFirebaseConfig
  recaptchaSiteId: string
  version: string
  // host: string
}
