import { Component, Inject, ViewChild, ErrorHandler, OnInit, OnDestroy, AfterContentInit, NgZone } from '@angular/core'
import { UntypedFormGroup, AbstractControl, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef, MatDialog } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'

import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { WindowSizeService } from '../_jaettu-angular/service/window.service'
import { Maa, MaaService } from '../_jaettu-angular/service/maa.service'

import { LemonaidValidators } from '../_angular/_validator/LemonaidValidators'

import { CurrencyService } from '../_shared-core/service/currency.service'
import { KnowYourCustomerSaveRequest, SopimuksenHyvaksynnanRequest, PalvelukuvauksenPdfRequest, PalvelukuvauksenPdfResponse, SopimusPdfRequest, SopimusPdfResponse } from '../_jaettu/model/sopimus'
import { ErrorResponse, LocalDate } from '../_shared-core/model/common'
import { CustomerOsakas, KnowYourCustomer, CustomerHallituksenJasen, CustomerBeneficiary, AsiakkaanHintaLemonaidissa } from '../_jaettu/model/kayttaja'

import { Observable, Subject } from 'rxjs'
import { map, takeUntil, takeWhile } from 'rxjs/operators'
import { TunnistautuminenService } from 'app/_angular/service/tunnistautuminen.service'
import { AllowedDialogsService } from 'app/_angular/service/allowed-dialogs.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { PepTekstiSharedDialog } from './pep-teksti-shared.dialog'
import { TallennaUserKycRequest } from 'app/_jaettu/model/tunnistaminen'

import * as base64 from 'base64-js'
import { DateService } from 'app/_shared-core/service/date.service'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { TuntemistiedotDialogService } from 'app/_angular/service/tuntemistiedot-dialog.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export interface TervetuloaSopimusDialogData {
  naytaSopimus: boolean
  naytaTunnistaminen: boolean
  naytaTuntemistiedot: boolean
  mobiili: boolean
  sopimuksenAvain: string
  hinta: AsiakkaanHintaLemonaidissa
  kayttajaJoTunnistettu: boolean
  pepMissing: boolean
}

@Component({
  templateUrl: './tervetuloa-sopimus.dialog.html',
  styleUrls: ['./tervetuloa-sopimus.dialog.css']
})
export class TervetuloaSopimusDialog implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('horizStepper', { read: MatStepper }) horizStepper: MatStepper
  @ViewChild('verticStepper', { read: MatStepper }) verticStepper: MatStepper

  private _ngUnsubscribe = new Subject<void>()

  sopimusFormGroup: UntypedFormGroup
  tunnistautujaForm: UntypedFormGroup
  valtuutusFormGroup: UntypedFormGroup
  tuntemistiedotFormGroup: UntypedFormGroup
  cellphone: boolean = true
  naytaSopimus: boolean
  naytaTunnistaminen: boolean
  naytaTuntemistiedot: boolean
  namename = 'asruopeq' + Math.random()
  commonError: string
  kieli: TuettuKieli = 'fi'

  private _tunnistamistiedot: KnowYourCustomer = {
    asiakasAvain: null,
    avain: null,
    edunsaajat: [],
    hallitus: [],
    luoja: null,
    luotu: null,
    osakkaat: [],
    paivitetty: null,
    paivittaja: null,
    poistettu: false
  }

  private _sopimusAvain: string

  ensimmainenLaskutettavaKuukausi: string
  // sopimusTekstiObservable: Observable<string>
  hintaPalvelunAlkaessaObservable: Observable<string>
  // naytaSopimusObservable: Observable<boolean>
  // naytaHetuObservable: Observable<boolean>
  // naytaTuntemistiedotObservable: Observable<boolean>
  hallitusRoolitObservable: Observable<{ avain: Pick<CustomerHallituksenJasen, 'rooli'>, nimi: string }[]>
  tunnistautujanTiedotAnnettu: boolean
  strongAuthResult: 'start' | 'success' | 'error' | 'error-cancel' = 'start'

  kansallisuudetObservable: Observable<Maa[]>

  private _ytunnus: string

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: TervetuloaSopimusDialogData,
    private _dialogRef: MatDialogRef<TervetuloaSopimusDialog>,
    private _ngZone: NgZone,
    private _dialog: MatDialog,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _currencyService: CurrencyService,
    private _windowSizeService: WindowSizeService,
    private _allowedDialogsService: AllowedDialogsService,
    private _maaService: MaaService,
    private _tunnistautuminenService: TunnistautuminenService,
    private _kayttajaService: KayttajaService,
    private _dateService: DateService,
    private _tuntemistiedotDialogService: TuntemistiedotDialogService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _fileSaverService: FileSaverService
  ) {

    this.naytaSopimus = this._data.naytaSopimus
    this.naytaTunnistaminen = this._data.naytaTunnistaminen
    this.naytaTuntemistiedot = this._data.naytaTuntemistiedot

    this.cellphone = window.innerWidth < 670 || window.innerHeight < 670

    this._windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this.cellphone = window.innerWidth < 670 || window.innerHeight < 670
    })

    if (this._data.hinta) {
      const hintaVoimassaAlkaen: LocalDate = this._data.hinta.voimassaAlkaen
      this.ensimmainenLaskutettavaKuukausi = hintaVoimassaAlkaen ? hintaVoimassaAlkaen.month + '/' + hintaVoimassaAlkaen.year : ''

      this.hintaPalvelunAlkaessaObservable = this._translationService.currentLanguageObservable.pipe(
        map(kieli => this._currencyService.formatoiRaha(this._data.hinta.hinta, 'EUR', kieli))
      )
    }
    this._sopimusAvain = this._data.sopimuksenAvain || ''

    this._translationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(k => {
      this.kieli = k || 'fi'
    })

    this.tunnistautujanTiedotAnnettu = !this._data.pepMissing
  }

  ngOnInit(): void {

    // this._kayttajaService.nykyinenAsiakasObservable.pipe(
    //   takeWhile(asiakas => !asiakas, true)
    // ).subscribe(asiakas => {
    //   if (asiakas.ytunnus) {
    //     this._ytunnus = asiakas.ytunnus.trim()
    //   }
    // })

    if (this.naytaTuntemistiedot) {
      // Save KYC-seen log if the entire pop-up is shown
      this._kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
        if (kayttajanTiedot?.asiakasAvain) {
          this._tuntemistiedotDialogService.savePopupSeenLog(kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid)
        }
      }).catch(err => {
        console.log(err)
      })
    }


    this._dialogRef.beforeClosed().pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      this._allowedDialogsService.allowAll()
    })

    this.kansallisuudetObservable = this._maaService.kaikkiMaatObservable.pipe(
      takeUntil(this._ngUnsubscribe),
      map(kaikkiMaat => {
        const maatCopy = [...kaikkiMaat]
        // Make selecting a bit easier
        const suomi = kaikkiMaat.find(maa => maa.koodi === 'FIN')
        const ruotsi = kaikkiMaat.find(maa => maa.koodi === 'SWE')
        maatCopy.unshift({ nimi: '---', koodi: null })
        maatCopy.unshift(ruotsi)
        maatCopy.unshift(suomi)
        return maatCopy
      })
    )

    this.hallitusRoolitObservable = this._translationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe),
      map(kieli => {
        const kaytettyKieli = kieli === 'en' ? 'en' : 'fi'
        const roolit = [
          { avain: 'puheenjohtaja', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-puheenjohtaja', kaytettyKieli) },
          { avain: 'jasen', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-jasen', kaytettyKieli) },
          { avain: 'varajasen', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-varajasen', kaytettyKieli) },
          { avain: 'toimitusjohtaja', nimi: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.toimitusjohtaja', kaytettyKieli) }
        ] as { avain: Pick<CustomerHallituksenJasen, 'rooli'>, nimi: string }[]

        return roolit
      })

    )

    this.valtuutusFormGroup = new UntypedFormGroup({
      'hetu': new UntypedFormControl('', [Validators.required, LemonaidValidators.finnishPersonalIdValidator])
    })

    this.tunnistautujaForm = new UntypedFormGroup({
      'nimi': new UntypedFormControl({ value: null, disabled: true }),
      'kansallisuus': new UntypedFormControl(null, [Validators.required]),
      'pep': new UntypedFormControl(null, [Validators.required])
    })

    this._kayttajaService.kayttajaObservable.pipe(
      takeWhile(kayttaja => !kayttaja, true)
    ).subscribe(kayttaja => {
      this.tunnistautujaForm.get('nimi').setValue(kayttaja ? kayttaja.etunimi + ' ' + kayttaja.sukunimi : '')
    })

    this.sopimusFormGroup = new UntypedFormGroup({
      'hyvaksy': new UntypedFormControl(false, [Validators.requiredTrue])
    })

    this.tuntemistiedotFormGroup = new UntypedFormGroup({
      'osakkaat': new UntypedFormArray([], LemonaidValidators.vahintaanYksiArrayssaValidator),
      'hallitus': new UntypedFormArray([], LemonaidValidators.vahintaanYksiArrayssaValidator),
      'muut': new UntypedFormArray([])
    })

    this.lisaaOsakas()
    this.lisaaHallituksenJasen()

  }

  private _getActiveStepper(): MatStepper | null {
    if (this.horizStepper) {
      return this.horizStepper
    }
    if (this.verticStepper) {
      return this.verticStepper
    }
    return null
  }

  private _executeOnStepper(toExecute: (stepper: MatStepper) => void) {
    const stepper = this._getActiveStepper()
    if (stepper) {
      this._ngZone.run(() => toExecute(stepper))
    } else {
      setTimeout(() => {
        this._executeOnStepper(toExecute)
      }, 50)
    }
  }

  ngAfterContentInit() {
    const tunnistautuminenState = this._tunnistautuminenService.getTunnistautuminenState()
    if (tunnistautuminenState) {
      // User has returned from strong auth
      this.strongAuthResult = tunnistautuminenState
      this._tunnistautuminenService.endClientSideAuthenticationSession()
      // Switch tab to tunnistaminen (index 1)
      this._executeOnStepper(stepper => {
        setTimeout(() => {
          this._ngZone.run(() => stepper.selectedIndex = 1)
        }, 500)
      })
    } else if (this._data.kayttajaJoTunnistettu && this._data.pepMissing) {
      // User has already completed strong auth, but is missing PEP data
      this.strongAuthResult = 'success'
      // Switch tab to tunnistaminen (index 1)
      this._executeOnStepper(stepper => {
        setTimeout(() => {
          this._ngZone.run(() => stepper.selectedIndex = 1)
        }, 500)
      })
    }
  }

  get osakkaatFormArray(): UntypedFormArray {
    return this.tuntemistiedotFormGroup.get('osakkaat') as UntypedFormArray
  }

  get osakasFormGroupit(): UntypedFormGroup[] {
    return this.osakkaatFormArray.controls as UntypedFormGroup[]
  }

  get hallitusFormArray(): UntypedFormArray {
    return this.tuntemistiedotFormGroup.get('hallitus') as UntypedFormArray
  }

  get hallitusFormGroupit(): UntypedFormGroup[] {
    return this.hallitusFormArray.controls as UntypedFormGroup[]
  }

  get saajaFormArray(): UntypedFormArray {
    return this.tuntemistiedotFormGroup.get('muut') as UntypedFormArray
  }

  get saajaFormGroupit(): UntypedFormGroup[] {
    return this.saajaFormArray.controls as UntypedFormGroup[]
  }

  poistaOsakas(index: number) {
    this.osakkaatFormArray.removeAt(index)
    this._tunnistamistiedot.osakkaat.splice(index, 1)
  }

  lisaaOsakas() {
    const osakas: CustomerOsakas = {
      hetuTaiYtunnus: '',
      nimi: '',
      osakkeita: null,
      kansallisuus: null,
      pep: null
    }

    const osakasFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(osakas.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(osakas.hetuTaiYtunnus, [Validators.required, LemonaidValidators.finnishHetuYtunnusOrVatNumberValidator]),
      'osakkeita': new UntypedFormControl(osakas.osakkeita, [Validators.required]),
      'kansallisuus': new UntypedFormControl(osakas.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(osakas.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(null),
      'onkoYritys': new UntypedFormControl(null, [Validators.required]),
      'birthDate': new UntypedFormControl({ value: osakas.birthDate ? this._dateService.localDateToDate(osakas.birthDate) : null, disabled: true }, Validators.required)
    })

    osakasFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.nimi = value || null })
    osakasFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.hetuTaiYtunnus = value || null })
    osakasFormGroup.get('osakkeita').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.osakkeita = value || null })
    osakasFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.kansallisuus = value || null })
    osakasFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { osakas.pep = value ?? null })
    osakasFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      osakas.noFinnishId = value ?? false
      if (osakas.noFinnishId) {
        osakasFormGroup.get('onkoYritys').enable()

        if (!osakasFormGroup.get('onkoYritys').value) {
          osakasFormGroup.get('birthDate').enable()
        }
        osakasFormGroup.get('hetu').removeValidators(LemonaidValidators.finnishPersonalIdValidator)
      } else {
        osakasFormGroup.get('onkoYritys').disable()
        osakasFormGroup.get('birthDate').disable()
        osakasFormGroup.get('hetu').addValidators(LemonaidValidators.finnishPersonalIdValidator)
      }
      osakasFormGroup.get('hetu').updateValueAndValidity()
    })

    osakasFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && osakasFormGroup.get('birthDate').valid) {
        osakas.birthDate = this._dateService.dateToLocalDate(value)
      }
    })

    osakasFormGroup.get('onkoYritys').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      osakas.onkoYritys = value ?? null
      if (value) {
        osakasFormGroup.get('birthDate').disable()
      } else {
        osakasFormGroup.get('birthDate').enable()
      }
    })

    osakasFormGroup.get('noFinnishId').setValue(osakas.noFinnishId)

    this.osakkaatFormArray.push(osakasFormGroup)
    this._tunnistamistiedot.osakkaat.push(osakas)
  }

  poistaHallituksenJasen(index: number) {
    this.hallitusFormArray.removeAt(index)
    this._tunnistamistiedot.hallitus.splice(index, 1)
  }

  lisaaHallituksenJasen() {
    const jasen: CustomerHallituksenJasen = {
      hetuTaiYtunnus: '',
      nimi: '',
      kansallisuus: null,
      pep: null,
      rooli: null
    }

    const jasenFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(jasen.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(jasen.hetuTaiYtunnus, [Validators.required, LemonaidValidators.finnishHetuYtunnusOrVatNumberValidator]),
      'kansallisuus': new UntypedFormControl(jasen.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(jasen.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(null),
      'birthDate': new UntypedFormControl({ value: jasen.birthDate ? this._dateService.localDateToDate(jasen.birthDate) : null, disabled: true }, Validators.required),
      'rooli': new UntypedFormControl(jasen.rooli, [Validators.required])
    })

    jasenFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.nimi = value || null })
    jasenFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.hetuTaiYtunnus = value || null })
    jasenFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.kansallisuus = value || null })
    jasenFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.pep = value ?? null })
    jasenFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      jasen.noFinnishId = value ?? false
      if (jasen.noFinnishId) {
        jasenFormGroup.get('birthDate').enable()
        jasenFormGroup.get('hetu').removeValidators(LemonaidValidators.finnishPersonalIdValidator)
      } else {
        jasenFormGroup.get('birthDate').disable()
        jasenFormGroup.get('hetu').addValidators(LemonaidValidators.finnishPersonalIdValidator)
      }
      jasenFormGroup.get('hetu').updateValueAndValidity()
    })
    jasenFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && jasenFormGroup.get('birthDate').valid) {
        jasen.birthDate = this._dateService.dateToLocalDate(value)
      }
    })
    jasenFormGroup.get('rooli').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { jasen.rooli = value || null })


    jasenFormGroup.get('noFinnishId').setValue(jasen.noFinnishId)
    this.hallitusFormArray.push(jasenFormGroup)
    this._tunnistamistiedot.hallitus.push(jasen)
  }

  poistaEdunsaaja(index: number) {
    this.saajaFormArray.removeAt(index)
    this._tunnistamistiedot.edunsaajat.splice(index, 1)
  }

  lisaaEdunsaaja() {
    const saaja: CustomerBeneficiary = {
      hetuTaiYtunnus: '',
      nimi: '',
      kansallisuus: null,
      pep: null
    }

    const saajaFormGroup = new UntypedFormGroup({
      'nimi': new UntypedFormControl(saaja.nimi, [Validators.required]),
      'hetu': new UntypedFormControl(saaja.hetuTaiYtunnus, [Validators.required, LemonaidValidators.finnishPersonalIdValidator]),
      'kansallisuus': new UntypedFormControl(saaja.kansallisuus, [Validators.required]),
      'pep': new UntypedFormControl(saaja.pep, [Validators.required]),
      'noFinnishId': new UntypedFormControl(null),
      'birthDate': new UntypedFormControl({ value: saaja.birthDate ? this._dateService.localDateToDate(saaja.birthDate) : null, disabled: true }, Validators.required)
    })

    saajaFormGroup.get('nimi').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.nimi = value || null })
    saajaFormGroup.get('hetu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.hetuTaiYtunnus = value || null })
    saajaFormGroup.get('kansallisuus').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.kansallisuus = value || null })
    saajaFormGroup.get('pep').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => { saaja.pep = value ?? null })
    saajaFormGroup.get('noFinnishId').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      saaja.noFinnishId = value ?? false
      if (saaja.noFinnishId) {
        saajaFormGroup.get('birthDate').enable()
        saajaFormGroup.get('hetu').removeValidators(LemonaidValidators.finnishPersonalIdValidator)
      } else {
        saajaFormGroup.get('birthDate').disable()
        saajaFormGroup.get('hetu').addValidators(LemonaidValidators.finnishPersonalIdValidator)
      }
      saajaFormGroup.get('hetu').updateValueAndValidity()
    })
    saajaFormGroup.get('birthDate').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      if (value && saajaFormGroup.get('birthDate').valid) {
        saaja.birthDate = this._dateService.dateToLocalDate(value)
      }
    })

    saajaFormGroup.get('noFinnishId').setValue(saaja.noFinnishId)

    this.saajaFormArray.push(saajaFormGroup)
    this._tunnistamistiedot.edunsaajat.push(saaja)
  }

  get hyvaksy(): AbstractControl {
    return this.sopimusFormGroup.get('hyvaksy')
  }

  get hetu(): AbstractControl {
    return this.valtuutusFormGroup.get('hetu')
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  async downloadSopimus() {

    this._ladataanService.aloitaLataaminen()

    const req: SopimusPdfRequest = {
      versio: 'uusin',
      kieli: this._translationService.nykyinenKieli || 'fi'
    }
    return this._firebaseLemonaid.functionsCall<SopimusPdfRequest, SopimusPdfResponse>('sopimusAnnaSopimuksenPdf', req).then(response => {
      if (!response || response.e || !response.base64String) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        this._downloadSopimusPdf(response.base64String)
      }
    }).catch(err => this._errorHandler.handleError(err)).finally(() => {
      this._ladataanService.lopetaLataaminen()
    })

  }

  private _downloadSopimusPdf(base64File: string) {
    const output = base64.toByteArray(base64File)
    const fileName = 'Tilitoimisto Lemon Tree Oy Toimeksiantosopimus.pdf'
    this._fileSaverService.saveAs(new Blob([output], { type: 'application/pdf' }), fileName)
  }

  async downloadPalvelukuvaus() {
    this._ladataanService.aloitaLataaminen()
    const req: PalvelukuvauksenPdfRequest = {
      kieli: this._translationService.nykyinenKieli || 'fi'
    }
    return this._firebaseLemonaid.functionsCall<PalvelukuvauksenPdfRequest, PalvelukuvauksenPdfResponse>('sopimusAnnaPalvelukuvausPdf', req).then(response => {
      if (!response || response.e || !response.base64String) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        this._downloadPalvelukuvausPdf(response.base64String)
      }
    }).catch(err => this._errorHandler.handleError(err)).finally(() => this._ladataanService.lopetaLataaminen())
  }

  private _downloadPalvelukuvausPdf(base64File: string) {
    const output = base64.toByteArray(base64File)
    const fileName = 'Tilitoimisto Lemon Tree Oy Palvelukuvaus.pdf'
    this._fileSaverService.saveAs(new Blob([output], { type: 'application/pdf' }), fileName)
  }

  async tallennaTunnistautujaLisatiedot() {

    if (!this.tunnistautujaForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.tunnistautujaForm)
      return
    }

    // Show loading indicator
    this._ladataanService.aloitaLataaminen()

    const req: TallennaUserKycRequest = {
      kansallisuus: this.tunnistautujaForm.get('kansallisuus').value,
      pep: this.tunnistautujaForm.get('pep').value
    }
    return this._firebaseLemonaid.functionsCall<TallennaUserKycRequest, ErrorResponse>('tunnistautuminenTallennaUserKyc', req).then(vastaus => {
      if (vastaus?.e) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(vastaus.e)
      } else {
        this.tunnistautujanTiedotAnnettu = true
      }
    }).catch(err => {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(err)
    }).finally(() => this._ladataanService.lopetaLataaminen())

  }

  tallennaKyc() {

    if (!this.tuntemistiedotFormGroup.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.tuntemistiedotFormGroup)
      return
    }

    // Show loading indicator
    this._ladataanService.aloitaLataaminen()

    // Remove unnecessary data (birthDate & onkoYritys fields are disabled when the osakas has a Finnish hetu/y-tunnus)
    for (const osakas of this._tunnistamistiedot.osakkaat) {
      if (!osakas.noFinnishId) {
        osakas.birthDate = null
        osakas.onkoYritys = null
      }
    }

    // Call CF
    const tallennusData: KnowYourCustomerSaveRequest = {
      kyc: this._tunnistamistiedot
    }
    return this._firebaseLemonaid.functionsCall<KnowYourCustomerSaveRequest, ErrorResponse>('knowYourCustomerTallennus', tallennusData).then(response => {

      // Stop loading indicator
      this._ladataanService.lopetaLataaminen()

      // If no response, or response contains an error
      if (!response || response.e) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        // All is good, let's advance
        this._executeOnStepper(stepper => stepper.next())
      }

    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(error)
    })

  }

  tallennaHyvaksynta() {

    if (!this.sopimusFormGroup.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.sopimusFormGroup)
      return
    }

    // Show loading indicator
    this._ladataanService.aloitaLataaminen()

    // Call CF
    const tallennusData: SopimuksenHyvaksynnanRequest = { sopimusAvain: this._sopimusAvain, kieli: this._translationService.nykyinenKieli }
    return this._firebaseLemonaid.functionsCall<SopimuksenHyvaksynnanRequest, ErrorResponse>('sopimusHyvaksynnanTallennus', tallennusData).then(response => {

      // If no response, or response contains an error
      if (!response || response.e) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        // All is good, let's advance
        this._executeOnStepper(stepper => stepper.next())
      }

    }).catch(error => {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(error)
    }).finally(() => {
      this._ladataanService.lopetaLataaminen()
    })

  }

  saveCurrentStep(event: MouseEvent) {

    const activeStepper: MatStepper = this._getActiveStepper()
    const activeStepperControl = activeStepper?.selected?.stepControl
    activeStepperControl?.markAllAsTouched()
    if (activeStepperControl?.errors?.length) {
      return
    }
    const currentStepLabel = activeStepper?.selected?.label

    if (currentStepLabel === 'sopimus') {
      this.stopClick(event)
      return this.tallennaHyvaksynta()
    }
    if (currentStepLabel === 'tunnistaminen') {
      this.stopClick(event)
      return
    }
    if (currentStepLabel === 'tuntemistiedot') {
      this.stopClick(event)
      return this.tallennaKyc()
    }

  }

  openPepText() {
    this._dialog.open(PepTekstiSharedDialog)
  }

  stopClick(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
  }

  valmis() {
    this._ladataanService.aloitaLataaminen()
    return this._firebaseLemonaid.functionsCall<string, ErrorResponse>('tervetuloaDialogiNaytettyPaivitys', '').then(response => {

      // Stop loading indicator
      this._ladataanService.lopetaLataaminen()

      // If no response, or response contains an error
      if (!response || response.e) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
        this._errorHandler.handleError(response ? response.e : 'no-response')
      } else {
        // All is good, close dialog
        this._dialogRef.close()
      }

    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe', this._translationService.nykyinenKieli)
      this._errorHandler.handleError(error)
    })
  }

  // isCompanysOwnYtunnusValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  //   if (!!this._ytunnus &&
  //     this._ytunnus === control.value?.trim()
  //   ) {
  //     return { ownytunnus: true }
  //   }
  //   return null
  // }

}
