import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { UserSettingsService } from 'app/_angular/service/user-settings.service'
import { MaksutLaskujenVastaanottoMainosOption, NewFeature, NewFeatureUserActionOption } from 'app/_jaettu/model/kayttaja'
import { take } from 'rxjs'

export interface MaksutMainosLaskujenVastaanottoDialogData {
  kayttajaUid: string
  kayttajaAsiakasAvain: string
}

@Component({
  selector: 'maksut-mainos-laskujen-vastaanotto-dialog',
  templateUrl: './maksut-mainos-laskujen-vastaanotto.dialog.html',
  styleUrls: ['./maksut-mainos-laskujen-vastaanotto.dialog.css']
})

export class MaksutMainosLaskujenVastaanottoDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MaksutMainosLaskujenVastaanottoDialogData,
    private _dialogRef: MatDialogRef<MaksutMainosLaskujenVastaanottoDialog, 'action-saved'>,
    private _router: Router,
    private _userSettingsService: UserSettingsService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    // this._dialogRef.disableClose = true
  }

  ngOnInit(): void {
    this._dialogRef.beforeClosed().pipe(
      take(1)
    ).subscribe(closeType => {

      if (closeType === 'action-saved') {
        return
      }
      const action: NewFeatureUserActionOption = MaksutLaskujenVastaanottoMainosOption.IKKUNA_SULJETTIIN

      return this._userSettingsService.setActionAndFeatureSeen(NewFeature.MAKSUT_LASKUJEN_VASTAANOTTO_MAINOS, action, this.data.kayttajaAsiakasAvain, this.data.kayttajaUid, this._firebaseLemonaid)
    })
  }

  async otaKayttoonVastaanotto(event: MouseEvent) {
    event?.preventDefault()
    event?.stopImmediatePropagation()
    event?.stopPropagation()
    window.scrollTo(0, 0)

    const action: NewFeatureUserActionOption = MaksutLaskujenVastaanottoMainosOption.OTA_KAYTTOON

    await this._userSettingsService.setActionAndFeatureSeen(NewFeature.MAKSUT_LASKUJEN_VASTAANOTTO_MAINOS, action, this.data.kayttajaAsiakasAvain, this.data.kayttajaUid, this._firebaseLemonaid)
    this._dialogRef.close('action-saved')

    this._router.navigate(['maksut', 'asetukset', 'vastaanotto'])
  }

  async ehkaMyohemmin(event: MouseEvent) {
    event?.preventDefault()
    event?.stopImmediatePropagation()
    event?.stopPropagation()
    window.scrollTo(0, 0)

    const action: NewFeatureUserActionOption = MaksutLaskujenVastaanottoMainosOption.EHKA_MYOHEMMIN

    await this._userSettingsService.setActionAndFeatureSeen(NewFeature.MAKSUT_LASKUJEN_VASTAANOTTO_MAINOS, action, this.data.kayttajaAsiakasAvain, this.data.kayttajaUid, this._firebaseLemonaid)
    this._dialogRef.close('action-saved')
  }
}

