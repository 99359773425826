<div class="all" mat-dialog-content style="padding: 0 6vw;">
  <div class="big-card">
    <div class="light-blue-text title-text">{{ 'holvi-oauth.pankkiyhteysvaltuutus' | translate | async }}</div>
    <div class="inner-container">
      <div class="text-box">
        <div style="margin-bottom: 40px;">
          <img src="https://storage.googleapis.com/lemon-public-images/holvi_logo.png" width="93" height="92" alt="Holvi" style="width: 93px;height: 92px;">
        </div>
        <div>{{ 'holvi-oauth.selitys' | translate | async }}</div>
      </div>

      <div class="button-container">
        <button class="secondarybutton" (click)="close()" mat-button>{{ 'yleiset.peruuta' | translate | async }}</button>
        <button class="primarybutton" (click)="goToHolvi()" mat-button>{{ 'yleiset.jatka' | translate | async }}</button>
      </div>
      <div class="red-text common-error" *ngIf="commonError">{{commonError}}</div>

    </div>
    <div #selitys class="gray-text valtuutus-extra-text" [hidden]="!extraTextExpanded" [innerHTML]="'viia-login-required.valtuutus-pitka-selitus' | translate | async"></div>
  </div>
</div>