import { Component, OnInit, forwardRef, Input } from '@angular/core'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

import { ValueAccessorBase } from '../_jaettu-angular/_components/value-accessor-base'
import { TositeService } from '../_angular/service/tosite/tosite.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { KopioijaPalvelu } from '../_jaettu/service/kopioija.service'

import { Maksutapa, MYYNTITOSITE_MAKSUTAPA_ID, TILIOTETOSITE_MAKSUTAPA_ID, PALKKATOSITE_MAKSUTAPA_ID } from '../_jaettu/model/tosite'

import { map } from 'rxjs/operators'
import { Observable, combineLatest } from 'rxjs'


interface MaksutapaJaKuva {
  maksutapa: Maksutapa
  kuva: SafeUrl
}

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MaksutMaksutavatComponent),
  multi: true
}

@Component({
  selector: '[app-maksut-maksutapa]',
  styleUrls: ['./maksut-maksutavat.component.css'],
  template: `
<div class="maksutavat-container" [class.error]="error">
  <div *ngFor="let maksutapaJaKuva of maksutavatJaKuvatObservable | async"
      (click)="valitseMaksutapa(maksutapaJaKuva)"
      class="maksutapa-container"
    >

    <div class="maksutapa-laatikko lemon-mat-elevation-z4" [class.select-disabled]="disableChange">

      <img [src]="maksutapaJaKuva.kuva"
        [class.light-gray-background]="maksutapaJaKuva.maksutapa.i != value"
        [class.light-blue-background]="maksutapaJaKuva.maksutapa.i == value && !disableChange"
        [class.gray-background]="maksutapaJaKuva.maksutapa.i == value && disableChange"
      />

      <div class="maksutavan-nimi-container"
        [class.light-gray-text]="maksutapaJaKuva.maksutapa.i != value"
        [class.light-blue-text]="maksutapaJaKuva.maksutapa.i == value && !disableChange"
        [class.dark-gray-text]="maksutapaJaKuva.maksutapa.i == value && disableChange">
        <span style="display: inline-block; vertical-align: middle;">{{maksutapaJaKuva.maksutapa.l}}</span>
      </div>

    </div>

  </div>
</div>`
  ,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MaksutMaksutavatComponent extends ValueAccessorBase<number> implements OnInit {

  @Input() error: boolean = false
  @Input() showAllPaymentMethods: boolean = false
  @Input() disableChange: boolean = false
  @Input() filterNonActive: boolean = false

  maksutavatJaKuvatObservable: Observable<MaksutapaJaKuva[]> = null

  constructor(
    public _tositeService: TositeService,
    private _sanitizer: DomSanitizer,
    private _translationService: LemonTranslationService,
    private _copyService: KopioijaPalvelu
    // @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    // @Optional() @Inject(NG_VALIDATORS) private validators: Array<any>,
    // @Optional() @Inject(NG_ASYNC_VALIDATORS) private asyncValidators: Array<any>
  ) {
    super()
  }

  ngOnInit() {
    if (this.showAllPaymentMethods) {
      this.maksutavatJaKuvatObservable = combineLatest([this._translationService.currentLanguageObservable, this._tositeService.expandedMaksutavatObservable]).pipe(
        map(([kieli, maksutavat]) => {
          const clonedMaksutavat = this._copyService.cloneDeep(maksutavat) as Maksutapa[]
          for (const maksutapa of clonedMaksutavat) {
            if (maksutapa.i === MYYNTITOSITE_MAKSUTAPA_ID) {
              maksutapa.l = this._translationService.lokalisoiKielella('app.myyntitositteet', kieli)
            } else if (maksutapa.i === TILIOTETOSITE_MAKSUTAPA_ID) {
              maksutapa.l = this._translationService.lokalisoiKielella('app.tiliotteet', kieli)
            } else if (maksutapa.i === PALKKATOSITE_MAKSUTAPA_ID) {
              maksutapa.l = this._translationService.lokalisoiKielella('app.palkkamateriaali', kieli)
            }
          }
          if (this.filterNonActive) {
            return this.maksutavatToMaksutavatJaKuvat(clonedMaksutavat.filter(m => m.a))
          }
          return this.maksutavatToMaksutavatJaKuvat(clonedMaksutavat)
        })
      )
    } else {
      this.maksutavatJaKuvatObservable = this._tositeService.maksutavatObservable.pipe(
        map(maksutavat => {
          if (this.filterNonActive) {
            return this.maksutavatToMaksutavatJaKuvat(maksutavat.filter(m => m.a))
          }
          return this.maksutavatToMaksutavatJaKuvat(maksutavat)
        })
      )
    }
  }

  public valitseMaksutapa(maksutapaJaKuva: MaksutapaJaKuva) {
    if (!this.disableChange) {
      this.value = maksutapaJaKuva.maksutapa.i
    }
  }

  private maksutavatToMaksutavatJaKuvat(maksutavat: Maksutapa[]) {
    const maksutavatJaKuvat: MaksutapaJaKuva[] = []
    for (const maksutapa of maksutavat) {
      const kuva = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + maksutapa.img)
      maksutavatJaKuvat.push({
        maksutapa: maksutapa,
        kuva: kuva,
      })
    }
    return maksutavatJaKuvat
  }
}
