import { Component, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'

import { MatDialog } from '@angular/material/dialog'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from '../vaihda-kieli.dialog'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'

@Component({
  templateUrl: './no-roles.component.html',
  styleUrls: ['./login.component.css']
})
export class NoRolesComponent {

  year = new Date().getFullYear()

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private ladataanService: LadataanService,
    private kayttajaService: KayttajaService,
    private errorHandler: ErrorHandler
  ) { }

  palaaSovellukseen() {
    this.router.navigate(['/'])
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this.dialog.open(VaihdaKieliDialog, { data: data })
  }
  logout() {
    this.ladataanService.aloitaLataaminen()
    this.kayttajaService.logout().then(res => {
      this.router.navigate(['/kirjaudu']).then(() => {
        this.ladataanService.lopetaLataaminen()
      }).catch(err => {
        this.ladataanService.lopetaLataaminen()
        this.errorHandler.handleError(err)
      })
    }).catch(err => {
      this.ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
    })
  }

}
