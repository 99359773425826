<div class="asetukset-page">
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab [label]="'asetukset.omat' | translate | async">
      <div class="sisalto">
        <app-perusasetukset></app-perusasetukset>
      </div>
    </mat-tab>
    <mat-tab [label]="'asetukset.lasku' | translate | async">
      <div class="sisalto">
        <app-laskuasetukset></app-laskuasetukset>
      </div>
    </mat-tab>
    <mat-tab [label]="'asetukset.spostipohja' | translate | async">
      <div class="sisalto">
        <app-emailpohja></app-emailpohja>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span *ngIf="(showNewFeatureSignObservable | async)" class="uutta-sign" style="left: -29px;">{{'app.uutta' | translate | async}}</span>
        <div>{{'asetukset.reskontra' | translate | async }}</div>
      </ng-template>
      <div class="sisalto" style="max-width: 630px;">
        <app-reskontra-asetukset></app-reskontra-asetukset>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>