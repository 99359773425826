import { ErrorResponse, BaseEntity, TuettuKieli } from '../../_shared-core/model/common'
import { KirjanpidonUlkopuolisetKulut } from './kayttaja'

// Shared between ajopäiväkirja & new user registration
export interface AnnaKayttajanTiedotReq {
  token: string
}
// eslint-disable-next-line no-shadow
export enum MagicLinkErrorResponse {
  UNKNOWN = 'unknown-error',
  NOPASS = 'no-password',
  NOTOKEN = 'no-token',
  NOUSER = 'no-kayttaja',
  NOCUSTOMER = 'no-asiakas',
  // WRONGPURPOSE = 'wrong-purpose',
  EXPIRED = 'expired-link', // NOTE!  Careful when changing - also used as translation string in UI
  USED = 'used-link' // NOTE! Careful when changing - also used as translation string in UI
}

// New user registration
export interface TervetuloaAnnaKayttajanTiedotResp extends ErrorResponse<MagicLinkErrorResponse> {
  etunimi: string
  sukunimi: string
  email: string
}
export interface LuoTiliReq extends AnnaKayttajanTiedotReq {
  password: string
}
export interface LuoTiliResp extends ErrorResponse<MagicLinkErrorResponse> {
  loginCodeId: string
}

// Ajopäiväkirja
export interface KirjanpidonUlkopuolisetKulutAnnaKayttajanTiedotResp extends ErrorResponse<MagicLinkErrorResponse> {
  asiakasAvain: string
  nimi: string
  verovuosi: number
  lang: TuettuKieli
  onkoHolvi: boolean
  subtype: 'yearly' | 'half-year'
  prefillData: KirjanpidonUlkopuolisetKulut
}
export interface TallennaKirjanpidonUlkopuolisetKulutReq extends AnnaKayttajanTiedotReq, Omit<KirjanpidonUlkopuolisetKulut, keyof BaseEntity | 'asiakasAvain'> {
}
export interface TallennaKirjanpidonUlkopuolisetKulutResp extends ErrorResponse<MagicLinkErrorResponse> { }
