import { Component, OnInit, Inject, ErrorHandler } from '@angular/core'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'


import { FirestoreTosite } from '../../_jaettu/model/tosite'


import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { TositeService } from 'app/_angular/service/tosite/tosite.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'

export interface LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialogData {
  hyvaksyttava: FirestoreTosite
}

@Component({
  templateUrl: './laskujen-hyvaksyminen.ei-voi-hyvaksya-enaa.dialog.html',
  styleUrls: ['./laskujen-hyvaksyminen.ei-voi-hyvaksya-enaa.dialog.css']
})
export class LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialogData,
    private dialogRef: MatDialogRef<LaskujenHyvaksyminenEiVoiHyvaksyaEnaaDialog>,
    private errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService,
    private _tositeService: TositeService,
    private _timestampService: TimestampService
  ) { }

  ngOnInit() {

  }

  async maksettu() {

    const kayttaja = await this._kayttajaService.getKayttaja()

    this.data.hyvaksyttava.einvoiceApprovalStatus = 'approved'

    if (!this.data.hyvaksyttava.einvoiceApprovals) {
      this.data.hyvaksyttava.einvoiceApprovals = {}
    }

    this.data.hyvaksyttava.einvoiceApprovals[kayttaja.avain] = {
      approved: this._timestampService.now(),
      firstName: kayttaja.etunimi,
      lastName: kayttaja.sukunimi
    }

    // By setting the last param to false, we don't notify Lemonator of the approval -> the actual forwarding does NOT happen,
    // but the invoice get's processed to accounting normally as part of the common chain.
    await this._tositeService.tallennaKuitti(this.data.hyvaksyttava, false)

    this.dialogRef.close()

  }

}
