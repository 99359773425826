import { Injectable, ErrorHandler } from '@angular/core'

import { KayttajaService } from '../kayttaja.service'

import {
  AsiakkaanTuote,
  EmailLahetysStatus,
  Lasku,
  LaskuBase,
  Laskuasetukset,
  LaskunListaustietorivi,
  LaskunAsiakas,
  LaskunumeroTyyppi,
  TypeaheadAsiakas,
  TypeaheadAsiakasDokumentti,
  TypeaheadTuote,
  TypeaheadTuoteDokumentti,
  LaskuToimintaloki,
  EI_LASKUNUMEROA_NUMERO,
  LaskunLiitetiedosto,
  LaskunSahkoinenOsoite,
  LaskuReskontra,
  LaskuMyyntiLaskuOstolaskuksiTyojonoMerkinta
} from '../../../_jaettu/model/lasku'
import { Kayttaja, KayttajanTiedot } from '../../../_jaettu/model/kayttaja'

import { LadattavaLiitetiedosto } from '../../../_jaettu-angular/laskut/muokkaa/lasku-rivit.component'

import { LaskuSharedService, ReskontraService } from '../../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuUriService } from '../../../_jaettu/service/lasku/lasku-uri.service'
import { LaskuIndeksoija } from '../../../_jaettu/service/lasku/lasku.indeksoija'
import { LaskuKopioija } from '../../../_jaettu/service/lasku/lasku.kopioija'
import { BatchManipulator, FirestoreProvider, FirestoreWriteBatch, LaskuTallennusService } from '../../../_jaettu/service/lasku/lasku-tallennus.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { DateService } from '../../../_shared-core/service/date.service'
import { TuettuKieli } from '../../../_shared-core/model/common'
import { TranslationService } from '../../../_jaettu/service/translation.service'
import { ViitenumeroService } from '../../../_shared-core/service/viitenumero.service'
import { StringService } from '../../../_shared-core/service/string.service'

import { of as observableOf, ReplaySubject, Observable, firstValueFrom } from 'rxjs'
import { mergeMap, map, switchMap, filter } from 'rxjs/operators'
import { Timestamp } from '../../../_shared-core/model/common'
import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'

import { FirebaseLemonaid } from '../firebase-lemonaid.service'

@Injectable()
export class LaskuService {

  private laskuTallennusService: LaskuTallennusService

  private asetuksetSubject = new ReplaySubject<Laskuasetukset>(1)
  asetuksetObservable = this.asetuksetSubject.asObservable()

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public LEMONTREE_ASIAKAS_ID = 9
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public LEMONTREE_ASIAKAS_AVAIN = 'jzATU474P4BRCkEANZ1H'


  constructor(
    private errorHandler: ErrorHandler,
    private kayttajaService: KayttajaService,
    private lemonaidFirebase: FirebaseLemonaid,
    private shared: LaskuSharedService,
    private laskuIndeksoija: LaskuIndeksoija,
    private laskuKopioija: LaskuKopioija,
    private currencyService: CurrencyService,
    private laskuUriService: LaskuUriService,
    private dateService: DateService,
    private translationService: TranslationService,
    private timestampService: TimestampService,
    private reskontraService: ReskontraService,
    private viitenumeroService: ViitenumeroService,
    private stringService: StringService
  ) {

    const firestoreProvider: FirestoreProvider = {
      annaDeleteArvo: () => { return lemonaidFirebase.firestoreDeleteMarker() },
      annaUusiAvain: () => { return this.lemonaidFirebase.firestoreCreateId() },
      // THIS IS REALLY COUNTERINTUITIVE, BUT THE DOC TYPE CONTAINS NO DATA
      // AND THESE DAYS OUR FRONT END FIREBASE INTEGRATION LIBRARY EXPECTS THE URI,
      // NOT THE DOC TO BE PRESENT!
      annaDoc: (uri: string) => { return uri },
      annaUusiBatch: () => { return this.lemonaidFirebase.firestoreBatch() }
    }

    this.laskuTallennusService = new LaskuTallennusService(
      this.shared,
      this.laskuKopioija,
      this.laskuIndeksoija,
      this.currencyService,
      this.dateService,
      this.laskuUriService,
      this.translationService,
      this.timestampService,
      this.reskontraService,
      firestoreProvider,
      this.viitenumeroService,
      this.stringService
    )

    this.kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (kayttajanTiedot) {
          return this.lemonaidFirebase.firestoreDoc<Laskuasetukset>('/laskut/' + kayttajanTiedot.asiakasId).listen()
        }
        return observableOf<Laskuasetukset>(null)
      })
    ).subscribe(asetukset => {
      this.asetuksetSubject.next(asetukset)
    })

  }

  annaAsetukset(kayttajanTiedot: KayttajanTiedot): Promise<Laskuasetukset | null> {
    if (kayttajanTiedot && kayttajanTiedot.asiakasId) {
      return this.lemonaidFirebase.firestoreDoc<Laskuasetukset>('/laskut/' + kayttajanTiedot.asiakasId).get()
    }
    return Promise.resolve(null)
  }

  annaAsetuksetKayttajalle(kayttaja: Kayttaja): Promise<Laskuasetukset | null> {
    if (kayttaja && kayttaja.asiakasId) {
      return this.lemonaidFirebase.firestoreDoc<Laskuasetukset>('/laskut/' + kayttaja.asiakasId).get()
    }
    return Promise.resolve(null)
  }

  async poistaAsiakkaat(asiakkaat: LaskunAsiakas[]) {
    if (asiakkaat) {

      const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
      const batch = this.lemonaidFirebase.firestoreBatch()

      for (const asiakas of asiakkaat) {
        asiakas.date = this.timestampService.now()
        asiakas.poistettu = true
        this.laskuTallennusService.lisaaAsiakasBatchiin(kayttajanTiedot.asiakasId, asiakas, batch)
      }

      return batch.commit().catch(error => {
        this.errorHandler.handleError(error)
      })
    }
  }

  async updateAsiakkaat(asiakkaat: LaskunAsiakas[]) {
    if (asiakkaat) {

      const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
      const batch = this.lemonaidFirebase.firestoreBatch()

      for (const asiakas of asiakkaat) {
        asiakas.date = this.timestampService.now()
        this.laskuTallennusService.lisaaAsiakasBatchiin(kayttajanTiedot.asiakasId, asiakas, batch)
      }

      return batch.commit().catch(error => {
        this.errorHandler.handleError(error)
      })
    }
  }

  public getTypeaheadAsiakkaat(kayttajanTiedot: KayttajanTiedot): Observable<TypeaheadAsiakas[]> {
    if (!kayttajanTiedot) {
      return observableOf([])
    }
    const typeaheadUri = this.laskuUriService.getTypeaheadAsiakasDokumentinUri(kayttajanTiedot.asiakasId)
    return this.lemonaidFirebase.firestoreDoc<TypeaheadAsiakasDokumentti>(typeaheadUri).listen().pipe(
      map(dokumentti => {
        const asiakkaat = dokumentti || {}
        return Object.keys(asiakkaat).map((key, index) => {
          const value = asiakkaat[key]
          const asiakas: TypeaheadAsiakas = {
            avain: key,
            nimi: value.nimi,
            ytunnus: value.ytunnus
          }
          return asiakas
        })
      })
    )
  }

  getAsiakasObservable(id: string, asiakasId?: string): Observable<LaskunAsiakas | null> {
    if (asiakasId) {
      return this.getAsiakasInternal(id, asiakasId)
    } else {
      return this.kayttajaService.kayttajanTiedotObservable.pipe(
        mergeMap(kayttajanTiedot => {
          if (kayttajanTiedot) {
            return this.getAsiakasInternal(id, kayttajanTiedot.asiakasId)
          }
          return observableOf(null)
        })
      )
    }
  }

  private getAsiakasInternal(id: string, asiakasId: string): Observable<LaskunAsiakas | null> {
    return this.lemonaidFirebase.firestoreDoc<LaskunAsiakas>('/laskut/' + asiakasId + '/asiakkaat/' + id)
      .listenSnap().pipe(
        map(snapshot => {
          const asiakas = snapshot.data() as LaskunAsiakas
          if (asiakas) {
            asiakas.avain = snapshot.id
            return asiakas
          }
          return null
        })
      )
  }

  async getTuote(id: string): Promise<AsiakkaanTuote> {
    return firstValueFrom(this.getTuoteObservable(id))
  }

  getTuoteObservable(id: string, asiakasId?: string): Observable<AsiakkaanTuote> {
    if (asiakasId) {
      return this.getTuoteObservableInternal(id, asiakasId)
    } else {
      return this.kayttajaService.kayttajanTiedotObservable.pipe(
        mergeMap(asiakkaanTiedot => {
          return this.getTuoteObservableInternal(id, asiakkaanTiedot.asiakasId)
        })
      )
    }
  }

  private getTuoteObservableInternal(id: string, asiakasId: string): Observable<AsiakkaanTuote> {
    return this.lemonaidFirebase.firestoreDoc<AsiakkaanTuote>('/laskut/' + asiakasId + '/tuotteet/' + id)
      .listenSnap().pipe(
        map(snapshot => {
          if (snapshot.exists()) {
            const asiakkaanTuote = snapshot.data() as AsiakkaanTuote
            asiakkaanTuote.$key = snapshot.id
            return asiakkaanTuote
          }
          return null
        })
      )
  }

  async poistaTuotteet(tuotteet: AsiakkaanTuote[]) {
    if (tuotteet) {

      const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
      const batch = this.lemonaidFirebase.firestoreBatch()

      for (const tuote of tuotteet) {
        tuote.poistettu = true
        this.laskuTallennusService.lisaaTuoteBatchiin(kayttajanTiedot.asiakasId, tuote, batch)
      }

      return batch.commit().catch(error => {
        this.errorHandler.handleError(error)
      })

    }
  }

  async updateTuotteet(tuotteet: AsiakkaanTuote[]) {
    if (tuotteet) {

      const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
      const batch = this.lemonaidFirebase.firestoreBatch()

      for (const tuote of tuotteet) {
        this.laskuTallennusService.lisaaTuoteBatchiin(kayttajanTiedot.asiakasId, tuote, batch)
      }

      return batch.commit().catch(error => {
        this.errorHandler.handleError(error)
      })
    }
  }

  public getTypeaheadTuotteet(kayttajanTiedot: KayttajanTiedot): Observable<TypeaheadTuote[]> {
    const typeaheadUri = this.laskuUriService.getTypeaheadTuoteDokumentinUri(kayttajanTiedot.asiakasId)
    return this.lemonaidFirebase.firestoreDoc<TypeaheadTuoteDokumentti>(typeaheadUri).listen().pipe(
      map(dokumentti => {
        const asiakkaat = dokumentti || {}
        return Object.keys(asiakkaat).map((key, index) => {
          const value = asiakkaat[key]
          const asiakas: TypeaheadTuote = {
            avain: key,
            nimi: value.nimi
          }
          return asiakas
        })
      })
    )
  }

  async getLasku(id: string): Promise<Lasku> {
    return firstValueFrom(this.getLaskuObservable(id))
  }

  async getLaskuAsiakkaalle(asiakasId: string | number, id: string): Promise<Lasku | null> {
    return firstValueFrom(this.getLaskuObservableAsiakkaalle(asiakasId, id))
  }

  getLaskuObservableAsiakkaalle(asiakasId: string | number, id: string): Observable<Lasku | null> {
    const laskuUri = this.laskuUriService.getLaskuUri(asiakasId, id)
    return this.lemonaidFirebase.firestoreDoc<Lasku>(laskuUri).listen().pipe(
      map(lasku => {
        if (lasku) {
          delete lasku['haku']
        }
        return lasku
      })
    )
  }

  getLaskuReskontraObservable(laskuAvain: string): Observable<LaskuReskontra[]> {
    return this.kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (kayttajanTiedot) {
          const laskuUri = this.laskuUriService.getLaskuReskontraCollectionUri(kayttajanTiedot.asiakasId, laskuAvain)
          return this.lemonaidFirebase.firestoreCollection<LaskuReskontra>(laskuUri)
            .orderBy('pvm', 'asc')
            .listen()
        }
        return observableOf<LaskuReskontra[]>(null as [])
      })
    )
  }

  private _getLaskuReskontra(laskuAvain: string): Promise<LaskuReskontra[]> {
    return firstValueFrom(this.getLaskuReskontraObservable(laskuAvain).pipe(filter(rskntr => !!rskntr)))
  }

  getLaskuToimintalokiObservable(laskuAvain: string): Observable<LaskuToimintaloki[]> {
    return this.kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (kayttajanTiedot) {
          const toimintalokiUri = this.laskuUriService.getLaskuToimintalokiCollectionUri(kayttajanTiedot.asiakasId, laskuAvain)
          return this.lemonaidFirebase.firestoreCollection<LaskuToimintaloki>(toimintalokiUri)
            .orderBy('pvm', 'asc')
            .orderBy('toiminto', 'asc')
            .listen()
        }
        return observableOf<LaskuToimintaloki[]>([])
      })
    )
  }

  getLaskuObservable(avain: string): Observable<Lasku | null> {
    return this.kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (kayttajanTiedot) {
          return this.getLaskuObservableAsiakkaalle(kayttajanTiedot.asiakasId, avain)
        }
        return observableOf<Lasku>(null)
      })
    )
  }

  haeLaskunumeroArvio(lasku: LaskuBase): Promise<number> {
    if (lasku.nro && lasku.nro !== EI_LASKUNUMEROA_NUMERO) {
      return Promise.resolve(lasku.nro)
    }
    const numerotyyppi = lasku.nrotyyppi === LaskunumeroTyyppi.HYVITYS ? LaskunumeroTyyppi.TAVALLINEN : lasku.nrotyyppi
    return this.getLaskunumeroInternal(lasku.pvm, lasku.avain, numerotyyppi).then(number => {
      const year = lasku.pvm.toDate().getFullYear().toString().substr(-2)
      return Number(year + number)
    })
  }

  private async getLaskunumeroInternal(laskupvm: Timestamp, laskuid: string, tyyppi: LaskunumeroTyyppi): Promise<number> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const uri = '/laskut/nrot/' + kayttajanTiedot.asiakasId + '/' + tyyppi + '/' + laskupvm.toDate().getFullYear()
    return this.lemonaidFirebase.databaseGet(uri)
      .then(snapshot => {
        const values = snapshot.val()
        if (values) {
          if (laskuid) {
            let index = 1
            for (const numero of values) {
              if (numero === laskuid) {
                return index
              }
              index++
            }
            return index
          } else {
            return values.length + 1
          }
        } else {
          return 1
        }
      })
  }

  updateLaskuasetukset(asetukset: Laskuasetukset): Promise<void> {
    if (asetukset) {

      const copied = this.laskuKopioija.copyAsetukset(asetukset)
      copied.date = this.timestampService.now()

      return this.kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
        if (kayttajanTiedot) {
          const uri = '/laskut/' + kayttajanTiedot.asiakasId
          return this.lemonaidFirebase.firestoreSetData<Laskuasetukset>(uri, copied)
        } else {
          return Promise.reject('Asetuksien tallentamisessa ei löytynyt käyttäjää!')
        }
      }).catch(error => {
        this.errorHandler.handleError(error)
      })

    }
  }

  private valmisteleTallennustaVarten(kasiteltava: LaskuBase) {
    if (kasiteltava.liitteet) {
      const jaljella: LaskunLiitetiedosto[] = []
      for (const liite of kasiteltava.liitteet) {
        const ladattavaliite = liite as LadattavaLiitetiedosto
        if (!ladattavaliite.uploadData || (!ladattavaliite.uploadError && ladattavaliite.uploadDone)) {
          jaljella.push(liite)
        }
      }
      kasiteltava.liitteet = jaljella
    }
  }

  async lahetaLaskuUudelleen(juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    return this.laskuTallennusService.lahetaLaskuUudelleen(kayttajanTiedot, juurilasku, kasiteltava, reskontra)
  }

  async lahetaEmailUudelleen(juurilasku: Lasku, kasiteltava: LaskuBase, vastaanottaja: EmailLahetysStatus, vanhaEmail: string): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.lahetaEmailUudelleen(kayttajanTiedot, juurilasku, kasiteltava, reskontra, vastaanottaja, vanhaEmail)
  }

  async lahetaSahkoinenUudelleen(juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.lahetaSahkoinenUudelleen(kayttajanTiedot, juurilasku, kasiteltava, reskontra)
  }

  async peruutaLuottotappiomerkinta(juurilasku: Lasku): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const viimeisinTavallinen = this.shared.annaViimeisinTavallinenLasku(juurilasku)
    this.valmisteleTallennustaVarten(viimeisinTavallinen)
    return this.laskuTallennusService.peruutaLuottotappiomerkinta(kayttajanTiedot, juurilasku, viimeisinTavallinen, reskontra)
  }

  async mitatoiLasku(juurilasku: Lasku): Promise<void | LaskuBase> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const viimeisinTavallinen = this.shared.annaViimeisinTavallinenLasku(juurilasku)
    this.valmisteleTallennustaVarten(viimeisinTavallinen)
    return this.laskuTallennusService.mitatoiLasku(kayttajanTiedot, juurilasku, viimeisinTavallinen, reskontra)
  }

  async paivitaTilaJaIndeksoiUudelleen(juurilasku: Lasku): Promise<void> {
    const kasiteltava = this.shared.annaViimeisinEiLuonnosLasku(juurilasku)
    this.valmisteleTallennustaVarten(kasiteltava)
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    this.shared.paivitaLaskunTila(juurilasku, reskontra)
    return this.indeksoiUudelleen(juurilasku)
  }

  async indeksoiUudelleen(juurilasku: Lasku): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    return this.laskuTallennusService.indeksoiUudelleen(kayttajanTiedot, juurilasku, reskontra)
  }

  async laskeReskontraUudelleen(juurilasku: Lasku): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    return this.laskuTallennusService.laskeReskontraUudelleen(kayttajanTiedot, juurilasku, reskontra)
  }

  async merkitseLuottotappioksi(juurilasku: Lasku): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const viimeisinTavallinen = this.shared.annaViimeisinTavallinenLasku(juurilasku)
    this.valmisteleTallennustaVarten(viimeisinTavallinen)
    return this.laskuTallennusService.merkitseLuottotappioksi(kayttajanTiedot, juurilasku, viimeisinTavallinen, reskontra)
  }

  async tallennaKommentti(juurilasku: Lasku, kommentti: string): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const kasiteltava = this.shared.annaViimeisinTavallinenLasku(juurilasku) ?? this.shared.annaViimeisinKasiteltavaLasku(juurilasku)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.tallennaKommentti(kayttajanTiedot, juurilasku, kasiteltava, reskontra, kommentti)
  }

  async lisaaReskontraMerkinta(juurilasku: Lasku, suoritus: number): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const kasiteltava = this.shared.annaViimeisinTavallinenLasku(juurilasku)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.lisaaReskontraMerkinta(kayttajanTiedot, juurilasku, kasiteltava, reskontra, suoritus)
  }

  async merkitseLaskuTaysinMaksetuksi(listaustieto: LaskunListaustietorivi): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await firstValueFrom(this.getLaskuReskontraObservable(listaustieto.juurilasku.avain).pipe(filter(rskntr => !!rskntr)))
    const juurilasku = await this.getLaskuAsiakkaalle(kayttajanTiedot.asiakasId, listaustieto.juuriAvain)
    return this.laskuTallennusService.merkitseLaskuTaysinMaksetuksi(kayttajanTiedot, juurilasku, reskontra)
  }

  async poistaLuonnos(juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.poistaLuonnos(kayttajanTiedot, juurilasku, kasiteltava, reskontra)
  }

  async tallennaLuonnos(juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.tallennaLuonnos(kayttajanTiedot, juurilasku, kasiteltava, reskontra)
  }

  async merkitseLaskuLahetetyksiTulostamalla(juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const manipulator = await this._annaMyyntilaskuOstolaskuksiBatchManipulator(kayttajanTiedot, kasiteltava)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.merkitseLaskuLahetetyksiTulostamalla(kayttajanTiedot, juurilasku, kasiteltava, reskontra, manipulator)
  }

  async merkitseLaskunMaksumuistutusLahetetyksiTulostamalla(juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const manipulator = await this._annaMyyntilaskuOstolaskuksiBatchManipulator(kayttajanTiedot, kasiteltava)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.merkitseLaskunMaksumuistutusLahetetyksiTulostamalla(kayttajanTiedot, juurilasku, kasiteltava, reskontra, manipulator)
  }

  async merkitseLaskunMaksumuistutusLahetetyksiSpostilla(templateId: number, juurilasku: Lasku, kasiteltava: LaskuBase, vastaanottajat: EmailLahetysStatus[], aihe: string, otsikko: string, teksti: string, slogan: string, paivitaAsiakkaanSaajat: boolean): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const manipulator = await this._annaMyyntilaskuOstolaskuksiBatchManipulator(kayttajanTiedot, kasiteltava)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.merkitseLaskunMaksumuistutusLahetetyksiSpostilla(kayttajanTiedot, templateId, juurilasku, kasiteltava, reskontra, vastaanottajat, aihe, otsikko, teksti, slogan, paivitaAsiakkaanSaajat, manipulator)
  }

  async merkitseLaskunPerintaLahetetyksiSpostilla(juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    return this.laskuTallennusService.merkitseLaskunPerintaLahetetyksiSpostilla(kayttajanTiedot, reskontra, juurilasku, kasiteltava)
  }

  async merkitseLaskunPerintaPerutuksiSpostilla(juurilasku: Lasku): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    return this.laskuTallennusService.merkitseLaskunPerintaPerutuksiSpostilla(kayttajanTiedot, reskontra, juurilasku)
  }

  async merkitseLaskuLahetetyksiSpostilla(templateId: number, juurilasku: Lasku, kasiteltava: LaskuBase, vastaanottajat: EmailLahetysStatus[], aihe: string, otsikko: string, teksti: string, slogan: string): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const manipulator = await this._annaMyyntilaskuOstolaskuksiBatchManipulator(kayttajanTiedot, kasiteltava)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.merkitseLaskuLahetetyksiSpostilla(kayttajanTiedot, templateId, juurilasku, kasiteltava, reskontra, vastaanottajat, aihe, otsikko, teksti, slogan, null, manipulator)
  }

  async merkitseLaskuLahetetyksiSahkoisesti(osoite: LaskunSahkoinenOsoite, juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const manipulator = await this._annaMyyntilaskuOstolaskuksiBatchManipulator(kayttajanTiedot, kasiteltava)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.merkitseLaskuLahetetyksiSahkoisesti(kayttajanTiedot, osoite, juurilasku, kasiteltava, reskontra, manipulator)
  }

  async merkitseLaskunMaksumuistutusLahetetyksiSahkoisesti(osoite: LaskunSahkoinenOsoite, juurilasku: Lasku, kasiteltava: LaskuBase): Promise<void> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const reskontra = await this._getLaskuReskontra(juurilasku.avain)
    const manipulator = await this._annaMyyntilaskuOstolaskuksiBatchManipulator(kayttajanTiedot, kasiteltava)
    this.valmisteleTallennustaVarten(kasiteltava)
    return this.laskuTallennusService.merkitseLaskunMaksumuistutusLahetetyksiSahkoisesti(kayttajanTiedot, osoite, juurilasku, kasiteltava, reskontra, manipulator)
  }

  async getPdfUrl(lasku: LaskuBase): Promise<string> {
    const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
    const url = this.laskuUriService.getPdfUri(kayttajanTiedot.asiakasId, lasku.avain)
    return url
  }

  private async _annaMyyntilaskuOstolaskuksiBatchManipulator(
    kayttajanTiedot: KayttajanTiedot,
    kasiteltava: LaskuBase
  ): Promise<BatchManipulator> {
    if (kayttajanTiedot?.asiakasAvain !== this.LEMONTREE_ASIAKAS_AVAIN) {
      return undefined
    }
    const manipulator: BatchManipulator = {
      manipulate: (firestoreProvider: FirestoreProvider, batch: FirestoreWriteBatch, jl: Lasku, ka: LaskuBase) => {

        const id = firestoreProvider.annaUusiAvain()
        const sahkoiseksiTyodata: LaskuMyyntiLaskuOstolaskuksiTyojonoMerkinta = {
          aloitettu: this.timestampService.now(),
          kasiteltavaAvain: ka.avain,
          laskuAvain: jl.avain,
          myyvanAsiakkaanAvain: this.LEMONTREE_ASIAKAS_AVAIN,
          ostavanAsiakkaanAvain: kasiteltava.asiakas.avain,
          myyvanAsiakkaanId: this.LEMONTREE_ASIAKAS_ID + '',
          uudelleenyrityksia: 0
        }
        const sahkoiseksiUri = this.laskuUriService.getTyojonoLaskuMyyntilaskuOstolaskuksi(sahkoiseksiTyodata.myyvanAsiakkaanAvain, id)
        const sahkoiseksiDoc = firestoreProvider.annaDoc(sahkoiseksiUri)
        batch.set(sahkoiseksiDoc, sahkoiseksiTyodata)

      }
    }
    return manipulator
  }

  annaPdfTiedostonNimi(juurilasku: Lasku, kasiteltava: LaskuBase, kieli: TuettuKieli): string {

    let nimi = this.translationService.lokalisoi('lasku.tiedostonimen-alku-ladattaessa', kieli)
    const laskunumero = this.shared.annaMuotoiltuLaskunumero(juurilasku, kasiteltava)
    if (laskunumero) {
      nimi += ' ' + laskunumero
    }
    if (kasiteltava && kasiteltava.asiakas && kasiteltava.asiakas.nimi) {
      nimi += ' ' + kasiteltava.asiakas.nimi
    }
    if (kasiteltava && kasiteltava.pvm) {
      nimi += ' ' + this.dateService.muotoilePaiva(kasiteltava.pvm, kieli)
    }
    nimi += '.pdf'

    return nimi
  }

  annaPdfTiedostonNimiVersiolle(juurilasku: Lasku, kasiteltava: LaskuBase, kieli: TuettuKieli): string {

    let nimi = this.translationService.lokalisoi('lasku.tiedostonimen-alku-ladattaessa', kieli)
    const laskunumero = this.shared.annaMuotoiltuLaskunumero(juurilasku, kasiteltava)
    if (laskunumero) {
      nimi += ' ' + laskunumero
    }
    if (kasiteltava && kasiteltava.asiakas && kasiteltava.asiakas.nimi) {
      nimi += ' ' + kasiteltava.asiakas.nimi
    }
    if (kasiteltava && kasiteltava.pvm) {
      nimi += ' ' + this.dateService.muotoilePaiva(kasiteltava.pvm, kieli)
    }
    if (kasiteltava && kasiteltava.date) {
      const asDate = this.dateService.timestampToDate(kasiteltava.date)
      nimi += ', tallennettu ' + this.dateService.muotoilePaivaJaAikaDate(asDate, kieli)
    }
    nimi += '.pdf'

    return nimi
  }

}
