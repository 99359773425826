<div class="detail-page">

  <form (ngSubmit)="save()" [formGroup]="form" novalidate>

    <div style="max-width: 450px;">
      <!-- <div> -->
      <!-- style="width: calc(96% - 120px); float: left;" -->
      <mat-form-field>
        <mat-label>{{ 'asiakas.nimi' | translate | async }}</mat-label>
        <input #nimiInput type="text" formControlName="nimi" [name]="namename" matInput required data-lpignore="true" />
        <mat-error>{{fieldErrors.nimi}}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field class="valitsin" style="width: 120px; float: right;">
          <lemon-henkilo-yritys-valitsin formControlName="asiakastyyppi" required></lemon-henkilo-yritys-valitsin>
          <mat-error>{{fieldErrors.asiakastyyppi}}</mat-error>
        </mat-form-field> -->
      <!-- </div> -->
      <!-- class="ei_alaviivaa" -->
      <!-- <mat-form-field [floatLabel]="'always'" class="radio-buttonit">
        <mat-label>{{ 'asiakas.asiakastyyppi.asiakastyyppi' | translate | async }}</mat-label>
<input type="text" formControlName="asiakastyyppi"  style="display: none;" matInput required data-lpignore="true" />
        <div style="transform: scale(.8); height: 28px; transform-origin: left 50%;">
          <mat-radio-group formControlName="asiakastyyppi" required>
            <mat-radio-button color="primary" value="h">{{'asiakas.asiakastyyppi.henkilo' | translate | async}}</mat-radio-button>
            <mat-radio-button color="primary" value="y">{{'asiakas.asiakastyyppi.yritys' | translate | async}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-error>{{fieldErrors.asiakastyyppi}}</mat-error>
      </mat-form-field> -->
      <mat-form-field>
        <mat-label>{{ 'asiakas.ytunnus' | translate | async }}</mat-label>
        <input type="text" formControlName="ytunnus" [name]="namename" matInput data-lpignore="true" />
        <mat-error>{{fieldErrors.ytunnus}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'asiakas.katuosoite' | translate | async }}</mat-label>
        <input type="text" formControlName="katuosoite" [name]="namename" matInput required data-lpignore="true" />
        <mat-error>{{fieldErrors.katuosoite}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'asiakas.postinro' | translate | async }}</mat-label>
        <input type="text" formControlName="postinro" [name]="namename" matInput required data-lpignore="true" />
        <mat-error>{{fieldErrors.postinro}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'asiakas.postitoimipaikka' | translate | async }}</mat-label>
        <input type="text" formControlName="postitoimipaikka" [name]="namename" matInput required data-lpignore="true" />
        <mat-error>{{fieldErrors.postitoimipaikka}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'asiakas.maa' | translate | async }}</mat-label>
        <mat-select formControlName="maa" style="width: 100%;">
          <mat-option *ngFor="let maa of maat" [value]="maa.koodi">
            {{ maa.nimi }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div style="margin-bottom: 0.5em; margin-top: 2em;">
        <h3>{{'asiakas.verkkolaskuotsikko' | translate | async}}</h3>
        <mat-form-field>
          <mat-label>{{ 'asiakas.verkkolaskuvalittaja' | translate | async }}</mat-label>
          <input type="text" [name]="namename" [matAutocomplete]="operaattoriauto" formControlName="elaskuvalittaja" matInput data-lpignore="true" />
          <mat-error>{{fieldErrors.elaskuvalittaja}}</mat-error>
        </mat-form-field>
        <mat-autocomplete #operaattoriauto="matAutocomplete" [displayWith]="valittajaDisplayFn">
          <mat-option *ngFor="let operaattori of verkkolaskuoperaattorit | async" [value]="operaattori">{{operaattori.tunnus}} {{operaattori.nimi}}</mat-option>
        </mat-autocomplete>

        <mat-form-field>
          <mat-label>{{ 'asiakas.verkkolaskuosoite' | translate | async }}</mat-label>
          <input type="text" [name]="namename" formControlName="elaskuosoite" matInput data-lpignore="true" />
          <mat-error>{{fieldErrors.elaskuosoite}}</mat-error>
        </mat-form-field>
      </div>

      <div style="margin-bottom: 0.5em; margin-top: 2em;">
        <h3>{{'asiakas.vastaanottaja' | translate | async}}</h3>
        <mat-chip-grid #vastaanottajaChips style="margin-bottom: 0.5em">
          <mat-chip-option *ngFor="let vastaanottaja of asiakas.laskunVastaanottajat" [removable]="true" (removed)="poistaAsiakas(vastaanottaja)">
            {{vastaanottaja}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-grid>
      </div>
      <mat-form-field>
        <mat-label>{{ 'lasku.sposti.lisaavastaanottaja' | translate | async }}</mat-label>
        <input type="email" autocomplete="kirjanpitotietoarkki" [name]="namename" [matChipInputFor]="vastaanottajaChips" (blur)="lisaa()" formControlName="lisaavastaanottaja" (matChipInputTokenEnd)="lisaaAsiakas($event)" matInput matInput data-lpignore="true" />
        <!-- <mat-icon matSuffix (click)="lisaa()">add</mat-icon> -->
        <mat-error>{{fieldErrors.lisaavastaanottaja}}</mat-error>
      </mat-form-field>

      <div style="display: flex; justify-content: space-between;">
        <button class="secondarybutton" mat-button routerLink="/laskutus/asiakkaat" type="button">{{'yleiset.peruuta' | translate | async}}</button>
        <button class="secondarybutton" *ngIf="asiakas?.avain && asiakas?.avain !== 'uusi'" mat-button (click)="poista()" type="button">{{'yleiset.poista' | translate | async}}</button>
        <button class="primarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
      </div>

    </div>

  </form>

</div>