import { Injectable, ErrorHandler } from '@angular/core'

import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { DataSource } from '@angular/cdk/table'

import { AsiakkaanTuotteenSorttaukset } from '../../_jaettu/service/lasku/lasku.indeksoija'
import { AsiakkaanTuote } from '../../_jaettu/model/lasku'
import { KayttajaService } from '../../_angular/service/kayttaja.service'

import { Observable, BehaviorSubject, combineLatest, of as observableOf } from 'rxjs'
import { map, tap, switchMap } from 'rxjs/operators'

import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { DocumentSnapshot } from 'firebase/firestore'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

interface Hakutiedot {
  vapaahaku: string
  sivutustiedot: PageEvent
  lajittelutiedot: Sort
}
@Injectable()
export class TuotteetFirestoreDataSource extends DataSource<AsiakkaanTuote> {

  private rivienMaaraSubject = new BehaviorSubject<number>(Number.MAX_SAFE_INTEGER)
  public rivienMaaraObservable: Observable<number> = this.rivienMaaraSubject.asObservable()

  private internalAsiakkaanTuotteetObservable: Observable<AsiakkaanTuote[]>
  public asiakkaanTuotteetObservable: Observable<AsiakkaanTuote[]>

  private lataaSubject = new BehaviorSubject<boolean>(true)
  public lataaObservable: Observable<boolean> = this.lataaSubject.asObservable()

  private lastVisible: DocumentSnapshot<AsiakkaanTuote>[] = []

  private oletussivutustiedot: PageEvent = {
    length: Number.MAX_SAFE_INTEGER,
    pageIndex: 0,
    pageSize: 20
  }

  private oletuslajittelutiedot: Sort = {
    active: 'nimi',
    direction: 'asc'
  }

  private oletusHakutiedot: Hakutiedot = {
    vapaahaku: null,
    lajittelutiedot: this.kopioiLajittelutiedot(this.oletuslajittelutiedot),
    sivutustiedot: this.kopioiSivutustiedot(this.oletussivutustiedot)
  }

  private hakutiedotSubject = new BehaviorSubject<Hakutiedot>(this.oletusHakutiedot)
  public hakutiedotObservable = this.hakutiedotSubject.asObservable()

  constructor(
    private errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    super()

    this.internalAsiakkaanTuotteetObservable = combineLatest([this.hakutiedotObservable, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      tap(() => {
        this.lataaSubject.next(true)
      }),
      switchMap(([tiedot, kayttaja]) => {

        if (!kayttaja) {
          this.resetSearchToDefaults()
          return observableOf<AsiakkaanTuote[]>([])
        }

        const vapaahaku = tiedot.vapaahaku
        const sort = tiedot.lajittelutiedot
        const pageEvent = tiedot.sivutustiedot

        const limitSize = pageEvent.pageSize + 1
        const sorttaus = AsiakkaanTuotteenSorttaukset.KAIKKI[sort.active]
        const vapaahakuS = vapaahaku ? vapaahaku : ''
        const searchProperty = 'haku.' + sorttaus.tunniste + vapaahakuS

        const uri = '/laskut/' + kayttaja.asiakasId + '/tuotteet'

        let q = this._firebaseLemonaid.firestoreCollection<AsiakkaanTuote>(uri)

        if (sorttaus.tyyppi === 'string') {
          q = q.whereFree(searchProperty, '>', '')
        } else if (sorttaus.tyyppi === 'number') {
          q = q.whereFree(searchProperty, '>', -99999999999)
        } else if (sorttaus.tyyppi === 'date') {
          q = q.whereFree(searchProperty, '>', -9999)
        }

        const direction = sort.direction === 'asc' ? 'asc' : 'desc'
        q = q.orderByFree(searchProperty, direction)

        if (pageEvent.pageIndex > 0 && pageEvent.pageIndex - 1 in this.lastVisible) {
          q = q.startAfter(this.lastVisible[pageEvent.pageIndex - 1]).limit(limitSize)
        } else {
          q = q.limit(limitSize)
        }

        return q.listenSnapshots().pipe(
          map(snapshots => {
            if (snapshots.length === limitSize) {
              this.rivienMaaraSubject.next(Number.MAX_SAFE_INTEGER)
              snapshots.pop()
            } else {
              this.rivienMaaraSubject.next(pageEvent.pageSize * pageEvent.pageIndex + snapshots.length)
            }
            if (snapshots.length > 0) {
              this.lastVisible[pageEvent.pageIndex] = snapshots[snapshots.length - 1]
            }
            const asiakkaat: AsiakkaanTuote[] = []
            for (const snapshot of snapshots) {
              const asiakas = snapshot.data()
              asiakas.$key = snapshot.id
              asiakkaat.push(asiakas)
            }
            return asiakkaat
          }),
          tap(() => {
            setTimeout(() => { this.lataaSubject.next(false) }, 0)
          })
        )

      }),
      lemonShare()
    )

    this.asiakkaanTuotteetObservable = combineLatest([this.internalAsiakkaanTuotteetObservable, this.lataaObservable]).pipe(
      map(([asiakkaanTuotteet, lataa]) => {
        if (lataa) {
          return []
        }
        return asiakkaanTuotteet
      })
    )

  }

  getSearch(): string {
    return this.hakutiedotSubject.value.vapaahaku
  }

  getPage(): PageEvent {
    return this.hakutiedotSubject.value.sivutustiedot
  }

  getSort() {
    return this.hakutiedotSubject.value.lajittelutiedot
  }

  changePage(pageEvent: PageEvent): void {
    const hakutiedot = this.hakutiedotSubject.value
    hakutiedot.sivutustiedot = pageEvent
    this.hakutiedotSubject.next(hakutiedot)
  }

  changeSort(sort: Sort): void {
    const hakutiedot = this.hakutiedotSubject.value
    this.valmistauduHakuehdonMuutokseen(hakutiedot)
    hakutiedot.lajittelutiedot = sort
    this.hakutiedotSubject.next(hakutiedot)
  }

  changeSearch(vapaahaku: string): void {
    const hakutiedot = this.hakutiedotSubject.value
    if (hakutiedot.vapaahaku !== vapaahaku) {
      this.valmistauduHakuehdonMuutokseen(hakutiedot)
      hakutiedot.vapaahaku = vapaahaku
      this.hakutiedotSubject.next(hakutiedot)
    }
  }

  connect(): Observable<AsiakkaanTuote[]> {
    return this.asiakkaanTuotteetObservable
  }

  disconnect() {

  }

  private kopioiSivutustiedot(lahde: PageEvent): PageEvent {
    return {
      length: lahde.length,
      pageIndex: lahde.pageIndex,
      pageSize: lahde.pageSize,
      previousPageIndex: lahde.previousPageIndex
    }
  }

  private kopioiLajittelutiedot(lahde: Sort): Sort {
    return {
      active: lahde.active,
      direction: lahde.direction
    }
  }

  resetSearchToDefaults() {

    const hakutiedot = this.hakutiedotSubject.value
    const lajittelutiedot = this.kopioiLajittelutiedot(this.oletuslajittelutiedot)
    const sivutustiedot = this.kopioiSivutustiedot(this.oletussivutustiedot)

    // If this is not here, a forever loop is reached when user logs out.
    if (
      hakutiedot.vapaahaku !== null ||
      JSON.stringify(hakutiedot.lajittelutiedot) !== JSON.stringify(lajittelutiedot) ||
      JSON.stringify(hakutiedot.sivutustiedot) !== JSON.stringify(sivutustiedot)
    ) {
      hakutiedot.vapaahaku = null
      hakutiedot.lajittelutiedot = lajittelutiedot
      hakutiedot.sivutustiedot = sivutustiedot
      this.lastVisible = []
      this.rivienMaaraSubject.next(Number.MAX_SAFE_INTEGER)
      this.hakutiedotSubject.next(hakutiedot)
    }

  }

  private valmistauduHakuehdonMuutokseen(hakutiedot: Hakutiedot) {
    this.lastVisible = []
    hakutiedot.sivutustiedot.pageIndex = 0
    delete hakutiedot.sivutustiedot.previousPageIndex
    hakutiedot.sivutustiedot.length = Number.MAX_SAFE_INTEGER
    this.rivienMaaraSubject.next(Number.MAX_SAFE_INTEGER)
  }

}
