import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export interface LoginInputData {email: string, password: string, loginCodeId: string}

@Injectable()
export class LoginService {
  prevView: string

  private sharedLoginCredentialsSubject = new BehaviorSubject<LoginInputData>({email: '', password: '', loginCodeId: ''})
  currentLoginCredentialsObservable = this.sharedLoginCredentialsSubject.asObservable()

  constructor() { }

  changeInput(data: LoginInputData) {
    this.sharedLoginCredentialsSubject.next(data)
  }

  clearCredentials() {
    this.sharedLoginCredentialsSubject.next({email: '', password: '', loginCodeId: ''})
  }
  setPrevView(setValue: string) {
    this.prevView = setValue
  }

}
