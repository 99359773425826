import { Injectable } from '@angular/core'

@Injectable()
export class UusiSopimusDialogService {
  private _postponeStarted: Date = null

  constructor(
  ) { }

  postpone() {
    this._postponeStarted = new Date()
  }

  postponedRecently(): boolean {
    if (!this._postponeStarted) {
      return false
    }
    const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000
    return new Date().getTime() - this._postponeStarted.getTime() < twelveHoursInMilliseconds
  }

}
