import { Component, OnInit, OnDestroy, Input, ErrorHandler } from '@angular/core'

import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'

import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { ReportImagesService } from './report-images.service'

import { CustomerHallituksenJasenRole, Tilikausi } from 'app/_jaettu/model/kayttaja'
import { DownloadTilinpaatosPdfRequest, DownloadTilinpaatosPdfResponse, SaveTilinpaatosSignersRequest, SaveTilinpaatosSignersResponse, SignTilinpaatosRequest, TilinpaatosHallitusUser, TilinpaatosUserData, TilinpaatosMetadata, TilinpaatosSigningData, TilinpaatosHallitusMultiRoleUser, SignTilinpaatosResponse, DownloadHallituksenPoytakirjaPdfRequest } from '../_jaettu/model/tilinpaatos'

import { map, Observable, Subject, switchMap, of as observableOf, combineLatest, takeUntil, firstValueFrom, BehaviorSubject, takeWhile } from 'rxjs'
import { PdfService } from 'app/_angular/service/pdf.service'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

import { DocumentSnapshot } from 'firebase/firestore'
import { NumberDate, Timestamp } from 'app/_shared-core/model/common'
import { TilinpaatosUriService } from 'app/_jaettu/service/tilinpaatos-uri.service'
import { ImageCacheClass } from './tilinpaatokset.component'
import { MatDialog } from '@angular/material/dialog'
import { TilinpaatosSignedDialog, TilinpaatosSignedDialogData } from './tilinpaatos-signed.dialog'
import { TilinpaatosAreYouSureDialog } from './tilinpaatos-are-you-sure.dialog'
import { lemonShare } from '../_jaettu-angular/_rxjs/lemon-share.operator'

import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'
import { DomSanitizer } from '@angular/platform-browser'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

interface ViewData {
  tilinpaatosHasBeenExported: boolean
  editMode: boolean
  pdfPages: ImageCacheClass[]
  signed: boolean
  startPdfImageRendering: () => Promise<void>
}

interface TilinpaatosHallitusUserPlusSigningStatus extends TilinpaatosHallitusMultiRoleUser {
  /** Concatenate roles into one string! */
  rolesLocalized: string
  signedAt: Timestamp
  isCurrentUser: boolean
}

interface BoardMemberGroup {
  name: FormControl<string>
  role: FormControl<CustomerHallituksenJasenRole>
  lemonaidUid: FormControl<string>
}

interface TilinpaatosFormGroup {
  boardMembers: FormArray<FormGroup<BoardMemberGroup>>
  boardMinutesDate: FormControl<Date>
  boardMinutesNumber: FormControl<string>
}

@Component({
  selector: 'app-reports-tilinpaatos',
  templateUrl: './tilinpaatos.component.html',
  styleUrls: ['./tilinpaatokset.component.css']
})
export class TilinpaatosComponent implements OnInit, OnDestroy {

  @Input() selectedTilikausiObservable: Observable<Tilikausi>
  @Input() tilinpaatosUsersObservable: Observable<TilinpaatosUserData>
  @Input() tilinpaatosSnapshotObservable: Observable<DocumentSnapshot<TilinpaatosMetadata>>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _saveStarted: boolean = false

  commonError: string
  form: FormGroup<TilinpaatosFormGroup>
  tilinpaatosSignersHaveBeenApproved: boolean
  approvedSignersObservable: Observable<TilinpaatosHallitusUserPlusSigningStatus[]>
  allBoardMemberRolesObservable: Observable<{ name: string, key: CustomerHallituksenJasenRole }[]>
  tilinpaatosViewDataObservable: Observable<ViewData>
  poytakirjaViewDataObservable: Observable<ViewData>

  private _tilinpaatosMetadataObservable: Observable<TilinpaatosMetadata>
  private _tilinpaatosSigningDataObservable: Observable<TilinpaatosSigningData>
  private _tilinpaatosPdfAsBase64Observable: Observable<string>
  private _hallituksenPoytakirjaPdfObservable: Observable<string>
  private _editingSignersSubject: BehaviorSubject<boolean> = new BehaviorSubject(null)


  // tilinpaatosHasBeenExportedObservable: Observable<HasBeenExported>
  // pdfPagesObservable: Observable<ImageCacheClass[]>
  // tilinpaatosPdfSignedAndUpdatedObservable: Observable<{ signed: boolean }>

  tilinpaatosSigningDisabledObservable: Observable<boolean>
  tilinpaatosMissingSignaturesObservable: Observable<boolean>

  constructor(
    private _kayttajaService: KayttajaService,
    private _translationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _errorHandler: ErrorHandler,
    private _validationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _reportImagesService: ReportImagesService,
    private _pdfService: PdfService,
    private _tilinpaatosUriService: TilinpaatosUriService,
    private _dialog: MatDialog,
    private _domSanitizer: DomSanitizer,
    private _dateService: DateService,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    this._tilinpaatosMetadataObservable = this.tilinpaatosSnapshotObservable.pipe(
      map(snap => snap?.data() || null)
    )

    const tilinpaatosSigningDataDocObservable = combineLatest([this._tilinpaatosMetadataObservable, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      switchMap(([metadata, kayttaja]) => {
        if (!metadata || !kayttaja) {
          return observableOf<DocumentSnapshot<TilinpaatosSigningData>>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<TilinpaatosSigningData>(this._tilinpaatosUriService.getTilinpaatosSignaturesDocUri(kayttaja.asiakasAvain, metadata.viimeisinTilinpaatosVersioAvain)).listenSnap()
      })
    )

    this._tilinpaatosSigningDataObservable = tilinpaatosSigningDataDocObservable.pipe(
      map(snap => snap?.data() ?? null)
    )

    this.tilinpaatosMissingSignaturesObservable = this._tilinpaatosSigningDataObservable.pipe(
      map(signingData => {
        if (!signingData?.signs?.length) {
          return true
        }
        return signingData.signs.filter(sign => !sign.signedAt).length > 0
      })
    )

    this._tilinpaatosPdfAsBase64Observable = combineLatest([this.tilinpaatosSnapshotObservable, this._translationService.currentLanguageObservable]).pipe(
      switchMap(([metadataSnap, lang]) => {
        if (metadataSnap?.exists() && lang) {
          const overrideLang = 'fi' // TODO! Remove once ENG translation is live
          const metadata = metadataSnap.data()
          const reqData: DownloadTilinpaatosPdfRequest = {
            uri: metadata.tilinpaatosPdfienUrit[metadata.viimeisinTilinpaatosVersioAvain + '_' + overrideLang]
          }
          return this._firebaseLemonaid.functionsCall<DownloadTilinpaatosPdfRequest, DownloadTilinpaatosPdfResponse>('tilinpaatosPdfDownload', reqData).then(resp => {
            if (!resp) {
              return null
            }
            if (resp.e) {
              this._errorHandler.handleError(new Error('Tilinpäätös PDF download failed! ' + resp.e))
              return null
            }
            return resp.base64Pdf
          })
        }
        return observableOf<string>(null)
      })
    )

    const tilinpaatosPdfPagesObservable = this._tilinpaatosPdfAsBase64Observable.pipe(
      switchMap(async base64String => {
        if (!base64String) {
          return []
        }
        const arrayBuffer = this._fileSaverService.base64StringToUint8Array(base64String)
        const wasmSettings = await this._pdfService.yritaParsiaPdf(arrayBuffer)
        const sivut: ImageCacheClass[] = []
        for (let kuvanIndeksi = 1; kuvanIndeksi <= wasmSettings.document.getPageCount(); kuvanIndeksi++) {
          // Create firestore tosite image metadata
          sivut.push(new ImageCacheClass(this._reportImagesService, wasmSettings, this._domSanitizer, kuvanIndeksi))
        }
        return sivut
      })
    )

    this.allBoardMemberRolesObservable = this._translationService.currentLanguageObservable.pipe(
      map(lang => {
        if (!lang) {
          return []
        }
        return [
          { name: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.toimitusjohtaja', lang), key: 'toimitusjohtaja' },
          { name: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-jasen', lang), key: 'jasen' },
          { name: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-puheenjohtaja', lang), key: 'puheenjohtaja' },
          { name: this._translationService.lokalisoiKielella('lemonaid-sopimus-dialog.kyc.hallituksen-varajasen', lang), key: 'varajasen' }
        ]
      })
    )

    this.approvedSignersObservable = combineLatest([this._tilinpaatosSigningDataObservable, this.allBoardMemberRolesObservable, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      map(([signingData, allRolesLocalized, kayttaja]) => {
        if (!kayttaja || !allRolesLocalized || !signingData?.signs?.length) {
          return []
        }
        const output: TilinpaatosHallitusUserPlusSigningStatus[] = []
        for (const signing of signingData.signs) {
          const user: TilinpaatosHallitusUserPlusSigningStatus = {
            lemonaidUid: signing.user.lemonaidUid,
            name: signing.user.name,
            roles: signing.user.roles,
            rolesLocalized: signing.user.roles.map(role => allRolesLocalized.find(localized => localized.key === role)?.name || '').join('\n'),
            signedAt: signing.signedAt,
            isCurrentUser: kayttaja.uid === signing.user.lemonaidUid
          }
          output.push(user)
        }
        return output
      }),
      lemonShare()
    )

    this._hallituksenPoytakirjaPdfObservable = combineLatest([this.selectedTilikausiObservable, this._tilinpaatosSigningDataObservable, this._translationService.currentLanguageObservable]).pipe(
      switchMap(([tilikausi, signingData, lang]) => {
        if (signingData?.boardMinutesNumber && lang) {
          const overrideLang = 'fi' // TODO! Remove once ENG translation is live

          const reqData: DownloadHallituksenPoytakirjaPdfRequest = {
            tilikausiAvain: tilikausi.avain,
            kieli: overrideLang
          }
          return this._firebaseLemonaid.functionsCall<DownloadHallituksenPoytakirjaPdfRequest, DownloadTilinpaatosPdfResponse>('hallituksenPoytakirjaPdfDownload', reqData).then(resp => {
            if (!resp) {
              return null
            }
            if (resp.e) {
              this._errorHandler.handleError(new Error('Tilinpäätös PDF download failed! ' + resp.e))
              return null
            }
            return resp.base64Pdf
          })
        }
        return observableOf<string>(null)
      })
    )

    const hallituksenPoytakirjaPdfPagesObservable = this._hallituksenPoytakirjaPdfObservable.pipe(
      switchMap(async base64String => {
        if (!base64String) {
          return []
        }
        const arrayBuffer = this._fileSaverService.base64StringToUint8Array(base64String)
        const wasmSettings = await this._pdfService.yritaParsiaPdf(arrayBuffer)
        const sivut: ImageCacheClass[] = []
        for (let kuvanIndeksi = 1; kuvanIndeksi <= wasmSettings.document.getPageCount(); kuvanIndeksi++) {
          // Create firestore tosite image metadata
          sivut.push(new ImageCacheClass(this._reportImagesService, wasmSettings, this._domSanitizer, kuvanIndeksi))
        }
        return sivut
      })
    )

    this.poytakirjaViewDataObservable = combineLatest([hallituksenPoytakirjaPdfPagesObservable, this._editingSignersSubject]).pipe(
      map(([pdfPages, editing]) => {
        if (!pdfPages) {
          return null
        }
        const viewData: ViewData = {
          editMode: editing,
          pdfPages: pdfPages,
          signed: true,
          tilinpaatosHasBeenExported: null,
          startPdfImageRendering: async () => {
            await this._sleep(750)
            for (const page of pdfPages) {
              await page.startImageRendering()
              await this._sleep(200)
            }
          }
        }
        viewData.startPdfImageRendering()
        return viewData
      })
    )

    this.tilinpaatosViewDataObservable = combineLatest([this.tilinpaatosSnapshotObservable, tilinpaatosPdfPagesObservable, this._editingSignersSubject]).pipe(
      map(([metadataDoc, pdfPages, editing]) => {
        if (!metadataDoc || !pdfPages) {
          return null
        }
        const metadata = metadataDoc.data()
        const viewData: ViewData = {
          editMode: editing,
          pdfPages: pdfPages,
          signed: !!metadata?.pdfsAreSigned,
          tilinpaatosHasBeenExported: metadataDoc.exists(),
          startPdfImageRendering: async () => {
            await this._sleep(750)
            for (const page of pdfPages) {
              await page.startImageRendering()
              await this._sleep(200)
            }
          }
        }
        viewData.startPdfImageRendering()
        return viewData
      })
    )

    // this.tilinpaatosPdfSignedAndUpdatedObservable = this.tilinpaatosSnapshotObservable.pipe(
    //   map(metadataDoc => {
    //     if (!metadataDoc) {
    //       return null
    //     }
    //     const metadata = metadataDoc.data()
    //     return { signed: !!metadata?.pdfsAreSigned }
    //   })
    // )

    // this.tilinpaatosHasBeenExportedObservable = this.tilinpaatosSnapshotObservable.pipe(
    //   map(metadataSnap => {
    //     if (metadataSnap) {
    //       const res: HasBeenExported = {
    //         exported: metadataSnap.exists()
    //       }
    //       return res
    //     }
    //     return null
    //   })
    // )

    this.form = new FormGroup<TilinpaatosFormGroup>({
      'boardMembers': new FormArray<FormGroup<BoardMemberGroup>>([], [LemonaidValidators.vahintaanYksiArrayssaValidator]),
      'boardMinutesDate': new FormControl<Date>(null, Validators.required),
      'boardMinutesNumber': new FormControl<string>(null, Validators.required)
    })

    this._tilinpaatosMetadataObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(metadata => {

      const currentVersionCreatedAt = metadata?.tilinpaatosVersioidenAikaleimat[metadata?.viimeisinTilinpaatosVersioAvain]
      this.form.get('boardMinutesDate').setValidators([FormValidators.minDateValidator(this._dateService.lisaaPaivia(currentVersionCreatedAt?.toDate() ?? new Date(), -1)), Validators.required])
    })


    const boardMembersObservable: Observable<TilinpaatosHallitusUser[]> = this.tilinpaatosUsersObservable.pipe(
      map(kyc => {
        return kyc?.hallitusUsers ?? []
      })
    )

    boardMembersObservable.pipe(
      map(boardMembers => {
        if (!boardMembers?.length) {
          return []
        }
        return boardMembers.map(member => {
          const multiRoleUser: { name: string, roles: CustomerHallituksenJasenRole[], lemonaidUid: string } = {
            name: member.etunimi + ' ' + member.sukunimi,
            roles: [member.role],
            lemonaidUid: member.lemonaidUid
          }
          return multiRoleUser
        })
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(signers => {
      this.boardMembersArray.clear()
      if (!signers.length) {
        return
      }
      for (const signer of signers) {
        for (const role of signer.roles) {
          const group = this.createBoardMemberFormGroup(signer, role)
          this.boardMembersArray.push(group)
        }
      }
    })

    this.tilinpaatosSigningDisabledObservable = boardMembersObservable.pipe(
      map(members => {
        return !!members.find(member => member.role !== 'varajasen' && !member.lemonaidUid)
      })
    )

    this._tilinpaatosSigningDataObservable.pipe(
      takeWhile(data => !data, true)
    ).subscribe((signingData) => {
      if (!signingData) {
        this._editingSignersSubject.next(true)
        this.form.get('boardMinutesDate').setValue(new Date())
        this.form.get('boardMinutesNumber').setValue('1/' + new Date().getFullYear())
      } else {
        this._editingSignersSubject.next(!signingData.signs?.length)
        this.form.get('boardMinutesDate').setValue(this._dateService.numberToDate(signingData.boardMinutesDate) ?? new Date())
        this.form.get('boardMinutesNumber').setValue(signingData.boardMinutesNumber ?? ('1/' + new Date().getFullYear()))
      }
    })

  }

  protected _sleep(millis: number): Promise<any> {
    return new Promise(resolve => {
      setTimeout(resolve, millis)
    })
  }

  get boardMembersArray(): FormArray<FormGroup<BoardMemberGroup>> {
    return this.form.get('boardMembers') as FormArray<FormGroup<BoardMemberGroup>>
  }

  get boardMembersControls(): FormGroup<BoardMemberGroup>[] {
    return this.boardMembersArray.controls
  }

  editSigners() {
    this._editingSignersSubject.next(true)
  }

  createBoardMemberFormGroup(boardMember: TilinpaatosHallitusMultiRoleUser, role: CustomerHallituksenJasenRole): FormGroup<BoardMemberGroup> {

    const group = new FormGroup<BoardMemberGroup>({
      // 'selected': new FormControl(isSelected),
      'name': new FormControl<string>(boardMember.name),
      'role': new FormControl<CustomerHallituksenJasenRole>(role),
      'lemonaidUid': new FormControl<string>({ value: boardMember.lemonaidUid, disabled: true })
    })

    if (!boardMember.lemonaidUid) {
      group.disable()
    }

    // group.get('selected').valueChanges.pipe(
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(val => {
    //   if (!val) {
    //     const idx = this._selectedSigners.findIndex(signer => boardMember.lemonaidUid === signer.lemonaidUid)
    //     this._selectedSigners.splice(idx, 1)
    //   } else {

    //     // Double-check that the signer hasn't been added to the array yet
    //     const signerNotYetCached = this._selectedSigners.findIndex(signer => boardMember.lemonaidUid === signer.lemonaidUid) === -1
    //     if (signerNotYetCached) {
    //       this._selectedSigners.push(boardMember)
    //     }
    //   }
    // })

    group.get('role').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      if (val !== 'varajasen') {
        this.commonError = null
      }
    })

    return group
  }

  async saveSignature() {
    if (!!this._saveStarted) {
      return
    }

    const isSure = await firstValueFrom(this._dialog.open(TilinpaatosAreYouSureDialog).afterClosed())
    if (!isSure) {
      return
    }

    this._saveStarted = true

    try {

      this._ladataanService.aloitaLataaminen()

      const metadataPromise = firstValueFrom(this._tilinpaatosMetadataObservable)
      const selectedTilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)
      const signaturesDataPromise = firstValueFrom(this._tilinpaatosSigningDataObservable)

      const [metadata, selectedTilikausi, signaturesData] = await Promise.all([metadataPromise, selectedTilikausiPromise, signaturesDataPromise])

      const request: SignTilinpaatosRequest = {
        tilinpaatosAvain: metadata.viimeisinTilinpaatosVersioAvain,
        tilikausiAvain: selectedTilikausi.avain
      }

      const resp = await this._firebaseLemonaid.functionsCall<SignTilinpaatosRequest, SignTilinpaatosResponse>('tilinpaatosSigning', request)
      if (!resp || resp.e) {
        this._errorHandler.handleError('Tilinpäätös signing failed! ' + (resp?.e || 'No response'))
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      } else {
        const isLastSigner = signaturesData.signs.filter(signer => !signer.signedAt).length <= 1
        const dialogData: TilinpaatosSignedDialogData = {
          tilintarkastetaan: metadata.tilintarkastetaan,
          isLastSigner: isLastSigner
        }
        this._dialog.open(TilinpaatosSignedDialog, { data: dialogData })
      }

    } catch (err: any) {
      this._errorHandler.handleError(err)
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._saveStarted = false
    }

  }

  async approveSigners() {
    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (!!this._saveStarted) {
      return
    }

    try {

      const selectedSigners = this._getSelectedSignersFromFormArray(this.boardMembersControls)
      if (!selectedSigners?.length) {
        this.commonError = this._translationService.lokalisoi('reports-lemonaid.tilinpaatos-no-signers-error')
        return
      }
      this._saveStarted = true

      this._ladataanService.aloitaLataaminen()

      const metadata = await firstValueFrom(this._tilinpaatosMetadataObservable)

      if (!metadata) {
        throw new Error('Cannot save tilinpäätös signers, no metadata found.')
      }

      const boardMinutesDate: NumberDate = this.form.get('boardMinutesDate').value ? this._dateService.dateToNumber(this.form.get('boardMinutesDate').value) : null
      const boardMinutesNumber: string = this.form.get('boardMinutesNumber').value

      const request: SaveTilinpaatosSignersRequest = {
        tilinpaatosAvain: metadata.viimeisinTilinpaatosVersioAvain,
        selectedSigners: selectedSigners,
        boardMinutesDate: boardMinutesDate,
        boardMinutesNumber: boardMinutesNumber
      }

      const resp = await this._firebaseLemonaid.functionsCall<SaveTilinpaatosSignersRequest, SaveTilinpaatosSignersResponse>('tilinpaatosSaveSigners', request)
      if (!resp || resp.e) {
        this._errorHandler.handleError('Tilinpäätös signers save failed! ' + (resp?.e || 'No response'))
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      } else {
        this._editingSignersSubject.next(false)
      }

    } catch (err: any) {
      this._errorHandler.handleError(err)
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._saveStarted = false
    }

  }

  async downloadPdf(type: 'tilinpaatos' | 'poytakirja') {

    this._ladataanService.aloitaLataaminen()

    try {

      const base64FilePromise = type === 'tilinpaatos' ? firstValueFrom(this._tilinpaatosPdfAsBase64Observable) : firstValueFrom(this._hallituksenPoytakirjaPdfObservable)
      const asiakasPromise = firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)
      const signingDataPromise = type === 'poytakirja' ? firstValueFrom(this._tilinpaatosSigningDataObservable) : Promise.resolve(null)

      const [base64File, asiakas, tilikausi, signingData] = await Promise.all([base64FilePromise, asiakasPromise, tilikausiPromise, signingDataPromise])

      if (!base64File) {
        return
      }

      let fileName = null
      if (type === 'tilinpaatos') {
        fileName = asiakas.nimi + ' - tilinpäätös - ' + tilikausi.loppuu.year + '.pdf'

      } else if (type === 'poytakirja') {
        fileName = asiakas.nimi + ' hallituksen pöytäkirja' + ' ' + signingData.boardMinutesNumber + '.pdf'
      }

      this._fileSaverService.saveBase64As(base64File, fileName, 'pdf')

    } catch (err: any) {
      this._errorHandler.handleError(err)
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')

    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private _getSelectedSignersFromFormArray(controls: AbstractControl[]): TilinpaatosHallitusMultiRoleUser[] {
    const output: TilinpaatosHallitusMultiRoleUser[] = []
    for (const control of controls) {
      if (control.disabled) {
        continue
      }

      const name = control.get('name').value
      const lemonaidUid = control.get('lemonaidUid').value
      const role = control.get('role').value as CustomerHallituksenJasenRole

      const addAsSigner = !!lemonaidUid && role !== 'varajasen'
      if (addAsSigner) {
        const alreadyAdded = output.find(added => added.lemonaidUid === lemonaidUid)
        if (alreadyAdded) {
          const selectedRole = role
          if (!alreadyAdded.roles.includes(selectedRole)) {
            alreadyAdded.roles.push(selectedRole)
          }
        } else {
          const user: TilinpaatosHallitusMultiRoleUser = {
            lemonaidUid: lemonaidUid,
            name: name,
            roles: [role]
          }
          output.push(user)
        }
      }
    }
    return output
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }
}
